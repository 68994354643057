import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const couponsApi = createApi({
  reducerPath: "couponsApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Coupons"],

  endpoints: (builder) => ({

    getCoupons: builder.query({
      query: (args) => ({
        url: `coupons?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["Coupons"],
    }),

    getCouponData: builder.query({
      query: ({ showCouponId }) => ({
        url: `coupons/${showCouponId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Coupons", id }],
    }),

    couponsChangeStatusAll: builder.mutation({
      query: ({ id }) => ({
        url: `couponchangeSatusall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Coupons"],
    }),
    
    couponsChangeStatusAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `couponchangeSatusall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Coupons"],
    }),

    couponsDeleteAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `coupondeleteall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Coupons"],
    }),

    updateCoupons: builder.mutation({
      query: ({ couponId, body }) => {
        return {
          url: `coupons/${couponId}`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Coupons"],
    }),

    addNewCoupon: builder.mutation({
      query: ({ body }) => {
        return {
          url: `coupons`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Coupons"],
    }),

    couponDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `coupondeleteall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Coupons"],
    }),

    filterCoupons: builder.mutation({
      query: ({ status, code }) => ({
        url: `coupons?status=${status}&code=${code}`,
				method: "GET",
			}),
		}),




    

  }),
});

// Export endpoints and hooks
export const {
  useGetCouponsQuery,
  useGetCouponDataQuery,
  useCouponsChangeStatusAllMutation,
  useCouponsChangeStatusAllByParamsMutation,
  useCouponsDeleteAllByParamsMutation,
  useUpdateCouponsMutation,
  useAddNewCouponMutation,
  useCouponDeleteItemMutation,
  useFilterCouponsMutation,
} = couponsApi;
