import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
import Context from "../store/context";

const HTTP_UNAUTHORIZED = 401,
	HTTP_FORBIDDEN = 403;

const AxiosInterceptors = ({ children }) => {
	const navigate = useNavigate();

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	// ====== axios_interceptors ===============
	useEffect(() => {
		const responseInterceptors = axios.interceptors.response.use(
			(success) => {
				if (
					success?.status === 200 &&
					["delete", "patch", "post", "pull", "put"].includes(
						success?.config?.method
					)
				) {
					setEndActionTitle(success?.data?.message?.ar);
				}
				return success;
			},
			(error) => {
				if (!error?.request?.responseURL.includes("login")) {
					if (JSON.parse(error?.response?.request?.response)?.message) {
						toast.error(
							JSON.parse(error?.response?.request?.response)?.message?.ar,
							{
								theme: "light",
							}
						);
					}

					// if the user in not login
					if (error.response.status === HTTP_UNAUTHORIZED) {
						localStorage.clear();
						navigate("/login");

						// if the user if not allowed to join any page
					} else if (error.response.status === HTTP_FORBIDDEN) {
						navigate("/");
					}
				}

				return Promise.reject(error);
			}
		);

		return () => {
			axios.interceptors.request.eject(responseInterceptors);
		};
	}, [navigate]);

	return <>{children}</>;
};

export default AxiosInterceptors;
