import React, { useState, useContext, useEffect } from "react";
import styles from "./EditCoupon.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

/* Context */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { BiRadioCircleMarked, BiRadioCircle } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { DataIcon } from "../../../../assets/Icons";

import {
	useGetCouponDataQuery,
	useUpdateCouponsMutation,
} from "../../../../RTK/apiSlices/couponsApi";

const EditCoupon = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [couponTypePercent, setCouponTypePercent] = useState(true);
	const [discountPercent, setDiscountPercent] = useState("");
	const [discountFixed, setDiscountFixed] = useState("");
	const [dateRangeFixed, setDateRangeFixed] = useState([null, null]);
	const [startDateFixed, endDateFixed] = dateRangeFixed;
	const [dateRangePercent, setDateRangePercent] = useState([null, null]);
	const [startDatePercent, endDatePercent] = dateRangePercent;
	const [totalRedemptionsPercent, setTotalRedemptionsPercent] = useState("");
	const [totalRedemptionsFixed, setTotalRedemptionsFixed] = useState("");

	const [userRedemptionsPercent, setUserRedemptionsPercent] = useState("");
	const [userRedemptionsFixed, setUserRedemptionsFixed] = useState("");

	const { data: fetchedData, isLoading: loading } = useGetCouponDataQuery({
		showCouponId: id,
	});

	const [couponData, setCouponData] = useState({
		code: "",
	});

	// user_redemptions;
	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			code: "",
		},
	});

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setCouponData({
				...couponData,
				code: fetchedData?.Coupons?.code,
			});
			if (fetchedData?.Coupons?.discount_type === "مبلغ ثابت") {
				setCouponTypePercent(false);
				setDiscountFixed(fetchedData?.Coupons?.discount);
				setTotalRedemptionsFixed(fetchedData?.Coupons?.total_redemptions);
				setDateRangeFixed([
					moment(fetchedData?.Coupons?.start_at, moment.defaultFormat).toDate(),
					moment(
						fetchedData?.Coupons?.expire_date,
						moment.defaultFormat
					).toDate(),
				]);
				setDateRangePercent([null, null]);
			} else {
				setCouponTypePercent(true);
				setDiscountPercent(fetchedData?.Coupons?.discount);
				setTotalRedemptionsPercent(fetchedData?.Coupons?.total_redemptions);
				setUserRedemptionsPercent(fetchedData?.Coupons?.user_redemptions);
				setDateRangePercent([
					moment(fetchedData?.Coupons?.start_at, moment.defaultFormat).toDate(),
					moment(
						fetchedData?.Coupons?.expire_date,
						moment.defaultFormat
					).toDate(),
				]);
				setDateRangeFixed([null, null]);
			}
		}
	}, [fetchedData]);

	useEffect(() => {
		reset(couponData);
	}, [couponData, reset]);

	const [DataError, setDataError] = useState({
		code: "",
		discount_type: "",
		discount: "",
		start_at: "",
		expire_date: "",
		total_redemptions: "",
		user_redemptions: "",
	});
	const resetData = () => {
		setDataError({
			code: "",
			discount_type: "",
			discount: "",
			start_at: "",
			expire_date: "",
			total_redemptions: "",
			user_redemptions: "",
		});
	};

	const [updateCoupons] = useUpdateCouponsMutation();

	const updateCouponsHandel = async (formData) => {
		try {
			const res = await updateCoupons({
				couponId: id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/coupons");
			} else {
				setLoadingTitle(null);
				setDataError({
					code: res?.data?.message?.en?.code?.[0],
					discount_type: res?.data?.message?.en?.discount_type?.[0],
					discount: res?.data?.message?.en?.discount?.[0],
					start_at: res?.data?.message?.en?.start_at?.[0],
					expire_date: res?.data?.message?.en?.expire_date?.[0],
					total_redemptions: res?.data?.message?.en?.total_redemptions?.[0],
					user_redemptions: res?.data?.message?.en?.user_redemptions?.[0],
				});
				toast.error(res?.data?.message?.en?.code?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.discount_type?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.discount?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.start_at?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.expire_date?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.total_redemptions?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.user_redemptions?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleCoupon = (dataCoupon) => {
		resetData();
		const formatDate = "YYYY-MM-DD";
		const formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("discount_type", couponTypePercent ? "percent" : "fixed");
		formData.append(
			"discount",
			couponTypePercent ? parseInt(discountPercent) : parseInt(discountFixed)
		);
		formData.append(
			"start_at",
			couponTypePercent
				? moment(startDatePercent).format(formatDate)
				: moment(startDateFixed).format(formatDate)
		);
		formData.append(
			"expire_date",
			couponTypePercent
				? moment(endDatePercent).format(formatDate)
				: moment(endDateFixed).format(formatDate)
		);
		formData.append(
			"total_redemptions",
			couponTypePercent
				? parseInt(totalRedemptionsPercent)
				: parseInt(totalRedemptionsFixed)
		);

		formData.append(
			"user_redemptions",
			couponTypePercent
				? parseInt(userRedemptionsPercent)
				: parseInt(userRedemptionsFixed)
		);
		setLoadingTitle("جاري تعديل الكوبون");
		formData.append("code", dataCoupon?.code);
		updateCouponsHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | تعديل كوبون</title>
			</Helmet>
			<div className='absolute xl:pl-36 md:top-0 -top-[72px] right-0  z-10 xl:pt-5 xl:pr-24  w-full  h-auto p-4 py-6 md:bg-[#fafafa] bg-[#FFFFFF]'>
				<form onSubmit={handleSubmit(handleCoupon)} className='h-full'>
					<div className='flex flex-col items-start justify-between gap-4 mb-2 md:flex-row md:items-center'>
						<div className='flex items-center gap-2'>
							<div
								onClick={() => {
									navigate("/coupons");
								}}
								className={` ${styles.arrow_con}`}>
								<GoArrowRight
									style={{ color: "#02466A", fontSize: "1.2rem" }}
								/>
							</div>
							<h2
								className='md:text-[18px] text-[16px] font-medium'
								style={{ color: "#011723" }}>
								جدول الكوبونات
							</h2>
							<h3
								className='md:text-[18px] text-[16px] font-medium'
								style={{ color: "#67747B" }}>
								/ تعديل كوبون
							</h3>
						</div>
						<Button
							btnType={"submit"}
							className='md:w-[195px] w-full md:h-[56px] h-[45px] md:text-[20px] text-[18px]'
							type={"normal"}
							style={{ backgroundColor: "#B6BE34" }}
							textStyle={{ color: "#EFF9FF" }}>
							تعديل الكوبون
						</Button>
					</div>
					{loading ? (
						<div className='flex flex-col items-center justify-center w-full h-full py-8'>
							<CircularLoading />
						</div>
					) : (
						<>
							<div className={"mt-8 mb-8"}>
								<div className='flex flex-col gap-[10px]'>
									<h2 style={{ fontSize: "18px", color: "#67747B" }}>
										<AiFillStar
											style={{
												display: "inline-block",
												marginLeft: "0.5rem",
												color: "red",
											}}></AiFillStar>
										كود التخفيض<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<Controller
											name={"code"}
											control={control}
											rules={{
												required: "حقل كود التخفيض مطلوب",
												pattern: {
													value: /^[A-Za-z0-9]+$/i,
													message: "يجب ان يتكون الاسم من أحرف انجليزية وارقام",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<input
													className='p-4 rounded-lg outline-none'
													style={{
														border: "1px solid #A7A7A7",
														backgroundColor: "#FFFFFF",
														width: "376px",
														color: "#0099FB",
													}}
													type='text'
													placeholder='أدخل حروف انجليزية وأرقام بدون مسافة'
													name='code'
													value={value}
													onChange={(e) => {
														onChange(e.target.value);
													}}
												/>
											)}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.code}
											{errors?.code && errors.code.message}
										</span>
									</div>
								</div>
								<h2
									style={{ fontSize: "18px", color: "#67747B" }}
									className='my-5'>
									اختر نوع الخصم<span className='text-red-500'>*</span>
								</h2>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<div className='flex flex-col h-full gap-6 xl:flex-row xl:gap-5'>
										<div
											style={{ boxShadow: "0px 3px 6px #00000029" }}
											className='flex-1 bg-white rounded-lg'>
											<div
												className='flex items-center w-full gap-2 px-4 rounded-lg cursor-pointer'
												style={{
													backgroundColor: couponTypePercent
														? "#1DBBBE"
														: "#EFF9FF",
													height: "75px",
												}}
												onClick={() => {
													setCouponTypePercent(true);
												}}>
												<div>
													{couponTypePercent ? (
														<BiRadioCircleMarked
															fill='#FFFFFF'
															size={"1.5rem"}
														/>
													) : (
														<BiRadioCircle fill='#BDBDBD' size={"1.5rem"} />
													)}
												</div>
												<h3
													className='font-medium'
													style={{
														fontSize: "24px",
														color: couponTypePercent ? "#fff" : "#011723",
													}}>
													نسبة الخصم
												</h3>
											</div>
											<div className='flex flex-col gap-5 px-5 py-6'>
												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														النسبة المئوية %
														{couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<input
														value={couponTypePercent ? discountPercent : ""}
														onChange={(e) =>
															setDiscountPercent(
																e.target.value.replace(/[^0-9]/g, "")
															)
														}
														className='xl:w-[376px] w-full outline-none p-4 rounded-lg'
														disabled={!couponTypePercent}
														style={{
															border: couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
															backgroundColor: "#FFFFFF",
														}}
														type='text'
														placeholder='أدخل الرقم'
													/>
													{couponTypePercent ? (
														<span className='text-red-500 text-[16px]'>
															{DataError?.discount}
														</span>
													) : (
														""
													)}
												</div>
												<div className='flex flex-col gap-[10px]'>
													<label
														className='mb-2 font-medium'
														style={{
															color: couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														تاريخ بداية ونهاية الخصم
														{couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<div
														className={`${
															couponTypePercent
																? styles.date_input
																: styles.disabled_date_input
														} xl:w-[376px] w-full flex flex-row items-center justify-between outline-none p-4 rounded-lg`}
														style={{
															border: couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
															backgroundColor: "#FFFFFF",
														}}>
														<DatePicker
															minDate={moment().toDate()}
															className='w-full outline-none'
															placeholderText='حدد التاريخ'
															selectsRange={true}
															startDate={startDatePercent}
															endDate={endDatePercent}
															onChange={(updatePercent) => {
																setDateRangePercent(updatePercent);
															}}
															disabled={!couponTypePercent}
														/>
														<DataIcon className={styles.icon} />
													</div>
													{couponTypePercent ? (
														<>
															<span className='text-red-500 text-[16px]'>
																{DataError?.start_at}
															</span>
															<span className='text-red-500 text-[16px]'>
																{DataError?.expire_date}
															</span>
														</>
													) : (
														""
													)}
												</div>
												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														عدد مرات الاستخدام للجميع
														{couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<input
														value={
															couponTypePercent ? totalRedemptionsPercent : ""
														}
														onChange={(e) =>
															setTotalRedemptionsPercent(
																e.target.value.replace(/[^0-9]/g, "")
															)
														}
														className='xl:w-[376px] w-full outline-none p-4 rounded-lg'
														disabled={!couponTypePercent}
														style={{
															border: couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
															backgroundColor: "#FFFFFF",
														}}
														type='text'
														placeholder='أدخل الرقم'
													/>
													{couponTypePercent ? (
														<span className='text-red-500 text-[16px]'>
															{DataError?.total_redemptions}
														</span>
													) : (
														""
													)}
												</div>

												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														عدد مرات الاستخدام للتاجر الواحد
														{couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<input
														value={
															couponTypePercent ? userRedemptionsPercent : ""
														}
														onChange={(e) =>
															setUserRedemptionsPercent(
																e.target.value.replace(/[^0-9]/g, "")
															)
														}
														className='xl:w-[376px] w-full outline-none p-4 rounded-lg'
														disabled={!couponTypePercent}
														style={{
															border: couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
															backgroundColor: "#FFFFFF",
														}}
														type='text'
														placeholder='أدخل الرقم'
													/>
													{couponTypePercent ? (
														<span className='text-red-500 text-[16px]'>
															{DataError?.user_redemptions}
														</span>
													) : (
														""
													)}
												</div>
											</div>
										</div>
										<div
											style={{ boxShadow: "0px 3px 6px #00000029" }}
											className='flex-1 bg-white rounded-lg'>
											<div
												className='flex items-center w-full gap-2 px-4 rounded-lg cursor-pointer'
												style={{
													backgroundColor: !couponTypePercent
														? "#1DBBBE"
														: "#EFF9FF",
													height: "75px",
												}}
												onClick={() => {
													setCouponTypePercent(false);
												}}>
												<div>
													{!couponTypePercent ? (
														<BiRadioCircleMarked
															fill='#FFFFFF'
															size={"1.5rem"}
														/>
													) : (
														<BiRadioCircle fill='#BDBDBD' size={"1.5rem"} />
													)}
												</div>
												<h3
													className='font-medium'
													style={{
														fontSize: "24px",
														color: couponTypePercent ? "#011723" : "#fff",
													}}>
													مبلغ ثابت
												</h3>
											</div>
											<div className='flex flex-col gap-5 px-5 py-6'>
												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: !couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: !couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														المبلغ الثابت
														{!couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<input
														value={couponTypePercent ? "" : discountFixed}
														onChange={(e) =>
															setDiscountFixed(
																e.target.value.replace(/[^\d.]|\.(?=.*\.)/g, "")
															)
														}
														className='xl:w-[376px] w-full outline-none p-4 rounded-lg'
														disabled={couponTypePercent}
														style={{
															backgroundColor: "#FFFFFF",
															border: !couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
														}}
														type='text'
														placeholder='أدخل الرقم'
													/>
													{couponTypePercent ? (
														""
													) : (
														<span className='text-red-500 text-[16px]'>
															{DataError?.discount}
														</span>
													)}
												</div>
												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: !couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: !couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														تاريخ بداية ونهاية الخصم
														{!couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<div
														className={`${
															couponTypePercent
																? styles.disabled_date_input
																: styles.date_input
														} xl:w-[376px] w-full flex flex-row items-center justify-between outline-none p-4 rounded-lg`}
														style={{
															border: couponTypePercent
																? "1px solid #D3D3D3"
																: "1px solid #242424",
															color: "#011723",
															fontSize: "16px",
															backgroundColor: "#FFFFFF",
														}}>
														<DatePicker
															minDate={moment().toDate()}
															className='w-full outline-none'
															placeholderText='حدد التاريخ'
															selectsRange={true}
															startDate={startDateFixed}
															endDate={endDateFixed}
															onChange={(updateFixed) => {
																setDateRangeFixed(updateFixed);
															}}
															disabled={couponTypePercent}
														/>
														<DataIcon className={styles.icon} />
													</div>
													{couponTypePercent ? (
														""
													) : (
														<>
															<span className='text-red-500 text-[16px]'>
																{DataError?.start_at}
															</span>
															<span className='text-red-500 text-[16px]'>
																{DataError?.expire_date}
															</span>
														</>
													)}
												</div>
												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: !couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: !couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														عدد مرات الاستخدام للجميع
														{!couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<input
														value={
															couponTypePercent ? "" : totalRedemptionsFixed
														}
														onChange={(e) =>
															setTotalRedemptionsFixed(
																e.target.value.replace(/[^0-9]/g, "")
															)
														}
														className='xl:w-[376px] w-full outline-none p-4 rounded-lg'
														disabled={couponTypePercent}
														style={{
															backgroundColor: "#FFFFFF",
															border: !couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
														}}
														type='text'
														placeholder='أدخل الرقم'
													/>
													{couponTypePercent ? (
														""
													) : (
														<span className='text-red-500 text-[16px]'>
															{DataError?.total_redemptions}
														</span>
													)}
												</div>

												<div className='flex flex-col gap-[10px]'>
													<label
														style={{
															fontSize: "18px",
															color: !couponTypePercent ? "#011723" : "#A7A7A7",
														}}>
														<AiFillStar
															style={{
																display: "inline-block",
																marginLeft: "0.5rem",
																color: !couponTypePercent
																	? "#FF3838"
																	: "#A7A7A7",
															}}></AiFillStar>
														عدد مرات الاستخدام للتاجر الواحد
														{!couponTypePercent && (
															<span className='text-red-500'>*</span>
														)}
													</label>
													<input
														value={
															couponTypePercent ? "" : userRedemptionsFixed
														}
														onChange={(e) =>
															setUserRedemptionsFixed(
																e.target.value.replace(/[^0-9]/g, "")
															)
														}
														className='xl:w-[376px] w-full outline-none p-4 rounded-lg'
														disabled={couponTypePercent}
														style={{
															backgroundColor: "#FFFFFF",
															border: !couponTypePercent
																? "1px solid #242424"
																: "1px solid #D3D3D3",
															color: "#011723",
															fontSize: "16px",
														}}
														type='text'
														placeholder='أدخل الرقم'
													/>
													{couponTypePercent ? (
														""
													) : (
														<span className='text-red-500 text-[16px]'>
															{DataError?.user_redemptions}
														</span>
													)}
												</div>
											</div>
										</div>
									</div>
								</LocalizationProvider>
							</div>
						</>
					)}
				</form>
				<div className='pb-20' />
			</div>
		</>
	);
};

export default EditCoupon;
