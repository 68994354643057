import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const websiteOrdersApi = createApi({
  reducerPath: "websiteOrdersApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["WebsiteOrders"],

  endpoints: (builder) => ({

    getWebsiteOrders: builder.query({
      query: (args) => ({
        url: `websiteorder?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["WebsiteOrders"],
    }),

    getWebsiteOrderData: builder.query({
      query: ({ serviceOrderId }) => ({
        url: `websiteorder/${serviceOrderId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "WebsiteOrders", id }],
    }),

    searchInWebsiteOrders: builder.mutation({
      query: (arg) => ({
        url: `searchOrderServiceName?query=${arg.query}`,
        method: "GET",
      }),
    }),

  rejectServiceById: builder.mutation({
    query: ({ id, body }) => {
      return {
        url: `rejectService/${id}`,
        method: "POST",
        data: body,
      };
    },

    invalidatesTags: ["WebsiteOrders"],
  }),

  acceptServiceById: builder.mutation({
    query: ({ id, body }) => {
      return {
        url: `acceptService/${id}`,
        method: "POST",
        data: body,
      };
    },

    invalidatesTags: ["WebsiteOrders"],
  }),

  websiteOrdersDeleteItem: builder.mutation({
    query: ({ id }) => ({
      url: `websiteorderdeleteall?id[]=${id}`,
      method: "GET",
    }),
    invalidatesTags: ["WebsiteOrders"],
  }),

    


  }),
});

// Export endpoints and hooks
export const {
  useGetWebsiteOrdersQuery,
  useGetWebsiteOrderDataQuery,
  useSearchInWebsiteOrdersMutation,
  useRejectServiceByIdMutation,
  useAcceptServiceByIdMutation,
  useWebsiteOrdersDeleteItemMutation,
} = websiteOrdersApi;
