import React, { useState } from "react";

/* Components */
import Plans from "./Plans/Plans";
import EditPackageTemplates from "../EditPackageTemplates/EditPackageTemplates";

const PackagesTypes = ({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) => {
	const [showEditTemplates, setShowEditTemplates] = useState(false);
	const [templatesEachPackage, setTemplatesEachPackage] = useState([]);

	return (
		<div className='h-full mb-4 md:mb-20'>
			{showEditTemplates && (
				<EditPackageTemplates
					templatesEachPackage={templatesEachPackage}
					showEditTemplates={showEditTemplates}
					cancel={() => {
						setShowEditTemplates(false);
					}}
				/>
			)}
			<Plans
				loading={loading}
				page_count={page_count}
				pageNumber={pageNumber}
				currentPage={currentPage}
				setPageNumber={setPageNumber}
				setCurrentPage={setCurrentPage}
				showEditTemplates={showEditTemplates}
				fetchedData={fetchedData}
				editPackageTemplate={(data) => {
					setShowEditTemplates(true);
					setTemplatesEachPackage(data);
				}}
			/>
		</div>
	);
};

export default PackagesTypes;
