import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
import OrdersStats from "../../../components/OrdersStats/OrdersStats";
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import ProductsTable from "../../../components/SouqOtlobhaComp/ProductsTable/ProductsTable";
import Button from "../../../UI/Button/Button";
import { Filter, Product } from "../../../assets/Icons/index";
import {
	useEtlobhaProductsDataQuery,
	useGetFilteredEtlobhaProductsMutation,
	useStoreCategoryDataQuery,
} from "../../../RTK/apiSlices/etlobhaApi";
import SouqAtlbhaFilterForm from "../../../components/SouqOtlobhaComp/SouqAtlbhaFilterForm";

const SouqOtlobha = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const [data, setData] = useState();
	const [dataFilter, setDataFilter] = useState();
	const [page, setPage] = useState(1);
	const [category, setCategory] = useState("");
	const [products, setProducts] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageCount, setPageCount] = useState(1);
	const [subCategory, setSubCategory] = useState([]);
	const [categoriesList, setCategoriesList] = useState([]);
	const [showFilteringOptions, setShowFilteringOptions] = useState(false);

	// handle get data from Api
	const { data: etlobhaCategories } = useStoreCategoryDataQuery();
	const { data: etlobhaProducts, isLoading } = useEtlobhaProductsDataQuery({
		page,
		number: rowsPerPage,
	});

	const [getFilteredEtlobhaProducts, { isLoading: filterIsLoading }] =
		useGetFilteredEtlobhaProductsMutation();

	useEffect(() => {
		if (etlobhaProducts) {
			setProducts(etlobhaProducts.products);
			setDataFilter(etlobhaProducts);
		}
		if (etlobhaCategories) {
			setCategoriesList(etlobhaCategories.categories);
		}
	}, [etlobhaProducts, etlobhaCategories]);

	const subcategory =
		categoriesList.filter((sub) => sub.id === parseInt(category)) || "";
	const subcategory_ids = subCategory
		?.map((id, index) => `subcategory_id[${index}]=${id}`)
		.join("&");

	const onClickFilter = async () => {
		if (subCategory?.length !== 0 || category !== "") {
			setPage(1);
			try {
				const response = await getFilteredEtlobhaProducts({
					page,
					number: rowsPerPage,
					category_id: category,
					subcategory_ids: subcategory_ids,
				});
				const responseData = response.data?.data;
				if (responseData) {
					setDataFilter(responseData);
					setData(responseData.products);
					setPageCount(responseData.page_count);
				}
			} catch (error) {
				console.error("Error fetching getFilteredEtlobhaProducts:", error);
			}
		} else {
			setData(products);
			setPageCount(etlobhaProducts?.page_count || 0);
			setDataFilter([]);
		}
	};

	useEffect(() => {
		if (category === "") {
			setData(products);
			setDataFilter([]);
			setPageCount(etlobhaProducts?.page_count || 0);
		}
	}, [products, category, etlobhaProducts?.page_count]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | سوق اطلبها</title>
			</Helmet>
			<div
				className={`p-4 2xl:pl-36 pl-4`}
				style={{ backgroundColor: "#fafafa" }}>
				<div className='flex flex-col items-start justify-between mb-5 lg:flex-row lg:items-center gap-y-4'>
					<PageNavigate
						currentPage={"سوق اطلبها"}
						nestedPage={true}
						parentPage={"السوق"}
					/>
					<div className='flex w-full gap-2 lg:w-auto '>
						<Button
							onClick={() => {
								setShowFilteringOptions(!showFilteringOptions);
							}}
							type={"outline"}
							svg={<Filter />}
							className={
								"flex justify-center items-center lg:w-[140px] w-full md:h-[56px] h-[45px] md:text-lg text-[16px] whitespace-nowrap rounded"
							}>
							فرز
						</Button>
						{permissions?.includes("admin.etlobha.store") && (
							<Button
								onClick={() => {
									navigate("/souq_atlbha/add-product");
								}}
								type={"normal"}
								color={"bTow"}
								className={
									"flex flex-row justify-center items-center lg:w-[180px] w-full md:h-[56px] h-[45px] rounded md:bg-[#1DBBBE] bg-[#EDEDEF]"
								}
								textStyle={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									gap: "10px",
								}}>
								<Product className='md:invert' />
								<p className='md:text-lg text-[16px] md:text-[#FFFFFF] text-[#011723] whitespace-nowrap'>
									اضافة منتج جديد
								</p>
							</Button>
						)}
					</div>
				</div>

				<div className='relative'>
					{/** orders state section */}

					{permissions?.includes("admin.etlobha.statistics") && (
						<OrdersStats fetchedData={etlobhaProducts} />
					)}

					{/** filter form  */}
					<SouqAtlbhaFilterForm
						showFilteringOptions={showFilteringOptions}
						category={category}
						setSubCategory={setSubCategory}
						setCategory={setCategory}
						categoriesList={categoriesList}
						subCategory={subCategory}
						subcategory={subcategory}
						onClickFilter={onClickFilter}
					/>
				</div>

				{/** Products Table */}
				<div className='mt-4' dir='ltr'>
					<ProductsTable
						data={data}
						loading={isLoading || filterIsLoading}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={pageCount}
					/>
				</div>
			</div>
		</>
	);
};

export default SouqOtlobha;
