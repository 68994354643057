import React, { useContext } from "react";
import ReactQuill from "react-quill";

// Context
import { TextEditorContext } from "../../store/TextEditorProvider";

// Css Styles
import styles from "./TextEditor.module.css";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({ ToolBar, placeholder, readOnly }) => {
  const editorContent = useContext(TextEditorContext);
  const { setEditorValue, editorValue } = editorContent;

  let toolbarOptions;
  if (ToolBar === "createOrEditPages") {
    toolbarOptions = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ["italic", "underline", "bold"],
      [{ background: [] }, { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ];
  } else if (ToolBar === "product") {
    toolbarOptions = [
      ["italic", "underline", "bold"],
      [{ align: [] }],
      [{ background: [] }, { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ];
  } else if (ToolBar === "TraderAlert") {
    toolbarOptions = [
      ["italic", "underline", "bold"],
      [{ align: [] }],
      [{ background: [] }, { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
    ];
  } else if (ToolBar === "Details") {
    toolbarOptions = [
      ["italic", "underline", "bold"],
      [{ list: "ordered" }, { list: "bullet" }],
    ];
  }

  return (
    <div className={styles.textEditor}>
      <ReactQuill
        theme="snow"
        dir="rtl"
        readOnly={readOnly}
        placeholder={placeholder}
        value={editorValue}
        onChange={setEditorValue}
        modules={{ toolbar: toolbarOptions }}
      />
    </div>
  );
};

export default TextEditor;
