import React from "react";
/* MUI */
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import {
	MdOutlineKeyboardArrowDown,
	MdOutlineArrowBackIosNew,
	MdOutlineArrowForwardIos,
} from "react-icons/md";

function Pagination({
	data,
	allRows,
	rowsPerPage,
	setPage,
	page,
	rowsPerPagesCount,
	handleRowsClick,
	open,
	anchorEl,
	handleClose,
	handleChangeRowsPerPage,
}) {
	return (
		<div className='flex md:flex-row flex-col items-center justify-between gap-[26px] md:mt-4 mt-8'>
			<div
				className='flex items-center gap-2 p-2 rounded-md'
				style={{ border: "1px solid #2D62ED" }}>
				<div
					id='basic-button'
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup='true'
					aria-expanded={open ? "true" : undefined}
					onClick={handleRowsClick}
					className={
						"h-9 w-9 rounded-sm flex justify-center items-center cursor-pointer"
					}
					style={{ backgroundColor: "#0099FB" }}>
					<MdOutlineKeyboardArrowDown
						color='#fff'
						fontSize={"1.5rem"}></MdOutlineKeyboardArrowDown>
				</div>
				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}>
					{rowsPerPagesCount?.map((rowsPer, rowsIdx) => {
						return (
							<MenuItem
								value={rowsPer}
								onClick={(e) => {
									handleChangeRowsPerPage(e);
									handleClose();
								}}
								key={rowsIdx}
								sx={{
									backgroundColor: "#FFEEEE",
									"ul:has(&)": {
										p: 0,
									},
									"ul:has(&) li:hover": {
										backgroundColor: "#C6E1F0",
									},
								}}>
								{rowsPer}
							</MenuItem>
						);
					})}
				</Menu>
				<h2 className='font-medium' style={{ color: "#0077FF" }}>
					عدد الصفوف
				</h2>
			</div>
			<div className='flex items-center gap-6'>
				<MdOutlineArrowBackIosNew
					className='cursor-pointer'
					style={{ visibility: page === 0 && "hidden" }}
					onClick={() => {
						setPage(page - 1);
					}}
				/>
				<div className='flex gap-4'>
					{allRows()?.map((item, itemIdx) => {
						if (
							(itemIdx < 1 || (itemIdx >= page - 1 && itemIdx <= page + 1)) &&
							(Math.ceil(data) <= 3 ||
								(Math.ceil(data) > 3 && itemIdx < allRows()?.length - 1))
						) {
							return (
								<div
									key={itemIdx}
									className='flex items-center justify-center w-6 h-6 font-medium rounded-lg cursor-pointer'
									style={{
										backgroundColor: item === page + 1 && "#508FF4",
										color: item === page + 1 && "#fff",
									}}
									onClick={() => {
										setPage(itemIdx);
									}}>
									{item}
								</div>
							);
						}
						return null;
					})}
					{Math.ceil(data / rowsPerPage) > 3 && <div>...</div>}
					{Math.ceil(data / rowsPerPage) > 1 && (
						<div
							className='flex items-center justify-center w-6 h-6 font-medium rounded-lg cursor-pointer'
							style={{
								backgroundColor:
									Math.ceil(data / rowsPerPage) === page + 1 && "#508FF4",
								color: Math.ceil(data / rowsPerPage) === page + 1 && "#fff",
							}}
							onClick={() => {
								setPage(Math.ceil(data / rowsPerPage) - 1);
							}}>
							{Math.ceil(data / rowsPerPage)}
						</div>
					)}
				</div>
				<MdOutlineArrowForwardIos
					className='cursor-pointer'
					style={{
						visibility: page + 1 === Math.ceil(data / rowsPerPage) && "hidden",
					}}
					onClick={() => {
						setPage(page + 1);
					}}
				/>
			</div>
			<div></div>
		</div>
	);
}

export default Pagination;
