import React from 'react'
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoIosArrowDown } from "react-icons/io";
import Button from '../../../UI/Button/Button';






const category = ["غير نشط", "نشط", "منتهي"];
const sections = ["ابجديا"];

const CouponsFilterForm = ({ showCoupons, setShowCoupons, sortCoupons, setSortCoupons, onClickFilter }) => {
  return (
    <div className={`flex lg:flex-row flex-col lg:gap-10 gap-4 lg:pb-4 lg:pt-4 lg:pr-2 lg:pl-6 px-2 py-3 mt-4 lg:bg-[#FAFAFA] bg-[#F4F5F7]  rounded-lg otlobha_filtering_sec`}>
          <div className="flex flex-row flex-wrap flex-1 gap-3 md:gap-8">
            <FormControl sx={{ minWidth: 200, flex: "1" }}>
              <h2 className="mb-2 text-lg font-medium"> عرض الكوبونات حسب</h2>
              <Select
                className="text-lg font-normal"
                IconComponent={() => {
                  return (
                    <IoIosArrowDown
                      className="duration-200 select_arrow"
                      size={"1.2rem"}
                    />
                  );
                }}
                value={showCoupons}
                onChange={(e) => {
                  setShowCoupons(e.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => {
                  if (showCoupons === "") {
                    return <h2>الكل</h2>;
                  }
                  return selected;
                }}
                sx={{
                  height: "3.5rem",
                  pl: "0.5rem",
                  borderRadius: "8px",
                  border: "1px solid #B6BE34",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    paddingRight: "10px",
                  },
                }}
              >
                <MenuItem
                  className="souq_storge_category_filter_items"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    height: "3rem",
                    "&:hover": {
                      backgroundColor: "rgba(242, 245, 207, 1)",
                    },
                  }}
                  value={""}
                >
                  الكل
                </MenuItem>
                {category?.map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      className="souq_storge_category_filter_items"
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        height: "3rem",
                        "&:hover": {
                          backgroundColor: "rgba(242, 245, 207, 1)",
                        },
                      }}
                      value={`${item}`}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200, flex: "1" }}>
              <h2 className="mb-2 text-lg font-medium"> ترتيب الكوبونات حسب</h2>
              <Select
                className="text-lg font-normal"
                IconComponent={() => {
                  return (
                    <IoIosArrowDown
                      className="duration-200 select_arrow"
                      size={"1.2rem"}
                    />
                  );
                }}
                value={sortCoupons}
                onChange={(e) => {
                  setSortCoupons(e.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => {
                  if (sortCoupons === "") {
                    return <h2>تلقائى</h2>;
                  }
                  return selected;
                }}
                sx={{
                  height: "3.5rem",
                  pl: "0.5rem",
                  borderRadius: "8px",
                  border: "1px solid #B6BE34",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    paddingRight: "10px",
                  },
                }}
              >
                <MenuItem
                  className="souq_storge_category_filter_items"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    height: "3rem",
                    "&:hover": {
                      backgroundColor: "rgba(242, 245, 207, 1)",
                    },
                  }}
                  value={""}
                >
                  تلقائى
                </MenuItem>
                {sections?.map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      className="souq_storge_category_filter_items"
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        height: "3rem",
                        "&:hover": {
                          backgroundColor: "rgba(242, 245, 207, 1)",
                        },
                      }}
                      value={`${item}`}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex items-end">
            <Button
              onClick={() => onClickFilter()}
              type={"normal"}
              className={
                "flex-1 text-lg font-normal md:w-44 w-full md:h-14 h-[45px]"
              }
            >
              تنفيذ الفرز
            </Button>
          </div>
        </div>
  )
}

export default CouponsFilterForm
