import React, { useState, useContext } from "react";
import styles from "./AddProductOptions.module.css";
/* Third party */
import { SketchPicker } from "react-color";
import ImageUploading from "react-images-uploading";
/* Context */
import Context from "../../../../store/context";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
/* Icons */
import {
  IoMdCloseCircleOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import CloseIcon from "@mui/icons-material/Close";
import { FaCartArrowDown } from "react-icons/fa6";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import {
  WriteIcon,
  ChoiceIcon,
  Currency,
  Delete,
} from "../../../../assets/Icons";
import { toast } from "react-toastify";

const BackDrop = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="fixed back_drop top-0 left-0 h-full w-full bg-[#242424] opacity-70 z-40"
    ></div>
  );
};

const select_value_options = ["نص", "نص و لون", "نص و صورة"];

const AddProductOptions = ({ closeDetails, editProduct, productId }) => {
  const contextStore = useContext(Context);
  const {
    productHasOptions,
    setProductHasOptions,
    attributes,
    setAttributes,
    optionsSection,
    setOptionsSection,
    clearOptions,
  } = contextStore;
  const [showColorPicker, setShowColorPicker] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleCancel = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /** ------------------------------------------------------------- */
  const handleChangeImage = (e, blockIndex, valueIndex) => {
    const updatedBlocks = [...optionsSection];

    updatedBlocks[blockIndex].values[valueIndex].image = e[0]?.file;
    updatedBlocks[blockIndex].values[valueIndex].previewImage = e[0]?.data_url;
    setOptionsSection(updatedBlocks);
  };

  const handleDeleteImages = (e, blockIndex, valueIndex) => {
    const updatedBlocks = [...optionsSection];
    updatedBlocks[blockIndex].values[valueIndex].image = "";
    updatedBlocks[blockIndex].values[valueIndex].previewImage = "";

    setOptionsSection(updatedBlocks);
  };

  /** ------------------------------------------------------------- */

  //handle name of section
  const handleSetTitleInput = (e, index) => {
    const updatedPackInfoInput = [...optionsSection];
    updatedPackInfoInput[index].name = e.target.value;
    setOptionsSection(updatedPackInfoInput);
  };

  //handle value selected value of section
  const handleSetSelectValue = (e, index) => {
    const updatedPackInfoInput = [...optionsSection];
    updatedPackInfoInput[index].select_value = e.target.value;
    setOptionsSection(updatedPackInfoInput);
  };

  //handle value title of block
  const handleSetValueTitleInput = (e, blockIndex, valueIndex) => {
    const updatedBlocks = [...optionsSection];
    updatedBlocks[blockIndex].values[valueIndex].title = e.target.value;
    setOptionsSection(updatedBlocks);
    // Generate Attributes
    const newAttributes = generateAttributes(updatedBlocks);
    setAttributes(newAttributes);
  };

  //handle default value of block
  const handleSetValueDefaultOption = (value, blockIndex, valueIndex) => {
    const updatedBlocks = optionsSection?.map((block, currentIndex) => {
      if (currentIndex === blockIndex) {
        const updatedValues = block?.values?.map((valueObj, index) => {
          if (index === valueIndex) {
            return {
              ...valueObj,
              defaultOption: value,
            };
          }
          return {
            ...valueObj,
            defaultOption: false,
          };
        });

        return {
          ...block,
          values: updatedValues,
        };
      }

      return block;
    });

    setOptionsSection(updatedBlocks);
  };

  //handle value color of block
  const handleSetValueColorInput = (e, blockIndex, valueIndex) => {
    const updatedBlocks = [...optionsSection];
    updatedBlocks[blockIndex].values[valueIndex].color = e.hex;
    setOptionsSection(updatedBlocks);
  };

  //handle add new value to block
  const handleAddNewValue = (blockIndex) => {
    const updatedBlocks = [...optionsSection];
    updatedBlocks[blockIndex].values.push({
      id: updatedBlocks[blockIndex].values.length + 1,
      title: "",
      color: "#000000",
      image: "",
      previewImage: "",
      defaultOption: false,
    });
    setOptionsSection(updatedBlocks);
  };

  const handleDeleteValue = (valueIndex, blockIndex) => {
    const updatedBlocks = [...optionsSection];
    updatedBlocks[blockIndex]?.values?.splice(valueIndex, 1);
    setOptionsSection(updatedBlocks);
    // Generate new Attributes based on the updated productOptions
    const newAttributes = generateAttributes(updatedBlocks);
    setAttributes(newAttributes);
  };

  const handleAddNewBlock = () => {
    // Create a new block with default values
    const newBlock = {
      name: "",
      select_value: "نص",
      values: [
        {
          id: 2,
          title: "",
          color: "#000000",
          image: "",
          previewImage: "",
          defaultOption: false,
        },
      ],
    };

    // Clone the existing productOptions array and add the new block
    const updatedBlocks = [...optionsSection, newBlock];

    // Update the state with the new array of blocks
    setOptionsSection(updatedBlocks);
  };

  /** handle delete options section */
  const handleDeleteBlock = (blockIndex) => {
    const updatedBlocks = optionsSection?.filter(
      (__item, index) => index !== blockIndex
    );
    setOptionsSection(updatedBlocks);
    // Generate new Attributes based on the updated productOptions
    const newAttributes = generateAttributes(updatedBlocks);
    setAttributes(newAttributes);
  };

  const generateAttributes = (blocks) => {
    const attributes = [];

    const backtrack = (currentAttribute, blockIndex) => {
      if (blockIndex === blocks.length) {
        attributes.push({
          values: [...currentAttribute],
          qty: 0,
          price: "",
          less_qty: 0,
        });
        return;
      }

      const block = blocks[blockIndex];

      for (const value of block.values) {
        currentAttribute[blockIndex] = { id: blockIndex, title: value.title };
        backtrack(currentAttribute, blockIndex + 1);
      }
    };

    backtrack(new Array(blocks.length), 0);

    return attributes;
  };

  /** handle add price value for attr */
  const addPriceToAttributes = (e, blockIndex) => {
    // to check if the one of some option is default
    const notSelectDefaultOption = optionsSection?.every((section) =>
      section?.values?.some((value) => value?.defaultOption === true)
    );

    if (!notSelectDefaultOption) {
      toast.warning(".يرجى تعيين قيمة افتراضية أولاً لكي تتمكن من وضع السعر", {
        theme: "light",
      });
      return;
    }
    const updatedAttributes = [...attributes];
    updatedAttributes[blockIndex].price = e.target.value;
    setAttributes(updatedAttributes);
  };

  /** handle add LessQtyAttr value for attr */
  const handleLessQtyAttr = (e, blockIndex) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[blockIndex].less_qty = e.target.value.replace(
      /[^0-9]/g,
      ""
    );
    setAttributes(updatedAttributes);
  };
  /** handle add qty value for attr */
  const changeQtyToAttributes = (e, blockIndex) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[blockIndex].qty = Number(
      e.target.value.replace(/[^0-9]/g, "")
    );
    setAttributes(updatedAttributes);
  };

  const increaseQtyToAttributes = (blockIndex) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[blockIndex].qty += 1;
    setAttributes(updatedAttributes);
  };

  const decreaseQtyToAttributes = (blockIndex) => {
    const updatedAttributes = [...attributes];
    if (updatedAttributes[blockIndex].qty > 0) {
      updatedAttributes[blockIndex].qty -= 1;
      setAttributes(updatedAttributes);
    }
  };

  /** ---------------------------------------------- */

  /** handle save Options  */
  const saveOptions = () => {
    if (productHasOptions === true) {
      // to check if the name of option in not empty or no
      const nameNotEmpty = optionsSection?.every(
        (section) => section?.name !== ""
      );

      // to check if the qtyAttrNotEmpty of option in not empty or no
      const valuesNotEmpty = optionsSection?.every((section) =>
        section?.values?.every((value) => value?.title !== "")
      );

      // to check if the price of option in not empty or no
      const priceAttrNotEmpty = attributes?.every((attr) => attr?.price > 0);

      // to check if the lessQtyAttrNotEmpty of option in not empty or no
      const lessQtyAttrNotEmpty = attributes?.every(
        (attr) => attr?.less_qty !== 0
      );

      // to check if the qtyAttrNotEmpty of option in not empty or no
      const qtyAttrNotEmpty = attributes?.every((attr) => attr?.qty !== 0);

      // to check if the one of some option is default
      const notSelectDefaultOption = optionsSection?.every((section) =>
        section?.values?.some((value) => value?.defaultOption === true)
      );

      if (
        nameNotEmpty &&
        valuesNotEmpty &&
        priceAttrNotEmpty &&
        notSelectDefaultOption &&
        qtyAttrNotEmpty
      ) {
        closeDetails();
        toast.success("تم حفظ خيارات المنتج", {
          theme: "light",
        });
      } else {
        !nameNotEmpty &&
          toast.warning("يرجى ملء حقل مسمى الخيار أولاً", {
            theme: "light",
          });
        !notSelectDefaultOption &&
          toast.warning("يرجى تعيين قيمة افتراضية أولاً", {
            theme: "light",
          });
        !valuesNotEmpty &&
          toast.warning("يرجى ملء حقل القيمة أولاً", {
            theme: "light",
          });
        !priceAttrNotEmpty &&
          toast.warning("يرجى ملء حقل السعر أولاً", {
            theme: "light",
          });
        !lessQtyAttrNotEmpty &&
          toast.warning("يرجى ملء حقل أقل طلب أولاً", {
            theme: "light",
          });
        !qtyAttrNotEmpty &&
          toast.warning("يرجى ملء حقل الكمية أولاً", {
            theme: "light",
          });
      }
    } else {
      closeDetails();
      toast.warning("  لم يتم اضافة اي خيارات ليتم حفظها", {
        theme: "light",
      });
    }
  };
  /** preview product image */
  const ProductImageModal = () => {
    return (
      <>
        <div
          onClick={handleCancel}
          className="bg-[#01172342] bottom-0 h-full left-0 fixed w-full z-40"
        ></div>
        <div
          className="absolute bg-[#ffffff] left-[22%] top-[117px] rounded-md p-[10px] z-50"
          style={{ boxShadow: "1px 1px 1px 1px #ddd" }}
        >
          <CloseIcon
            fontSize="small"
            className="mb-2 cursor-pointer"
            onClick={handleCancel}
          />
          <div className="product-option-img-wrap">
            <img
              src={previewImage}
              alt="img"
              className="max-w-[200px] h-auto rounded-sm"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <BackDrop
        onClick={() => {
          if (productId) {
            closeDetails();
          } else {
            closeDetails();
            clearOptions();
          }
        }}
      />
      <div
        className="fixed z-40 flex flex-col overflow-hidden rounded-md top-24 translate-x-2/4 right-2/4"
        style={{
          minHeight: "auto",
          width: "60.25rem",
          maxHeight: "36rem",
          maxWidth: "90%",
        }}
      >
        <div
          className="min-h-[4rem] w-full flex items-center justify-between px-4"
          style={{ backgroundColor: "#1DBBBE" }}
        >
          {editProduct?.name ? (
            <h2 className="text-slate-50 md:text-lg text-[15px]">
              تعديل خيارات للمنتج - {editProduct?.name}
            </h2>
          ) : (
            <h2 className="text-slate-50 md:text-lg text-[15px]">
              اضافة خيارات للمنتج
            </h2>
          )}

          <IoMdCloseCircleOutline
            color={"#fff"}
            className={"cursor-pointer w-5 h-5"}
            onClick={() => {
              if (productId) {
                closeDetails();
              } else {
                closeDetails();
                clearOptions();
              }
            }}
          ></IoMdCloseCircleOutline>
        </div>
        <div
          className="flex-1 px-4 pt-6 pb-2 overflow-scroll hide_scrollbar"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div
            className="w-full flex flex-row items-center gap-2 mb-6 text-[#0b4aa0] bg-[#5196f31a] px-[15px] py-[10px] rounded-sm"
            style={{ border: "1px solid #5196f333" }}
          >
            <IoMdInformationCircleOutline fontSize={"1.2rem"} />
            <span className="md:text-[1rem] sm:text-[0.9rem] text-[0.8rem]">
              بإمكانك إدارة الكمية بناء على خيارات المنتج
            </span>
          </div>
          <div className="flex gap-4">
            <div
              className={`w-8 h-5 relative rounded-xl cursor-pointer shadow-inner duration-500 ${""}`}
              style={{
                backgroundColor: productHasOptions ? "#3AE374" : "#A7A7A7",
              }}
              onClick={() => {
                setProductHasOptions(!productHasOptions);
              }}
            >
              <div
                className={`w-3 h-3 rounded-full absolute bg-slate-50 top-1  duration-100 ${
                  productHasOptions ? "left-4" : "left-1"
                }`}
              ></div>
            </div>
            <h2 className="md:text-[1rem] text-[0.9rem] font-semibold">
              تفعيل خيارات المنتج{" "}
            </h2>
          </div>
          {productHasOptions && (
            <>
              {optionsSection?.map((section, blockIndex) => (
                <div
                  key={blockIndex}
                  className="relative p-4 mt-6 md:py-7 md:px-5"
                  style={{
                    backgroundColor: "#EDEDEF",
                    border: "1px solid #E4E4E4",
                  }}
                >
                  <div className="flex flex-col gap-3 md:flex-row md:gap-5 mb-7">
                    <div className="flex flex-col flex-1 gap-3 md:h-12 md:flex-row md:gap-5">
                      <div
                        className="md:h-12 min-h-[45px] flex flex-1 gap-4 px-2 items-center"
                        style={{
                          backgroundColor: "#FAFAFA",
                          border: "1px solid #D3D3D3",
                        }}
                      >
                        <WriteIcon
                          fill="#ADB5B9"
                          className="min-w-[20px]"
                        ></WriteIcon>
                        <input
                          type="text"
                          placeholder="مسمى الخيار (مثل اللون , القياس)"
                          value={section?.name}
                          onChange={(e) => handleSetTitleInput(e, blockIndex)}
                          style={{ backgroundColor: "transparent" }}
                          className="flex-1 w-full outline-none"
                        />
                      </div>
                      <div
                        className="md:h-12 min-h-[45px] flex flex-1 gap-4 px-2 items-center"
                        style={{
                          backgroundColor: "#FAFAFA",
                          border: "1px solid #D3D3D3",
                        }}
                      >
                        <ChoiceIcon fill="#ADB5B9"></ChoiceIcon>
                        <Select
                          value={section?.select_value}
                          IconComponent={() => {
                            return <IoIosArrowDown size={"1rem"} />;
                          }}
                          onChange={(e) => handleSetSelectValue(e, blockIndex)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={(selected) => {
                            return selected;
                          }}
                          className={"font-medium"}
                          sx={{
                            height: "100%",
                            pl: "1rem",
                            width: "100%",

                            "& .MuiSelect-select.MuiSelect-outlined": {
                              p: 0,
                              display: "flex",
                              alignItems: "center",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&  svg": {
                              display: "block",
                            },
                          }}
                        >
                          {select_value_options?.map((option, index) => (
                            <MenuItem
                              key={index}
                              className="souq_storge_category_filter_items "
                              sx={{
                                backgroundColor: "#FAFAFA",
                                color: "#011723",
                                "ul:has(&) li:hover": {
                                  backgroundColor: "#B4EDEE",
                                },
                                height: "3rem",
                                "&:hover": {},
                              }}
                              value={`${option}`}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      {optionsSection?.length > 1 && (
                        <div
                          onClick={() => {
                            handleDeleteBlock(blockIndex);
                          }}
                          className="md:relative absolute md:h-12 md:w-12 h-6 w-6 fcc md:rounded-sm rounded-full cursor-pointer md:left-0 md:top-0 left-[-10px] top-[-10px]"
                          style={{ backgroundColor: "#FF3838" }}
                        >
                          <Box sx={{ "& path": { fill: "#fff" } }}>
                            <Delete className="md:w-[24px] w-[18px]"></Delete>
                          </Box>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {section?.values?.map((item, itemIndex) => (
                      <div className="flex flex-row items-center gap-2 md:gap-4">
                        <div
                          key={itemIndex}
                          className={`${styles.OptionValue} max-w-full relative md:h-12 min-h-[45px] flex flex-1 gap-2 px-2 items-center`}
                          style={{
                            backgroundColor: "#FAFAFA",
                            border: "1px solid #D3D3D3",
                          }}
                        >
                          <ToggleButton
                            value="check"
                            title="تعيينه كخيار افتراضي"
                            selected={item?.defaultOption}
                            onChange={() => {
                              handleSetValueDefaultOption(
                                true,
                                blockIndex,
                                itemIndex
                              );
                            }}
                          >
                            <CheckIcon />
                          </ToggleButton>
                          <WriteIcon
                            fill="#ADB5B9"
                            className="min-w-[20px]"
                          ></WriteIcon>
                          <input
                            type="text"
                            placeholder={`القيمة ${itemIndex + 1}`}
                            value={item?.title}
                            onChange={(e) => {
                              handleSetValueTitleInput(
                                e,
                                blockIndex,
                                itemIndex
                              );
                            }}
                            style={{ backgroundColor: "transparent" }}
                            className="flex-1 w-full outline-none"
                          />
                          {section?.select_value === "نص و لون" && (
                            <div
                              onClick={() => {
                                setShowColorPicker(item?.id);
                              }}
                              className="absolute w-6 h-6 -translate-y-1/2 rounded-full cursor-pointer left-4 top-1/2"
                              style={{
                                backgroundColor: item?.color,
                                border:
                                  item?.color === "#ffffff"
                                    ? "1px solid #d4d4d4"
                                    : "none",
                              }}
                            ></div>
                          )}
                          {showColorPicker === item?.id &&
                            section?.select_value === "نص و لون" && (
                              <div className="absolute bottom-0 left-0 z-50 translate-y-full">
                                <SketchPicker
                                  color={item?.color}
                                  onChange={(e) => {
                                    handleSetValueColorInput(
                                      e,
                                      blockIndex,
                                      itemIndex
                                    );
                                  }}
                                ></SketchPicker>
                                <div className="absolute top-0 right-0 -translate-y-full cursor-pointer z-60">
                                  <TiDeleteOutline
                                    onClick={() => {
                                      setShowColorPicker(null);
                                    }}
                                    size={"1.5rem"}
                                  ></TiDeleteOutline>
                                </div>
                              </div>
                            )}
                          {section?.select_value === "نص و صورة" && (
                            <>
                              <ImageUploading
                                value={item?.image}
                                onChange={(e) => {
                                  handleChangeImage(e, blockIndex, itemIndex);
                                }}
                                maxNumber={1}
                                dataURLKey="data_url"
                                acceptType={["jpg", "png", "jpeg"]}
                                allowNonImageType={true}
                              >
                                {({ onImageUpload }) =>
                                  item?.previewImage ? (
                                    <>
                                      <div
                                        className="w-fit flex justify-center items-center gap-2 px-2 ml-[3px] rounded-[2px]"
                                        style={{ border: "1px solid #f4f4f4" }}
                                      >
                                        <div className="inline-block  h-[38px] py-[2px] px-[10px]">
                                          <img
                                            src={item?.previewImage}
                                            alt="img"
                                            className="w-full h-full"
                                          />
                                        </div>
                                        <div className="flex items-center justify-center gap-2">
                                          <VisibilityIcon
                                            fontSize="small"
                                            className="cursor-pointer"
                                            onClick={() => {
                                              setPreviewOpen(true);
                                              setPreviewImage(
                                                item?.previewImage
                                              );
                                            }}
                                          />
                                          <CloseIcon
                                            fontSize="small"
                                            className="cursor-pointer"
                                            onClick={(e) => {
                                              handleDeleteImages(
                                                e,
                                                blockIndex,
                                                itemIndex
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={onImageUpload}
                                      className="flex items-center justify-end h-full w-fit"
                                    >
                                      استعراض...
                                    </button>
                                  )
                                }
                              </ImageUploading>
                            </>
                          )}
                        </div>
                        {section?.values?.length > 1 && (
                          <div
                            className="w-6 h-6 rounded-sm cursor-pointer md:h-12 md:w-12 fcc"
                            onClick={() => {
                              handleDeleteValue(itemIndex, blockIndex);
                            }}
                          >
                            <Box sx={{}}>
                              <Delete className="md:w-[24px] w-[20px]"></Delete>
                            </Box>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    className="flex items-center justify-center w-full gap-3 py-3 mx-auto mt-4 cursor-pointer"
                    style={{ width: "376px", border: "1px dashed #1DBBBE" }}
                    onClick={() => handleAddNewValue(blockIndex)}
                  >
                    <IoMdAdd fill="#1DBBBE"></IoMdAdd>
                    <h2 style={{ color: "#1DBBBE" }}>اضافة قيمة</h2>
                  </div>
                </div>
              ))}
              <div
                className="gap-3 py-3 mt-4 cursor-pointer fcc md:mt-8"
                style={{ width: "100%", border: "1px dashed #1DBBBE" }}
                onClick={handleAddNewBlock}
              >
                <IoMdAdd fill="#1DBBBE"></IoMdAdd>
                <h2 style={{ color: "#1DBBBE" }}>اضافة خيار جديد</h2>
              </div>
              {attributes?.length > 0 && (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row items-end justify-end w-full mt-10">
                    <h6 className="text-[1rem] font-semibold text-[#212121]">
                      إجمالي الكمية{" "}
                      <span>
                        {attributes?.reduce(
                          (total, attribute) => total + attribute?.qty,
                          0
                        )}
                      </span>
                    </h6>
                  </div>
                  <div className={styles.Accordions}>
                    {attributes?.map((attribute, attributeIndex) => (
                      <Accordion
                        expanded={expanded === attributeIndex}
                        onChange={handleChange(attributeIndex)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${attributeIndex}-content`}
                          id={`${attributeIndex}-header`}
                        >
                          <div className="flex flex-row flex-wrap items-center justify-between flex-1 gap-1 sm:gap-4">
                            <div className="flex flex-row items-center gap-1 sm:gap-2">
                              {attribute?.values?.map((value, index) => (
                                <>
                                  {index !== 0 && <span>/</span>}
                                  <h6
                                    key={value?.id}
                                    className="sm:text-[0.9rem] text-[15px] text-[#444444] sm:font-semibold font-medium"
                                  >
                                    {value?.title}
                                  </h6>
                                </>
                              ))}
                            </div>
                            <h6 className="sm:text-[0.9rem] text-[14px] text-[#999999] sm:font-semibold font-medium whitespace-nowrap">
                              {" "}
                              متوفر عدد <span>{attribute?.qty}</span>
                            </h6>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="md:h-12 min-h-[45px] flex flex-1 gap-4 px-2 items-center"
                            style={{
                              backgroundColor: "#FAFAFA",
                              border: "1px solid #D3D3D3",
                            }}
                          >
                            <Currency
                              fill="#ADB5B9"
                              className="min-w-[20px]"
                            ></Currency>
                            <input
                              type="number"
                              placeholder="السعر"
                              value={attribute?.price}
                              onChange={(e) => {
                                addPriceToAttributes(e, attributeIndex);
                              }}
                              style={{ backgroundColor: "transparent" }}
                              className="flex-1 w-full outline-none"
                            />
                            <span className="text-[0.9rem] text-[#999999] font-semibold whitespace-nowrap">
                              ر.س
                            </span>
                          </div>
                          <div
                            className="md:h-12 min-h-[45px] flex flex-1 gap-4 px-2 items-center"
                            style={{
                              backgroundColor: "#FAFAFA",
                              border: "1px solid #D3D3D3",
                            }}
                          >
                            <FaCartArrowDown
                              fill="#ADB5B9"
                              className="min-w-[20px]"
                            />
                            <input
                              type="text"
                              placeholder="أقل طلب"
                              value={attribute?.less_qty}
                              onChange={(e) => {
                                handleLessQtyAttr(e, attributeIndex);
                              }}
                              style={{ backgroundColor: "transparent" }}
                              className="flex-1 w-full outline-none"
                            />
                            <span className="text-[0.9rem] text-[#999999] font-semibold whitespace-nowrap">
                              قطعة
                            </span>
                          </div>
                          <div
                            className="flex flex-row items-center flex-1 gap-4 pr-1 md:h-12 md:pr-2"
                            style={{
                              backgroundColor: "#FAFAFA",
                              border: "1px solid #D3D3D3",
                            }}
                          >
                            <span className="flex-1 md:text-[1rem] sm:text-[0.9rem] text-[0.8rem] text-[#000000] font-semibold whitespace-nowrap">
                              الكمية المتوفرة
                            </span>
                            <Box
                              className="flex h-full"
                              sx={{
                                "& div": {
                                  height: "100%",
                                  border: "1px solid #ADB5B966",
                                },
                              }}
                            >
                              <div
                                onClick={() => {
                                  increaseQtyToAttributes(attributeIndex);
                                }}
                                className="md:w-[56px] w-[40px] fcc min-h-[45px] cursor-pointer"
                              >
                                <AiOutlinePlus></AiOutlinePlus>
                              </div>
                              <div className="md:w-[56px] sm:w-[50px] w-[45px] fcc min-h-[45px]">
                                <input
                                  type="text"
                                  placeholder="الكمية"
                                  value={attribute?.qty}
                                  onChange={(e) => {
                                    changeQtyToAttributes(e, attributeIndex);
                                  }}
                                  className="w-full h-full text-[16px] text-[#333333] bg-transparent outline-none border-none"
                                  style={{ textAlign: "center" }}
                                />
                              </div>
                              <div
                                onClick={() => {
                                  decreaseQtyToAttributes(attributeIndex);
                                }}
                                className="md:w-[56px] w-[40px] fcc min-h-[45px] cursor-pointer"
                              >
                                <AiOutlineMinus></AiOutlineMinus>
                              </div>
                            </Box>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="flex flex-row items-center gap-3 md:gap-6">
            <Button
              onClick={() => {
                saveOptions();
              }}
              type={"normal"}
              className={
                "w-full mt-5 md:text-[1.1rem] text-[0.9rem] min-w-[100px] h-12 md:h-14"
              }
            >
              حفظ
            </Button>
            <Button
              onClick={() => {
                if (productId) {
                  closeDetails();
                } else {
                  closeDetails();
                  clearOptions();
                }
              }}
              type={"outline"}
              className={
                "w-full mt-5 md:text-[1.1rem] text-[0.9rem] min-w-[100px] h-12 md:h-14"
              }
            >
              الغاء
            </Button>
          </div>
        </div>
      </div>
      {previewOpen && <ProductImageModal />}
    </>
  );
};

export default AddProductOptions;
