import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./TableComp.module.css";
/* Third party */
import moment from "moment";
/* Context */
import Context from "../../../../store/context";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	SortIcon,
	CheckedSquare,
	SwitchOn,
	Delete,
	DocumentIcon,
	EditButton,
} from "../../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import NewPagination from "../../../PaginationComp/NewPagination";
import {
	useCouponDeleteItemMutation,
	useCouponsChangeStatusAllByParamsMutation,
	useCouponsChangeStatusAllMutation,
	useCouponsDeleteAllByParamsMutation,
} from "../../../../RTK/apiSlices/couponsApi";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";
import { toast } from "react-toastify";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		textAlign: "center",
		sortLabel: "status",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "نوع الخصم",
		sort: true,
		sortLabel: "discount_type",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "تاريخ الانتهاء",
		sort: true,
		sortLabel: "expire_date",
	},
	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "رمز الكوبون",
	},

	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "rgba(182, 190, 52, 0.2)" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='text-lg font-medium'
						key={headCell.id}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#02466A",
							textAlign: headCell?.textAlign,
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				gap: "2rem",
				justifyContent: "flex-end",
			}}>
			<div className='flex flex-col items-center gap-2 py-1 md:flex-row md:py-1'>
				{permissions?.includes("admin.coupon.changesatusall") &&
					numSelected > 0 && (
						<div
							className='rounded-full fcc'
							style={{
								width: "130px",
								backgroundColor: "rgba(255, 56, 56, 0.1)",
								cursor: "pointer",
							}}
							onClick={() => {
								setNotificationTitle(
									"سيتم تغيير حالة جميع الكوبونات التي قمت بتحديدهم"
								);
								setItems(itemsSelected);
								setActionType("ChangeStatus");
							}}>
							<h2 className={"font-semibold"} style={{ color: "#FF9F1A" }}>
								تعطيل
							</h2>
							<Box
								sx={{
									"& #Path_820": {
										fill: "#FF9F1A",
									},
								}}>
								<SwitchOn
									style={{
										cursor: "pointer",
										color: "red",
										fontSize: "0.5rem",
									}}
									className={"w-5"}
								/>
							</Box>
						</div>
					)}
				{permissions?.includes("admin.coupon.deleteall") && numSelected > 0 && (
					<div
						className='rounded-full fcc'
						style={{
							width: "130px",
							backgroundColor: "rgba(255, 56, 56, 0.1)",
							cursor: "pointer",
						}}
						onClick={() => {
							setNotificationTitle("سيتم حذف جميع الكوبونات التي قمت بتحديدهم");
							setItems(itemsSelected);
							setActionType("deleteAll");
						}}>
						<h2 className={"font-medium"} style={{ color: "#FF3838" }}>
							حذف الكل
						</h2>
						<IconButton>
							<Delete
								style={{
									cursor: "pointer",
									color: "red",
									fontSize: "0.8rem",
								}}></Delete>
						</IconButton>
					</div>
				)}
			</div>

			<div className='flex items-center'>
				<h2 className='font-medium'>تحديد الكل</h2>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#011723",
						"& .MuiSvgIcon-root": {
							color: "#011723",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": "select all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	showCoupons,
	sortCoupons,
}) {
	const navigate = useNavigate();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [userMenuOpenedId, setUserMenuOpenedId] = React.useState(null);
	const [rowAnchorEl, setRowAnchorEl] = React.useState(null);

	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const handleOptionsClick = (event) => {
		setAnchorEl(event.currentTarget);
		setUserMenuOpenedId(event.currentTarget.id);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setRowAnchorEl(null);
		setUserMenuOpenedId(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [couponsChangeStatusAll] = useCouponsChangeStatusAllMutation();

	// change Status for single item
	const changeCouponStatus = async (id) => {
		try {
			await couponsChangeStatusAll({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [couponsChangeStatusAllByParams] =
		useCouponsChangeStatusAllByParamsMutation();

	const couponsChangeStatusAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await couponsChangeStatusAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [couponsDeleteAllByParams] = useCouponsDeleteAllByParamsMutation();

	const couponsDeleteAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await couponsDeleteAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	const [couponDeleteItem] = useCouponDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await couponDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.length) : 0;
	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={fetchedData?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={fetchedData?.length || 0}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(fetchedData, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row?.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row?.id}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<Button
																id={index}
																aria-controls={
																	userMenuOpenedId ? "basic-menu" : undefined
																}
																aria-haspopup='true'
																aria-expanded={
																	userMenuOpenedId ? "true" : undefined
																}
																onClick={handleOptionsClick}>
																<BsThreeDotsVertical
																	onClick={() => {}}
																	style={{
																		cursor: "pointer",
																		color: "rgba(0, 0, 0, 1)",
																		fontSize: "1.3rem",
																	}}></BsThreeDotsVertical>
															</Button>
															<Menu
																id='basic-menu'
																anchorEl={anchorEl}
																open={userMenuOpenedId == index}
																onClose={handleClose}
																MenuListProps={{
																	"aria-labelledby": "basic-button",
																}}>
																{permissions?.includes("admin.coupon.show") && (
																	<MenuItem
																		className='text-lg font-normal '
																		onClick={() => {
																			navigate(
																				`/coupons/show-coupon/${row?.id}`
																			);
																			handleClose();
																		}}>
																		<DocumentIcon
																			className={` w-5 h-5 ml-2 ${styles.detailIcon}`}
																		/>
																		التفاصيل
																	</MenuItem>
																)}
																{permissions?.includes(
																	"admin.coupon.update"
																) && (
																	<MenuItem
																		className='text-lg font-normal '
																		onClick={() => {
																			navigate(
																				`/coupons/edit-coupon/${row?.id}`
																			);
																			handleClose();
																		}}>
																		<EditButton
																			className={` w-5 h-5 ml-2 ${styles.editIcon}`}
																		/>
																		تعديل
																	</MenuItem>
																)}
																{permissions?.includes(
																	"admin.coupon.deleteall"
																) && (
																	<MenuItem
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف الكوبون وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																		}}
																		className='text-lg font-normal '>
																		<Delete
																			className={` w-5 h-5 ml-2 ${styles.deleteIcon}`}
																		/>
																		حذف الكل
																	</MenuItem>
																)}
															</Menu>
														</TableCell>
														<TableCell align='right'>
															<div className='flex flex-col items-center justify-center'>
																<Switch
																	onChange={() => {
																		if (
																			permissions?.includes(
																				"admin.coupon.changesatusall"
																			)
																		) {
																			changeCouponStatus(row?.id);
																		}
																	}}
																	className=''
																	sx={{
																		width: "50px",
																		"& .MuiSwitch-thumb": {
																			width: "11px",
																			height: "11px",
																		},
																		"& .MuiSwitch-switchBase": {
																			padding: "6px",
																			top: "9px",
																			left: "9px",
																		},
																		"& .MuiSwitch-switchBase.Mui-checked": {
																			left: "-1px",
																		},
																		"& .Mui-checked .MuiSwitch-thumb": {
																			backgroundColor: "#FFFFFF",
																		},
																		"& .MuiSwitch-track": {
																			height: "16px",
																			borderRadius: "20px",
																		},
																		"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track":
																			{
																				backgroundColor: "#3AE374",

																				opacity: 1,
																			},
																	}}
																	checked={row?.status === "نشط" ? true : false}
																/>
															</div>
														</TableCell>
														<TableCell align='right'>
															<h2 className='text-lg font-normal'>
																{row?.discount_type}
															</h2>
														</TableCell>

														<TableCell align='right'>
															<h2 className='text-lg font-normal'>
																{moment(row?.expire_date).format("YYYY-MM-DD")}
															</h2>
														</TableCell>
														<TableCell align='right'>
															<h2 className='text-lg font-normal'>
																{row?.code}
															</h2>
														</TableCell>

														<TableCell
															align='right'
															className='text-lg font-normal'>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<Checkbox
																checkedIcon={<CheckedSquare />}
																sx={{
																	color: "#011723",
																	"& .MuiSvgIcon-root": {
																		color: "#011723",
																	},
																}}
																checked={isItemSelected}
																onClick={(event) => handleClick(event, row?.id)}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}

									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{showCoupons && sortCoupons ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}

			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={couponsDeleteAllByParamsHandel}
					handleChangeAllItemsStatus={couponsChangeStatusAllByParamsHandel}
				/>
			)}
		</Box>
	);
}
