import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";

const UserFilterForm = ({
	role,
	handleCategory,
	setFilterResults,
	roleList,
	search,
	setSearch,
}) => {
	return (
		<div className='flex md:flex-row flex-col-reverse items-end md:gap-8 gap-4 md:bg-[#FFFFFF] bg-[#F2F8FA] rounded-lg py-4 px-2'>
			<div className='flex flex-col flex-1 w-full gap-2'>
				<h2 className='md:text-[18px] text-[16px]'>فرز حسب</h2>
				<FormControl
					className='flex flex-row gap-4'
					sx={{ width: "100%", maxWidth: "100%", flex: "1" }}>
					<Select
						value={role}
						onChange={handleCategory}
						displayEmpty
						IconComponent={() => {
							return <IoIosArrowDown size={"1rem"} />;
						}}
						inputProps={{ "aria-label": "Without label" }}
						renderValue={(selected) => {
							if (role === "") {
								return <h2>الكل</h2>;
							}
							return selected;
						}}
						className={"flex-1"}
						sx={{
							height: "3.5rem",
							pl: "1rem",
							border: "1px solid #A7A7A7",
							borderRadius: "8px",
							backgroundColor: "transparent",
							"& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							},
						}}>
						<MenuItem
							className='souq_storge_category_filter_items'
							onClick={() => setFilterResults(null)}
							sx={{
								backgroundColor: "rgba(255, 255, 255, 0)",
								height: "3rem",
								"&:hover": {
									backgroundColor: "rgba(242, 245, 207, 1)",
								},
							}}
							value={""}>
							الكل
						</MenuItem>
						{roleList?.roles?.map((item, index) => {
							return (
								<MenuItem
									key={index}
									className='souq_storge_category_filter_items'
									sx={{
										backgroundColor: "#EBEBEB",
										height: "3rem",
										"&:hover": {},
										"ul:has(&) li:hover": {
											backgroundColor: "rgba(29, 187, 190, 1)",
											color: "#fff",
										},
									}}
									value={item?.name}>
									{item?.name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
			<FormControl
				className='flex flex-row gap-4'
				sx={{ width: "100%", maxWidth: "100%", flex: "1" }}>
				<label className={`flex-1 h-14 relative `}>
					<input
						className='w-full h-full pr-10 bg-transparent rounded-lg outline-0'
						placeholder=' ابحث عن مستخدم'
						type='text'
						name='name'
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						style={{ border: "1px solid #A7A7A7" }}
					/>
					<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
						<AiOutlineSearch color='#1DBBBE' size={"18px"}></AiOutlineSearch>
					</div>
				</label>
			</FormControl>
		</div>
	);
};

export default UserFilterForm;
