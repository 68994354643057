import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const marketersApi = createApi({
	reducerPath: "marketersApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.com/api/Admin/",
	}),
	tagTypes: ["Marketers"],

	endpoints: (builder) => ({
		getMarketers: builder.query({
			query: (args) => ({
				url: `marketer?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Marketers"],
		}),

		getMarketerData: builder.query({
			query: ({ marketerId }) => ({
				url: `marketer/${marketerId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Marketers", id }],
		}),

		searchInMarketers: builder.mutation({
			query: (arg) => ({
				url: `searchMarketerName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		marketerDeleteAll: builder.mutation({
			query: ({ queryParams }) => ({
				url: `marketerdeleteall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Marketers"],
		}),

		marketerDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `marketerdeleteall?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Marketers"],
		}),

		updateMarketer: builder.mutation({
			query: ({ marketerId, body }) => {
				return {
					url: `marketer/${marketerId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Marketers"],
		}),

		addNewMarketer: builder.mutation({
			query: ({ body }) => {
				return {
					url: `marketer`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Marketers"],
		}),

		getRegistrationMarketerShow: builder.query({
			query: () => ({ url: `registration_marketer_show` }),

			transformResponse: (response) => response.data,
			providesTags: ["Marketers"],
		}),

		addNewRegistrationMarketer: builder.mutation({
			query: ({ body }) => {
				return {
					url: `registrationMarketer`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Marketers"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useGetMarketersQuery,
	useGetMarketerDataQuery,
	useGetRegistrationMarketerShowQuery,
	useSearchInMarketersMutation,
	useMarketerDeleteAllMutation,
	useMarketerDeleteItemMutation,
	useUpdateMarketerMutation,
	useAddNewMarketerMutation,
	useAddNewRegistrationMarketerMutation,
} = marketersApi;
