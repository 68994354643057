import React, { useState } from "react";

// Third party
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Components */
import OrdersStats from "./OrdersStats/OrdersStats";
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import SouqAtlbhaOrdersTable from "../../../components/SouqOtlobhaComp/SouqAtlbhaOrdersTable/SouqAtlbhaOrdersTable";
import { useImportOrdersDataQuery } from "../../../RTK/apiSlices/talabatEtlobhaApi";

const SouqOtlbhaOrders = () => {
  // ----------------------------------------------------------------------
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: fetchedData, isLoading: loading } = useImportOrdersDataQuery({
    page,
    number: rowsPerPage,
  });

  // Search on Orders
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const orders = search ? searchResults?.orders : fetchedData?.orders;
  // --------------------------------------------------------------------

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | طلبات سوق اطلبها</title>
      </Helmet>
      <div
        className={`p-4 2xl:pl-32 pl-4`}
        style={{ backgroundColor: "#fafafa" }}
      >
        <>
          <div className="flex flex-col items-start justify-between mb-5 lg:flex-row lg:items-center gap-y-4">
            <PageNavigate
              currentPage={"طلبات سوق اطلبها"}
              nestedPage={true}
              parentPage={"السوق"}
            />
          </div>
          <div className="relative">
            {/** orders state section */}
            <OrdersStats fetchedData={fetchedData} />
          </div>

          {/** Orders Table */}
          <div className="mt-4" dir="ltr">
            <SouqAtlbhaOrdersTable
              search={search}
              loading={loading}
              fetchedData={orders}
              setSearch={setSearch}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              pageNumber={page}
              setPageNumber={setPage}
              currentPage={rowsPerPage}
              setCurrentPage={setRowsPerPage}
              page_count={fetchedData?.page_count}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default SouqOtlbhaOrders;
