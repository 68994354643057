import React, { useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import MarketsApis from "../../../components/GeneralSouqComp/MarketsApis";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { usePlatformDataQuery } from "../../../RTK/apiSlices/platformApi";

const GeneralSouq = () => {
	const navigate = useNavigate();
	const { data: fetchedData, isLoading: loading } = usePlatformDataQuery();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | السوق العام</title>
			</Helmet>
			<div className={`xl:p-4 2xl:pl-36 pl-4 p-5 xl:bg-[#fafafa] bg-[#F7F7F7]`}>
				<div className='flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4'>
					<PageNavigate
						currentPage={"السوق العام"}
						nestedPage={true}
						parentPage={"السوق"}
					/>
					{permissions?.includes("admin.platform.store") && (
						<Button
							className={
								"flex justify-center items-center md:w-[180px] w-full md:h-[56px] h-[45px] text-lg"
							}
							type={"normal"}
							svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
							color={"white"}
							onClick={() => {
								navigate("/general_souq/add-souq");
							}}>
							اضافة API
						</Button>
					)}
				</div>
				<div className='md:mt-8 mt-[50px]'>
					<MarketsApis
						fetchedData={fetchedData?.platforms}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
};

export default GeneralSouq;
