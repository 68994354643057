import React, { useState } from "react";
/* Custom Hook */

/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
import { useAdminSelectorTemplatesQuery } from "../../../RTK/apiSlices/selectorApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-40 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};
const PackageTemplate = ({ getSelectedTemplate, cancel }) => {
	const { data: fetchedData, isLoading: loading } =
		useAdminSelectorTemplatesQuery();

	const [templateSelected, setTemplateSelected] = useState([]);
	getSelectedTemplate(templateSelected);

	return (
		<>
			<BackDrop onClick={cancel} />
			<div className='fixed z-50 flex flex-col w-full overflow-hidden rounded-lg top-16 translate-x-2/4 right-2/4'>
				{loading ? (
					<div className='mt-28'>
						<CircularLoading />
					</div>
				) : (
					<div className='flex flex-col items-center'>
						<div className='flex flex-row flex-wrap items-center justify-center flex-1 gap-4 p-4 md:px-10 md:py-12 md:gap-6'>
							{fetchedData?.templates?.map((item, index) => (
								<div
									key={index}
									className='md:w-[250px] w-[162px] md:h-[250px] h-[150px] rounded-md flex flex-col'
									style={{
										backgroundColor: "#ECFEFF",
										border: "1px solid #B4EDEE",
									}}>
									<div className='flex flex-col items-center justify-center flex-1 w-full'>
										<h6
											style={{ color: "#011723", fontSize: "18px" }}
											className='font-medium'>
											{item.name}
										</h6>
									</div>
									<div
										style={{ height: "45px", backgroundColor: "#FF38380A" }}
										className='flex flex-col items-center justify-center w-full'>
										<input
											style={{ width: "16px", height: "16px" }}
											type='checkbox'
											value={item.id}
											onChange={(e) => {
												if (e.target.checked) {
													setTemplateSelected([
														...templateSelected,
														parseInt(e.target.value),
													]);
												} else {
													setTemplateSelected(
														templateSelected.filter(
															(template) => parseInt(template) !== item.id
														)
													);
												}
											}}
										/>
									</div>
								</div>
							))}
						</div>
						<div className='md:w-[572px] w-[338px] flex  flex-row items-center'>
							<Button
								onClick={() => {
									cancel();
								}}
								type={"normal"}
								style={{ backgroundColor: "#1DBBBE", color: "#F7FCFF" }}
								className={"w-full text-center py-4 rounded-lg"}>
								<h2 className='font-medium'>اعتماد</h2>
							</Button>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default PackageTemplate;
