import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const GraphSec = ({ count_orders_of_Etlobha }) => {
  const data = [
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[0]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[0]
        : 0,
    },
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[1]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[1]
        : 0,
    },
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[2]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[2]
        : 0,
    },
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[3]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[3]
        : 0,
    },
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[4]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[4]
        : 0,
    },
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[5]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[5]
        : 0,
    },
    {
      name: count_orders_of_Etlobha
        ? Object.keys(count_orders_of_Etlobha)[6]
        : "...",
      pv: count_orders_of_Etlobha
        ? Object.values(count_orders_of_Etlobha)[6]
        : 0,
    },
  ];
  return (
    <div className="h-full mt-4 md:mt-8">
      <div className="flex h-full gap-4">
        <div className="mt-8" style={{ width: "100%", height: "200px" }}>
          <ResponsiveContainer>
            <LineChart width={600} height={100} data={data}>
              <CartesianGrid horizontal={false} />
              <XAxis
                axisLine={false}
                dataKey="name"
                interval={0}
                tickLine={false}
                dx={6}
              />
              <YAxis
                scale="sqrt"
                tickMargin="60"
                tickLine={false}
                axisLine={false}
                interval="preserveStartEnd"
                orientation={"right"}
                tickFormatter={(e) => {
                  return `${e}`;
                }}
              />

              <Line
                type=""
                dot={false}
                dataKey="pv"
                strokeWidth={3}
                stroke="#1dbbbe99"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default GraphSec;
