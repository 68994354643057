import { configureStore } from "@reduxjs/toolkit";

// Apis
import { loginApi } from "./apiSlices/loginApi";
import { logOutApi } from "./apiSlices/logOutApi";
import { profileApi } from "./apiSlices/profileApi";
import { mainPageApi } from "./apiSlices/mainPageApi";
import { etlobhaApi } from "./apiSlices/etlobhaApi";
import { statisticsApi } from "./apiSlices/statisticsApi";
import { talabatEtlobhaApi } from "./apiSlices/talabatEtlobhaApi";
import { platformApi } from "./apiSlices/platformApi";
import { stockApi } from "./apiSlices/stockApi";
import { marketsApi } from "./apiSlices/marketsApi";
import { selectorApi } from "./apiSlices/selectorApi";
import { productsApi } from "./apiSlices/productsApi";
import { verificationApi } from "./apiSlices/verificationApi";
import { subscriptionsApi } from "./apiSlices/subscriptionsApi";
import { servicesApi } from "./apiSlices/servicesApi";
import { coursesApi } from "./apiSlices/coursesApi";
import { templateApi } from "./apiSlices/templateApi";
import { pagesApi } from "./apiSlices/pagesApi";
import { commonQuestionsApi } from "./apiSlices/commonQuestionsApi";
import { usersApi } from "./apiSlices/usersApi";
import { rolesApi } from "./apiSlices/rolesApi";
import { couponsApi } from "./apiSlices/couponsApi";
import { marketerApi, marketersApi } from "./apiSlices/marketersApi";
import { categoriesApi } from "./apiSlices/categoriesApi";
import { websiteOrdersApi } from "./apiSlices/websiteOrdersApi";
import { technicalSupportApi } from "./apiSlices/technicalSupportApi";
import { shippingTypeApi } from "./apiSlices/shippingTypeApi";
import { paymentTypeApi } from "./apiSlices/paymentTypeApi";
import { settingsApi } from "./apiSlices/settingsApi";
import { countriesApi } from "./apiSlices/countriesApi";
import { citiesApi } from "./apiSlices/citiesApi";
import { currencyApi } from "./apiSlices/currencyApi";
import { emailIndexApi } from "./apiSlices/emailIndexApi";
import { websiteSocialMediaApi } from "./apiSlices/websiteSocialMediaApi";
import { registrationStatusApi } from "./apiSlices/registrationStatusApi";
import { notificationsApi } from "./apiSlices/notificationsApi";
import { seoApi } from "./apiSlices/seoApi";
import { reviewsApi } from "./apiSlices/reviewsApi";
import { deleteApi } from "./apiSlices/deleteApi";
import { contactUsApi } from "./apiSlices/contactUsApi";
import { domainOrdersApi } from "./apiSlices/domainOrdersApi";

// store
export const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			loginApi.middleware,
			logOutApi.middleware,
			profileApi.middleware,
			etlobhaApi.middleware,
			mainPageApi.middleware,
			talabatEtlobhaApi.middleware,
			statisticsApi.middleware,
			platformApi.middleware,
			stockApi.middleware,
			marketsApi.middleware,
			selectorApi.middleware,
			productsApi.middleware,
			verificationApi.middleware,
			subscriptionsApi.middleware,
			servicesApi.middleware,
			coursesApi.middleware,
			templateApi.middleware,
			pagesApi.middleware,

			contactUsApi.middleware,
			commonQuestionsApi.middleware,
			usersApi.middleware,
			rolesApi.middleware,
			couponsApi.middleware,
			marketersApi.middleware,
			categoriesApi.middleware,
			websiteOrdersApi.middleware,
			technicalSupportApi.middleware,
			shippingTypeApi.middleware,
			paymentTypeApi.middleware,
			settingsApi.middleware,
			countriesApi.middleware,
			citiesApi.middleware,
			currencyApi.middleware,
			emailIndexApi.middleware,
			websiteSocialMediaApi.middleware,
			registrationStatusApi.middleware,
			notificationsApi.middleware,
			domainOrdersApi.middleware,
			seoApi.middleware,
			reviewsApi.middleware,
			deleteApi.middleware
		),
	reducer: {
		[loginApi.reducerPath]: loginApi.reducer,
		[logOutApi.reducerPath]: logOutApi.reducer,
		[profileApi.reducerPath]: profileApi.reducer,
		[etlobhaApi.reducerPath]: etlobhaApi.reducer,
		[mainPageApi.reducerPath]: mainPageApi.reducer,
		[statisticsApi.reducerPath]: statisticsApi.reducer,
		[talabatEtlobhaApi.reducerPath]: talabatEtlobhaApi.reducer,
		[platformApi.reducerPath]: platformApi.reducer,
		[stockApi.reducerPath]: stockApi.reducer,
		[marketsApi.reducerPath]: marketsApi.reducer,
		[selectorApi.reducerPath]: selectorApi.reducer,
		[productsApi.reducerPath]: productsApi.reducer,
		[verificationApi.reducerPath]: verificationApi.reducer,
		[subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
		[servicesApi.reducerPath]: servicesApi.reducer,
		[coursesApi.reducerPath]: coursesApi.reducer,
		[templateApi.reducerPath]: templateApi.reducer,
		[pagesApi.reducerPath]: pagesApi.reducer,
		[contactUsApi.reducerPath]: contactUsApi.reducer,
		[usersApi.reducerPath]: usersApi.reducer,
		[rolesApi.reducerPath]: rolesApi.reducer,
		[couponsApi.reducerPath]: couponsApi.reducer,
		[marketersApi.reducerPath]: marketersApi.reducer,
		[categoriesApi.reducerPath]: categoriesApi.reducer,
		[websiteOrdersApi.reducerPath]: websiteOrdersApi.reducer,
		[commonQuestionsApi.reducerPath]: commonQuestionsApi.reducer,
		[technicalSupportApi.reducerPath]: technicalSupportApi.reducer,
		[shippingTypeApi.reducerPath]: shippingTypeApi.reducer,
		[paymentTypeApi.reducerPath]: paymentTypeApi.reducer,
		[settingsApi.reducerPath]: settingsApi.reducer,
		[countriesApi.reducerPath]: countriesApi.reducer,
		[citiesApi.reducerPath]: citiesApi.reducer,
		[currencyApi.reducerPath]: currencyApi.reducer,
		[emailIndexApi.reducerPath]: emailIndexApi.reducer,
		[websiteSocialMediaApi.reducerPath]: websiteSocialMediaApi.reducer,
		[registrationStatusApi.reducerPath]: registrationStatusApi.reducer,
		[notificationsApi.reducerPath]: notificationsApi.reducer,
		[seoApi.reducerPath]: seoApi.reducer,
		[reviewsApi.reducerPath]: reviewsApi.reducer,
		[deleteApi.reducerPath]: deleteApi.reducer,
		[domainOrdersApi.reducerPath]: domainOrdersApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
		// [profileApi.reducerPath]: profileApi.reducer,
	},
});
