import React, { useEffect, useState } from "react";

// third party
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

// components
import Button from "../../../../UI/Button/Button";
import { TripDescriptionEditor } from "./TextEditor";

// icons
import { IoMdCloseCircleOutline } from "react-icons/io";

// css styles
import styles from "./AddBusinessTripProgram.module.css";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-50 w-full h-full opacity-50 back_drop bg-slate-900 '></div>
	);
};

const AddBusinessTripProgram = ({
	setOpenModal,
	tripProgram,
	setTripProgram,
	editData,
}) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	useEffect(() => {
		if (editData) {
			setTitle(editData.title);
			setDescription(editData.description);
		}
	}, [editData]);

	const handleTripProgram = () => {
		if (!title.trim()) {
			toast.error("ادخل عنوان الفقره", { theme: "light" });
			return;
		}
		if (!description.trim()) {
			toast.error("ادخل وصف الفقره", { theme: "light" });
			return;
		}

		const updatedItem = {
			title: title.trim(),
			description: description.trim(),
		};

		if (editData) {
			// Edit existing item
			const updatedTripProgram = tripProgram.map((item) =>
				item.id === editData.id ? { ...item, ...updatedItem } : item
			);
			setTripProgram(updatedTripProgram);
			toast.success("تم تحديث الفقرة بنجاح", { theme: "light" });
		} else {
			// Add new item
			const newItem = {
				...updatedItem,
				id: Date.now(), // Generate a unique id
			};
			setTripProgram([...tripProgram, newItem]);
			toast.success("تمت إضافة الفقرة بنجاح", { theme: "light" });
		}

		handleClosePage();
	};

	// handle close page
	const handleClosePage = () => {
		setTitle("");
		setOpenModal(false);
		setDescription("");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | اضافة محتوي البرنامج </title>
			</Helmet>
			<BackDrop onClick={() => setOpenModal(false)} />
			<div
				className=' sticky z-50 flex  flex-col  rounded-lg  md:top-22 top-20 translate-x-0 '
				style={{ width: "60.25rem", maxWidth: "99%" }}>
				<div
					className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'
					style={{ backgroundColor: "#1DBBBE" }}>
					<h2 className='text-slate-50 md:text-xl text-[16px] text-center'>
						اضافه محتوي البرنامج
					</h2>
					<IoMdCloseCircleOutline
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={() => setOpenModal(false)}
					/>
				</div>

				<div
					className='flex-1 p-4 py-8  md:pb-6 '
					style={{ backgroundColor: "#F6F6F6" }}>
					<div
						className={` w-full mx-auto gap-4 justify-center  ${styles.inputs}`}>
						<div className='flex flex-col gap-[2px] mb-10'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								عنوان الفقرة <span className='text-red-500'>*</span>
							</label>
							<input
								className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
								style={{
									border: "1px solid #67747B33",
								}}
								type='text'
								name='title'
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
						</div>

						<div className='flex flex-col gap-[2px] mb-8'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								وصف الفقرة <span className='text-red-500'>*</span>
							</label>
							<div className={styles.editor}>
								<TripDescriptionEditor
									tripDescription={description}
									setTripDescription={setDescription}
									placeholder={"ادخل وصف الفقرة..."}
								/>
							</div>
						</div>
						<div className='flex md:flex-row flex-col gap-4'>
							<Button
								onClick={handleTripProgram}
								type={"normal"}
								className={"text-center w-full md:h-14 h-[45px]"}>
								حفظ
							</Button>
							<Button
								onClick={handleClosePage}
								type={"outline"}
								className={"text-center w-full md:h-14 h-[45px]"}>
								إلغاء
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddBusinessTripProgram;
