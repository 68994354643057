import React, { useContext, useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import FaqsTable from "../../components/FaqsComp/FaqsTable/FaqsTable";
/* Custom Button */
import Button from "../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useGetCommonQuestionsQuery } from "../../RTK/apiSlices/commonQuestionsApi";

function Faqs() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: fetchedData, isLoading: loading } = useGetCommonQuestionsQuery({
    page,
    number: rowsPerPage,
  });

  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;
  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | الأسئلة الشائعة</title>
      </Helmet>
      <div
        className={`p-4 2xl:pl-36 pl-4`}
        style={{ backgroundColor: "#fafafa" }}
      >
        <div className="flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4">
          <PageNavigate currentPage={"الأسئلة الشائعة"} />
          {permissions?.includes("admin.commonquestion.store") && (
            <Button
              className={
                "md:h-14 h-[45px] md:w-44 w-full flex justify-center items-center text-xl"
              }
              type={"normal"}
              svg={<AiOutlinePlus color="#fff" className="w-5 h-5" />}
              style={{ backgroundColor: "#02466A" }}
              color={"white"}
              onClick={() => {
                navigate("/faqs/add-question");
              }}
            >
              اضافة سؤال
            </Button>
          )}
        </div>
        <div dir={"ltr"} className="mt-5 md:mt-20 pb-30">
          <FaqsTable
            fetchedData={fetchedData}
            loading={loading}
            pageNumber={page}
            setPageNumber={setPage}
            currentPage={rowsPerPage}
            setCurrentPage={setRowsPerPage}
            page_count={fetchedData?.page_count}
          />
        </div>
      </div>
    </>
  );
}

export default Faqs;
