import React from "react";
/* Third party */
import { DatePicker as DateRange } from "antd";
import moment from "moment/moment";
/* MUI */
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
/* Icons */
import { DataIcon } from "../../../../assets/Icons/index";

const DatePicker = ({ getStartDate2, getEndDate2 }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        spacing={3}
        sx={{
          flexDirection: "row",
          "&:has(.ant-picker-focused) .suffix_icon": {
            backgroundColor: "#0BF1D1 !important",
          },
          "& .ant-picker": {
            borderColor: "rgb(2, 70, 106)",
            transition: "0.2s",
          },
          "& .ant-picker.ant-picker-focused": {
            borderColor: "#0BF1D1 !important",
          },
        }}
      >
        <div
          className="w-20 flex suffix_icon duration-200 justify-center h-[48px] p-2 items-center rounded-r-md"
          style={{ backgroundColor: "#02466A" }}
        >
          <DataIcon />
        </div>
        <DateRange.RangePicker
          className="main_page_date_picker rounded-none rounded-l-lg  mt-0  h-[48px]  lg:w-[538px] w-full"
          style={{
            backgroundColor: "rgb(239, 249, 255)",
          }}
          placeholder={["اختر الفترة من إلى", ""]}
          allowEmpty={[true, true]}
          onChange={(e) => {
            getStartDate2(moment(new Date(e[0])).format("YYYY-MM-DD"));
            getEndDate2(moment(new Date(e[1])).format("YYYY-MM-DD"));
          }}
          suffixIcon={""}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default DatePicker;
