import React, { useState, useContext, useEffect } from "react";
import styles from "./TraderAlert.module.css";
/* Third party */
/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
import { TextEditorContext } from "../../../../store/TextEditorProvider";
/* Components */
import { TextEditor } from "../../../TextEditor";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { FiSend } from "react-icons/fi";
import { useAddNewEmailIndexMutation } from "../../../../RTK/apiSlices/emailIndexApi";

const BackDrop = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900"
    ></div>
  );
};

const TraderAlert = ({ cancel, traderPackageDetails, showEmailInfo }) => {
  const userEmail = traderPackageDetails?.store?.user?.email;
  const LoadingStore = useContext(LoadingContext);
  const { setLoadingTitle } = LoadingStore;
  const editorContent = useContext(TextEditorContext);
  const { editorValue, setEditorValue } = editorContent;

  const [subject, setSubject] = useState("");
  const [DataError, setDataError] = useState({
    subject: "",
    message: "",
  });
  const resetData = () => {
    setDataError({
      subject: "",
      message: "",
    });
  };

  useEffect(() => {
    if (showEmailInfo) {
      setEditorValue(traderPackageDetails?.message);
    } else {
      setEditorValue("");
    }
  }, [traderPackageDetails?.message]);

  const [addNewEmailIndex] = useAddNewEmailIndexMutation();

  const addNewEmailIndexHandel = async (data) => {
    try {
      const res = await addNewEmailIndex({
        body: data,
      });
      if (res?.data?.success === true && res?.data?.data?.status === 200) {
        setLoadingTitle(null);

        cancel();
      } else {
        setLoadingTitle(null);
        setDataError({
          subject: res?.data?.message?.en?.subject?.[0],
          message: res?.data?.message?.en?.message?.[0],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // add email function
  const addEmail = () => {
    resetData();
    setLoadingTitle("جاري ارسال تنبيه للتاجر ");
    const data = {
      subject: subject,
      message: editorValue,
      store_id: traderPackageDetails?.store?.id,
    };
    addNewEmailIndexHandel(data);
  };
  return (
    <>
      <BackDrop onClick={cancel} />
      <div
        className="fixed trader_alert   flex flex-col top-[55%] translate-x-2/4 -translate-y-2/4 right-2/4 z-20 rounded-2xl overflow-hidden"
        style={{ width: "51.25rem", maxWidth: "90%", maxHeight: "662px" }}
      >
        <div
          className="flex items-center justify-center w-full h-16 px-4 py-4 trader_alert"
          style={{ backgroundColor: "#1DBBBE" }}
        >
          <h2
            style={{ color: "#ECFEFF" }}
            className="md:text-[22px] text-[18px] font-medium text-center"
          >
            {showEmailInfo ? "تفاصيل الرسالة " : "ارسال تنبيه للتاجر"}
          </h2>
        </div>
        <div className="flex-1 pb-4" style={{ backgroundColor: "#FAFAFA" }}>
          <div
            style={{
              backgroundColor: "#F4F5F7",
              border: "1px solid #67747B33",
            }}
            className="flex flex-row items-center gap-4 px-5 py-4"
          >
            <h2
              className="md:text-[20px] text-[16px] font-medium"
              style={{ color: "#011723" }}
            >
              إلى
            </h2>
            <span
              className="md:text-[20px] text-[16px] font-medium"
              style={{ color: "#67747B" }}
            >
              {userEmail}
            </span>
          </div>
          <textarea
            disabled={showEmailInfo && true}
            style={{ color: "#67747B" }}
            className="w-full md:text-[18px] text-[16px] p-4 text-md font-medium outline-none"
            value={showEmailInfo ? traderPackageDetails?.subject : subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="الموضوع"
            rows={3}
          ></textarea>
          <span className="text-red-500 text-[16px] px-3">
            {DataError?.subject}
          </span>
          <div
            style={{
              backgroundColor: "#F4F5F7",
              border: "1px solid #67747B33",
            }}
            className="flex flex-row items-center gap-4 px-5 py-4"
          >
            <h2 className="md:text-[20px] text-[16px] font-medium">
              نص الرسالة
            </h2>
          </div>
          <div className={styles.editor}>
            {showEmailInfo ? (
              <TextEditor
                ToolBar={"TraderAlert"}
                readOnly={true}
                placeholder={"تفاصيل الرسالة"}
              />
            ) : (
              <TextEditor
                ToolBar={"TraderAlert"}
                placeholder={
                  " صديقنا التاجر، باقي 20 يوم على انتهاء اشتراكك تواصل مع الدعم الفني للحصول على كود خصم لتجديد اشتراكك "
                }
              />
            )}
          </div>
          <span className="text-red-500 text-[16px] px-3">
            {DataError?.message}
          </span>
          {showEmailInfo ? (
            <div className="flex justify-center gap-5">
              <Button
                type={"outline"}
                className={"md:text-[20px] text-[16px] text-center  mt-12"}
                style={{ borderColor: "#02466A" }}
                textStyle={{ color: "#02466A" }}
                onClick={cancel}
              >
                إغلاق
              </Button>
            </div>
          ) : (
            <div className="flex justify-center gap-5">
              <Button
                onClick={addEmail}
                type={"normal"}
                className={"md:text-[20px] text-[16px] text-center mt-12"}
                style={{ backgroundColor: "#02466A" }}
                svg={<FiSend color={"#fff"} />}
              >
                ارسال
              </Button>
              <Button
                type={"outline"}
                className={"md:text-[20px] text-[16px] text-center  mt-12"}
                style={{ borderColor: "#02466A" }}
                textStyle={{ color: "#02466A" }}
                onClick={cancel}
              >
                الغاء
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TraderAlert;
