import React, { useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import ChangeLogoSec from "../../components/TemplatePageComps/MainPage/ChangeLogoSec/ChangeLogoSec";
import ChangeBannerSec from "../../components/TemplatePageComps/MainPage/ChangeBannerSec/ChangeBannerSec";
import { useGetHomePageDataQuery } from "../../RTK/apiSlices/templateApi";

const TemplatePage = () => {
	// Fetch data from API
	const { data: fetchedData, isLoading: loading } = useGetHomePageDataQuery()

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | القالب</title>
			</Helmet>
			<div
				className={`px-4 md:pt-4 pt-0 pb-10`}
				style={{ backgroundColor: "#fafafa" }}>
				<div
					className='md:pr-5 py-3 px-2 rounded font-normal md:text-lg text-[14px]'
					style={{ color: "#EFF9FF", backgroundColor: "#237EAE" }}>
					هذه الواجهة خاصة بإعدادات الصفحة الرئيسية للموقع الإلكتروني
				</div>
				<div className='mt-4 md:mt-6'>
					<PageNavigate currentPage={"تنسيق القالب"} />
				</div>
				<div>
					{/** change logo  */}
					{permissions?.includes("admin.homepage.logoUpdate") && (
						<ChangeLogoSec
							fetchedData={fetchedData}
							loading={loading}
						/>
					)}

					{/** change banners  */}
					{permissions?.includes("admin.homepage.banarUpdate") && (
						<ChangeBannerSec
							fetchedData={fetchedData}
							loading={loading}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default TemplatePage;
