import React from "react";

// MUI
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

// Product Table
const headCells = [
	{
		id: "opened",
		textAlign: "right",
		label: "م",
	},
	{
		id: "product",
		textAlign: "right",
		label: "المنتج",
	},

	{
		id: "quantity",
		textAlign: "center",
		label: "الكمية",
	},

	{
		id: "totalPrice",
		textAlign: "left",
		label: "الإجمالي",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#1DBBBE" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='text-lg font-medium'
						key={headCell.id}
						align={headCell?.textAlign}
						padding={"normal"}
						sx={{
							color: "#fff",
						}}>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const OrderProductsInfo = ({ order }) => {
	return (
		<div className='p-4 mb-8 bg-white shadow-sm rounded-xl'>
			<div className='flex items-center justify-between '>
				<h3 className='mb-3 text-2xl font-medium text-gray-900 '>
					تفاصيل المنتجات
				</h3>
				<h3 className='mb-3 text-lg font-normal text-gray-900 '>
					عدد القطع
					<div className='flex justify-center items-center shadow-sm md:rounded-md rounded-sm text-center text-[16px] font-normal bg-slate-50 py-1 px-2 border-[1px] border-[#f9f8f8]'>
						{order?.totalCount} قطعة
					</div>
				</h3>
			</div>

			<div className='flex justify-start items-center gap-5 flex-wrap mb-4 rounded-s-md  rounded-e-md border-[1px] border-[#f9f8f8]'>
				<TableContainer
					sx={{
						borderRadius: "inherit",
					}}>
					<Table>
						<EnhancedTableHead />
						<TableBody>
							{order?.orderItem?.map((row, index) => {
								return (
									<TableRow hover tabIndex={-1} key={row?.id}>
										<TableCell
											align='right'
											sx={{
												padding: "10px 16px",
												borderBottom: "1px solid rgb(247 247 247)",
											}}
											className='font-normal md:text-lg text-[16px]'>
											{(index + 1).toLocaleString("en-US", {
												minimumIntegerDigits: 2,
												useGrouping: false,
											})}
										</TableCell>

										<TableCell
											align='right'
											sx={{
												padding: "10px 16px",
												borderBottom: "1px solid rgb(247 247 247)",
											}}
											className='max-w-[240px]'>
											<div className='flex flex-row items-center justify-end gap-3'>
												<div
													className='img_icons'
													style={{
														minWidth: "35px",
														minHeight: "35px",
														maxWidth: "35px",
														maxHeight: "35px",
													}}>
													<img
														src={row?.product?.cover}
														alt=''
														loading='lazy'
													/>
												</div>
												<h2
													className='w-full md:text-[18px] text-[16px] font-normal overflow-hidden whitespace-nowrap'
													style={{
														color: "#4D4F5C",

														textOverflow: "ellipsis",
														direction: "rtl",
													}}>
													{row?.product?.name}
													<div className='options'>
														{row?.options?.map((option, index) => (
															<span
																className='text-sm font-normal text-gray-500 '
																key={index}>{`${
																index === 0 ? `${option}` : `/ ${option}`
															}`}</span>
														))}
													</div>
												</h2>
											</div>
										</TableCell>

										<TableCell
											align='center'
											sx={{
												padding: "10px 16px",
												borderBottom: "1px solid rgb(247 247 247)",
											}}>
											<h2 className='font-normal md:text-lg text-[16px]'>
												<span> {row?.quantity}</span>

												<span className='text-sm font-normal text-gray-500'>
													{" × "} {row?.price} ر.س
												</span>
											</h2>
										</TableCell>

										<TableCell
											align='left'
											sx={{
												padding: "10px 16px",
												borderBottom: "1px solid rgb(247 247 247)",
											}}>
											<h2 className='font-normal md:text-lg text-[16px]'>
												{row?.sum} ر.س
											</h2>
										</TableCell>
									</TableRow>
								);
							})}
							<TableRow>
								<TableCell
									sx={{
										border: "none",
										padding: "10px 16px",
									}}
									colSpan={3}
									component='th'
									scope='row'
									align='right'
									className='md:font-medium font-normal md:text-lg text-[16px]'>
									السعر
								</TableCell>
								<TableCell
									sx={{
										border: "none",
										padding: "10px 16px",
									}}
									component='th'
									scope='row'
									align='left'
									className='font-medium md:text-lg text-[16px] '>
									{order?.subtotal} ر.س
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell
									sx={{
										border: "none",
										padding: "10px 16px",
									}}
									colSpan={3}
									component='th'
									scope='row'
									align='right'
									className='md:font-medium font-normal md:text-lg text-[16px]'>
									الضريبة
								</TableCell>

								<TableCell
									sx={{
										border: "none",
										padding: "10px 16px",
									}}
									component='th'
									scope='row'
									align='left'
									className='font-medium md:text-lg text-[16px]'>
									{order?.tax} ر.س
								</TableCell>
							</TableRow>

							{order?.overweight !== 0 && (
								<TableRow>
									<TableCell
										sx={{
											border: "none",
											padding: "10px 16px",
										}}
										colSpan={3}
										component='th'
										scope='row'
										align='right'
										className=' md:font-medium font-normal md:text-lg text-[16px]'>
										تكلفة الوزن الزائد ({order?.overweight} kg)
									</TableCell>

									<TableCell
										sx={{
											border: "none",
											padding: "10px 16px",
										}}
										component='th'
										scope='row'
										align='left'
										className='font-medium md:text-lg text-[16px] '>
										{order?.overweight_price}
										ر.س
									</TableCell>
								</TableRow>
							)}

							<TableRow>
								<TableCell
									sx={{
										border: "none",
										padding: "10px 16px",
									}}
									colSpan={3}
									component='th'
									scope='row'
									align='right'
									className='md:font-medium font-normal md:text-lg text-[16px]'>
									الشحن
								</TableCell>

								<TableCell
									sx={{
										border: "none",
										padding: "10px 16px",
									}}
									component='th'
									scope='row'
									align='left'
									className='font-medium md:text-lg text-[16px]'>
									{order?.shipping_price} ر.س
								</TableCell>
							</TableRow>

							{order?.discount !== 0 && (
								<TableRow>
									<TableCell
										sx={{
											border: "none",
											padding: "10px 16px",
										}}
										colSpan={3}
										component='th'
										scope='row'
										align='right'
										className=' md:font-medium font-normal md:text-lg text-[16px]'>
										الخصم
									</TableCell>

									<TableCell
										sx={{
											border: "none",
											padding: "10px 16px",
										}}
										component='th'
										scope='row'
										align='left'
										className='font-medium md:text-lg text-[16px] '>
										{order?.discount}
										ر.س
									</TableCell>
								</TableRow>
							)}

							<TableRow>
								<TableCell
									sx={{
										border: "none",
										backgroundColor: "#f3f3f3",
										padding: "10px 16px",
									}}
									colSpan={3}
									component='th'
									scope='row'
									align='right'
									className='md:text-lg text-[16px] font-bold'>
									الإجمالي
								</TableCell>

								<TableCell
									sx={{
										border: "none",
										backgroundColor: "#f3f3f3",
										padding: "10px 16px",
									}}
									component='th'
									scope='row'
									align='left'
									className='md:text-lg text-[16px] font-bold '>
									{order?.total_price} ر.س
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
};

export default OrderProductsInfo;
