import React from 'react';
import styles from "./Suggestions.module.css";
/* Third party */
import { Link } from 'react-router-dom';

function Suggestions({ data, setSuggestions, setSearch }) {
    return (
        <div className='absolute w-full top-[55px] left-0 bg-white shadow-lg rounded-sm z-10'>
            <ul className={styles.suggestions}>
                {data?.map((item) => (
                    <li className='sm:p-3 p-2 hover:bg-[#00000008] border-b-[1px] border-solid border-gray-100' key={item?.id}>
                        <Link className='flex flex-row items-center gap-2' to={`/${item?.link}`} onClick={() => { setSuggestions(null); setSearch(""); }}>{item?.icon} {item?.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Suggestions