import React from "react";
import styles from "./LoadingPage.module.css";
import Logo from "../../assets/images/main_logo.png";

function LoadingPage() {
	return (
		<div className={styles.Loading}>
			<div className={styles.Logo}>
				<img width='100%' height='100%' src={Logo} alt='logo' />
			</div>
		</div>
	);
}

export default LoadingPage;
