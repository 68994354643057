import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const deleteApi = createApi({
  reducerPath: "deleteApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin",
  }),
  tagTypes: ["Delete"],

  endpoints: (builder) => ({

    deleteItem: builder.mutation({
      query: (url) => ({
        url,
        method: 'DELETE',
      }),
    }),

    getDeleteItem: builder.mutation({
      query: (url) => ({
        url: url,
        method: "GET",
      }),
    }),

  }),
});

// Export endpoints and hooks
export const {
  useGetDeleteItemMutation,
  useDeleteItemMutation,
} = deleteApi;
