import React from "react";
import ReactQuill from "react-quill";

// Css Styles
import styles from "./TripDescriptionEditor.module.css";
import "react-quill/dist/quill.snow.css";

const TripDescriptionEditor = ({
	placeholder,
	tripDescription,
	setTripDescription,
}) => {
	const toolbarOptions = [
		["italic", "underline", "bold"],
		[{ align: [] }],
		[{ background: [] }, { color: [] }],
		[{ list: "ordered" }, { list: "bullet" }],
		["link"],
	];

	return (
		<div className={styles.textEditor}>
			<ReactQuill
				theme='snow'
				name='description'
				dir='rtl'
				value={tripDescription}
				placeholder={placeholder}
				onChange={setTripDescription}
				modules={{ toolbar: toolbarOptions }}
			/>
		</div>
	);
};

export default TripDescriptionEditor;
