import React from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import InputsComps from "../../../components/SettingComp/PrimarySettingComp/InputsComps/InputsComps";

const PrimarySettings = () => {
  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | الاعدادات الاساسية</title>
      </Helmet>
      <div className={`p-4`} style={{ backgroundColor: "#F7F7F7" }}>
        <PageNavigate
          nestedPage={true}
          parentPage={"الاعدادات"}
          currentPage={"الاعدادات الاساسية"}
        />
        <InputsComps></InputsComps>
      </div>
    </>
  );
};

export default PrimarySettings;
