import React, { useState } from "react";
/* Custom Hook */

/* Components */
import TableComp from "../../../components/SettingComp/EmailPageComp/TableComp/TableComp";
import TraderAlert from "../../../components/SettingComp/EmailPageComp/TraderAlert/TraderAlert";
import { useGetEmailIndexQuery } from "../../../RTK/apiSlices/emailIndexApi";

const EmailSettingPage = () => {
	// get data from api

	const { data: fetchedData, isLoading: loading } = useGetEmailIndexQuery()

	const [traderAlert, setTraderAlert] = useState(false);
	const [traderPackageDetails, setTraderPackageDetails] = useState([]);
	const [showEmailInfo, setShowEmailInfo] = useState(false);

	return (
		<div>
			{traderAlert && (
				<TraderAlert
					cancel={() => {
						setTraderAlert(false);
					}}
					traderPackageDetails={traderPackageDetails}
					showEmailInfo={showEmailInfo}
				/>
			)}
			<div>
				<TableComp
					loading={loading}
					fetchedData={fetchedData}
					setShowEmailInfo={setShowEmailInfo}
					openTraderAlert={(row) => {
						setTraderAlert(true);
						setTraderPackageDetails(row);
					}}
				/>
			</div>
		</div>
	);
};

export default EmailSettingPage;
