import React from "react";

/* MUI */
import { FormControl, MenuItem, Select } from "@mui/material";

// Icons
import { IoIosArrowDown } from "react-icons/io";

/** select Style */
const selectStyle = {
  height: "56px",
  pl: "0.5rem",
  borderRadius: "6px",
  border: "1px solid #f9f8f8",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    paddingRight: "10px",
  },
};

// order status array
const orderStatusArray = [
  {
    id: 1,
    name_en: "ready",
    name_ar: "قيد التجهيز (يرجى ملء بيانات الشحنة أولاً)",
  },
  { id: 2, name_en: "completed", name_ar: "تم الشحن" },
  {
    id: 3,
    name_en: "canceled",
    name_ar: " إلغاء الشحنة (إلغاء الطلب بالكامل) ",
  },
];

const ChangeOrderStatus = ({
  order,
  shipping,
  shippingErrors,
  updateOrderStatus,
  handleOnChangeShipping,
}) => {
  return (
    <>
      <FormControl className="md:w-[35%] w-full">
        <div>
          تعديل حالة الطلب
          {shippingErrors?.status && (
            <span className="text-[14px] text-normal text-red-500">
              ({shippingErrors?.status})
            </span>
          )}
        </div>

        <Select
          className="text-lg font-normal md:h-14 h-10 pr-3 border-[#bbbbbb] "
          IconComponent={() => (
            <IoIosArrowDown
              className="duration-200 select_arrow"
              size={"1.2rem"}
            />
          )}
          name="status"
          value={shipping?.status}
          onChange={handleOnChangeShipping}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={(selected) => {
            if (shipping?.status === "") {
              return <p className="text-[#333333]">جديد</p>;
            }
            const result =
              orderStatusArray?.filter(
                (status) => status?.name_en === selected
              ) || "";
            return result[0]?.name_ar || shipping?.status;
          }}
          sx={selectStyle}
        >
          {orderStatusArray?.map((status, index) => (
            <MenuItem
              key={index}
              className="souq_storge_category_filter_items"
              sx={{
                backgroundColor: "rgba(211, 211, 211, 1)",
                height: "3rem",
              }}
              disabled={
                (order?.status === "جديد" && status?.name_en === "completed") ||
                (order?.status === "قيد التجهيز" && status?.name_en === "ready")
                  ? true
                  : false
              }
              value={status?.name_en}
            >
              {status?.name_ar}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="flex-1 w-full ">
        <button
          disabled={
            (order?.status === "ملغي" || order?.status === "مكتمل"
              ? true
              : false) || shipping?.status === ""
          }
          onClick={updateOrderStatus}
          className={` ${
            order?.status === "ملغي" || order?.status === "مكتمل"
              ? " cursor-not-allowed"
              : "cursor-pointer"
          }  text-lg font-medium  w-full h-10 md:h-14  text-white bg-[#1DBBBE] py-2 p-3 rounded-md `}
        >
          تعديل الحالة
        </button>
      </div>
    </>
  );
};

export default ChangeOrderStatus;
