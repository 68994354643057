import React, { useEffect, useState } from "react";
import TableComp from "./TableComp/TableComp";
import { useAdminRolesQuery } from "../../RTK/apiSlices/selectorApi";
import { useFilterUsersByRoleMutation } from "../../RTK/apiSlices/usersApi";
import UserFilterForm from "./UserFilterForm";

const UsersTable = ({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
}) => {
	const { data: roleList } = useAdminRolesQuery();
	const [role, setRole] = React.useState("");

	const handleCategory = (event) => {
		setRole(event.target.value);
	};

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [filterResults, setFilterResults] = useState("");

	let resultData = fetchedData?.users || [];
	let filterData = resultData;

	if (search !== "") {
		resultData = searchResults?.users;
	} else {
		resultData = fetchedData?.users;
	}

	const findIdRole = (array, itemName) => {
		return array.find((item) => item.name === itemName).id;
	};

	const [filterUsersByRole, { isLoading: filterIsLoading }] =
		useFilterUsersByRoleMutation();
	const filterUsersByRoleHandel = async () => {
		try {
			const response = await filterUsersByRole({
				page: pageNumber,
				number: currentPage,
				id: findIdRole(roleList.roles, role),
			});
			setFilterResults(response?.data?.data);
			setSearch("");
		} catch (error) {
			console.error("Error filtering orders:", error);
		}
	};

	useEffect(() => {
		if (role) {
			filterUsersByRoleHandel();
		} else {
			resultData = fetchedData?.users;
		}
	}, [role, pageNumber, currentPage]);

	if (role !== "") {
		filterData = filterResults?.users;
	} else {
		filterData = resultData;
	}

	return (
		<div className='mt-4 md:xl-10'>
			<UserFilterForm
				role={role}
				handleCategory={handleCategory}
				setFilterResults={setFilterResults}
				roleList={roleList}
				search={search}
				setSearch={setSearch}
			/>

			<div dir={"ltr"}>
				<TableComp
					fetchedData={filterData}
					loading={loading || filterIsLoading}
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={
						filterResults ? filterResults.page_count : fetchedData?.page_count
					}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
					role={role}
					setRole={setRole}
					setFilterResults={setFilterResults}
				/>
			</div>
		</div>
	);
};

export default UsersTable;
