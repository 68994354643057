import React from "react";
import styles from "./MapChartSec.module.css";
/* Third party */
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import mapDataIE from "@highcharts/map-collection/countries/sa/sa-all.topo.json";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";

highchartsMap(Highcharts);
const MapChartSec = ({ loading, Subscriptions_city }) => {
	const COUNTRIES = Subscriptions_city ? Object.keys(Subscriptions_city) : [];
	const COLORS = [
		"#1DBBBE",
		"#B6BE34",
		"#0077FF",
		"#3AE374",
		"#FF9F1A",
		"#FF3838",
	];

	const PRICES = Subscriptions_city ? Object.values(Subscriptions_city) : [];

	const mapOptions = {
		chart: {
			map: mapDataIE,
			width: 500,
			height: 200,
			className: "home-map",
		},
		title: {
			text: "",
		},
		credits: {
			enabled: false,
		},
		mapNavigation: {
			enabled: true,
			buttonOptions: {
				alignTo: "spacingBox",
				verticalAlign: "bottom",
			},
		},
		legend: {
			enabled: false,
			floating: false,
		},
		plotOptions: {
			mappoint: {
				keys: ["id", "lat", "lon"],
				marker: {
					symbol: "mapmarker",
					radius: 8,
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					headerFormat: "{point.point.id}<br>",
					pointFormat: "المبلغ: {point.value} ر.س",
				},
			},
		},
		series: [
			{
				name: "Saudi Arabia",
				showInLegend: false,
				borderColor: "#A0A0A0",
				nullColor: "#ffffff",
				states: {
					inactive: {
						enabled: false,
					},
				},
			},
			{
				type: "mappoint",
				id: "SA.TB",
				name: COUNTRIES[0],
				color: COLORS[0],
				cursor: "pointer",
				dataLabels: {
					format: "{point.id}",
				},
				data: [
					{
						id: COUNTRIES[0],
						lat: 23.3432,
						lon: 45.1404,
						value: PRICES[0],
					},
				],
			},
			{
				type: "mappoint",
				id: "SA.JZ",
				name: COUNTRIES[1],
				color: COLORS[1],
				cursor: "pointer",
				dataLabels: {
					format: "{point.id}",
				},
				data: [
					{
						id: COUNTRIES[1],
						lat: 21.507,
						lon: 39.224,
						value: PRICES[1],
					},
				],
			},
			{
				type: "mappoint",
				id: "SA.NJ",
				name: COUNTRIES[2],
				color: COLORS[2],
				dataLabels: {
					format: "{point.id}",
				},
				data: [
					{
						id: COUNTRIES[2],
						lat: 21.403,
						lon: 39.87,
						value: PRICES[2],
					},
				],
			},
			{
				type: "mappoint",
				id: "SA.RI",
				name: COUNTRIES[3],
				color: COLORS[3],
				dataLabels: {
					format: "{point.id}",
				},
				data: [
					{
						id: COUNTRIES[3],
						lat: 17.3028,
						lon: 42.726,
						value: PRICES[3],
					},
				],
			},
			{
				type: "mappoint",
				id: "SA.4",
				name: COUNTRIES[4],
				color: COLORS[4],
				dataLabels: {
					format: "{point.id}",
				},
				data: [
					{
						id: COUNTRIES[4],
						lat: 24.158,
						lon: 47.325,
						value: PRICES[4],
					},
				],
			},
			{
				type: "mappoint",
				id: "SA.5",
				name: COUNTRIES[5],
				color: COLORS[5],
				dataLabels: {
					format: "{point.id}",
				},
				data: [
					{
						id: COUNTRIES[5],
						lat: 21.287,
						lon: 40.428,
						value: PRICES[5],
					},
				],
			},
		],
	};

	return (
		<div className='flex md:flex-row flex-col-reverse md:p-4 h-full'>
			{loading ? (
				<div className='w-full flex flex-col items-center justify-center'>
					<CircularLoading />
				</div>
			) : (
				<>
					<div className='flex-1 flex flex-col justify-between'>
						{COUNTRIES?.map((item, idx) => {
							return (
								<div key={idx} className='flex justify-between gap-8'>
									<div className='flex items-center gap-2'>
										<div
											className='h-3 w-3 rounded-full'
											style={{ border: `2px solid ${COLORS[idx]}` }}></div>
										<h2 className='font-normal text-[15px] text-[#4D4F5C]'>
											{item}
										</h2>
									</div>
									<h2 className='font-normal text-[13px] text-[#4D4F5C] whitespace-nowrap'>
										{PRICES[idx]} ريال
									</h2>
								</div>
							);
						})}
					</div>
					<div
						className={`${styles.map_chart} w-full flex-1 flex justify-center items-center mr-3`}>
						<HighchartsReact
							constructorType={"mapChart"}
							highcharts={Highcharts}
							options={mapOptions}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default MapChartSec;
