import React from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
import DynamicMenu from "../../DynamicMenu/DynamicMenu";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useGetServiceDataQuery } from "../../../RTK/apiSlices/servicesApi";

const BackDrop = () => {
	return (
		<div className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const ShowServiceDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: fetchedData, isLoading: loading } = useGetServiceDataQuery({
		showServiceId: id,
	});
	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | تفاصيل الخدمة</title>
			</Helmet>
			<BackDrop />
			<div
				className='fixed z-20 flex flex-col overflow-hidden trader_alert top-1/2 translate-x-2/4 -translate-y-2/4 right-2/4 rounded-2xl'
				style={{ width: "51.25rem", maxWidth: "90%", maxHeight: "500px" }}>
				<div
					className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'
					style={{ backgroundColor: "#02466A" }}>
					<h2
						style={{ color: "#ECFEFF" }}
						className='font-medium md:text-[22px] text-[18px] text-center flex-1'>
						التفاصيل
					</h2>
					<IoMdCloseCircleOutline
						width='20px'
						height='20px'
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={() => {
							navigate("/services");
						}}
					/>
				</div>
				<div
					className='flex-1 md:px-[98px] md:py-[60px] p-0'
					style={{ backgroundColor: "#FFFFFF", overflowY: "auto" }}>
					{loading ? (
						<div className='flex flex-col items-center justify-center w-full '>
							<CircularLoading />
						</div>
					) : fetchedData?.stores?.length !== 0 ? (
						<div className='flex flex-row shadow-lg'>
							<div className='flex flex-col flex-1 py-2'>
								<div
									className='md:h-[60px] h-[45px] flex flex-col items-center justify-center'
									style={{ width: "100%", backgroundColor: "#F2FBFF" }}>
									<p
										className='md:text-[18px] text-[16px]'
										style={{ color: "#4D4F5C" }}>
										اسم المتجر
									</p>
								</div>
								<div className='flex flex-col'>
									{fetchedData?.stores?.map((item, index) => (
										<div
											key={index}
											className='flex flex-col items-center justify-center'
											style={{ width: "100%", height: "52px" }}>
											<p
												className='md:text-[18px] text-[16px]'
												style={{ color: "#4D4F5C" }}>
												{item?.store_name}
											</p>
										</div>
									))}
								</div>
							</div>
							<div className='flex flex-col flex-1 py-2'>
								<div
									className='md:h-[60px] h-[45px] md:text-[18px] text-[16px] flex flex-col items-center justify-center'
									style={{ width: "100%", backgroundColor: "#F2FBFF" }}>
									<p
										className='md:text-[18px] text-[16px]'
										style={{ color: "#4D4F5C" }}>
										الأنشطة
									</p>
								</div>
								<div className='flex flex-col'>
									{fetchedData?.stores?.map((item, index) =>
										item?.activity?.length === 0 ? (
											<div
												style={{ width: "100%", height: "52px" }}
												className='text-[16px] text-[#1dbbbe]  flex items-center justify-center'>
												لا يوجد نشاطات لهذا المتجر
											</div>
										) : (
											<div
												key={index}
												className='flex flex-col items-start justify-center'
												style={{ width: "100%", height: "52px" }}>
												<div className='flex flex-row items-center md:gap-3 gap-2 min-h-[25px] max-h-[25px]'>
													{item?.activity?.[0]?.icon && (
														<img
															src={item?.activity[0]?.icon}
															alt='gift-icon'
															style={{
																minWidth: "25px",
																maxWidth: "25px",
																height: "25px",
																objectFit: "contain",
															}}
															loading='lazy'
														/>
													)}
													<p
														className='md:text-[18px] text-[16px]'
														style={{ color: "#4D4F5C" }}>
														{item?.activity[0]?.name}
													</p>
													{item?.activity?.length > 1 && (
														<DynamicMenu items={item?.activity} />
													)}
												</div>
											</div>
										)
									)}
								</div>
							</div>
						</div>
					) : (
						<div className='flex items-center justify-center md:h-full h-52'>
							لايوجد تفاصيل متعلقه بهذه الخدمة
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ShowServiceDetails;
