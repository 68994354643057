import React from "react";
/* MUI */
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularLoading() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				gap: "1rem",
				direction: "ltr",
			}}>
			<CircularProgress size='24px' /> <span>جاري التحميل</span>
		</Box>
	);
}
