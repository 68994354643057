import React, { useState, useContext, useEffect } from "react";
import styles from "./NewProduct.module.css";
/* Third party */
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import Context from "../../../store/context";
import { LoadingContext } from "../../../store/LoadingProvider";
import { TextEditorContext } from "../../../store/TextEditorProvider";
/* Components */
import { TextEditor } from "../../TextEditor";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
import AddProductOptions from "./AddProductOptions/AddProductOptions";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import IconButton from "@mui/material/IconButton";
/* Icons */
import { IoMdCloudUpload } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import { BsPlayCircle } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import { Currency, AddIcon, Arrow } from "../../../assets/Icons/index";
import { MdInfoOutline } from "react-icons/md";
import { useAddNewEtlobhaProductMutation } from "../../../RTK/apiSlices/etlobhaApi";
import { useAdminSelectorEtlobahCategoryQuery } from "../../../RTK/apiSlices/selectorApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const formTitleClasses = "font-medium md:text-xl text-[16px]";
const formTitleStyle = { width: "315px" };
//
const formInputClasses = "p-4 outline-0 rounded-md text-lg font-normal";
const formInputStyle = {
	width: "555px",
	maxWidth: "100%",
	border: "1px solid rgba(167, 167, 167, 0.5)",
	backgroundColor: "#f6f6f6",
	fontWight: "400",
	color: "#000000",
};

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#1dbbbe",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#1dbbbe",
		whiteSpace: "normal",
	},
}));

const NewProduct = () => {
	const navigate = useNavigate();
	const { data: category } = useAdminSelectorEtlobahCategoryQuery();

	const contextStore = useContext(Context);
	const { productHasOptions, attributes, optionsSection, clearOptions } =
		contextStore;
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;
	const [url, setUrl] = useState("");
	const [nameLength, setNameLength] = useState(false);
	const [shortDescriptionLength, setShortDescriptionLength] = useState(false);
	const [multiImages, setMultiImages] = useState([]);
	const [keyWords, setKeyWords] = useState([]);
	const [productData, setProductData] = useState({
		name: "",
		short_description: "",
		description: "",
		stock: "",
		weight: "",
		cover: "",
		images: [],
		category_id: "",
		slug: "",
		quantity: "",
		subcategory_id: [],
		infinity: 0,
		purchasing_price: "",
		selling_price: "",
		less_qty: "",
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			short_description: "",
			description: "",
			purchasing_price: "",
			selling_price: "",
			stock: "",
			weight: "",
			category_id: "",
			less_qty: "",
		},
	});

	useEffect(() => {
		if (attributes?.length !== 0) {
			const qty = attributes?.reduce(
				(accumulator, attr) => accumulator + attr?.qty,
				0
			);
			const defaultOptions = optionsSection?.map((option) =>
				option?.values?.filter((value) => value?.defaultOption === true)
			);
			const defaultOptionsArray =
				defaultOptions?.map((option) => option?.[0]?.title) || [];
			const matchingObject = attributes?.find(
				(obj) =>
					obj?.values?.length === defaultOptions?.length &&
					obj?.values?.every(
						(value, index) => value?.title === defaultOptionsArray[index]
					)
			);

			setProductData({
				...productData,
				stock: qty,
				purchasing_price: Number(matchingObject?.price) || "",
				less_qty: Number(matchingObject?.less_qty) || "",
			});
		}
	}, [attributes, optionsSection]);

	// handle onChange function to get all values from inputs
	const handleProductData = (e) => {
		const { name, value } = e.target;
		setProductData((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const [showAddProductOptions, setShowAddProductOptions] = useState(false);

	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	// to get multi images
	const emptyMultiImages = [];
	for (let index = 0; index < 5 - multiImages?.length; index++) {
		emptyMultiImages?.push(index);
	}

	const onChangeMultiImages = (imageList, addUpdateIndex) => {
		// Check the size for each image in the list
		const isSizeValid = imageList?.every((image) =>
			image?.image ? true : image?.file?.size <= maxFileSize
		);

		// Check if this file is video
		const isVideo =
			imageList?.[imageList?.length - 1]?.file?.type.startsWith("video/");

		if (!isSizeValid) {
			toast.warning(
				isVideo
					? "حجم الفيديو يجب أن لا يزيد عن 1 ميجابايت."
					: "حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
				{
					theme: "light",
				}
			);
			setDataError({
				...DataError,
				images: isVideo
					? "حجم الفيديو يجب أن لا يزيد عن 1 ميجابايت."
					: "حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			setMultiImages([...multiImages]);
		} else {
			setDataError({
				...DataError,
				images: null,
			});
			setMultiImages(imageList);
		}
	};

	// to get the cover image
	const [images, setImages] = useState([]);
	const maxNumber = 2;
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList?.every(
			(image) => image?.file?.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				cover: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, cover: null });
		}
	};

	const [DataError, setDataError] = useState({
		name: "",
		short_description: "",
		description: "",
		purchasing_price: "",
		selling_price: "",
		category_id: "",
		quantity: "",
		less_qty: "",
		stock: "",
		weight: "",
		subcategory_id: "",
		cover: "",
		images: "",
		data: "",
		amount: "",
		SEOdescription: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			short_description: "",
			description: "",
			purchasing_price: "",
			selling_price: "",
			category_id: "",
			quantity: "",
			less_qty: "",
			stock: "",
			weight: "",
			subcategory_id: "",
			cover: "",
			images: "",
			data: "",
			amount: "",
			SEOdescription: "",
		});
	};

	// Only allow non-empty keywords
	const beforeAddValidate = (keyword) => {
		return keyword.trim() !== "";
	};

	const [addNewEtlobhaProduct] = useAddNewEtlobhaProductMutation();
	const etlobhaAddNewProduct = async (formData) => {
		try {
			const res = await addNewEtlobhaProduct({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/souq_atlbha");

				setEditorValue("");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					short_description: res?.data?.message?.en?.short_description?.[0],
					description: res?.data?.message?.en?.description?.[0],
					purchasing_price: res?.data?.message?.en?.purchasing_price?.[0],
					selling_price: res?.data?.message?.en?.selling_price?.[0],
					category_id: res?.data?.message?.en?.category_id?.[0],
					quantity: res?.data?.message?.en?.quantity?.[0],
					less_qty: res?.data?.message?.en?.less_qty?.[0],
					stock: res?.data?.message?.en?.stock?.[0],
					weight: res?.data?.message?.en?.weight?.[0],
					subcategory_id: res?.data?.message?.en?.subcategory_id?.[0],
					cover: res?.data?.message?.en?.cover?.[0],
					images: res?.data?.message?.en?.images?.[0],
					data: res?.data?.message?.en?.data?.[0],
					amount: res?.data?.message?.en?.amount?.[0],
					SEOdescription: res?.data?.message?.en?.SEOdescription?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.short_description?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.description?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.purchasing_price?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.selling_price?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.category_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.quantity?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.less_qty?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.stock?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.weight?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.subcategory_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.cover?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.images?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.data?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.amount?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.SEOdescription?.[0], {
					theme: "light",
				});
			}
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const handleProduct = (data) => {
		resetData();
		let formData = new FormData();
		formData.append("name", data?.name);
		formData.append("short_description", data?.short_description);
		formData.append("description", editorValue);
		formData.append("purchasing_price", data?.purchasing_price);
		formData.append("selling_price", data?.selling_price);
		formData.append("less_qty", data?.less_qty);
		formData.append("stock", data?.stock);
		formData.append("weight", data?.weight);
		formData.append("category_id", productData?.category_id);
		formData.append(
			"SEOdescription",
			keyWords?.length === 0 ? "" : keyWords?.join(",")
		);

		// create looping to get all ids for activity_ids and assign it
		for (let i = 0; i < productData?.subcategory_id?.length; i++) {
			formData.append([`subcategory_id[${i}]`], productData?.subcategory_id[i]);
		}

		if (images.length !== 0) {
			formData.append("cover", images[0]?.file || null);
		}

		if (multiImages.length !== 0) {
			for (let i = 0; i < multiImages?.length; i++) {
				formData.append(
					[`images[${i}]`],
					multiImages[i]?.file || multiImages[i]?.image
				);
			}
		}

		formData.append("product_has_options", productHasOptions === true ? 1 : 0);
		formData.append("amount", 1);
		if (productHasOptions === true) {
			for (let i = 0; i < optionsSection?.length; i++) {
				formData.append([`attribute[${i}][title]`], optionsSection[i]?.name);
				formData.append(
					[`attribute[${i}][type]`],
					optionsSection[i]?.select_value
				);
				for (let v = 0; v < optionsSection[i]?.values?.length; v++) {
					formData.append(
						[`attribute[${i}][value][${v}][title]`],
						optionsSection[i]?.values[v]?.title
					);
					formData.append(
						[`attribute[${i}][value][${v}][default_option]`],
						optionsSection[i]?.values[v]?.defaultOption === true ? 1 : 0
					);
					optionsSection[i]?.values[v]?.color &&
						optionsSection[i]?.select_value === "نص و لون" &&
						formData.append(
							[`attribute[${i}][value][${v}][color]`],
							optionsSection[i]?.values[v]?.color
						);
					optionsSection[i]?.values[v]?.image &&
						optionsSection[i]?.select_value === "نص و صورة" &&
						formData.append(
							[`attribute[${i}][value][${v}][image]`],

							optionsSection[i]?.values[v]?.image
						);
				}
			}
			for (let i = 0; i < attributes?.length; i++) {
				formData.append([`data[${i}][price]`], attributes[i]?.price || 0);
				formData.append([`data[${i}][less_qty]`], attributes[i]?.less_qty || 0);
				formData.append([`data[${i}][quantity]`], attributes[i]?.qty);
				for (let v = 0; v < attributes[i]?.values?.length; v++) {
					formData.append(
						[`data[${i}][name][${v}]`],
						attributes[i]?.values[v]?.title
					);
				}
			}
		}

		setLoadingTitle("جاري حفظ المنتج");
		etlobhaAddNewProduct(formData);
	};

	/* 
		handle sub categories
	-------------------------------*/
	const subcategory =
		category?.categories.filter(
			(sub) => sub?.id === parseInt(productData?.category_id)
		) || "";

	/*  
		Product Video Modal
	-------------------------------*/
	const closeVideoModal = () => {
		setUrl("");
	};
	const videoModal = () => {
		return (
			<>
				<div
					onClick={closeVideoModal}
					className={`h-full fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-50 ${styles.back_drop}`}></div>
				<div
					className='w-[400px] h-[300px] z-50 max-w-[90%]'
					style={{
						position: "fixed",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CloseIcon
						onClick={closeVideoModal}
						className='cursor-pointer text-[#ffffff]'
					/>
					<video src={url} controls />
				</div>
			</>
		);
	};

	return (
		<>
			{url !== "" && videoModal()}
			<BackDrop
				onClick={() => {
					navigate("/souq_atlbha");
					clearOptions();
				}}
			/>

			{showAddProductOptions && (
				<AddProductOptions
					closeDetails={() => {
						setShowAddProductOptions(false);
					}}
					editProduct={productData}></AddProductOptions>
			)}
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30  otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleProduct)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='md:p-8 p-4 px-5 py-[20px] pt-24 md:pt-10'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2 className='font-bold md:text-2xl text-[20px] mb-3'>
								اضافة منتج جديد للسوق
							</h2>
							<h2 className='md:text-xl text-[14px] font-normal'>
								أدخل بيانات المنتج ليتم اضافته في منتجات سوق اطلبها
							</h2>
						</div>
						<div
							className={`flex-1 overflow-y-scroll md:py-12 md:pr-8 px-4 py-6 bg-[#f6f6f6] ${styles.content}`}>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									اسم المنتج<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<Controller
										name={"name"}
										control={control}
										rules={{
											required: "حقل اسم المنتج مطلوب",
										}}
										render={({ field: { onChange, value } }) => (
											<input
												className={`${formInputClasses} md:h-14 h-[45px]`}
												style={formInputStyle}
												placeholder='ادخل اسم للمنتج لا يتجأوز 25 حرف'
												type='text'
												name='name'
												value={value}
												onChange={(e) => {
													if (e.target.value.length <= 25) {
														onChange(e.target.value.substring(0, 25));
														setNameLength(false);
														setProductData({
															...productData,
															name: e.target.value.substring(0, 25),
														});
													} else {
														setNameLength(true);
													}
												}}
											/>
										)}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name}
										{errors?.name && errors.name.message}
									</span>
									{nameLength && (
										<span className='text-red-500 text-[16px]'>
											اسم المنتج يجب ان لا يتجأوز 25 حرف
										</span>
									)}
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									وصف قصير للمنتج<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<Controller
										name={"short_description"}
										control={control}
										rules={{
											required: "حقل وصف قصير للمنتج مطلوب",
										}}
										render={({ field: { onChange, value } }) => (
											<textarea
												className='md:w-[555px] w-full p-4 outline-0 rounded-md text-lg font-normal'
												style={{
													backgroundColor: "#02466A00",
													border: "1px solid #A7A7A780",
													resize: "none",
												}}
												name='short_description'
												placeholder='اكتب وصف قصير للمنتج لا يتجأوز  100 حرف'
												rows={5}
												value={value}
												onChange={(e) => {
													if (e.target.value.length <= 100) {
														onChange(e.target.value.substring(0, 100));
														setShortDescriptionLength(false);
														setProductData({
															...productData,
															short_description: e.target.value.substring(
																0,
																100
															),
														});
													} else {
														setShortDescriptionLength(true);
													}
												}}></textarea>
										)}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.short_description}
										{errors?.short_description &&
											errors.short_description.message}
									</span>
									{shortDescriptionLength && (
										<span className='text-red-500 text-[16px]'>
											الوصف يجب ان لا يتجأوز 100 حرف
										</span>
									)}
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									وصف المنتج<span className='text-red-500'>*</span>
								</h2>
								<div className={`${styles.productEditor} flex flex-col gap-2`}>
									<TextEditor
										ToolBar={"product"}
										placeholder={"وصف تفاصيل المنتج"}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.description}
										{errors?.description && errors.description.message}
									</span>
								</div>
							</div>

							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									صور المنتج الرئيسية<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<ImageUploading
										value={images}
										onChange={onChange}
										maxNumber={maxNumber}
										dataURLKey='data_url'
										acceptType={["jpg", "png", "jpeg"]}>
										{({
											imageList,
											onImageUpload,
											onImageRemoveAll,
											onImageUpdate,
											onImageRemove,
											isDragging,
											dragProps,
										}) => (
											// write your building UI
											<div>
												<div
													className='md:h-[180px] h-[100px] md:w-[555px] w-full upload__image-wrapper relative overflow-hidden'
													style={{
														border: "3px dashed #ccc",
														borderRadius: "10px",
													}}
													onClick={() => {
														onImageUpload();
													}}
													{...dragProps}>
													<div className='image-item w-full cursor-pointer md:h-[180px] h-full'>
														<div className='flex flex-col items-center justify-center w-full h-full md:gap-2'>
															<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
															<h2 className='font-semibold'>اسحب الصورة هنا</h2>
															<div className='flex flex-col items-center gap-1'>
																<h2 className='mb-0'>
																	(سيتم قبول الصور png & jpg & jpeg)
																</h2>
																<span className='text-gray-400 font-light text-[14px]'>
																	(الحد الأقصى للصورة 1MB)
																</span>
															</div>
														</div>
													</div>
												</div>
												{(images[0] || productData?.cover) && (
													<div className='md:w-28 w-[60px] md:h-28 h-[58px] mt-4'>
														<img
															className='max-w-full max-h-full object-cotain'
															src={images[0]?.data_url || productData?.cover}
															alt='preview-img'
															loading='lazy'
														/>
													</div>
												)}
											</div>
										)}
									</ImageUploading>
									<span className='text-red-500 text-[16px]'>
										{DataError?.cover}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									الصور المتعددة أو الفيديو
									<br />
									<span className='text-gray-400 font-light text-[14px]'>
										(الحد الأقصى للصورة أو الفيديو 1MB)
									</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<ImageUploading
										value={multiImages}
										onChange={onChangeMultiImages}
										multiple
										maxNumber={5}
										dataURLKey='data_url'
										acceptType={[
											"jpg",
											"png",
											"jpeg",
											"svg",
											"gif",
											"mp4",
											"avi",
											"mov",
											"mkv",
										]}
										allowNonImageType={true}>
										{({
											imageList,
											onImageUpload,
											onImageRemoveAll,
											onImageUpdate,
											onImageRemove,
											isDragging,
											dragProps,
										}) => (
											// write your building UI
											<div className='md:w-[555px] w-full upload__image-wrapper relative flex gap-4'>
												{imageList?.map((image, index) => {
													const isVideo =
														image?.data_url?.includes(
															"video/mp4" ||
																"video/avi" ||
																"video/mov" ||
																"video/mkv"
														) ||
														image?.image?.includes(
															".mp4" || ".avi" || ".mov" || ".mkv"
														);
													if (isVideo) {
														return (
															<div
																key={index}
																className='relative md:h-24 h-[50px] md:w-24 w-[60px] flex justify-center items-center cursor-pointer'
																style={{
																	backgroundColor: "#FAFAFA",
																	border: "2px dashed #237EAE",
																	borderRadius: "4px",
																}}>
																<video
																	className=' md:h-24 h-[50px] md:w-24 w-[60px]'
																	src={image.data_url || image?.image}
																	poster={image.data_url || image?.image}
																/>

																<BsPlayCircle
																	onClick={() => setUrl(image?.data_url)}
																	className='absolute cursor-pointer'
																	color='#ADB5B9'
																/>

																<div className='absolute h-[22px] w-[22px] top-0 left-0 hover:bg-slate-900 hover:opacity-70 opacity-70 duration-200 flex justify-center items-center'>
																	<TiDeleteOutline
																		onClick={() => onImageRemove(index)}
																		style={{
																			fontSize: "1.5rem",
																			color: "red",
																		}}
																	/>
																</div>
															</div>
														);
													} else {
														return (
															<div
																key={index}
																className='relative md:h-24 h-[50px] md:w-24 w-[60px] flex justify-center items-center cursor-pointer'
																style={{
																	backgroundColor: "#FAFAFA",
																	border: "2px dashed #237EAE",
																	borderRadius: "4px",
																}}>
																<img
																	className='object-contain md:h-24 h-[50px] md:w-24 w-[60px]'
																	src={image?.data_url || image?.image}
																	alt=''
																	loading='lazy'
																/>
																<div
																	onClick={() => onImageRemove(index)}
																	className='absolute top-0 left-0 flex items-center justify-center w-full h-full duration-200 opacity-0 hover:bg-slate-900 hover:opacity-70'>
																	<TiDeleteOutline
																		style={{
																			fontSize: "1.5rem",
																			color: "red",
																		}}></TiDeleteOutline>
																</div>
															</div>
														);
													}
												})}
												{emptyMultiImages?.map((image, idx) => {
													return (
														<div
															key={idx}
															className=' md:h-24 h-[50px] md:w-24 w-[60px] flex justify-center items-center cursor-pointer'
															style={{
																backgroundColor: "#FAFAFA",
																border: "2px dashed #237EAE",
																borderRadius: "4px",
															}}
															onClick={() => {
																onImageUpload();
															}}>
															<Box sx={{ "& circle": { fill: "#ADB5B9" } }}>
																<AddIcon className='w-4 h-4 md:w-5 md:h-5' />
															</Box>
														</div>
													);
												})}
											</div>
										)}
									</ImageUploading>
									<span className='text-red-500 text-[16px]'>
										{DataError?.images}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									النشاط الرئيسي
									<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<FormControl className='md:h-14 h-[45px] md:w-[555px] w-full'>
										<Controller
											name={"category_id"}
											control={control}
											rules={{ required: "حقل التصنيف مطلوب" }}
											render={({ field: { onChange, value } }) => (
												<Select
													className={`text-lg font-normal rounded-lg ${styles.select}`}
													value={value}
													name='category_id'
													onChange={(e) => {
														if (value !== e.target.value) {
															setProductData({
																...productData,
																subcategory_id: [],
															});
														}
														handleProductData(e);
														onChange(e);
													}}
													displayEmpty
													IconComponent={(props) => (
														<Arrow fill='#242424' {...props} />
													)}
													inputProps={{ "aria-label": "Without label" }}
													renderValue={(selected) => {
														if (productData?.category_id === "" || !selected) {
															return (
																<h2 className='text-[#011723]'>
																	اختر النشاط الرئيسي
																</h2>
															);
														}
														const result =
															category?.categories?.filter(
																(item) => item?.id === parseInt(selected)
															) || "";
														return result[0]?.name;
													}}
													sx={{
														height: "100%",
														border: "1px solid rgba(167, 167, 167, 0.5)",
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none",
														},
													}}>
													{category?.categories?.map((item, idx) => {
														return (
															<MenuItem
																key={idx}
																className='souq_storge_category_filter_items'
																sx={{
																	backgroundColor: "rgba(211, 211, 211, 1)",
																	height: "3rem",
																	"&:hover": {},
																}}
																value={item?.id}>
																{item?.name}
															</MenuItem>
														);
													})}
												</Select>
											)}
										/>
									</FormControl>
									<span className='text-red-500 text-[16px]'>
										{DataError?.category_id}
										{errors?.category_id && errors.category_id.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<label
									className='font-medium md:text-[20px] text-[16px] md:w-[315px] w-full'
									style={{ color: "#011723" }}>
									الأنشطة الفرعية
								</label>
								<div className='flex flex-col gap-2'>
									<FormControl className='md:w-[555px] w-full md:h-[56px] h-[45px]'>
										{productData?.category_id !== "" &&
										subcategory[0]?.subcategory?.length === 0 ? (
											<div
												className='flex flex-row items-center justify-center'
												style={{ color: "#1dbbbe" }}>
												لا يوجد نشاطات أو تصنيفات فرعيه للنشاط أو التصنيف
												الرئيسي الذي اخترته
											</div>
										) : (
											<Select
												className={`text-lg font-normal rounded-lg ${styles.select}`}
												IconComponent={(props) => (
													<Arrow fill='#242424' {...props} />
												)}
												multiple
												displayEmpty
												value={productData?.subcategory_id}
												onChange={(e) => {
													setProductData({
														...productData,
														subcategory_id: e.target.value,
													});
												}}
												renderValue={(selected) => {
													if (productData?.subcategory_id.length === 0) {
														return " اختر الأنشطة الفرعية";
													}
													return selected?.map((item) => {
														const result =
															subcategory[0]?.subcategory?.filter(
																(sub) => sub?.id === parseInt(item)
															) || productData?.subcategory_id;
														return `${result[0]?.name} , `;
													});
												}}
												sx={{
													height: "3.5rem",
													border: "1px solid #A7A7A780",
													borderRadius: "4px",
													"& .MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
												}}>
												{subcategory[0]?.subcategory?.map((sub, index) => (
													<MenuItem
														className='souq_storge_category_filter_items multiple_select'
														key={index}
														value={sub?.id}>
														<Checkbox
															checked={
																productData?.subcategory_id?.indexOf(sub?.id) >
																-1
															}
														/>
														<ListItemText primary={sub?.name} />
													</MenuItem>
												))}
											</Select>
										)}
									</FormControl>
									<span className='text-red-500 text-[16px]'>
										{DataError?.subcategory_id}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									سعر الشراء<span className='text-red-500'>*</span>
									<BootstrapTooltip
										className={"p-0"}
										TransitionProps={{ timeout: 300 }}
										TransitionComponent={Zoom}
										title='سيتم استبدال قيمة سعر الشراء بقيمة السعر للخيار الافتراضي في حال تم اضافة خيارات للمنتج'
										placement='top'>
										<IconButton>
											<MdInfoOutline color='#1DBBBE' size={"14px"} />
										</IconButton>
									</BootstrapTooltip>
								</h2>
								<div className='flex flex-col gap-2'>
									{attributes?.length !== 0 ? (
										<div className='text-[14px] -mb-1'>
											لتعديل السعر قم بالدخول إلى خيارات المنتج
										</div>
									) : null}
									<label
										className='md:h-14 h-[45px] flex rounded-md overflow-hidden'
										style={formInputStyle}>
										<div className='flex flex-1 p-2'>
											<img
												className='ml-2 opacity-50'
												src={Currency}
												alt=''
												loading='lazy'
											/>
											{attributes?.length !== 0 ? (
												<Controller
													name={"purchasing_price"}
													control={control}
													rules={{
														required: "حقل سعر الشراء مطلوب",
														pattern: {
															value: /^[0-9.]+$/i,
															message: "حقل سعر الشراء يجب ان يكون رقم",
														},
														min: {
															value: 1,
															message: "حقل سعر الشراء يجب ان يكون اكبر من 0",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<input
															className='flex-1 border-none outline-none bg-[#f6f6f6]'
															style={{
																direction: "ltr",
																textAlign: "end",
																cursor: "pointer",
															}}
															placeholder='0'
															type='text'
															name='purchasing_price'
															readOnly='true'
															onClick={() => {
																setShowAddProductOptions(true);
															}}
															title='قم بالضغط علي الحقل لتعديل السعر'
															value={value}
															onChange={(e) => {
																setProductData({
																	...productData,
																	purchasing_price: e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	),
																});
																onChange(
																	e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	)
																);
															}}
														/>
													)}
												/>
											) : (
												<Controller
													name={"purchasing_price"}
													control={control}
													rules={{
														required: "حقل سعر الشراء مطلوب",
														pattern: {
															value: /^[0-9.]+$/i,
															message: "حقل سعر الشراء يجب ان يكون رقم",
														},
														min: {
															value: 1,
															message: "حقل سعر الشراء يجب ان يكون اكبر من 0",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<input
															className='flex-1 border-none outline-none bg-[#f6f6f6]'
															style={{ direction: "ltr", textAlign: "end" }}
															placeholder='0'
															type='text'
															name='purchasing_price'
															value={value}
															onChange={(e) => {
																setProductData({
																	...productData,
																	purchasing_price: e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	),
																});
																onChange(
																	e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	)
																);
															}}
														/>
													)}
												/>
											)}
										</div>
										<div
											className='flex items-center justify-center w-16 h-full text-lg'
											style={{
												borderRight: "1px solid #ccc",
												backgroundColor: "#fafafa",
											}}>
											ر.س
										</div>
									</label>

									<span className='text-red-500 text-[16px]'>
										{DataError?.purchasing_price}
										{errors?.purchasing_price &&
											errors.purchasing_price.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									سعر البيع <span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<label
										className='md:h-14 h-[45px] flex rounded-md overflow-hidden'
										style={formInputStyle}>
										<div className='flex flex-1 p-3 p-md-4'>
											<img
												className='ml-2 opacity-50'
												src={Currency}
												alt=''
												loading='lazy'
											/>
											<Controller
												name={"selling_price"}
												control={control}
												rules={{
													required: "حقل سعر البيع مطلوب",
													pattern: {
														value: /^[0-9.]+$/i,
														message: "حقل سعر البيع يجب ان يكون رقم",
													},
													min: {
														value: 1,
														message: "حقل سعر البيع يجب ان يكون اكبر من 0",
													},
												}}
												render={({ field: { onChange, value } }) => (
													<input
														className='flex-1 border-none outline-none bg-[#f6f6f6]'
														style={{ direction: "ltr", textAlign: "end" }}
														placeholder='0'
														type='text'
														name='selling_price'
														value={value}
														onChange={(e) => {
															setProductData({
																...productData,
																selling_price: e.target.value.replace(
																	/[^\d.]|\.(?=.*\.)/g,
																	""
																),
															});
															onChange(
																e.target.value.replace(/[^\d.]|\.(?=.*\.)/g, "")
															);
														}}
													/>
												)}
											/>
										</div>
										<div
											className='flex items-center justify-center w-16 h-full text-lg'
											style={{
												borderRight: "1px solid #ccc",
												backgroundColor: "#fafafa",
											}}>
											ر.س
										</div>
									</label>
									{productData?.selling_price !== "" &&
										productData?.purchasing_price === "" && (
											<span className='text-red-500 text-[16px]'>
												يرجى ادخال سعر الشراء أولاًً
											</span>
										)}
									{productData?.selling_price !== "" &&
										Number(productData?.selling_price) <
											Number(productData?.purchasing_price) && (
											<span className='text-red-500 text-[16px]'>
												يجب ان يكون سعر البيع اكبر من أو يسأوي
												{productData?.purchasing_price}
											</span>
										)}
									<span className='text-red-500 text-[16px]'>
										{DataError?.selling_price}
										{errors?.selling_price && errors.selling_price.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									أقل طلب<span className='text-red-500'>*</span>
									<BootstrapTooltip
										className={"p-0"}
										TransitionProps={{ timeout: 300 }}
										TransitionComponent={Zoom}
										title='سيتم استبدال قيمة اقل طلب بقيمة اقل طلب للخيار الافتراضي في حال تم اضافة خيارات للمنتج'
										placement='top'>
										<IconButton>
											<MdInfoOutline color='#1DBBBE' size={"14px"} />
										</IconButton>
									</BootstrapTooltip>
								</h2>
								<div className='flex flex-col gap-2'>
									{attributes?.length !== 0 ? (
										<div className='text-[14px] -mb-1 '>
											لتعديل قيمة أقل طلب قم بالدخول إلى خيارات المنتج
										</div>
									) : null}
									<label
										className='md:h-14 h-[45px] flex rounded-md overflow-hidden'
										style={formInputStyle}>
										<div className='flex flex-1 p-3 p-md-4'>
											{attributes?.length !== 0 ? (
												<Controller
													name={"less_qty"}
													control={control}
													rules={{
														required: "حقل أقل طلب مطلوب",
														pattern: {
															value: /^[0-9.]+$/i,
															message: "حقل أقل طلب يجب ان يكون رقم",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<input
															className='flex-1 border-none outline-none bg-[#f6f6f6] cursor-pointer'
															style={{ direction: "ltr", textAlign: "end" }}
															placeholder='0'
															type='text'
															name='less_qty'
															value={value}
															readOnly='true'
															onClick={() => {
																setShowAddProductOptions(true);
															}}
															title='قم بالضغط علي الحقل لتعديل قيمة أقل طلب'
															onChange={(e) => {
																setProductData({
																	...productData,
																	less_qty: e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	),
																});
																onChange(
																	e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	)
																);
															}}
														/>
													)}
												/>
											) : (
												<Controller
													name={"less_qty"}
													control={control}
													rules={{
														required: "حقل أقل طلب مطلوب",
														pattern: {
															value: /^[0-9.]+$/i,
															message: "حقل أقل طلب يجب ان يكون رقم",
														},
													}}
													render={({ field: { onChange, value } }) => (
														<input
															className='flex-1 border-none outline-none bg-[#f6f6f6]'
															style={{ direction: "ltr", textAlign: "end" }}
															placeholder='0'
															type='text'
															name='less_qty'
															value={value}
															onChange={(e) => {
																setProductData({
																	...productData,
																	less_qty: e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	),
																});
																onChange(
																	e.target.value.replace(
																		/[^\d.]|\.(?=.*\.)/g,
																		""
																	)
																);
															}}
														/>
													)}
												/>
											)}
										</div>
									</label>
									<span className='text-red-500 text-[16px]'>
										{DataError?.less_qty}
										{errors?.less_qty && errors.less_qty.message}
									</span>
								</div>
							</div>

							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									المخزون<span className='text-red-500'>*</span>
									<BootstrapTooltip
										className={"p-0"}
										TransitionProps={{ timeout: 300 }}
										TransitionComponent={Zoom}
										title='سيتم استبدال قيمة المخزون الحالية بقيمة إجمالي الكمية الخاصة بخيارات المنتج في حال تم اضافة خيارات للمنتج'
										placement='top'>
										<IconButton>
											<MdInfoOutline color='#1DBBBE' size={"14px"} />
										</IconButton>
									</BootstrapTooltip>
								</h2>
								<div className='flex flex-col gap-2'>
									<span className='text-gray-400 font-light text-[14px] -mb-1'>
										تأكد ان المخزون يشمل إجمالي الكمية الخاصه بخيارات المنتج
									</span>
									{attributes?.length !== 0 ? (
										<Controller
											name={"stock"}
											control={control}
											rules={{
												required: "حقل المخزون مطلوب",
												pattern: {
													value: /^[0-9]+$/i,
													message: "حقل المخزون يجب ان يكون رقم",
												},
												min: {
													value: 1,
													message: "حقل المخزون يجب ان يكون أكبر من 0",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<input
													className={`${formInputClasses} md:h-14 h-[45px] cursor-pointer`}
													style={formInputStyle}
													placeholder='0'
													type='text'
													name='stock'
													readOnly='true'
													onClick={() => {
														setShowAddProductOptions(true);
													}}
													title='قم بالضغط علي الحقل لتعديل المخزون'
													value={value}
													onChange={(e) =>
														onChange(e.target.value.replace(/[^0-9]/g, ""))
													}
												/>
											)}
										/>
									) : (
										<Controller
											name={"stock"}
											control={control}
											rules={{
												required: "حقل المخزون مطلوب",
												pattern: {
													value: /^[0-9]+$/i,
													message: "حقل المخزون يجب ان يكون رقم",
												},
												min: {
													value: 1,
													message: "حقل المخزون يجب ان يكون أكبر من 0",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<input
													className={`${formInputClasses} md:h-14 h-[45px]`}
													style={formInputStyle}
													placeholder='0'
													type='text'
													name='stock'
													value={value}
													onChange={(e) =>
														onChange(e.target.value.replace(/[^0-9]/g, ""))
													}
												/>
											)}
										/>
									)}

									{attributes?.length !== 0 ? (
										<div className='text-[14px] '>
											لتعديل المخزون قم بالدخول إلى خيارات المنتج
										</div>
									) : null}
									<span className='text-red-500 text-[16px]'>
										{DataError?.stock}
										{errors?.stock && errors.stock.message}
									</span>
								</div>
							</div>

							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<h2 className={formTitleClasses} style={formTitleStyle}>
									الوزن<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col gap-2'>
									<span className='text-gray-400 font-light text-[14px]'>
										ضع الوزن التقريبي للمنتج بالجرام
									</span>
									<Controller
										name={"weight"}
										control={control}
										rules={{
											required: "حقل الوزن مطلوب",
											pattern: {
												value: /^[0-9]+$/i,
												message: "حقل الوزن يجب ان يكون رقم",
											},
										}}
										render={({ field: { onChange, value } }) => (
											<input
												className={`${formInputClasses} md:h-14 h-[45px]`}
												style={formInputStyle}
												placeholder='1000'
												type='text'
												name='weight'
												value={value}
												onChange={(e) =>
													onChange(e.target.value.replace(/[^0-9.]/g, ""))
												}
											/>
										)}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.weight}
										{errors?.weight && errors.weight.message}
									</span>
								</div>
							</div>

							{productData?.quantity && (
								<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
									<label
										className='font-medium md:text-[20px] text-[16px] md:w-[315px] w-full'
										style={{ color: "#011723" }}>
										الكمية المتوفرة
									</label>
									<div className='flex flex-col gap-2'>
										<label
											className='md:h-14 h-[45px] flex rounded-md overflow-hidden'
											style={formInputStyle}>
											<div className='flex flex-1 p-3 p-md-4'>
												<input
													value={productData?.quantity}
													onChange={(e) =>
														setProductData({
															...productData,
															quantity: e.target.value,
														})
													}
													className='flex-1 border-none outline-none bg-[#f6f6f6]'
													type='number'
												/>
											</div>
										</label>
										<span className='text-red-500 text-[16px]'>
											{DataError?.quantity}
										</span>
									</div>
								</div>
							)}
							<div className='flex flex-col gap-2'>
								<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
									<label
										className='font-medium md:text-[20px] text-[16px] md:w-[315px] w-full'
										style={{ color: "#011723" }}>
										الكلمات المفتاحية للمنتج
									</label>
									<div className={`${styles.keywords} flex flex-col gap-2`}>
										<TagsInput
											value={keyWords}
											onChange={setKeyWords}
											beforeAddValidate={beforeAddValidate}
											name='key_words'
											placeHolder='ضع الكلمة ثم اضغط enter'
											classNames={`${styles.keywords}`}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.SEOdescription}
										</span>
									</div>
								</div>
							</div>
							<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
								<label
									className='font-medium md:text-[20px] text-[16px] md:w-[315px] w-full'
									style={{ color: "#011723" }}>
									اضافة خيارات المنتج
								</label>
								<div
									className='md:w-[555px] w-full md:h-[56px] h-[45px] fcc p-3 gap-4 border-2 border-dashed cursor-pointer rounded-lg'
									style={formInputStyle}
									onClick={() => {
										setShowAddProductOptions(true);
									}}>
									اضافة خيارات للمنتج
									<Box sx={{ "& circle": { fill: "#ADB5B9" } }}>
										<AddIcon className='md:w-[24px] w-[20px]'></AddIcon>
									</Box>
								</div>
							</div>

							{/*
								<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
									<h2 className={formTitleClasses} style={formTitleStyle}>
										رابط جوجل أناليتكس
									</h2>
									<div className='flex flex-col gap-2'>
										<input
											className={formInputClasses}
											style={{
												width: "555px",
												maxWidth: "100%",
												border: "1px solid rgba(167, 167, 167, 0.5)",
												backgroundColor: "#f6f6f6",
												fontWight: "400",
												color: "#000000",
												textAlign: "left",
												direction: "ltr",
											}}
											placeholder='https://analytics.google.com/analytics/web/#/report'
											type='text'
											name='googleAnalyticsLink'
											{...register("googleAnalyticsLink", {
												pattern: "يجب ان يكون الرابط Valid URL",
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.googleAnalyticsLink}
											{errors?.googleAnalyticsLink &&
												errors.googleAnalyticsLink.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
									<label
										className='font-medium md:text-[20px] text-[16px] md:w-[315px] w-full'
										style={{ olor: "#011723" }}>
										إعدادات ملف Robots
									</label>
									<div className='flex flex-col gap-2'>
										<textarea
											className='md:w-[555px] w-full p-4 outline-0 rounded-md text-lg font-normal'
											style={{
												backgroundColor: "#02466A00",
												border: "1px solid #A7A7A780",
												resize: "none",
												textAlign: "left",
												direction: "ltr",
											}}
											placeholder='Sitemap: https://utlopha.sa/sitemap.xml User-agent: * Allow: / Disallow: /*<iframe Disallow: /*?currency='
											cols='30'
											rows='4'
											name='robotLink'
											{...register("robotLink")}></textarea>
										<span className='text-red-500 text-[16px]'>
											{DataError?.robotLink}
											{errors?.robotLink && errors.robotLink.message}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
										<div className='md:w-[315px] w-full flex flex-row gap-[10px] items-center'>
											<SnapIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												سناب بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={snapchat}
												setValue={setSnapchat}
												placeholder='Snapchat pixel code'
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.snappixel}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
										<div className='md:w-[315px] w-full flex flex-row gap-[10px] items-center'>
											<TiktokIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												تيك توك بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={tiktok}
												setValue={setTiktok}
												placeholder='Tiktok pixel code'
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.tiktokpixel}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
										<div className='md:w-[315px] w-full flex flex-row gap-[10px] items-center'>
											<TwitterIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												تويتر بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={twitter}
												setValue={setTwitter}
												placeholder='Twitter pixel code'
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.twitterpixel}
										</span>
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex flex-col mb-4 md:flex-row gap-y-2 md:mb-8'>
										<div className='md:w-[315px] w-full flex flex-row gap-[10px] items-center'>
											<InstagramIcon />
											<h6 className='text-[18px] font-medium text-[#200407]'>
												انستجرام بكسل
											</h6>
										</div>
										<div className={`${styles.pixels}`}>
											<TextareaCode
												value={instagram}
												setValue={setInstagram}
												placeholder='Instagram pixel code'
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.instapixel}
										</span>
									</div>
								</div>
							*/}
						</div>
						<div
							className='flex items-center justify-center gap-4 p-4 md:p-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<Button
								btnType={"submit"}
								className='md:w-1/3 w-full md:h-[56px] h-[45px] md:text-2xl text-[18px] font-medium'
								style={{
									backgroundColor: "#02466A",
								}}
								type={"normal"}>
								حفظ
							</Button>
							<Button
								className='md:w-1/3 w-full md:h-[56px] h-[45px] md:text-2xl text-[18px] font-medium'
								style={{
									borderColor: `rgba(2, 70, 106, 1)`,
								}}
								textStyle={{ color: "rgba(2, 70, 106, 1)" }}
								type={"outline"}
								onClick={() => {
									navigate("/souq_atlbha");
									clearOptions();
								}}>
								إلغاء
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default NewProduct;
