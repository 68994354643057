import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

export const mainPageApi = createApi({
  reducerPath: "mainPageApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin",
  }),

  tagTypes: ["MainPage"],

  endpoints: (builder) => ({
    // get data from api based on date
    getMainPageData: builder.query({
      query: ({
        startDate1 = "",
        endDate1 = "",
        startDate2 = "",
        endDate2 = "",
      }) => {
        const params = new URLSearchParams();

        if (startDate1) params.append("startDate1", startDate1);
        if (endDate1) params.append("endDate1", endDate1);
        if (startDate2) params.append("startDate2", startDate2);
        if (endDate2) params.append("endDate2", endDate2);

        return { url: `/home?${params.toString()}` };
      },
      transformResponse: (response) => response.data,
      providesTags: ["MainPage"],
    }),
  }),
});

export const { useGetMainPageDataQuery } = mainPageApi;
