import React from "react";

import moment from "moment";

// Icons
import { TfiWrite } from "react-icons/tfi";
import { TbStatusChange } from "react-icons/tb";
import { MdWbTwighlight } from "react-icons/md";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { CiCalendarDate, CiWallet } from "react-icons/ci";

const OrderInfo = ({ order }) => {
  return (
    <div className="p-4 mb-8 bg-white shadow-sm rounded-xl order-details">
      <h3 className="mb-5 text-2xl font-medium text-gray-900 ">بيانات الطلب</h3>
      <div className="flex flex-col flex-wrap items-center justify-start gap-4 mb-5 md:flex-row boxes">
        {order?.status && (
          <div className="md:w-[18.666667%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <TbStatusChange className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">الحالة</span>
            </div>
            <h5 className="shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.status}
            </h5>
          </div>
        )}
        {order?.created_at && (
          <div className="md:w-[18.666667%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <CiCalendarDate className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">تاريخ الطلب</span>
            </div>
            <h5 className="shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {moment(order?.created_at).format("YYYY-MM-DD")}
            </h5>
          </div>
        )}
        {order?.total_price && (
          <div className="md:w-[18.666667%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <CiWallet className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">إجمالي الطلب</span>
            </div>
            <h5 className="shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.total_price}
            </h5>
          </div>
        )}
        {order?.quantity && (
          <div className="md:w-[18.666667%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <HiOutlineArchiveBox className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">عدد المنتجات</span>
            </div>
            <h5 className="shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.quantity}
            </h5>
          </div>
        )}
        {order?.weight && (
          <div className="md:w-[18.666667%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <MdWbTwighlight className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">وزن الطلب</span>
            </div>
            <div className="flex justify-center items-center gap-1 shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              <span>Kg</span>
              <h5>{order?.weight}</h5>
            </div>
          </div>
        )}
      </div>
      {order?.description && (
        <div className="flex flex-wrap items-center justify-start ">
          <div className="w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <TfiWrite className=" w-5 h-5 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">ملاحظات الطلب</span>
            </div>
            <h5 className="shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-8 py-4 border-[1px] border-[#f9f8f8]">
              {order?.description}
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderInfo;
