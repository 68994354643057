import React, { useState, useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import MarketsTableSec from "../../../components/MarketsPagesComp/MarketsTable/MarketsTableSec/MarketsTableSec";
import TraderAlert from "../../../components/MarketsPagesComp/MarketsTable/TraderAlert/TraderAlert";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { IoMdAdd } from "react-icons/io";
import {
  useMarketsDataQuery,
  useUnVerificationStoreDataQuery,
} from "../../../RTK/apiSlices/marketsApi";

const MarketsTable = () => {
  const navigate = useNavigate();
  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;
  const [storesTabs, setStoresTabs] = useState(1);
  const [traderAlert, setTraderAlert] = useState(false);
  const [traderPackageDetails, setTraderPackageDetails] = useState({});

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data: fetchedData, isLoading: loading } = useMarketsDataQuery({
    page,
    number: rowsPerPage,
  });

  const { data: unVerificationStore } = useUnVerificationStoreDataQuery();

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | عرض المتاجر</title>
      </Helmet>
      <div className="lg:pr-4 2xlg:pl-16 p-4 lg:bg-[#fafafa] bg-[#ffffff]">
        <div className="flex md:flex-row flex-col md:items-center items-start justify-between gap-[22px] mb-4">
          <PageNavigate currentPage={"عرض المتاجر"} parentPage={"المتاجر"} />
          {permissions?.includes("admin.store.store") && (
            <Button
              onClick={() => {
                navigate("/markets/add-market");
              }}
              className="md:w-[140px] w-full md:h-[56px] h-[44px]"
              type={"normal"}
              svg={<IoMdAdd color={"#fff"} />}
              style={{ backgroundColor: "#02466A" }}
              textStyle={{ color: "#EFF9FF", fontSize: "18px" }}
            >
              انشاء متجر
            </Button>
          )}
        </div>

        {/* tabs of stores */}
        <div className="flex items-center justify-start gap-2">
          <button
            onClick={() => {
              setStoresTabs(1);
            }}
            className={`${
              storesTabs === 1
                ? "text-[#EFF9FF] bg-[#02466a]"
                : " text-[#02466a]  bg-[#EFF9FF]"
            } md:w-[180px] w-full md:h-[46px] h-[42px] text-lg rounded-xl border-[1px] border-[#02466a52]`}
          >
            المتاجر المفعلة
          </button>

          {permissions?.includes("admin.store.unVerificationStore") && (
            <button
              onClick={() => {
                setStoresTabs(2);
              }}
              className={`${
                storesTabs === 2
                  ? "text-[#EFF9FF] bg-[#02466a]"
                  : "text-[#02466a] bg-[#EFF9FF]"
              } md:w-[180px] w-full md:h-[46px] h-[42px] text-lg rounded-xl border-[1px] border-[#02466a52]  `}
            >
              المتاجر الغير مفعلة
            </button>
          )}
        </div>

        {traderAlert && (
          <TraderAlert
            cancel={() => {
              setTraderAlert(false);
            }}
            traderPackageDetails={traderPackageDetails}
          />
        )}

        <div className="mt-4" dir="ltr">
          <MarketsTableSec
            loading={loading}
            storesTabs={storesTabs}
            fetchedData={storesTabs === 1 ? fetchedData : unVerificationStore}
            openTraderAlert={(row) => {
              setTraderAlert(true);
              setTraderPackageDetails(row);
            }}
            pageNumber={page}
            setPageNumber={setPage}
            currentPage={rowsPerPage}
            setCurrentPage={setRowsPerPage}
            page_count={fetchedData?.page_count}
          />
        </div>
      </div>
    </>
  );
};

export default MarketsTable;
