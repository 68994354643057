import React from "react";

import { MdNewReleases } from "react-icons/md";
import { FaLayerGroup } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineFreeCancellation } from "react-icons/md";

const OrdersStats = ({ fetchedData }) => {
	const OrdersStatsData = [
		{
			id: 1,
			title: "الطلبات الجديدة",
			icon: (
				<span>
					<MdNewReleases className='w-8 h-8 text-[#ff9f1a]' />
				</span>
			),

			num: fetchedData?.new || 0,
		},
		{
			id: 2,
			title: "طلبات  مكتملة ",
			icon: (
				<span>
					<FaCheckCircle className='w-7 h-7 text-[#3ae374]' />
				</span>
			),
			num: fetchedData?.completed || 0,
		},
		{
			id: 3,
			title: "طلبات ملغية",
			icon: (
				<span className='flex items-center justify-center p-1 bg-red-400 rounded-full'>
					<MdOutlineFreeCancellation className='w-5 h-5 text-white' />{" "}
				</span>
			),
			num: fetchedData?.canceled || 0,
		},

		{
			id: 5,
			title: "اجمالي الطلبات",
			icon: (
				<span className='p-1 bg-[#b6be34] flex items-center justify-center rounded-full'>
					<FaLayerGroup className='w-5 h-5 text-white ' />
				</span>
			),
			num: fetchedData?.all || 0,
		},
	];
	return (
		<div className='grid grid-cols-2 gap-4 mb-10 lg:grid-cols-4 '>
			{OrdersStatsData?.map((item) => (
				<div
					key={item.id}
					className='bg-[#F3FCFD] shadow-[0px_3px_6px_#0000000F] rounded-lg 
				text-center md:py-4 md:px-6 p-2'>
					<div className='flex items-center justify-center gap-2'>
						{item?.icon}
						<h4 className='text-[#02466A] font-normal md:text-lg text-[16px] whitespace-nowrap'>
							{item.title}
						</h4>
					</div>
					<div className='text-[#02466A] font-medium md:text-[20px] text-[18px] whitespace-nowrap text-ellipsis'>
						{item.num}
					</div>
				</div>
			))}
		</div>
	);
};

export default OrdersStats;
