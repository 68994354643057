import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "../../../OtlobhaAcademyComp/TrainingCourses/TrainingCourses.module.css";

/* Third party */
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageUploading from "react-images-uploading";

// Context
import { LoadingContext } from "../../../../store/LoadingProvider";
import { TextEditorContext } from "../../../../store/TextEditorProvider";

// Components
import Button from "../../../../UI/Button/Button";

// Icons
import { GoArrowRight } from "react-icons/go";
import { IoMdCloudUpload } from "react-icons/io";

// RTK query
import {
	useEditBusinessTripMutation,
	useShowBusinessTripQuery,
} from "../../../../RTK/apiSlices/subscriptionsApi";

import { TextEditor } from "../../../TextEditor";
import { Delete, EditButton } from "../../../../assets/Icons";
import AddBusinessTripProgram from "../AddNewBusinessTrip/AddBusinessTripProgram";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";

const EditBusinessTrip = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: currentBusinessTrips, isLoading } = useShowBusinessTripQuery({
		id,
	});

	const [openModal, setOpenModal] = useState(null);
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const [tripProgram, setTripProgram] = useState([]);
	const [data, setData] = useState({
		title: "",
		image: "",
	});

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			title: "",
		},
	});

	useEffect(() => {
		reset(currentBusinessTrips);
	}, [currentBusinessTrips, reset]);

	const handleClosePage = () => {
		navigate("/business-trips");
		setEditorValue("");
	};

	// upload course image
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);
	const ref = useRef(null);

	// errors
	const [DataError, setDataError] = useState({
		title: "",
		description: "",
		packageId: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			title: "",
			description: "",
			packageId: "",
			image: "",
		});
	};

	// handle upload image
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	const [editBusinessTrip] = useEditBusinessTripMutation();
	const EditTripProgramHandel = async (formData) => {
		try {
			const res = await editBusinessTrip({
				id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				handleClosePage();
				setEditorValue("");
				setTripProgram([]);
			} else {
				setLoadingTitle(null);
				setDataError({
					title: res?.data?.message?.en?.title?.[0],
					description: res?.data?.message?.en?.description?.[0],
					package_id: res?.data?.message?.en?.package_id?.[0],
					image: res?.data?.message?.en?.image?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.description?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.package_id?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddBusinessTrip = (dataCourse) => {
		resetData();
		const formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("title", dataCourse?.title);
		formData.append("description", editorValue);

		if (images.length !== 0) {
			formData.append("image", images[0]?.file || "");
		}

		tripProgram.forEach((item, index) => {
			formData.append(`data[${index}][title]`, item.title);
			formData.append(`data[${index}][description]`, item.description);
		});

		setLoadingTitle("جاري حفظ تعديلات الرحلة التجارية");
		EditTripProgramHandel(formData);
	};

	// handle scroll to element
	const scrollToElement = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToElement();
	}, []);

	// handle delete trip program item
	const handleDeleteTripItem = (index) => {
		const cloneArray = [...tripProgram];
		const newItems = cloneArray?.filter((item) => item === index);
		setTripProgram(newItems);
	};

	useEffect(() => {
		if (currentBusinessTrips) {
			setData({
				...data,
				title: currentBusinessTrips?.title || "",
				image: currentBusinessTrips?.image || "",
			});

			setEditorValue(currentBusinessTrips?.description || "");

			setTripProgram(currentBusinessTrips?.details?.map((trip) => trip) || []);
		} else {
			setEditorValue("");
		}
	}, [currentBusinessTrips]);

	// handle edit trip program item
	const [editIndex, setEditIndex] = useState(null);
	const handleEditTripItem = (index) => {
		setEditIndex(index);
		setOpenModal(true);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | اضافة رحلة جديدة </title>
			</Helmet>
			<form onSubmit={handleSubmit(handleAddBusinessTrip)}>
				<div className=' absolute lg:pl-[140px] md:pr-5 md:py-[43px] md:pt-4 top-0 right-0 z-30 md:pb-36 w-full md:bg-[#fafafa] bg-[#FFFFFF] otlobha_acadmic'>
					<div
						ref={ref}
						className='flex items-center justify-between px-4 pt-6 mb-2 md:p-0 md:pt-0'>
						<div className='flex'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={handleClosePage}
									className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
									<GoArrowRight
										style={{ color: "#02466A", fontSize: "1.2rem" }}
									/>
								</div>

								<Link
									to={"/"}
									className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2'>
									الرئيسية
								</Link>
							</div>

							<h2
								onClick={handleClosePage}
								className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2 cursor-pointer'>
								/ الرحلات التجارية
							</h2>

							<h3
								className='md:text-[18px] text-[15px] font-medium'
								style={{ color: "#67747B" }}>
								/ تعديل الرحلة
							</h3>
						</div>
					</div>
					<div className='flex flex-col gap-5 mt-[42px] md:pr-[78px] p-4 h-full'>
						{isLoading ? (
							<div className='flex flex-col h-screen mt-28'>
								<CircularLoading />
							</div>
						) : (
							<>
								<div className='flex flex-col gap-[10px]'>
									<label
										className='md:text-[18px] text-[16px]'
										style={{ color: "#011723" }}>
										عنوان الرحلة التجارية<span className='text-red-500'>*</span>
									</label>
									<input
										className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
										style={{
											border: "1px solid #67747B33",
										}}
										type='text'
										name='title'
										{...register("title", {
											required: "حقل العنوان مطلوب",
											pattern: {
												value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
												message: "يجب ان يتكون العنوان من أحرف",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.title}
										{errors?.title && errors.title.message}
									</span>
								</div>

								<div className='flex flex-col gap-[10px]'>
									<label
										className='md:text-[18px] text-[16px]'
										style={{ color: "#011723" }}>
										وصف قصير للرحلة <span className='text-red-500'>*</span>
									</label>
									<div className={styles.editor}>
										<TextEditor
											ToolBar={"createOrEditPages"}
											placeholder={"ادخل وصف قصير عن الرحلة ..."}
										/>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.description}
									</span>
								</div>

								<div className='flex flex-col items-start md:flex-row md:items-center md:gap-16 gap-y-4'>
									<label
										className='md:text-[18px] text-[16px] font-medium whitespace-nowrap'
										style={{ color: "#011723" }}>
										الصورة التعريفية<span className='text-red-500'>*</span>
									</label>
									<div className='flex flex-col w-full gap-2'>
										<div className='text-gray-400 font-light text-[16px]'>
											المقاس المقترح : 300 * 600
										</div>
										<ImageUploading
											value={images}
											onChange={onChange}
											maxNumber={2}
											dataURLKey='data_url'
											acceptType={["jpg", "png", "jpeg"]}>
											{({ onImageUpload, dragProps }) => (
												// write your building UI
												<div
													className='relative mx-auto overflow-hidden upload__image-wrapper'
													style={{
														width: "100%",
														padding: "8px",
														border: "1px dashed #02466A",
														borderRadius: "10px",
														strokeDasharray: "'6%2c5'",
													}}
													onClick={() => {
														onImageUpload();
													}}
													{...dragProps}>
													<div className='w-full h-full cursor-pointer image-item'>
														<div className='flex flex-col justify-center items-center gap-[5px] h-full w-full'>
															<IoMdCloudUpload size={"2em"} />
															<h2
																style={{ color: "#011723", fontSize: "16px" }}>
																اسحب الصورة هنا
															</h2>
															<div className='flex flex-col items-center gap-1'>
																<h2 className='mb-0'>
																	(سيتم قبول الصور png & jpg & jpeg)
																</h2>
																<span className='text-gray-400 font-light text-[14px]'>
																	(الحد الأقصى للصورة 1MB)
																</span>
															</div>
														</div>
													</div>
												</div>
											)}
										</ImageUploading>
										{DataError?.image && (
											<span className='text-red-500 text-[16px]'>
												{DataError?.image}
											</span>
										)}
									</div>
								</div>
								<div className='flex-1 relative m-auto'>
									{(images[0] || currentBusinessTrips) && (
										<div className='md:max-w-[600px] w-[100%]  h-[100%] mt-4 '>
											<img
												className=' object-cover border border-primary-800'
												src={images[0]?.data_url || data?.image}
												alt='preview-img'
												loading='lazy'
											/>
										</div>
									)}
								</div>

								<div className='flex flex-col gap-[10px]'>
									<label
										className='md:text-[18px] text-[16px]'
										style={{ color: "#011723" }}>
										برنامج الرحلة التجارية{" "}
										<span className='text-red-500'>*</span>
									</label>
									{tripProgram?.length > 0 &&
										tripProgram?.map(
											(item, index) =>
												item?.title && (
													<div className='flex flex-col gap-[10px]' key={index}>
														<div className='col-md-3 col-12'>
															<label
																htmlFor='md:text-[18px] text-[16px]'
																style={{
																	color: "#1DBBBE",
																}}>
																الفقرة رقم {index + 1}
															</label>
														</div>
														<div className='flex justify-between items-center gap-2 '>
															<input
																className='flex-1 md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
																type='text'
																readOnly
																value={item?.title}
																style={{
																	color: "#1DBBBE",
																	border: "1px solid #1DBBBE",
																}}
															/>

															<div className=' flex justify-center gap-1 items-center'>
																<EditButton
																	onClick={() => {
																		handleEditTripItem(index);
																	}}
																	style={{
																		width: "25px",
																		height: "25px",
																		cursor: "pointer",
																	}}
																/>

																<Delete
																	onClick={() => {
																		handleDeleteTripItem(index);
																	}}
																	style={{
																		width: "25px",
																		height: "25px",
																		cursor: "pointer",
																	}}
																/>
															</div>
														</div>
													</div>
												)
										)}
									<button
										type='button'
										onClick={() => {
											setOpenModal(true);
											setEditIndex(null);
										}}
										className=' w-full border-2 text-lg font-normal border-[#1DBBBE] text-[#1DBBBE] border-dashed  py-2 rounded-md '>
										اضافة محتوي البرنامج
									</button>
								</div>

								<>
									<div className='flex gap-4 mt-10'>
										<Button
											btnType={"submit"}
											style={{ width: "100%", backgroundColor: "#1DBBBE" }}
											textStyle={{ color: "#EFF9FF" }}
											className={
												"md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
											}
											type={"normal"}>
											حفظ التعديلات
										</Button>
										<Button
											style={{ borderColor: "#02466A" }}
											textStyle={{ color: "#02466A" }}
											className={
												"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
											}
											type={"outline"}
											onClick={handleClosePage}
											btnType={"button"}>
											إلغاء
										</Button>
									</div>
								</>
							</>
						)}
					</div>
				</div>
			</form>

			{openModal ? (
				<AddBusinessTripProgram
					tripProgram={tripProgram}
					setOpenModal={setOpenModal}
					setTripProgram={setTripProgram}
					editIndex={editIndex}
					editData={editIndex !== null ? tripProgram[editIndex] : null}
				/>
			) : null}
		</>
	);
};

export default EditBusinessTrip;
