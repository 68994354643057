import React from "react";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
/* Icons */
import { BsStarFill, BsStarHalf } from "react-icons/bs";

const MostMarkets = ({ more_store_visit, loading }) => {
	return (
		<TableContainer
			component={Paper}
			sx={{ height: "100%" }}
			className='shadow-[0px_3px_6px_#00000029] rounded-lg'>
			<h2 className='p-4 text-[22px] font-medium'>المتاجر الأكثر زيارة</h2>
			<Table sx={{}} aria-label='simple table'>
				<TableHead>
					<TableRow
						className='bg-[#E6F5F652]'
						sx={{
							"& .MuiTableCell-root.MuiTableCell-head": {
								fontFamily: "'Tajawal', sans-serif",
								border: "none",
							},
						}}>
						<TableCell
							align='center'
							className='text-[#52575D] text-base font-medium pr-3'>
							اسم المتجر
						</TableCell>
						<TableCell
							align='right'
							className='text-[#52575D] text-base font-medium'>
							الأنشطة
						</TableCell>
						<TableCell
							align='center'
							className='text-[#52575D] text-base font-medium'>
							تقييم العملاء
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody
					sx={{
						"& .MuiTableRow-root .MuiTableCell-root": {
							fontFamily: "'Tajawal', sans-serif",
							border: "none",
						},
					}}>
					{loading ? (
						<TableRow>
							<TableCell colSpan={3}>
								<CircularLoading />
							</TableCell>
						</TableRow>
					) : (
						<>
							{more_store_visit?.length === 0 ? (
								<TableRow>
									<TableCell colSpan={5}>
										<p>لاتوجد متاجر حتي الآن</p>
									</TableCell>
								</TableRow>
							) : (
								more_store_visit?.map((row, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
											"& .MuiTableCell-root": {
												height: "4rem",
												border: "none",
											},
										}}>
										<TableCell
											component='th'
											scope='row'
											align='center'
											className='text-base font-normal '>
											<span className='text-ellipsis w-32'>
												{row?.store_name}
											</span>
										</TableCell>
										<TableCell
											sx={{
												display: "flex",
												alignItems: "right",
												gap: "0.5rem",
											}}
											component='th'
											scope='row'>
											<div className='flex flex-row items-center text-base font-normal justify-start min-h-[35px] max-h-[35px] gap-2'>
												{row?.activity[0]?.icon && (
													<div
														className='img_icons'
														style={{
															minWidth: "35px",
															minHeight: "35px",
															maxWidth: "35px",
															maxHeight: "35px",
														}}>
														<img
															src={row?.activity[0]?.icon}
															alt=''
															loading='lazy'
														/>
													</div>
												)}
												<span className='text-ellipsis w-30'>
													{row?.activity[0]?.name}
												</span>
											</div>
										</TableCell>

										<TableCell align='center' className='text-base font-normal'>
											<div className='flex items-center justify-center'>
												<div
													className='flex justify-between items-center py-1 px-1 w-16 h-6 rounded-md'
													style={{ backgroundColor: "rgb(164,161,251)" }}>
													<h2 className='font-medium' style={{ color: "#fff" }}>
														{row?.rate}
													</h2>
													{row?.rate > 3 ? (
														<BsStarFill color='#fff' />
													) : (
														<BsStarHalf color='#fff' />
													)}
												</div>
											</div>
										</TableCell>
									</TableRow>
								))
							)}
						</>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MostMarkets;
