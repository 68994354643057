import React, { useEffect, useContext, Fragment } from "react";
/* Third party */
/* Contexts */
import Context from "../../../../store/context";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* Icons */
import {
	SortIcon,
	Delete,
	CheckedSquare,
	SwitchOn,
} from "../../../../assets/Icons";
import NewPagination from "../../../PaginationComp/NewPagination";
import {
	useCurrencyChangeStatusAllByParamsMutation,
	useCurrencyDeleteItemMutation,
} from "../../../../RTK/apiSlices/currencyApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},

	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "اسم العملة",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "رمز العملة",
	},

	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#eaecd0" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='md:text-[18px] text-[16px] font-medium'
						key={index}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#02466A",
							whiteSpace: "nowrap",
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}>
								{headCell.label}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell />
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	const { permissions } = RolePermissionsStore;
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				gap: "2rem",
				justifyContent: "flex-end",
			}}>
			<div
				className='gap-2 px-4 rounded-full fcc'
				style={{ backgroundColor: "rgba(255, 159, 26, 0.04)" }}></div>
			<div className='flex items-center gap-2'>
				{permissions?.includes("admin.currency.changeSatusall") &&
					numSelected > 0 && (
						<div
							className='gap-2 px-2 rounded-full fcc'
							style={{ minWidth: "114px", backgroundColor: "#FF9F1A0A" }}
							onClick={() => {
								setNotificationTitle(
									"سيتم تغيير حالة جميع العملات التي قمت بتحديدهم"
								);
								setItems(itemsSelected);
								setActionType("ChangeStatus");
							}}>
							<h2
								className={"font-medium md:text-[18px] text-[16px]"}
								style={{ color: "#FF9F1A" }}>
								تعطيل
							</h2>
							<Box
								sx={{
									"& #Path_820": {
										fill: "#FF9F1A",
									},
								}}>
								<SwitchOn
									style={{
										cursor: "pointer",
										color: "red",
										fontSize: "0.5rem",
									}}
									className={"w-5"}></SwitchOn>
							</Box>
						</div>
					)}
			</div>

			<div className='flex items-center'>
				<h2 className='font-medium md:text-[18px] text-[16px]'>تحديد الكل</h2>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#011723",
						"& .MuiSvgIcon-root": {
							color: "#011723",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": " all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	setDataRow,
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(4);

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.Currencies?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [currencyDeleteItem] = useCurrencyDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await currencyDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const [currencyChangeStatusAllByParams] =
		useCurrencyChangeStatusAllByParamsMutation();

	const currencyChangeStatusAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await currencyChangeStatusAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.Currencies.length)
			: 0;
	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={fetchedData?.Currencies.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							onSelectAllClick={handleSelectAllClick}
							rowCount={fetchedData?.Currencies.length || 0}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={4}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.Currencies?.map((row, index) => {
										const isItemSelected = isSelected(row?.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={index}
												selected={isItemSelected}>
												<TableCell component='th' id={labelId} scope='row'>
													{permissions?.includes("admin.currency.destroy") && (
														<Delete
															title='حذف'
															onClick={() => {
																setActionDelete(
																	"سيتم حذف العملة وهذة الخطوة غير قابلة للرجوع"
																);
																setItemId(row?.id);
															}}
															style={{
																cursor: "pointer",
																color: "red",
																fontSize: "1rem",
															}}></Delete>
													)}
												</TableCell>

												<TableCell align='right'>
													<h2
														style={{ color: "#4D4F5C" }}
														className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'>
														{row?.name}
													</h2>
												</TableCell>

												<TableCell align='right'>
													<div
														className='img_icons'
														style={{ marginLeft: "auto" }}>
														<img
															className='img_icons'
															src={row?.image ? row?.image : PlaceholderImage}
															alt={row?.name}
															loading='lazy'
															style={{
																width: "100%",
																height: "100%",
																objectFit: "cover",
																borderRadius: "50%",
															}}
														/>
													</div>
												</TableCell>
												<TableCell
													align='right'
													className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'>
													{(index + 1).toLocaleString("en-US", {
														minimumIntegerDigits: 2,
														useGrouping: false,
													})}
												</TableCell>
												<TableCell padding='none' align={"right"}>
													<Checkbox
														sx={{
															color: "#1DBBBE",
															"& .MuiSvgIcon-root": {
																color: "#011723",
															},
														}}
														checked={isItemSelected}
														onClick={(event) => handleClick(event, row?.id)}
														inputProps={{
															"aria-labelledby": labelId,
														}}
													/>
												</TableCell>
											</TableRow>
										);
									})}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<NewPagination
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				page_count={page_count}
			/>
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleChangeAllItemsStatus={currencyChangeStatusAllByParamsHandel}
				/>
			)}
		</Box>
	);
}
