import React, { Fragment } from "react";

import { Pagination, Select, MenuItem } from "@mui/material";
import "./Pagination.css";
import { IoIosArrowDown } from "react-icons/io";

const NewPagination = ({
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	search,
}) => {
	return (
		<Fragment>
			{search ? null : (
				<>
					<div className='btn-container-row'>
						<div className='row-per-page-box'>
							<i>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									version='1.1'
									viewBox='0 0 64 64'>
									<g>
										<path d='M48.5 20.8C43.4 25.9 38.4 31 33.3 36.1l-.8.8L18.6 23l-2.2-2.2c-1.8-1.8-4.7 1-2.8 2.8l15.3 15.3 2.2 2.2c.8.8 2.1.8 2.8 0C39 36 44.1 31 49.2 25.9l2.2-2.2c1.8-1.8-1-4.7-2.9-2.9z'></path>
									</g>
								</svg>
							</i>
							عدد الصفوف
							<Select
								value={currentPage}
								onChange={(event) => {
									setCurrentPage(event.target.value);
									setPageNumber(1);
								}}
								IconComponent={IoIosArrowDown}>
								{[10, 20, 30, 50, 100]?.map((item) => (
									<MenuItem key={item} value={item}>
										{item}
									</MenuItem>
								))}
							</Select>
						</div>

						{page_count === 1 ? null : (
							<div>
								<Pagination
									count={page_count}
									page={pageNumber}
									onChange={(event, value) => {
										setPageNumber(value);
									}}
								/>
							</div>
						)}

						<div></div>
					</div>
				</>
			)}
		</Fragment>
	);
};

export default NewPagination;
