import React, { useContext } from "react";
import styles from "../../../components/SupportPageComp/TableComp/TableComp.module.css";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
/* Contexts */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import DeleteOneModalComp from "../../../components/DeleteOneModal/DeleteOneModal";
import Pagination from "../../../components/PaginationComp/Pagination";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* MUI */
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch } from "@mui/material";
/* Icons */
import { FaCheck } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { Delete, InfoIcon } from "../../../assets/Icons";

import {
	useContactChangeStatusMutation,
	useDeleteContactItemMutation,
} from "../../../RTK/apiSlices/contactUsApi";

// Status array
const stateChanges = [
	{ value: "منتهية", color: "#3AE374", icon: <FaCheck fill='#fff' /> },
	{
		value: "غير منتهية",
		color: "#D3D3D3",
		icon: <BsClockHistory fill='#fff' />,
	},
];

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		width: "131px",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "محتوي الرسالة ",
		width: "290px",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "عنوان الرسالة",
	},
	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "البريد الالكتروني",
		width: "120px",
	},
	{
		id: "title",
		numeric: true,
		disablePadding: false,
		label: "الاسم",
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
		width: "2rem",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#5EBFF2", borderRadius: "4px" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='text-lg font-medium '
						key={headCell?.id}
						sx={{
							textAlign: "center",
							width: headCell.width ? headCell.width : "auto",
							color: "#EFF9FF",
							whiteSpace: "nowrap",
						}}>
						{headCell?.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	rowCount: PropTypes.number.isRequired,
};

export default function ContactUsTable({
	atlobhaContact,
	loading,
	showDetails,
}) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(9);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const open = Boolean(anchorEl);
	const rowsPerPagesCount = [10, 20, 30, 50, 100];
	const handleRowsClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - atlobhaContact?.length)
			: 0;
	const allRows = () => {
		const num = Math.ceil(atlobhaContact?.length / rowsPerPage);
		const arr = [];
		for (let index = 0; index < num; index++) {
			arr.push(index + 1);
		}
		return arr;
	};

	// change status for item
	const [contactChangeStatus] = useContactChangeStatusMutation();
	const changeContactChangeStatus = async (id) => {
		try {
			await contactChangeStatus({ id })
				.unwrap()
				.then((data) => {
					console.log(data);
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	// delete item
	const [deleteContactItem] = useDeleteContactItemMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await deleteContactItem({ id })
				.unwrap()
				.then((data) => {
					console.log(data);
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead rowCount={atlobhaContact?.length || 0} />
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={7}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{atlobhaContact?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={7}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										atlobhaContact
											?.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											?.map((row, index) => {
												const labelId = `enhanced-table-checkbox-${index}`;
												const findStateChanges = stateChanges.find(
													(i) => row?.status === i?.value
												);
												return (
													<TableRow
														hover
														role='checkbox'
														tabIndex={-1}
														key={row.id}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-2'>
																{permissions?.includes(
																	"admin.atlobhaContact.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف الرسالة وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.atlobhaContact.changeStatus"
																) && (
																	<Switch
																		title='تغيير الحالة'
																		onChange={() =>
																			changeContactChangeStatus(row?.id)
																		}
																		className=''
																		sx={{
																			width: "50px",

																			"& .MuiSwitch-thumb": {
																				width: "11px",
																				height: "11px",
																			},
																			"& .MuiSwitch-switchBase": {
																				top: "6px",
																				left: "5px",
																			},
																			"& .MuiSwitch-switchBase.Mui-checked": {
																				left: "-5px",
																			},
																			"& .Mui-checked .MuiSwitch-thumb": {
																				backgroundColor: "#FFFFFF",
																			},
																			"& .MuiSwitch-track": {
																				height: "16px",
																				borderRadius: "20px",
																			},
																			"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track":
																				{
																					backgroundColor: "#3AE374",

																					opacity: 1,
																				},
																		}}
																		checked={
																			row?.status === "messagesfinished"
																				? true
																				: false
																		}
																	/>
																)}
																{permissions?.includes(
																	"admin.atlobhaContact.show"
																) && (
																	<InfoIcon
																		title='تفاصيل'
																		onClick={() => {
																			showDetails(row?.id);
																		}}
																		style={{
																			cursor: "pointer",
																			fontSize: "1.2rem",
																		}}
																	/>
																)}
															</div>
														</TableCell>
														<TableCell
															className='pr-0'
															align='center'
															sx={{ "& path": { fill: "#fff" } }}>
															<div
																className={
																	"flex ml-auto gap-2 items-center justify-center rounded-full p-2"
																}
																style={{
																	backgroundColor: findStateChanges?.color,
																	maxWidth: "131px",
																}}>
																<h2 className='text-base font-normal text-slate-50 whitespace-nowrap'>
																	{row?.status}
																</h2>
																<div
																	className={`w-4 h-4 flex items-center justify-center ${styles.icons}`}>
																	{findStateChanges?.icon}
																</div>
															</div>
														</TableCell>
														<TableCell
															align='center'
															className=' max-w-[290px]'>
															<h2
																className='w-full overflow-hidden font-normal md:text-lg text-[16px] whitespace-nowrap'
																style={{
																	textOverflow: "ellipsis",
																	direction: "rtl",
																}}>
																{row?.content}
															</h2>
														</TableCell>
														<TableCell align='center'>
															<h2 className='font-normal md:text-lg text-[16px] whitespace-nowrap'>
																{row?.title}
															</h2>
														</TableCell>

														<TableCell align='center'>
															<h2 className='font-normal md:text-lg text-[16px] whitespace-nowrap'>
																{row?.email}
															</h2>
														</TableCell>
														<TableCell align='center'>
															<h2
																className='w-full overflow-hidden font-normal md:text-lg text-[16px] whitespace-nowrap'
																style={{
																	textOverflow: "ellipsis",
																	direction: "rtl",
																}}>
																{row?.name}
															</h2>
														</TableCell>
														<TableCell
															align='center'
															className=' font-normal md:text-lg text-[16px] whitespace-nowrap'>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
													</TableRow>
												);
											})
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{atlobhaContact?.length !== 0 && !loading && (
				<Pagination
					data={atlobhaContact?.length}
					allRows={allRows}
					rowsPerPage={rowsPerPage}
					setPage={setPage}
					page={page}
					rowsPerPagesCount={rowsPerPagesCount}
					handleRowsClick={handleRowsClick}
					open={open}
					anchorEl={anchorEl}
					handleClose={handleClose}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			)}

			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</Box>
	);
}
