import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const notificationsApi = createApi({
	reducerPath: "notificationsApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.com/api/Admin/",
	}),
	tagTypes: ["Notifications"],

	endpoints: (builder) => ({
		getNotifications: builder.query({
			query: () => ({ url: `NotificationIndex` }),

			transformResponse: (response) => response.data,
			providesTags: ["Notifications"],
		}),

		notificationsChangeStatusAllByParams: builder.mutation({
			query: ({ queryParams }) => ({
				url: `websiteorderSatusall?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Notifications"],
		}),

		notificationsDeleteAllByParams: builder.mutation({
			query: ({ queryParams }) => ({
				url: `NotificationDeleteAll?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Notifications"],
		}),

		addNewEmail: builder.mutation({
			query: ({ body }) => {
				return {
					url: `addEmail`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Notifications"],
		}),

		notificationsDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `NotificationDelete/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Notifications"],
		}),

		markSingleNotificationAsRead: builder.mutation({
			query: ({ notificationId }) => ({
				url: `NotificationRead?id[]=${notificationId}`,
				method: "GET",
			}),
			invalidatesTags: ["Notifications"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useAddNewEmailMutation,
	useGetNotificationsQuery,
	useNotificationsDeleteItemMutation,
	useMarkSingleNotificationAsReadMutation,
	useNotificationsDeleteAllByParamsMutation,
	useNotificationsChangeStatusAllByParamsMutation,
} = notificationsApi;
