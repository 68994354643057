import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const shippingTypeApi = createApi({
  reducerPath: "shippingTypeApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["ShippingType"],

  endpoints: (builder) => ({
    getShippingType: builder.query({
      query: () => ({url: `shippingtype`}),

      transformResponse: (response) => response.data,
      providesTags: ["ShippingType"],
    }),

    changeShippingTypeStatus: builder.mutation({
      query: ({ id }) => ({
        url: `changeShippingtypeStatus/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["ShippingType"],
    }),

  }),
});

// Export endpoints and hooks
export const {
  useGetShippingTypeQuery,
  useChangeShippingTypeStatusMutation,
} = shippingTypeApi;
