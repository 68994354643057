import React from "react";
import "./App.css";
/* Third party */
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* Components */
import {
	Login,
	MainPage,
	SouqOtlobha,
	GeneralSouq,
	Storage,
	Markets,
	MarketsTable,
	ProductsTable,
	VerificationTable,
	RecordingStatus,
	Packages,
	CurrentSubscriptions,
	ServicesPage,
	OtlobhaAcademy,
	TemplatePage,
	SliderPage,
	PartitionsPage,
	PagesPage,
	Users,
	CouponsPage,
	DelegatesTablePage,
	AddNewDelegatePage,
	EditDelegatePage,
	ShowDelegatePage,
	StatusPage,
	ShopVarieties,
	SupportPage,
	ShippingCompaniesPage,
	PaymentGatewaysPage,
	PrimarySettings,
	CountriesPages,
	CitiesPage,
	CurrencyPage,
	EmailSettingPage,
	SocialMediaLinksPage,
	RegistrationCasesPage,
	NotificationsPage,
	SEOPage,
	ReviewsPage,
	ContactUs,
	SouqOtlbhaOrders,
	Faqs,
	SouqAtlbhaOrdersDetails,
	OrdersPage,
	DomainOrders,
	DomainOrdersDetails,
} from "./pages/index";
import PrivateRoutes from "./PrivateRoutes";
import AxiosInterceptors from "./API/AxiosInterceptors";

//Country
import AddCountry from "./components/SettingComp/CountriesPageComp/AddCountry/AddCountry";
import EditCountry from "./components/SettingComp/CountriesPageComp/EditCountry/EditCountry";
import ShowCountry from "./components/SettingComp/CountriesPageComp/ShowCountry/ShowCountry";
//City
import AddCity from "./components/SettingComp/CitiesPageComp/AddCity/AddCity";
import EditCity from "./components/SettingComp/CitiesPageComp/EditCity/EditCity";
import ShowCity from "./components/SettingComp/CitiesPageComp/ShowCity/ShowCity";
//Currency
import AddNewCurrency from "./components/SettingComp/CurrencyPageComp/AddNewCurrency/AddNewCurrency";
//SocialMediaLinks
import EditNewLink from "./components/SettingComp/SocialMediaLinksPageComp/EditNewLink/EditNewLink";
import AddNewLink from "./components/SettingComp/SocialMediaLinksPageComp/AddNewLink/AddNewLink";
//Supports
import ComplaintDetails from "./components/SupportPageComp/ComplaintDetails/ComplaintDetails";
//Orders
import ServiceOrder from "./components/OrderPageComp/ServiceOrder/ServiceOrder";
//Varieties
import AddVariety from "./components/VarietiesComp/ShopVarieties/AddVariety/AddVariety";
import EditVariety from "./components/VarietiesComp/ShopVarieties/EditVariety/EditVariety";
//Coupons
import AddNewCoupon from "./components/MarketingPageComp/CouponsPageComp/AddNewCoupon/AddNewCoupon";
import EditCoupon from "./components/MarketingPageComp/CouponsPageComp/EditCoupon/EditCoupon";
import ShowCoupon from "./components/MarketingPageComp/CouponsPageComp/ShowCoupon/ShowCoupon";
//Services
import AddNewService from "./components/ServicesPageComp/AddNewService/AddNewService";
import EditService from "./components/ServicesPageComp/EditService/EditService";
import ShowServiceDetails from "./components/ServicesPageComp/ShowServiceDetails/ShowServiceDetails";
//Pages
import AddNewPage from "./components/PagesPageComp/AddNewPage/AddNewPage";
import EditPage from "./components/PagesPageComp/EditPage/EditPage";
//Users
import AddNewUser from "./components/UsersPageComp/AddNewUser/AddNewUser";
import UserDetails from "./components/UsersPageComp/UserDetails/UserDetails";
import EditUser from "./components/UsersPageComp/EditUser/EditUser";
//Roles
import FunctionalRoles from "./components/UsersPageComp/FunctionalRoles/FunctionalRoles";
import CreateRole from "./components/UsersPageComp/CreateRole/CreateRole";
import EditRole from "./components/UsersPageComp/EditRole/EditRole";
//OtlobhaAcademy

import AddNewLesson from "./components/OtlobhaAcademyComp/AcademyLessons/AddNewLesson/AddNewLesson";
import EditLesson from "./components/OtlobhaAcademyComp/AcademyLessons/EditLesson/EditLesson";
//GeneralSouq
import AddApi from "./components/GeneralSouqComp/AddApi/AddApi";
import EditApi from "./components/GeneralSouqComp/EditApi/EditApi";
//Markets
import AddNewMarket from "./components/MarketsPagesComp/MarketsTable/AddNewMarket/AddNewMarket";
import ShowCurrentMarket from "./components/MarketsPagesComp/MarketsTable/ShowCurrentMarket/ShowCurrentMarket";
import EditMarket from "./components/MarketsPagesComp/MarketsTable/EditMarket/EditMarket";
//Verification
import ShowVerificationData from "./components/MarketsPagesComp/VerificationTable/ShowVerificationData/ShowVerificationData";
import EditVerificationData from "./components/MarketsPagesComp/VerificationTable/EditVerificationData/EditVerificationData";
//Storage
import NewProduct from "./components/SoquStorageComp/NewProduct/NewProduct";
import EditProduct from "./components/SoquStorageComp/EditProduct/EditProduct";
//Packages
import AddNewPackagePlan from "./components/PackagesPageComp/AddNewPackagePlan/AddNewPackagePlan";
//SouqOtlobha
import NewProductSouqOtlobha from "./components/SouqOtlobhaComp/NewProduct/NewProduct";
import EditProductSouqOtlobha from "./components/SouqOtlobhaComp/EditProduct/EditProduct";
import DuplicateProduct from "./components/SouqOtlobhaComp/DuplicateProduct/DuplicateProduct";

import AddQuestion from "./components/FaqsComp/AddQuestion/AddQuestion";
import EditQuestion from "./components/FaqsComp/EditQuestion/EditQuestion";
import EditPackagePlan from "./components/PackagesPageComp/EditPackagePlan/EditPackagePlan";
import PackageFeatures from "./components/PackagesPageComp/PackageFeatures/PackageFeatures";
import EditPackageFeatures from "./components/PackagesPageComp/PackageFeatures/EditPackageFeatures";
import AddPackageFeatures from "./components/PackagesPageComp/PackageFeatures/AddPackageFeatures";
import AddNewCourse from "./components/OtlobhaAcademyComp/TrainingCourses/AddNewCourse/AddNewCourse";
import EditCourse from "./components/OtlobhaAcademyComp/TrainingCourses/EditCourse/EditCourse";
import AddNewLiveCourse from "./components/OtlobhaAcademyComp/LiveCourses/AddNewLiveCourse";
import EditLiveCourse from "./components/OtlobhaAcademyComp/LiveCourses/EditLiveCourse";
import BusinessTrips from "./pages/Packages/BusinessTrips/BusinessTrips";
import AddNewBusinessTrip from "./components/PackagesPageComp/BusinessTripsComp/AddNewBusinessTrip/AddNewBusinessTrip";
import EditBusinessTrip from "./components/PackagesPageComp/BusinessTripsComp/EditBusinessTrip/EditBusinessTrip";

function App() {
	return (
		<BrowserRouter>
			<AxiosInterceptors>
				<Routes>
					<Route element={<PrivateRoutes />}>
						<Route exact path='/' element={<MainPage />} />
						{/*************************** السوق ************************/}
						{/*************************** سوق أطلبها ************************/}
						<Route path='/souq_atlbha' element={<SouqOtlobha />} />
						<Route
							path='/souq_atlbha/add-product'
							element={<NewProductSouqOtlobha />}
						/>
						<Route
							path='/souq_atlbha/edit-product/:id'
							element={<EditProductSouqOtlobha />}
						/>
						<Route
							path='/souq_atlbha/duplicate-product/:id'
							element={<DuplicateProduct />}
						/>
						{/*************************** طلبات سوق اطلبها ************************/}
						<Route path='/talabat_souq_atlbha' element={<SouqOtlbhaOrders />} />
						<Route
							path='/talabat_souq_atlbha/show-details/:id'
							element={<SouqAtlbhaOrdersDetails />}
						/>
						{/************************* السوق العام ***********************/}
						<Route path='/general_souq' element={<GeneralSouq />} />
						<Route path='/general_souq/add-souq' element={<AddApi />} />
						<Route path='/general_souq/edit-souq/:id' element={<EditApi />} />
						{/************************* المخزون ***********************/}
						<Route path='/storage' element={<Storage />} />
						<Route path='/storage/add-product' element={<NewProduct />} />
						<Route path='/storage/edit-product/:id' element={<EditProduct />} />
						{/************************* المتاجر **********************/}
						<Route path='/markets_section' element={<Markets />} />
						{/************************* المتاجر **********************/}
						<Route path='/markets' element={<MarketsTable />} />
						<Route path='/markets/add-market' element={<AddNewMarket />} />
						<Route path='/markets/edit-market/:id' element={<EditMarket />} />
						<Route
							path='/markets/show-market/:id'
							element={<ShowCurrentMarket />}
						/>
						{/************************* المنتجات **********************/}
						<Route path='/products' element={<ProductsTable />} />
						{/************************* التوثيق **********************/}
						<Route path='/verification' element={<VerificationTable />} />
						<Route
							path='/verification/show-verification/:id'
							element={<ShowVerificationData />}
						/>
						<Route
							path='/verification/edit-verification/:id'
							element={<EditVerificationData />}
						/>
						{/************************* حالة_التسجيل **********************/}
						<Route path='/حالة_التسجيل' element={<RecordingStatus />} />
						{/************************* الباقات ***********************/}
						<Route path='/packages' element={<Packages />} />
						<Route
							path='/packages/add-package'
							element={<AddNewPackagePlan />}
						/>
						<Route
							path='/packages/edit-package/:id'
							element={<EditPackagePlan />}
						/>
						{/* business trips */}
						<Route path='/business-trips' element={<BusinessTrips />} />
						<Route
							path='/business-trips/add-new-business-trip'
							element={<AddNewBusinessTrip />}
						/>
						<Route
							path='/business-trips/edit-business-trip/:id'
							element={<EditBusinessTrip />}
						/>
						{/* Package Features */}
						<Route
							path='/packages/package-features'
							element={<PackageFeatures />}
						/>
						<Route
							path='/packages/add-package-features'
							element={<AddPackageFeatures />}
						/>
						<Route
							path='/packages/edit-package-features/:id'
							element={<EditPackageFeatures />}
						/>
						<Route path='/subscriptions' element={<CurrentSubscriptions />} />
						{/********************** الخدمات *******************/}
						<Route path='/services' element={<ServicesPage />} />
						<Route path='/services/add-service' element={<AddNewService />} />
						<Route
							path='/services/edit-service/:id'
							element={<EditService />}
						/>
						<Route
							path='/services/show-service/:id'
							element={<ShowServiceDetails />}
						/>
						{/* الاكاديمية  */}
						<Route path='/atlbha_academy' element={<OtlobhaAcademy />} />
						{/* add new  course */}
						<Route path='/add-new-course' element={<AddNewCourse />} />
						{/* edit  course*/}
						<Route path='/edit-course/:id' element={<EditCourse />} />
						{/* add new lesson */}
						<Route path='/add-lesson' element={<AddNewLesson />} />
						{/* edit lesson */}
						<Route path='/edit-lesson/:id' element={<EditLesson />} />
						{/* add new live course */}
						<Route path='/add-new-live-course' element={<AddNewLiveCourse />} />
						{/* edit live course*/}
						<Route path='/edit-live-course/:id' element={<EditLiveCourse />} />
						{/* القالب */}
						<Route path='/template_page' element={<TemplatePage />} />
						<Route path='/sliders' element={<SliderPage />} />
						<Route path='/partitions' element={<PartitionsPage />} />
						{/* الصفحات */}
						<Route path='pages' element={<PagesPage />} />
						<Route path='pages/add-page' element={<AddNewPage />} />
						<Route path='pages/edit-page/:id' element={<EditPage />} />
						=======
						{/* الأسئلة الشائعة */}
						<Route path='/faqs' element={<Faqs />} />
						{/* المستخدمين */}
						<Route path='/users' element={<Users />} />
						<Route path='/users/add-user' element={<AddNewUser />} />
						<Route path='/users/edit-user/:id' element={<EditUser />} />
						<Route path='/users/show-user/:id' element={<UserDetails />} />
						{/* الادوار */}
						<Route path='/users/roles' element={<FunctionalRoles />} />
						<Route path='/users/roles/add-role' element={<CreateRole />} />
						<Route path='/users/roles/edit-role/:id' element={<EditRole />} />
						{/* التسويق */}
						<Route path='/coupons' element={<CouponsPage />} />
						<Route path='/coupons/add-coupon' element={<AddNewCoupon />} />
						<Route path='/coupons/edit-coupon/:id' element={<EditCoupon />} />
						<Route path='/coupons/show-coupon/:id' element={<ShowCoupon />} />
						{/* المندوبين */}
						<Route path='/marketers' element={<DelegatesTablePage />} />
						<Route path='/add_marketer' element={<AddNewDelegatePage />} />
						<Route path='/edit_marketer/:id' element={<EditDelegatePage />} />
						<Route path='/show_marketer/:id' element={<ShowDelegatePage />} />
						<Route
							path='/registration_marketers_status'
							element={<StatusPage />}
						/>
						{/* التصنيفات */}
						<Route path='/categories_activities' element={<ShopVarieties />} />
						<Route
							path='/categories_activities/add-category'
							element={<AddVariety />}
						/>
						<Route
							path='/categories_activities/edit-category/:id'
							element={<EditVariety />}
						/>
						{/* الطلبات */}
						<Route path='/orders' element={<OrdersPage />} />
						<Route path='/domain-orders' element={<DomainOrders />} />
						<Route
							path='/domain-orders/show-details/:id'
							element={<DomainOrdersDetails />}
						/>
						<Route path='/orders/show-details/:id' element={<ServiceOrder />} />
						{/* الدعم الفنى */}
						<Route path='/technical_support' element={<SupportPage />} />
						<Route
							path='/technical_support/show-details/:id'
							element={<ComplaintDetails />}
						/>
						{/* تواصل معنا  */}
						<Route path='/contact_us' element={<ContactUs />} />
						{/* شركات_الشحن */}
						<Route
							path='/shipping_companies'
							element={<ShippingCompaniesPage />}
						/>
						{/* بوابات_الدفع */}
						<Route path='/payment_gates' element={<PaymentGatewaysPage />} />
						{/* الإعدادات */}
						<Route path='/basic_settings' element={<PrimarySettings />} />
						{/* الدول */}
						<Route path='/countries' element={<CountriesPages />} />
						<Route path='/countries/add-country' element={<AddCountry />} />
						<Route
							path='/countries/edit-country/:id'
							element={<EditCountry />}
						/>
						<Route
							path='/countries/show-country/:id'
							element={<ShowCountry />}
						/>
						{/* المدن */}
						<Route path='/cities' element={<CitiesPage />} />
						<Route path='/cities/add-city' element={<AddCity />} />
						<Route path='/cities/edit-city/:id' element={<EditCity />} />
						<Route path='/cities/show-city/:id' element={<ShowCity />} />
						{/* العملات */}
						<Route path='/currencies' element={<CurrencyPage />} />
						<Route
							path='/currencies/add-currency'
							element={<AddNewCurrency />}
						/>
						{/* الايميلات */}
						<Route path='/emails' element={<EmailSettingPage />} />
						{/* التواصل الاجتماعي */}
						<Route path='/social_media' element={<SocialMediaLinksPage />} />
						<Route
							path='/social_media/add-social-media'
							element={<AddNewLink />}
						/>
						<Route
							path='/social_media/edit-social-media/:id'
							element={<EditNewLink />}
						/>
						{/* حالات التسجيل */}
						<Route path='/registrations' element={<RegistrationCasesPage />} />
						<Route path='/notifications' element={<NotificationsPage />} />
						<Route path='/seo_setting' element={<SEOPage />} />
						{/* تقييمات_المنصة */}
						<Route path='/reviews' element={<ReviewsPage />} />
						<Route path='/faqs/add-question' element={<AddQuestion />} />
						<Route path='/faqs/edit-question/:id' element={<EditQuestion />} />
					</Route>
					<Route exact path='/login' element={<Login />} />
				</Routes>
			</AxiosInterceptors>
		</BrowserRouter>
	);
}

export default App;
