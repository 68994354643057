import React, { useState } from "react";

export const RolePermissionsContext = React.createContext({});

const RolePermissionsProvider = (props) => {
	const [role, setRole] = useState(null);
	const [permissions, setPermissions] = useState(null);
	const [permissionsLoading, setPermissionsLoading] = useState(false);

	const data = {
		role,
		setRole,
		permissions,
		setPermissions,
		permissionsLoading,
		setPermissionsLoading
	};

	return (
		<RolePermissionsContext.Provider value={data}>
			{props.children}
		</RolePermissionsContext.Provider>
	);
};

export default RolePermissionsProvider;