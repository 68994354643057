import React, { useContext } from 'react';
import ReactDom from 'react-dom';
import styles from './SendingLoadingComp.module.css';
/* Context */
import { LoadingContext } from '../../store/LoadingProvider';
/* MUI */
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';



const SendingLoading = () => {
	const LoadingProvider = useContext(LoadingContext);
	const { loadingTitle } = LoadingProvider;
	return (
		<div className={`${styles.backdrop} fixed back_drop  bottom-0 left-0 w-full   opacity-90 z-50 bg-[#FFF]`}>
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
				<CircularProgress size='48px' /> <span className='text-black text-2xl'>{loadingTitle}</span>
			</Box>
		</div>
	);
};

const SendingLoadingComp = ({ title }) => {
	return <>{ReactDom.createPortal(<SendingLoading title={title} />, document.getElementById('action_div'))}</>;
};

export default SendingLoadingComp;
