import React, { useState, useContext, useEffect } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import StorageStatus from "../../../components/SoquStorageComp/StorageStatus/StorageStatus";
import StorageFilter from "../../../components/SoquStorageComp/StorageFilter/StorageFilter";
import StorageTable from "../../../components/SoquStorageComp/StorageTable/StorageTable";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import {
	useFilterStockByCategoryMutation,
	useSearchInStockMutation,
	useStockDataQuery,
} from "../../../RTK/apiSlices/stockApi";

const Storage = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useStockDataQuery({
		page,
		number: rowsPerPage,
	});

	const [search, setSearch] = useState("");
	const [sortSelector, setSortSelector] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [filterResults, setFilterResults] = useState([]);

	let products = fetchedData?.products || [];
	let filterPoducts = products;

	const getSearch = (value) => {
		setSearch(value);
	};
	const getSortSelector = (value) => {
		setSortSelector(value);
	};

	if (search !== "") {
		products = searchResults.products;
	} else {
		products = fetchedData?.products;
	}

	if (sortSelector !== "") {
		filterPoducts = filterResults?.products;
	} else {
		filterPoducts = products;
	}

	const [filterStockByCategory, { isLoading: filterIsLoading }] =
		useFilterStockByCategoryMutation();

	const filterStockByCategoryHandel = async () => {
		try {
			const response = await filterStockByCategory({
				page: page,
				number: rowsPerPage,
				category_id: sortSelector,
			});
			setFilterResults(response?.data?.data);
			setSearch("");
		} catch (error) {
			console.error("Error filtering orders:", error);
		}
	};

	const [searchInStock, { isLoading: searchIsLoading }] =
		useSearchInStockMutation();
	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInStock({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(fetchedData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	useEffect(() => {
		if (sortSelector) {
			filterStockByCategoryHandel();
		} else {
			filterPoducts = products;
		}
	}, [sortSelector, page, rowsPerPage]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | المخزون</title>
			</Helmet>
			<div className={`px-4 lg:pt-8`} style={{ backgroundColor: "#fafafa" }}>
				<div
					className='md:p-4 p-2 md:text-[18px] text-[14px] font-medium text-right'
					style={{ color: "#237EAE", backgroundColor: "#C0E9FF" }}>
					هذه الواجهة خاصة بإدارة المخزون الخاص بسوق اطلبها
				</div>
				<div className='flex md:flex-row flex-col md:items-center items-start justify-between gap-y-4 mt-4 xl:mb-[18px] lg:pl-[14px] 2xl:pl-[124px]'>
					<PageNavigate
						currentPage={"المخزون"}
						nestedPage={true}
						parentPage={"السوق"}
					/>
					{permissions?.includes("admin.stock.store") && (
						<Button
							className={
								"md:w-[180px] w-full md:h-12 h-[45px] md:text-[18px] text-[16px] flex justify-center items-center"
							}
							style={{ backgroundColor: "#B6BE34" }}
							textStyle={{ color: "#FFFFFF" }}
							type={"normal"}
							svg={<AiOutlinePlus color='#FFFFFF' className='w-4 h-4' />}
							color={"white"}
							onClick={() => {
								navigate("/storage/add-product");
							}}>
							اضافة منتج
						</Button>
					)}
				</div>

				<div className={"p-4 2xl:pl-36 pl-4"}>
					<StorageStatus fetchedData={fetchedData} />
					<StorageFilter
						products={filterPoducts}
						getSearch={getSearch}
						getSortSelector={getSortSelector}
						search={search}
						setSearch={setSearch}
						searchResults={searchResults}
						setFilterResults={setFilterResults}
					/>
					<div dir='ltr'>
						<StorageTable
							search={search}
							data={filterPoducts}
							loading={loading || searchIsLoading || filterIsLoading}
							pageNumber={page}
							setPageNumber={setPage}
							currentPage={rowsPerPage}
							setCurrentPage={setRowsPerPage}
							page_count={
								filterResults?.length > 0
									? filterResults?.page_count
									: fetchedData?.page_count
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Storage;
