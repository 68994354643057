import * as React from "react";
/* Components */
import DatePicker from "./DatePicker/DatePicker";
import GraphSec from "./GraphSec/GraphSec";
/* MUI */
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
/* Icons */
import { Income, Money, Growth } from "../../../assets/Icons/index";

const StoreGraph = ({
  loading,
  array_store,
  count_of_stores,
  Subscriptions,
  all_income,
  Etlobha_income,
  getStartDate1,
  getEndDate1,
}) => {
  return (
    <div className="mt-5 md:mt-8">
      <DatePicker
        getStartDate1={getStartDate1}
        getEndDate1={getEndDate1}
      ></DatePicker>
      <div className="flex flex-wrap gap-6 mt-2 md:mt-6 xl:h-96">
        <div
          className="lg:w-[70%] w-full flex-1 p-4 shadow-[0px_6px_12px_#0000000F] rounded-lg"
          style={{ backgroundColor: "#fff" }}
        >
          <GraphSec array_store={array_store} />
        </div>
        <div className="w-full xl:w-64">
          <Stack
            className="flex flex-row flex-wrap"
            gap={2}
            sx={{ height: "100%" }}
          >
            <Paper
              sx={{
                maxWidth: "100%",
                flex: "1",
                p: "0.5rem 1rem",
                display: "flex",
                alignItems: "center",
              }}
              className="md:gap[3rem] gap-[30px] shadow-[0px_6px_12px_#0000000F] rounded-lg"
            >
              <div>
                <div className="flex items-center justify-center w-10 h-10 p-1 rounded-full">
                  <Income />
                </div>
              </div>
              <div className="flex flex-col justify-center flex-1 h-full">
                <h2 className="font-medium md:text-2xl text-[20px]">
                  {loading ? 0 : count_of_stores || 0}
                </h2>
                <div className="flex justify-between ">
                  <h2 className="font-normal md:text-[18px] text-[15px] whitespace-nowrap">
                    عدد المتاجر
                  </h2>
                </div>
              </div>
            </Paper>
            <Paper
              sx={{
                maxWidth: "100%",
                flex: "1",
                p: "0.5rem 1rem",
                display: "flex",
                alignItems: "center",
              }}
              className="md:gap[3rem] gap-[30px] shadow-[0px_6px_12px_#0000000F] rounded-lg"
            >
              <div>
                <div className="flex items-center justify-center w-10 h-10 p-1 rounded-full">
                  <Money />
                </div>
              </div>
              <div className="flex flex-col justify-center flex-1 h-full">
                <h2 className="font-medium md:text-2xl text-[20px]">
                  {loading ? 0 : Subscriptions || 0} ريال
                </h2>
                <div className="flex justify-between ">
                  <h2 className="font-normal md:text-[18px] text-[15px] whitespace-nowrap">
                    قيمة الاشتراكات
                  </h2>
                </div>
              </div>
            </Paper>
            <Paper
              sx={{
                maxWidth: "100%",
                flex: "1",
                p: "0.5rem 1rem",
                display: "flex",
                alignItems: "center",
              }}
              className="md:gap[3rem] gap-[30px] shadow-[0px_6px_12px_#0000000F] rounded-lg"
            >
              <div>
                <div className="flex items-center justify-center w-10 h-10 p-1 rounded-full">
                  <Growth />
                </div>
              </div>
              <div className="flex flex-col justify-center flex-1 h-full">
                <h2 className="font-medium md:text-2xl text-[20px]">
                  {loading ? 0 : Etlobha_income || 0} ريال
                </h2>
                <div className="flex justify-between ">
                  <h2 className="font-normal md:text-[18px] text-[15px] whitespace-nowrap">
                    إيرادات سوق إطلبها
                  </h2>
                </div>
              </div>
            </Paper>
            <Paper
              sx={{
                maxWidth: "100%",
                flex: "1",
                p: "0.5rem 1rem",
                display: "flex",
                alignItems: "center",
              }}
              className="md:gap[3rem] gap-[30px] shadow-[0px_6px_12px_#0000000F] rounded-lg"
            >
              <div>
                <div className="flex items-center justify-center w-10 h-10 p-1 rounded-full">
                  <Money />
                </div>
              </div>
              <div className="flex flex-col justify-center flex-1 h-full">
                <h2 className="font-medium md:text-2xl text-[20px]">
                  {loading ? 0 : all_income || 0} ريال
                </h2>
                <div className="flex justify-between ">
                  <h2 className="font-normal md:text-[18px] text-[15px] whitespace-nowrap">
                    اجمالي الإيرادات
                  </h2>
                </div>
              </div>
            </Paper>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default StoreGraph;
