import React from "react";
import { Helmet } from "react-helmet";
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import SocialMediaActivity from "../../../components/SettingComp/SocialMediaLinksPageComp/SocialMediaActivity/SocialMediaActivity";
import { useGetWebsiteSocialMediaQuery } from "../../../RTK/apiSlices/websiteSocialMediaApi";


const SocialMediaLinksPage = () => {
  const { data: fetchedData, isLoading: loading } = useGetWebsiteSocialMediaQuery();

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | صفحات التواصل الاجتماعي</title>
      </Helmet>
      <div className={`p-4`} style={{ backgroundColor: "#F7F7F7" }}>
        <PageNavigate
          nestedPage={true}
          parentPage={"الاعدادات"}
          currentPage={"صفحات التواصل الاجتماعي"}
        />

        {/** social media links  */}
        <SocialMediaActivity fetchedData={fetchedData} loading={loading} />
      </div>
    </>
  );
};

export default SocialMediaLinksPage;
