import React, { useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Components */
import MarketsStatus from "../../components/MarketsPagesComp/MainPage/MarketsStatus";
import QuickMarketsInfo from "../../components/MarketsPagesComp/MainPage/QuickMarketsInfo";
import { useGetStoreReportYearDataQuery } from "../../RTK/apiSlices/marketsApi";

const Markets = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const getYear = (y) => {
    setYear(y);
  };

  const { data: fetchedData } = useGetStoreReportYearDataQuery({ year });

  const marketsInfo = {
    countStore: fetchedData?.count_of_stores,
    averageStore: parseFloat(
      fetchedData?.average_of_stores.replace("%", "")
    ).toFixed(0),
    activeStore: fetchedData?.active_of_stores,
    notActiveStore: fetchedData?.not_active_of_stores,
  };

  const quickInfo = {
    last24HoursOfStores: fetchedData?.last_24_hours_of_stores,
    last24HoursOfPendingOrders: fetchedData?.last_24_hours_of_pending_orders,
    last24HoursOfCompleteOrders: fetchedData?.last_24_hours_of_complete_orders,
    lastMonthOfStores: fetchedData?.last_month_of_stores,
    lastMonthOfCompleteOrders: fetchedData?.last_month_of_complete_orders,
  };
  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | المتاجر</title>
      </Helmet>
      <div className="p-4 2xl:pl-36 pl-4 lg:bg-[#fafafa] bg-white flex flex-col">
        <MarketsStatus
          marketsInfo={marketsInfo}
          chartInfo={fetchedData?.array_store}
          getYear={getYear}
        />
        <QuickMarketsInfo
          latestStores={fetchedData?.latest_stores}
          quickInfo={quickInfo}
        />
      </div>
    </>
  );
};

export default Markets;
