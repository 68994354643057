import React, { useState, useContext } from "react";
/* Contexts */
import Context from "../../../../store/context";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";

const BackDrop = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="fixed back_drop top-0 left-0 h-full w-full bg-slate-900 opacity-50 z-[99]"
    ></div>
  );
};

const AddSubVariety = ({ cancel }) => {
  const contextStore = useContext(Context);
  const { setSubCategories } = contextStore;
  const [subcat, setSubCat] = useState("");
  const [subError, setSubError] = useState("");

  const addSubCat = () => {
    setSubCategories((subCategories) => [...subCategories, { name: subcat }]);
    setSubCat("");
    if (subcat) {
      cancel();
    } else {
      setSubError("يرجي اضافة الأنشطة أولاًً");
    }
  };

  return (
    <>
      <BackDrop onClick={cancel} />
      <div
        className="fixed  flex flex-col top-14 md:top-32 translate-x-2/4  right-2/4 z-[100] rounded-lg overflow-hidden"
        style={{ width: "60.25rem", maxWidth: "90%" }}
      >
        <div
          className="flex items-center justify-between w-full h-16 px-4 py-4 trader_alert"
          style={{ backgroundColor: "#02466A" }}
        >
          <h2
            style={{ color: "#ECFEFF" }}
            className="md:text-[22px] text-[18px] font-medium text-center flex-1"
          >
            اضف نشاط أو تصنيف فرعي
          </h2>
          <IoMdCloseCircleOutline
            size={"1.25rem"}
            color={"#fff"}
            className={"cursor-pointer"}
            onClick={cancel}
          ></IoMdCloseCircleOutline>
        </div>
        <div
          className="flex flex-col items-center justify-center flex-1 px-4 py-10"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col w-full gap-3 md:w-fit">
              <label
                className="md:text-[18px] text-[16px]"
                style={{ color: "#011723" }}
              >
                الأنشطة الفرعية
              </label>
              <input
                className="md:w-[475px] w-full p-4 outline-none rounded-lg"
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #EBEBEB",
                  boxShadow: "0px 3px 6px #00000029",
                }}
                placeholder="ادخل اسم الأنشطة الفرعية"
                type="text"
                name="name"
                value={subcat}
                onChange={(e) => setSubCat(e.target.value)}
              />
              {subError && <div className="text-[red]">{subError}</div>}
            </div>
            <div className="flex flex-row items-center w-full gap-5 md:w-fit mt-36">
              <Button
                onClick={() => {
                  addSubCat();
                }}
                type={"normal"}
                className={
                  "md:w-[227px] w-full md:h-[56px] h-[45px] md:text-[22px] text-[18px] text-center rounded-lg"
                }
                style={{ backgroundColor: "#02466A" }}
                textStyle={{ color: "#EFF9FF" }}
              >
                تأكيد
              </Button>
              <Button
                onClick={() => {
                  cancel();
                }}
                type={"outline"}
                className={
                  "md:w-[227px] w-full md:h-[56px] h-[45px] md:text-[22px] text-[18px] text-center rounded-lg"
                }
                style={{
                  backgroundColor: "#02466A00",
                  border: "1px solid #02466A",
                }}
                textStyle={{ color: "#02466A" }}
              >
                الغاء
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubVariety;
