import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const logOutApi = createApi({
  reducerPath: "logOutApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/",
  }),

  endpoints: (builder) => ({
    // create logout endpoint
    logOut: builder.mutation({
      query: () => ({
        url: `logout`,
        method: "GET",
      }),
    }),
  }),
});

// Export endpoints and hooks
export const { useLogOutMutation } = logOutApi;
