import React, { useContext } from "react";
import ReactDom from "react-dom";
import styles from "./ActionCompleteComp.module.css";
/* Context */
import Context from "../../store/context";
/* MUI */
import Box from "@mui/material/Box";
import { CheckMarkIcon, ClearIcon, Rejected } from "../../assets/Icons";
/* Icons */

const BackDrop = () => {
  return (
    <div
      className={`${styles.backdrop} fixed back_drop  bottom-0 left-0 w-full  md:opacity-50 opacity-70 z-50 md:bg-[#f6f6f6bf] bg-[#242424]`}
    ></div>
  );
};

const ActionComplete = () => {
  const contextStore = useContext(Context);
  const { title, actionWarning, setEndActionTitle } = contextStore;

  return (
    <>
      <BackDrop />
      <div
        className="fixed z-50 p-6 -translate-x-1/2 rounded shadow-lg fcc back_drop left-1/2 bg-slate-50"
        style={{
          height: "170px",
          width: "556px",
          maxWidth: "90%",
          top: "100px",
        }}
      >
        <Box
          onClick={() => {
            setEndActionTitle(null);
          }}
          className={"absolute cursor-pointer top-6 left-6"}
        >
          <ClearIcon></ClearIcon>
        </Box>
        <div
          className={`absolute bottom-0 right-0 h-4 ${styles.line_anim}`}
          style={{
            backgroundColor: actionWarning ? "rgba(255, 56, 56, 1)" : "#3AE374",
          }}
        ></div>
        <div className="flex items-center gap-5 md:gap-8">
          <Box
            sx={{
              "& svg": {
                width: "2rem",
                height: "2rem",
              },
            }}
          >
            {actionWarning ? (
              <Rejected></Rejected>
            ) : (
              <CheckMarkIcon></CheckMarkIcon>
            )}
          </Box>

          <h2
            className="font-medium md:text-[24px] text-[18px] whitespace-nowrap"
            style={{ color: "#011723" }}
          >
            {title}
          </h2>
        </div>
      </div>
    </>
  );
};

const ActionCompleteComp = ({ title, cancelEarly }) => {
  return (
    <>
      {ReactDom.createPortal(
        <ActionComplete title={title} cancelEarly={cancelEarly} />,
        document.getElementById("action_div")
      )}
    </>
  );
};

export default ActionCompleteComp;
