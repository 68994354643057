import React from "react";

const AcademyTabs = ({ tabs, selectedId, setSelectedId }) => {
	return (
		<div className='flex flex-col md:flex-row items-center gap-4 md:gap-6'>
			{tabs?.map((tab, index) => (
				<div
					key={index}
					className='md:w-[270px] w-full md:h-[140px] h-[60px] px-2 flex cursor-pointer duration-200 rounded-lg justify-center items-center'
					style={{
						backgroundColor: selectedId === tab.id ? "#B4EDEE" : "#B4EDEE33",
					}}
					onClick={() => {
						setSelectedId(tab.id);
					}}>
					<h2
						className='md:text-2xl text-[18px] font-medium whitespace-nowrap'
						style={{ color: "#02466A" }}>
						{tab.title}
					</h2>
				</div>
			))}
		</div>
	);
};

export default AcademyTabs;
