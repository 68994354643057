import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const statisticsApi = createApi({
  reducerPath: "statisticsApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["StatisticsProducts"],

  endpoints: (builder) => ({
    // Get Statistics Products Data 
    getStatisticsProductsData: builder.query({
      query: (args) => ({
        url: `statistics/${args.id}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["EtlobhaProducts"],
    }),
  }),
});

// Export endpoints and hooks
export const { useGetStatisticsProductsDataQuery } = statisticsApi;
