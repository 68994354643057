import React, { useState, useEffect } from "react";
/* Icons */
import CloseIcon from "@mui/icons-material/Close";

function ShowVideo({ url, setUrl }) {
  const [youtubeVideoLink, setYoutubeVideoLink] = useState("");

  useEffect(() => {
    // استخراج الرابط عند تحميل المكون
    if (url) {
      const parser = new DOMParser();
      const doc = parser?.parseFromString(url, "text/html");
      const iframeSrc = doc?.querySelector("iframe")?.getAttribute("src");
      setYoutubeVideoLink(iframeSrc);
    }
  }, [url]);

  const closeVideoModal = () => {
    setUrl("");
  };

  return (
    <>
      <div
        onClick={closeVideoModal}
        className={`h-full fixed back_drop bottom-0 left-0 w-full bg-slate-900 z-50 opacity-50`}
      ></div>
      <div
        className="w-[500px] h-[350px] z-50 max-w-[90%]"
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CloseIcon
          onClick={closeVideoModal}
          className="cursor-pointer text-[#ffffff]"
        />
        <iframe
          style={{ maxWidth: "100%" }}
          width="560"
          height="315"
          src={youtubeVideoLink}
          title="YouTube video player"
          allowFullScreen
        />
      </div>
    </>
  );
}

export default ShowVideo;
