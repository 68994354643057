import React, { useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";

/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import ContactUsTable from "./ContactUsTable/ContactUsTable";
import ContactUsDetails from "./ContactUsDetails/ContactUsDetails";

/* MUI */
import FormControl from "@mui/material/FormControl";
/* Icons */
import { AiOutlineSearch } from "react-icons/ai";
import { useAtlobhaContactDataQuery } from "../../RTK/apiSlices/contactUsApi";

const ContactUs = () => {
	const { data: fetchedData, isLoading: loading } =
		useAtlobhaContactDataQuery();

	// To Handle Search by Email
	const [showContactUsDetails, setShowContactUsDetails] = useState(false);
	const [contactUsDetails, setContactUsDetails] = useState(null);
	const [search, setSearch] = useState("");
	let resultData = fetchedData?.atlobhaContact;
	if (search !== "") {
		resultData = fetchedData?.atlobhaContact?.filter(
			(item) => item?.email?.includes(search) || item?.title?.includes(search)
		);
	} else {
		resultData = fetchedData?.atlobhaContact;
	}

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | تواصل معنا</title>
			</Helmet>
			<div className='relative xl:pl-24 xl:pr-8 p-4 xl:bg-[#F7F7F7] bg-[#FFFFFF] h-full'>
				<div className='flex md:flex-row flex-col md:items-center items-start justify-between gap-y-4 mt-4 xl:mb-[18px] xl:pl-[124px]'>
					<PageNavigate currentPage={"تواصل معنا"} nestedPage={false} />
				</div>
				<div className={"mt-8"} style={{ backgroundColor: "#FFF" }}>
					<div className='p-4'>
						<div className='flex flex-col justify-between xl:flex-row'>
							<div>
								<div className='w-full mb-4'>
									<FormControl
										className='flex flex-row w-full gap-4'
										sx={{ minWidth: 120, flex: "1" }}>
										<label className={`flex-1  w-full h-14 relative `}>
											<input
												className='md:bg-[#ECFEFF] bg-[#ECFEFF00] h-14 w-[474px] outline-0 pr-12 rounded-lg '
												placeholder=' ادخل الاسم أو البريد الالكتروني '
												type='text'
												name='name'
												value={search}
												onChange={(e) => setSearch(e.target.value)}
												style={{
													border: "1px solid #7FFCFF",
													maxWidth: "100%",
												}}
											/>
											<div
												className={`absolute top-1/2 right-4 -translate-y-2/4`}>
												<AiOutlineSearch color='#7FFCFF' size={"20px"} />
											</div>
										</label>
									</FormControl>
								</div>
							</div>
						</div>
						{showContactUsDetails && (
							<ContactUsDetails
								contactUsDetails={contactUsDetails}
								cancel={() => {
									setShowContactUsDetails(false);
								}}></ContactUsDetails>
						)}
						<div dir={"ltr"}>
							<ContactUsTable
								atlobhaContact={resultData}
								loading={loading}
								showDetails={(row) => {
									setContactUsDetails(row);
									setShowContactUsDetails(true);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUs;
