import React, { useContext, Fragment } from "react";
/* Third party */
import { useNavigate } from "react-router-dom";
/* Contexts */
import Context from "../../../../store/context";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* Icons */
import { MdOutlineAddCircle } from "react-icons/md";
import { Delete, EditButton } from "../../../../assets/Icons";
import {
	useChangeWebsiteSocialMediaStatusMutation,
	useWebsiteSocialMediaDeleteItemMutation,
} from "../../../../RTK/apiSlices/websiteSocialMediaApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";

const SocialMediaActivity = ({ fetchedData, loading }) => {
	const navigate = useNavigate();
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const [changeWebsiteSocialMediaStatus] =
		useChangeWebsiteSocialMediaStatusMutation();

	const changeStatus = async (id) => {
		try {
			await changeWebsiteSocialMediaStatus({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [websiteSocialMediaDeleteItem] =
		useWebsiteSocialMediaDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await websiteSocialMediaDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<div
			className='mt-4 rounded-lg shadow-md md:mt-8'
			style={{ backgroundColor: "#FFFFFF" }}>
			<div
				className='flex flex-col items-center justify-between p-4 rounded-t-lg md:px-3 md:py-5 md:flex-row'
				style={{ backgroundColor: "#F7FCFF", border: "1px solid #EFEFEF" }}>
				<h2 className='md:text-[22px] text-[18px] font-semibold flex items-center whitespace-nowrap flex-wrap gap-3'>
					التواصل الاجتماعي
					<span className='md:text-[16px] text-[14px] font-normal whitespace-pre-line'>
						(تستطيع تفعيل وتعطيل واضافة صفحات منصات التواصل الاجتماعي)
					</span>
				</h2>
			</div>
			<div className='flex flex-col items-center py-0 md:py-7 '>
				<div style={{ width: "572px", maxWidth: "100%" }}>
					{loading ? (
						<CircularLoading />
					) : (
						<Fragment>
							{fetchedData?.website_socialmedia?.map((socialmedia) => (
								<div
									className='flex items-center justify-between w-full p-8 rounded'
									style={{ border: "1px solid #E3E3E3" }}
									key={socialmedia?.id}>
									<div className='flex flex-row items-center gap-6'>
										<div
											className='img_icons'
											style={{
												minWidth: "35px",
												minHeight: "35px",
												maxWidth: "35px",
												maxHeight: "35px",
											}}>
											<img
												src={
													socialmedia?.logo
														? socialmedia?.logo
														: PlaceholderImage
												}
												alt={socialmedia?.name}
												loading='lazy'
												style={{
													width: "100%",
													height: "100%",
													objectFit: "cover",
													borderRadius: "50%",
												}}
											/>
										</div>

										<h2
											className='font-medium md:text-[18px] text-[16px]'
											style={{ color: "#011723" }}>
											{socialmedia?.name}
										</h2>
									</div>
									<div className='flex flex-row items-center gap-4'>
										{permissions?.includes(
											"admin.websitesocialmedia.update"
										) && (
											<EditButton
												title=' تعديل بيانات الصفحه'
												className='w-6 h-6 cursor-pointer'
												onClick={() => {
													navigate(
														`/social_media/edit-social-media/${socialmedia?.id}`
													);
												}}
												width={"20px"}
											/>
										)}
										{permissions?.includes(
											"admin.websitesocialmedia.changeStatus"
										) && (
											<Switch
												title='تغيير الحالة'
												onChange={() => changeStatus(socialmedia?.id)}
												sx={{
													width: "32px",
													padding: 0,
													height: "20px",
													borderRadius: "0.75rem",
													"& .MuiSwitch-thumb": {
														width: "12px",
														height: "12px",
													},
													"& .MuiSwitch-switchBase": {
														padding: "0",
														top: "4px",
														left: "4px",
													},
													"& .MuiSwitch-switchBase.Mui-checked": {
														left: "-4px",
													},
													"& .Mui-checked .MuiSwitch-thumb": {
														backgroundColor: "#FFFFFF",
													},
													"& .MuiSwitch-track": {
														height: "100%",
													},
													"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
														backgroundColor: "#3AE374",
														opacity: 1,
													},
												}}
												checked={socialmedia?.status === "نشط" ? true : false}
											/>
										)}
										{permissions?.includes(
											"admin.websitesocialmedia.destroy"
										) && (
											<Delete
												title='حذف'
												onClick={() => {
													setActionDelete(
														"سيتم حذف صفحة التواصل وهذة الخطوة غير قابلة للرجوع"
													);
													setItemId(socialmedia?.id);
												}}
												style={{
													cursor: "pointer",
													color: "red",
													fontSize: "1.2rem",
												}}></Delete>
										)}
									</div>
								</div>
							))}
						</Fragment>
					)}

					{/** add new social link */}
					{permissions?.includes("admin.websitesocialmedia.store") && (
						<div
							className='flex items-center justify-between w-full p-8 mt-5 rounded cursor-pointer'
							style={{ border: "1px solid #E3E3E3" }}
							onClick={() => navigate("/social_media/add-social-media")}>
							<div className='flex items-center gap-6'>
								<Box sx={{}}>
									<MdOutlineAddCircle
										color={"#67747B"}
										size={"25px"}></MdOutlineAddCircle>
								</Box>

								<h2
									className='font-medium md:text-[18px] text-[16px]'
									style={{ color: "#011723" }}>
									اضافة رابط جديد
								</h2>
							</div>
						</div>
					)}
				</div>
				<Button
					className={"mx-auto my-8"}
					fontSize={"text-2xl font-thin"}
					style={{ backgroundColor: "#02466A" }}
					type={"normal"}>
					حفظ
				</Button>
			</div>
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</div>
	);
};

export default SocialMediaActivity;
