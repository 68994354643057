import React, { useState, useContext } from "react";

/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";

/* Components */
import PackagesTypes from "../../components/PackagesPageComp/PackagesTypes/PackagesTypes";

/* Custom Button */
import Button from "../../UI/Button/Button";

/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useGetPackageQuery } from "../../RTK/apiSlices/subscriptionsApi";

const Packages = () => {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(6);

	const { data: fetchedData, isLoading: loading } = useGetPackageQuery({
		page,
		number: rowsPerPage,
	});

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | الباقات</title>
			</Helmet>
			<div className='p-4 relative md:bg-[#fafafa] bg-[#ffffff]'>
				{permissions?.includes("admin.package.store") && (
					<div className='flex justify-end mb-10'>
						<Button
							className='md:w-[265px] w-full md:h-[56px] h-[45px] text-xl'
							style={{ backgroundColor: "#B6BE34" }}
							svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
							type={"normal"}
							onClick={() => {
								navigate("/packages/add-package");
							}}>
							اضافة باقة جديدة
						</Button>
					</div>
				)}

				<PackagesTypes
					loading={loading}
					pageNumber={page}
					setPageNumber={setPage}
					currentPage={rowsPerPage}
					setCurrentPage={setRowsPerPage}
					page_count={fetchedData?.page_count}
					fetchedData={fetchedData?.packages || []}
				/>
			</div>
		</>
	);
};

export default Packages;
