import React, { useContext, useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import ServicesTable from "../../components/ServicesPageComp/ServicesTable/ServicesTable";
/* Custom Button */
import Button from "../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useGetServicesQuery } from "../../RTK/apiSlices/servicesApi";

const ServicesPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Fetch data from API
  const { data: fetchedData, isLoading: loading } = useGetServicesQuery({
    page,
    number: rowsPerPage,
  });
  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | الخدمات</title>
      </Helmet>
      <div
        className={`p-4 2xl:pl-36 pl-4`}
        style={{ backgroundColor: "#fafafa" }}
      >
        <div className="flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4">
          <PageNavigate currentPage={"الخدمات"} />
          {permissions?.includes("admin.service.store") && (
            <Button
              className={
                "md:h-14 h-[45px] md:w-44 w-full flex justify-center items-center text-xl"
              }
              type={"normal"}
              svg={<AiOutlinePlus color="#fff" className="w-5 h-5" />}
              style={{ backgroundColor: "#02466A" }}
              color={"white"}
              onClick={() => {
                navigate("/services/add-service");
              }}
            >
              اضافة خدمة
            </Button>
          )}
        </div>
        <div dir={"ltr"} className="mt-5 md:mt-20 pb-30">
          <ServicesTable
            fetchedData={fetchedData}
            loading={loading}
            pageNumber={page}
            setPageNumber={setPage}
            currentPage={rowsPerPage}
            setCurrentPage={setRowsPerPage}
            page_count={fetchedData?.page_count}
          />
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
