import React, { useEffect, useRef } from "react";
import styles from "./EditPackageTemplates.module.css";
/* Third party */
import { Helmet } from "react-helmet";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { Delete } from "../../../assets/Icons";

const EditPackageTemplates = ({ templatesEachPackage, cancel }) => {
	const ref = useRef(null);
	const scrollToElement = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToElement();
	}, []);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | القوالب</title>
			</Helmet>
			<div
				className='absolute top-0 right-0 z-10 w-full h-full p-4 md:pl-36 md:pr-24'
				style={{ backgroundColor: "#fafafa" }}>
				<div className='flex items-center gap-2 md:mb-14 mb-[24px]'>
					<div onClick={cancel} className={` ${styles.arrow_con}`}>
						<GoArrowRight style={{ color: "#02466A", fontSize: "1.2rem" }} />
					</div>
					<h2 ref={ref} className='font-medium md:text-lg text-[16px]'>
						الباقات والأسعار
					</h2>
					<h2
						className='font-medium md:text-lg text-[16px]'
						style={{ color: "rgba(103, 116, 123, 1)" }}>
						/ كل الباقات
					</h2>
				</div>
				<div class='flex flex-row flex-wrap gap-4'>
					{templatesEachPackage?.map((template, index) => {
						return (
							<div
								key={index}
								className='flex-1 relative md:w-[278px] min-w-[162px] md:h-[228px] h-[162px]'
								style={{
									border: "1px solid rgba(180, 237, 238, 1)",
									backgroundColor: "rgba(236, 254, 255, 1)",
								}}>
								<div
									className='flex flex-col items-center justify-between'
									style={{
										height: "100%",
										backgroundColor: "rgba(236, 254, 255, 1)",
									}}>
									<div className='flex flex-col items-center justify-center flex-1'>
										<h2
											className='text-xl font-medium'
											style={{ color: "rgba(1, 23, 35, 1)" }}>
											{template?.name}
										</h2>
									</div>
									<div
										className={"fcc gap-5 w-full"}
										style={{
											height: "50px",
											backgroundColor: "rgba(255, 56, 56, 0.04)",
										}}>
										<Delete
											style={{
												cursor: "pointer",
												color: "red",
												fontSize: "1rem",
											}}
										/>
										<h2
											className='text-lg font-medium'
											style={{ color: "rgba(255, 56, 56, 1)" }}>
											حذف القالب
										</h2>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default EditPackageTemplates;
