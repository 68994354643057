import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const commonQuestionsApi = createApi({
  reducerPath: "commonQuestionsApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["CommonQuestions"],

  endpoints: (builder) => ({

    // Get Pages Data
    getCommonQuestions: builder.query({
      query: (args) => ({
        url: `commonquestion?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["CommonQuestions"],
    }),
    
    getCommonQuestionData: builder.query({
      query: ({ questionId }) => ({
        url: `commonquestion/${questionId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "CommonQuestions", id }],
    }),

    searchInCommonQuestions: builder.mutation({
      query: (arg) => ({
        url: `searchQuestionName?query=${arg.query}`,
        method: "GET",
      }),
    }),

    commonQuestionsDeleteAll: builder.mutation({
      query: ({ queryParams }) => ({
        url: `commonDeleteall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["CommonQuestions"],
    }),

    addNewCommonQuestions: builder.mutation({
      query: ({ body }) => {
        return {
          url: `commonquestion`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["CommonQuestions"],
    }),

    updateCommonQuestions: builder.mutation({
      query: ({ questionId, body }) => {
        return {
          url: `commonquestion/${questionId}`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["CommonQuestions"],
    }),

    commonQuestionsDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `commonDeleteall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["CommonQuestions"],
    }),
    
    

  }),
});

// Export endpoints and hooks
export const {
  useGetCommonQuestionsQuery,
  useGetCommonQuestionDataQuery,
  useSearchInCommonQuestionsMutation,
  useCommonQuestionsDeleteAllMutation,
  useAddNewCommonQuestionsMutation,
  useUpdateCommonQuestionsMutation,
  useCommonQuestionsDeleteItemMutation,
} = commonQuestionsApi;
