import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const currencyApi = createApi({
  reducerPath: "currencyApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Currency"],

  endpoints: (builder) => ({
    getCurrency: builder.query({
      query: (args) => ({
        url: `currency?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["Currency"],
    }),

    currencyChangeStatusAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `currencychangeSatusall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Currency"],
    }),

    addNewCurrency: builder.mutation({
      query: ({ body }) => {
        return {
          url: `currency`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Currency"],
    }),

    currencyDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `currency/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Currency"],
    }),




  }),
});

// Export endpoints and hooks
export const {
  useGetCurrencyQuery,
  useCurrencyChangeStatusAllByParamsMutation,
  useAddNewCurrencyMutation,
  useCurrencyDeleteItemMutation,
} = currencyApi;
