import React, { useState, useContext, useEffect } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../components/PageNavigate/PageNavigate";
import PagesPageTable from "../../components/PagesPageComp/PagesPageTable/PagesPageTable";
import Filtering from "../../components/PagesPageComp/Filtering/Filtering";
/* Custom Button */
import Button from "../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import {
	useFilterPagesByStatusMutation,
	useGetPagesDataQuery,
	useSearchInPagesMutation,
} from "../../RTK/apiSlices/pagesApi";

const PagesPage = () => {
	const navigate = useNavigate();
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [selected, setSelected] = useState("");

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetPagesDataQuery({
		page,
		number: rowsPerPage,
	});

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [filterResults, setFilterResults] = useState([]);

	let pages = fetchedData?.pages || [];
	if (search !== "") {
		pages = searchResults?.pages;
	} else {
		pages = fetchedData?.pages;
	}

	const [sortSelector, setSortSelector] = useState("");
	let filterPages = pages;
	const getSearch = (value) => {
		setSearch(value);
	};
	const getSortSelector = (value) => {
		setSortSelector(value);
	};

	const [filterPagesByStatus, { isLoading: filterIsLoading }] =
		useFilterPagesByStatusMutation();
	const filterPagesByStatusHandel = async () => {
		try {
			const response = await filterPagesByStatus({
				pageStatus:
					selected === "محظور"
						? "not_active"
						: selected === "تم النشر"
						? "active"
						: "all",
			});

			setFilterResults(response?.data?.data);
			setSearch("");
		} catch (error) {
			console.error("Error filtering orders:", error);
		}
	};

	if (selected !== "") {
		filterPages = filterResults?.pages;
	} else {
		filterPages = pages;
	}

	useEffect(() => {
		if (selected) {
			filterPagesByStatusHandel();
		} else {
			filterPages = pages;
		}
	}, [selected, page]);

	const [searchInPages, { isLoading: searchIsLoading }] =
		useSearchInPagesMutation();
	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInPages({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(pages);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | الصفحات</title>
			</Helmet>
			<div className={`px-4 bg-[#FFFFFF] md-bg-[#fafafa]`}>
				<div
					className='md:pr-5 py-3 px-2 rounded font-normal md:text-lg text-[14px]'
					style={{ color: "#EFF9FF", backgroundColor: "#237EAE" }}>
					هذه الواجهة خاصة بإعدادات الصفحة الرئيسية للموقع الإلكتروني
				</div>
				<div className='p-0 2xl:ml-36'>
					<div className='flex flex-col items-start justify-between mt-6 md:flex-row md:items-center gap-y-4'>
						<PageNavigate currentPage={"الصفحات"} />
						{(permissions?.includes("admin.page.store") ||
							permissions?.includes("admin.page.publish")) && (
							<Button
								className='flex justify-center items-center md:h-14 h-[45px] md:w-[183px] w-full text-lg'
								type={"normal"}
								svg={<AiOutlinePlus color='#fff' className='w-5 h-5' />}
								color={"white"}
								onClick={() => {
									navigate("/pages/add-page");
								}}>
								انشاء صفحة
							</Button>
						)}
					</div>

					{/** filter */}
					<Filtering
						selected={selected}
						setSelected={setSelected}
						getSearch={getSearch}
						getSortSelector={getSortSelector}
						search={search}
						setSearch={setSearch}
						searchResults={searchResults}
						setSearchResults={setSearchResults}
					/>

					{/** pages table*/}
					<div dir='ltr' className='mt-5 md:mt-10'>
						<PagesPageTable
							fetchedData={filterPages}
							loading={loading || searchIsLoading || filterIsLoading}
							search={search}
							setSearch={setSearch}
							searchResults={searchResults}
							setSearchResults={setSearchResults}
							pageNumber={page}
							setPageNumber={setPage}
							currentPage={rowsPerPage}
							setCurrentPage={setRowsPerPage}
							page_count={fetchedData?.page_count}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PagesPage;
