import React from "react";
import styles from "./EmailMenu.module.css";
/* Third party */
import { Link } from "react-router-dom";
import moment from "moment-with-locales-es6";
/* Custom Hook */

/* Icons */
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useGetEmailIndexQuery } from "../../RTK/apiSlices/emailIndexApi";

const BackDrop = ({ closeMenu }) => {
  return (
    <div
      onClick={closeMenu}
      className="fixed bottom-0 left-0 z-10 w-full h-full back_drop"
    ></div>
  );
};

const EmailMenu = () => {
  // get data from api
  const { data: fetchedData, error } = useGetEmailIndexQuery();

  const [open, setOpen] = React.useState(false);

  // formatDate
  const formatDate = (date) => {
    const calcPassedMinutes = (date1, date2) =>
      Math.round(Math.abs(date2 - date1) / (1000 * 60));

    const currentMinutes = calcPassedMinutes(+new Date(), +new Date(date));

    if (currentMinutes < 1) {
      return "الآن";
    } else if (currentMinutes === 1) {
      return "منذ دقيقة";
    } else if (currentMinutes === 2) {
      return "منذ دقيقتين";
    } else if (currentMinutes <= 10) {
      return `منذ ${currentMinutes} دقائق`;
    } else if (currentMinutes < 60 && currentMinutes >= 11) {
      return `منذ ${currentMinutes} دقيقة`;
    } else if (currentMinutes === 60) {
      return "منذ ساعة";
    } else if (currentMinutes === 120) {
      return "منذ ساعتين";
    } else if (currentMinutes < 1440) {
      let hours = Math.floor(currentMinutes / 60);
      let min = currentMinutes % 60;
      if (hours === 1) {
        return `منذ ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
      } else if (hours === 2) {
        return `منذ  ساعتين و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
      } else if (hours <= 10) {
        return `منذ ${hours} ساعات و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
      } else {
        return `منذ ${hours} ساعة و ${min} ${min <= 10 ? "دقائق" : "دقيقة"} `;
      }
    }

    const currentDate = Math.round(currentMinutes / 60 / 24);

    if (currentDate === 1) {
      return "أمس، الساعة " + moment(date).locale("ar").format(" h:mm a");
    } else if (currentDate === 2) {
      return " منذ يومين، الساعة" + moment(date).locale("ar").format(" h:mm a");
    } else if (currentDate <= 7) {
      return (
        `منذ ${currentDate}  أيام، الساعة` +
        moment(date).locale("ar").format(" h:mm a")
      );
    }

    return moment(date).locale("ar").format("D MMMM YYYY");
  };
  // -----------------------------------------------------------------

  return (
    <div className="relative">
      <MailOutlineIcon
        onClick={() => setOpen(!open)}
        title="البريد الالكتروني"
        style={{
          width: "24px",
          height: "24px",
          fill: "#1dbbbe",
          cursor: "pointer",
        }}
      />
      <div className={`${open ? "flex" : "hidden"}`}>
        <BackDrop
          closeMenu={() => {
            setOpen(false);
          }}
        />
        <div className={`${styles.EmailMenu} z-20`}>
          {error === "Request failed with status code 403" ? (
            <div className="flex items-center justify-center h-full text-xl text-gray-600">
              ليس لديك صلاحية الوصول!
            </div>
          ) : fetchedData?.emails?.length === 0 ? (
            <div className="flex items-center justify-center h-full text-xl text-gray-600">
              لا يوجد رسائل حتي هذة اللحظة!
            </div>
          ) : (
            fetchedData?.emails?.map((item) => (
              <div
                key={item?.id}
                className={`${styles.email_box} w-full flex flex-row items-center justify-between gap-4`}
              >
                <div className="flex flex-row items-center justify-between gap-4">
                  <div
                    style={{
                      borderRadius: "50%",
                    }}
                    className="md:w-[35px] w-[30px] md:h-[35px] h-[30px] flex flex-col items-center justify-center bg-purple-500 text-white font-medium"
                  >
                    <img
                      src={item?.store?.user?.image}
                      alt={item?.store?.user?.name}
                      className="md:min-w-[35px] min-w-[30px] md:min-h-[35px] min-h-[30px] md:max-w-[35px] max-w-[30px] md:max-h-[35px] max-h-[30px] p-[5px]"
                      style={{
                        borderRadius: "50%",
                        border: "1px solid #dddddd",
                      }}
                      loading="lazy"
                    />
                  </div>
                  <Link to="/emails" onClick={() => setOpen(!open)}>
                    <div className="flex flex-col">
                      <h6 className="md:text-[18px] text-[14px] font-medium text-black">
                        {item?.store?.user?.name}
                      </h6>
                      <p className="md:text-[16px] text-[12px] font-normal text-black">
                        {item?.subject}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="flex flex-row items-center justify-between gap-4">
                  <div className="flex flex-col flex-1">
                    <h6 className="min-w-[105px] md:text-[16px] text-[12px] font-light text-gray-400 whitespace-nowrap">
                      {formatDate(item?.created_at)}
                    </h6>
                  </div>
                  <StarBorderIcon className="cursor-pointer text-gray-500 md:text-[24px] text-[18px]" />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
export default EmailMenu;
