import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// RTK query
import {
	useDeleteUnitByIdMutation,
	useDeleteVideoByIdMutation,
	useGetCourseDataQuery,
	useUpdateCourseMutation,
} from "../../../../RTK/apiSlices/coursesApi";

// context
import { TextEditorContext } from "../../../../store/TextEditorProvider";
import { LoadingContext } from "../../../../store/LoadingProvider";

// third party library
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { TagsInput } from "react-tag-input-component";

// components
import ShowVideo from "../AddNewCourse/ShowVideo";
import { TextEditor } from "../../../TextEditor";
import Button from "../../../../UI/Button/Button";
import AddUnit from "../AddNewCourse/AddUnit/AddUnit";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import VideoOfCourseDuration from "../../VideoOfCourseDuration/VideoOfCourseDuration";

// icons
import { GoArrowRight } from "react-icons/go";
import { IoAddCircleSharp } from "react-icons/io5";
import { IoMdCloudUpload } from "react-icons/io";
import { Copy, Delete, PDF } from "../../../../assets/Icons";

// css styles
import styles from "../AddNewCourse/AddNewCourse.module.css";

// MUI
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Context from "../../../../store/context";

const EditCourse = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const contextStore = useContext(Context);
	const { setSelectedId } = contextStore;

	const handleClosePage = () => {
		navigate("/atlbha_academy");
		setSelectedId(1);
	};
	const { data: currentCourse, isLoading: loading } = useGetCourseDataQuery({
		id,
	});

	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;
	const [data, setData] = useState({
		minute: currentCourse?.duration,
		hour: currentCourse?.duration,
		image: currentCourse?.image,
		link: currentCourse?.url,
		couresDuration: currentCourse?.durationCourse,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: currentCourse?.name || "",
		},
	});
	const [tagsSelected, setTagsSelected] = useState(currentCourse?.tags || []);
	const [showAddUnit, setShowAddUnit] = useState(false);
	const [unitDetails, setUnitDetails] = useState([]);
	const [copy, setCopy] = useState(false);

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);
	const [url, setUrl] = useState("");
	const ref = useRef(null);

	// handle errors
	const [DataError, setDataError] = useState({
		name: "",
		description: "",
		tags: "",
		file: "",
		video: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			description: "",
			tags: "",
			file: "",
			video: "",
			image: "",
		});
	};

	useEffect(() => {
		if (currentCourse) {
			setData({
				...data,
				name: currentCourse?.["$courses"]?.name || "",
				minute: currentCourse?.["$courses"]?.duration || "",
				hour: currentCourse?.["$courses"]?.duration || "",
				image: currentCourse?.["$courses"]?.image || "",
				link: currentCourse?.["$courses"]?.url || "",
				couresDuration:
					currentCourse?.["$courses"]?.durationCourse || "00:00:00",
			});
			setTagsSelected(currentCourse?.["$courses"]?.tags || []);
			setEditorValue(currentCourse?.["$courses"]?.description || "");
		} else {
			setEditorValue("");
		}
	}, [currentCourse]);

	useEffect(() => {
		reset(data);
	}, [data, reset]);

	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};
	const handelCopy = () => {
		navigator.clipboard.writeText(data?.link);

		setCopy(true);
		setTimeout(() => {
			setCopy(false);
		}, 5000);
	};

	// handle update course
	const [updateCourse] = useUpdateCourseMutation();
	const updateCourseHandeler = async (formData) => {
		try {
			const res = await updateCourse({
				id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				handleClosePage();
				setEditorValue("");
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					description: res?.data?.message?.en?.description?.[0],
					tags: res?.data?.message?.en?.tags?.[0],
					file: res?.data?.message?.en?.["data.0.file.0"]?.[0],
					video: res?.data?.message?.en?.["data.0.video.0"]?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.description?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.tags?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.["data.0.file.0"]?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.["data.0.video.0"]?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleCourse = (dataCourse) => {
		resetData();
		const formData = new FormData();
		formData.append("name", dataCourse?.name);
		formData.append(
			"description",
			editorValue || currentCourse?.["$courses"]?.description
		);
		formData.append("duration", data?.minute);
		formData.append("tags", tagsSelected.join(","));
		if (images.length !== 0) {
			formData.append("image", images[0]?.file || "");
		}
		for (let i = 0; i < unitDetails?.length; i++) {
			formData.append([`data[${i}][title]`], unitDetails[i]?.title);
			if (unitDetails[i]?.documents?.length === 0) {
				formData.append([`data[${i}][file][${0}]`], "");
			} else {
				for (let d = 0; d < unitDetails[i]?.documents?.length; d++) {
					formData.append(
						[`data[${i}][file][${d}]`],
						unitDetails[i]?.documents[d]
					);
				}
			}

			for (let v = 0; v < unitDetails[i]?.videos?.length; v++) {
				formData.append([`data[${i}][video][${v}]`], unitDetails[i]?.videos[v]);
			}
		}

		setLoadingTitle("جاري تعديل الدورة");
		formData.append("_method", "PUT");
		updateCourseHandeler(formData);
	};

	// delete video from course
	const [deleteVideoById] = useDeleteVideoByIdMutation();
	const deleteVideo = async (id) => {
		setLoadingTitle("جاري حذف الفيديو");
		try {
			await deleteVideoById({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setLoadingTitle(null);
					} else {
						setLoadingTitle(null);
					}
				});
		} catch (err) {
			console.error("Failed to delete", err);
		}
	};

	// delete course unit
	const [deleteUnitById] = useDeleteUnitByIdMutation();
	const deleteUnit = async (id) => {
		setLoadingTitle("جاري حذف الدرس");
		try {
			await deleteUnitById({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setLoadingTitle(null);
					} else {
						setLoadingTitle(null);
					}
				});
		} catch (err) {
			console.error("Failed to delete", err);
		}
	};

	const scrollToElement = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToElement();
	}, []);

	const CouresDurationHours = (duration) => {
		const [hours, minutes] = duration?.split(":");
		return parseInt(hours, 10);
	};

	const CouresDurationMinutes = (duration) => {
		const [hours, minutes] = duration?.split(":");
		return parseInt(minutes, 10);
	};

	// Only allow non-empty keywords
	const beforeAddValidate = (keyword) => {
		return keyword.trim() !== "";
	};

	return (
		<>
			{url !== "" && <ShowVideo url={url} setUrl={setUrl} />}
			<Helmet>
				<title>لوحة تحكم أطلبها | تعديل دورة</title>
			</Helmet>
			<form className='' onSubmit={handleSubmit(handleCourse)}>
				<div className=' absolute lg:pl-[140px] md:pr-5 md:py-[43px] md:pt-4 top-0 right-0 z-30 md:pb-36 w-full md:bg-[#fafafa] bg-[#FFFFFF] otlobha_acadmic'>
					{showAddUnit && (
						<AddUnit
							unitDetails={(details) =>
								setUnitDetails([...unitDetails, details])
							}
							cancel={() => {
								setShowAddUnit(false);
							}}
						/>
					)}
					<div
						ref={ref}
						className='flex items-center justify-between px-4 pt-6 mb-2 md:p-0 md:pt-0'>
						<div className='flex'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={handleClosePage}
									className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
									<GoArrowRight
										style={{ color: "#02466A", fontSize: "1.2rem" }}
									/>
								</div>

								<Link
									to={"/"}
									className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2'>
									الرئيسية
								</Link>
							</div>

							<h2
								onClick={handleClosePage}
								className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2 cursor-pointer'>
								/ أكاديمية اطلبها
							</h2>
							<h3
								className='md:text-[18px] text-[15px] font-medium'
								style={{ color: "#67747B" }}>
								/ تعديل دورة تدريبية
							</h3>
						</div>
					</div>
					{loading ? (
						<div className='flex flex-col h-screen mt-28'>
							<CircularLoading />
						</div>
					) : (
						<div className='flex flex-col gap-5 mt-[42px] md:pr-[78px] p-4 h-full'>
							<div className='flex flex-col gap-[10px]'>
								<label
									className='md:text-[18px] text-[16px]'
									style={{ color: "#011723" }}>
									اسم الدورة التدريبية<span className='text-red-500'>*</span>
								</label>
								<input
									className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
									style={{
										backgroundColor: "#F4F5F7",
										border: "1px solid #67747B33",
									}}
									type='text'
									name='name'
									{...register("name", {
										required: "حقل الاسم مطلوب",
										pattern: {
											value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
											message: "يجب ان يتكون الاسم من أحرف",
										},
									})}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.name}
									{errors?.name && errors.name.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									className='md:text-[18px] text-[16px]'
									style={{ color: "#011723" }}>
									الوصف<span className='text-red-500'>*</span>
								</label>
								<div className={styles.editor}>
									<TextEditor
										ToolBar={"createOrEditPages"}
										placeholder={" وصف الدورة..."}
									/>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.description}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<h2
									className='md:text-[18px] text-[16px]'
									style={{ color: "#011723" }}>
									محاور الدورة TAGS<span className='text-red-500'>*</span>
								</h2>
								<div
									style={{
										backgroundColor: "#F4F5F7",
										border: "1px solid #67747B33",
									}}
									className={`${styles.tags} flex flex-row items-center flex-wrap rounded-lg`}>
									<TagsInput
										value={tagsSelected}
										onChange={setTagsSelected}
										beforeAddValidate={beforeAddValidate}
										name='tags'
										placeHolder='ضع الكلمة ثم اضغط enter'
									/>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.tags}
								</span>
							</div>

							<div className='flex flex-col gap-3'>
								<div className='flex flex-col gap-1 md:flex-row'>
									<h2
										className='md:text-[18px] text-[16px]'
										style={{ color: "#011723" }}>
										مدة الدورة
									</h2>
									<p
										className='md:text-[16px] text-[14px]'
										style={{ color: "#ADB5B9" }}>
										(يتم احتسابها تلقائياً بحسب مدة الفديويهات الخاصة بالدورة)
									</p>
								</div>
								<div className='flex'>
									<div
										className='flex-1 w-full p-4 rounded-tr-lg rounded-br-lg outline-none'
										style={{
											color: "#67747B",
											backgroundColor: "#F4F5F7",
											border: "1px solid #67747B33",
										}}>
										{CouresDurationHours(data?.couresDuration || "") === 0
											? "--"
											: CouresDurationHours(data?.couresDuration || "") === 1
											? "ساعة"
											: CouresDurationHours(data?.couresDuration || "") === 2
											? "ساعتين"
											: CouresDurationHours(data?.couresDuration || "") <= 10
											? `ساعات ${CouresDurationHours(
													data?.couresDuration || ""
											  )}`
											: `ساعة ${CouresDurationHours(
													data?.couresDuration || ""
											  )}`}
									</div>
									<div
										className='flex-1 w-full p-4 rounded-tl-lg rounded-bl-lg outline-none'
										style={{
											color: "#67747B",
											backgroundColor: "#F4F5F7",
											border: "1px solid #67747B33",
										}}>
										{CouresDurationMinutes(data?.couresDuration || "") === 0
											? "--"
											: CouresDurationMinutes(data?.couresDuration || "") <= 9
											? `${CouresDurationMinutes(
													data?.couresDuration || ""
											  )} دقائق`
											: `${CouresDurationMinutes(
													data?.couresDuration || ""
											  )} دقيقة`}
									</div>
								</div>
							</div>

							{!currentCourse?.["$courses"]?.unit && (
								<div className='mb-[80px] mt[33px] flex flex-col gap-4'>
									<h6
										className='md:text-[24px] text-[20px]'
										style={{ fontWeight: "500", color: "#000000" }}>
										دروس الدورة
									</h6>
									{unitDetails?.length !== 0 ? (
										<div>
											{unitDetails?.map((item, index) => (
												<Accordion
													sx={{
														".Mui-expanded": {
															margin: 0,
														},
													}}
													key={index}
													style={{
														width: "100%",
														backgroundColor: "#F4F5F7",
														border: "1px solid #67747B33",
														boxShadow: "none",
													}}
													className='relative md:h-[56px] h-[45px]'>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls='panel1a-content'
														id='panel1a-header'
														sx={{
															"&.Mui-expanded": {
																minHeight: "48px",
															},
														}}>
														<div className='flex flex-row items-center flex-1 gap-2'>
															<h6
																className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap sm:w-auto w-full'
																style={{
																	fontWeight: "500",
																	color: "#000000",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																}}>
																{item?.title}
															</h6>
															<span
																className='hidden md:flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																style={{
																	color: "#67747B",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																}}>
																(
																{item?.videos?.length === 1
																	? " درس واحد "
																	: item?.videos?.length === 2
																	? "درسين"
																	: `${item?.videos?.length} دروس`}
																)
															</span>
															<span
																className='md:hidden flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																style={{
																	color: "#67747B",
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																}}>
																({item?.videos?.length})
															</span>
														</div>
													</AccordionSummary>

													<AccordionDetails
														style={{ backgroundColor: "#F4F5F7" }}
														className='flex flex-col gap-5 absolute z-10 left-0 top-[55px] w-full p-5'>
														{item?.videos?.map(
															(video, index) =>
																video !== "" && (
																	<div
																		key={index}
																		className='flex flex-row items-center justify-between'>
																		<div className='flex flex-row items-center'>
																			<PlayCircleOutlineIcon
																				onClick={() => setUrl(video)}
																				className='cursor-pointer'
																			/>

																			<h6
																				style={{
																					fontWeight: "500",
																					color: "#011723",
																				}}
																				className='md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																				مقطع فيديو {index + 1}
																			</h6>
																		</div>

																		<Delete className='' />
																	</div>
																)
														)}

														{Array.from(item?.documents || [])?.map(
															(file, index) => (
																<div
																	key={index}
																	className='flex flex-row items-center justify-between overflow-hidden'>
																	<div className='flex flex-row items-center overflow-hidden'>
																		<PDF />

																		<h6
																			style={{
																				fontWeight: "500",
																				color: "#0077FF",
																				overflow: "hidden",
																				textOverflow: "ellipsis",
																				whiteSpace: "nowrap",
																			}}
																			className='w-[70%] md:w-full md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																			{file?.name}
																		</h6>
																	</div>
																	<a
																		href={file}
																		download={file}
																		target='_blank'
																		rel='noreferrer'>
																		<h6
																			className='md:text-[18px] text-[16px]'
																			style={{
																				color: "#0077FF",
																				cursor: "pointer",
																			}}>
																			تحميل
																		</h6>
																	</a>
																</div>
															)
														)}
													</AccordionDetails>
												</Accordion>
											))}
										</div>
									) : (
										<div
											className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
											style={{
												backgroundColor: "#F4F5F7",
												border: "1px solid #67747B33",
											}}>
											<h6 className='text-sm md:text-lg'>
												لايوجد دروس حتى الان
											</h6>
										</div>
									)}
									<span className='text-red-500 text-[16px]'>
										{DataError?.file}
									</span>
									<span className='text-red-500 text-[16px]'>
										{DataError?.video}
									</span>
								</div>
							)}

							{currentCourse?.["$courses"]?.unit &&
								currentCourse?.["$courses"]?.unit?.length !== 0 && (
									<div className='mb-[80px] mt[33px] flex flex-col gap-4'>
										<h6
											className='md:text-[24px] text-[20px]'
											style={{ fontWeight: "500", color: "#000000" }}>
											دروس الدورة
										</h6>
										<div>
											{currentCourse?.["$courses"]?.unit?.length !== 0 ? (
												currentCourse?.["$courses"]?.unit?.map(
													(item, index) => (
														<Accordion
															sx={{
																".Mui-expanded": {
																	margin: 0,
																},
															}}
															key={Number(index) + 100}
															style={{
																width: "100%",
																backgroundColor: "#F4F5F7",
																border: "1px solid #67747B33",
																boxShadow: "none",
															}}
															className='relative md:h-[56px] h-[45px]'>
															<AccordionSummary
																expandIcon={<ExpandMoreIcon />}
																aria-controls='panel1a-content'
																id='panel1a-header'
																sx={{
																	"&.Mui-expanded": {
																		minHeight: "48px",
																	},
																}}>
																<div className='flex flex-row items-center flex-1 gap-2'>
																	<h6
																		className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																		style={{
																			fontWeight: "500",
																			color: "#000000",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}>
																		{item?.title}
																	</h6>
																	<span
																		className='hidden md:flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																		style={{
																			color: "#67747B",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}>
																		(
																		{item?.unitvideo === 1
																			? " درس واحد"
																			: item?.unitvideo === 2
																			? "درسين"
																			: `${item?.unitvideo} دروس`}
																		)
																	</span>
																	<span
																		className='md:hidden flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																		style={{
																			color: "#67747B",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}>
																		({item?.unitvideo})
																	</span>
																	<span
																		className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap md:w-full w-auto'
																		style={{
																			color: "#67747B",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}>
																		(
																		<VideoOfCourseDuration
																			duration={item?.durationUnit || ""}
																		/>
																		)
																	</span>

																	<Delete
																		className='cursor-pointer min-w-[1.4rem] min-h-[1.4rem]'
																		onClick={() => {
																			deleteUnit(item?.id);
																		}}
																	/>
																</div>
															</AccordionSummary>
															<AccordionDetails
																style={{ backgroundColor: "#F4F5F7" }}
																className='flex flex-col gap-5 absolute z-10 left-0 top-[55px] w-full p-5'>
																{item?.videos?.length !== 0 &&
																	item?.videos?.map(
																		(video, index) =>
																			video !== "" && (
																				<div
																					key={Number(index) + 500}
																					className='flex flex-row items-center justify-between overflow-hidden'>
																					<div className='flex flex-row items-center'>
																						<PlayCircleOutlineIcon
																							onClick={() =>
																								setUrl(video?.video)
																							}
																							className='cursor-pointer'
																						/>
																						<h6
																							style={{
																								overflow: "hidden",
																								whiteSpace: "nowrap",
																								textOverflow: "ellipsis",
																								fontWeight: "500",
																								color: "#011723",
																							}}
																							className='md:text-[20px] text-[18px] mr-[20px] ml-[30px] lg:max-w-[600px] md:max-w-[200px] max-w-[100px]'>
																							{video?.name || ""}
																						</h6>
																						<span
																							className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap sm:w-auto w-full'
																							style={{
																								color: "#67747B",
																								overflow: "hidden",
																								textOverflow: "ellipsis",
																							}}>
																							<VideoOfCourseDuration
																								duration={video?.duration || ""}
																							/>
																						</span>
																					</div>
																					<Delete
																						className='cursor-pointer min-w-[1.4rem] min-h-[1.4rem]'
																						onClick={() => {
																							deleteVideo(video?.id);
																						}}
																					/>
																				</div>
																			)
																	)}
																{item?.file?.length !== 0 &&
																	item?.file?.map((file, index) => (
																		<div
																			key={index}
																			className='flex flex-row items-center justify-between overflow-hidden'>
																			<div className='flex flex-row items-center overflow-hidden'>
																				<PDF />
																				<h6
																					style={{
																						fontWeight: "500",
																						color: "#0077FF",
																						overflow: "hidden",
																						textOverflow: "ellipsis",
																						whiteSpace: "nowrap",
																					}}
																					className='w-[70%] md:w-full md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																					{file}
																				</h6>
																			</div>
																			<a
																				href={file}
																				download={file}
																				target='_blank'
																				rel='noreferrer'
																				className='md:text-[18px] text-[16px]'
																				style={{
																					color: "#0077FF",
																					cursor: "pointer",
																				}}>
																				تحميل
																			</a>
																		</div>
																	))}
															</AccordionDetails>
														</Accordion>
													)
												)
											) : (
												<div
													className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
													style={{
														backgroundColor: "#F4F5F7",
														border: "1px solid #67747B33",
													}}>
													<h6 className='text-sm md:text-lg'>
														لايوجد دروس حتى الان
													</h6>
												</div>
											)}
											{unitDetails?.length !== 0 &&
												unitDetails?.map((item, index) => (
													<Accordion
														sx={{
															".Mui-expanded": {
																margin: 0,
															},
														}}
														key={index}
														style={{
															width: "100%",
															backgroundColor: "#F4F5F7",
															border: "1px solid #67747B33",
															boxShadow: "none",
														}}
														className='relative md:h-[56px] h-[45px]'>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-controls='panel1a-content'
															id='panel1a-header'
															sx={{
																"&.Mui-expanded": {
																	minHeight: "48px",
																},
															}}>
															<div className='flex flex-row items-center flex-1 gap-2'>
																<h6
																	className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																	style={{
																		fontWeight: "500",
																		color: "#000000",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																	}}>
																	{item?.title}
																</h6>
																<span
																	className='hidden md:flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																	style={{
																		color: "#67747B",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																	}}>
																	(
																	{item?.videos?.length === 1
																		? "درس واحد"
																		: item?.videos?.length === 2
																		? "درسين"
																		: `${item?.videos?.length} دروس`}
																	)
																</span>
																<span
																	className='md:hidden flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
																	style={{
																		color: "#67747B",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																	}}>
																	({item?.videos?.length})
																</span>
															</div>
														</AccordionSummary>
														<AccordionDetails
															style={{ backgroundColor: "#F4F5F7" }}
															className='flex flex-col gap-5 absolute z-10 left-0 top-[55px] w-full p-5'>
															{item?.videos?.map(
																(video, index) =>
																	video !== "" && (
																		<div
																			key={index}
																			className='flex flex-row items-center justify-between'>
																			<div className='flex flex-row items-center'>
																				<PlayCircleOutlineIcon />
																				<h6
																					style={{
																						fontWeight: "500",
																						color: "#011723",
																					}}
																					className='md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																					{video?.name}
																				</h6>
																				<span
																					className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap sm:w-auto w-full'
																					style={{
																						color: "#67747B",
																						overflow: "hidden",
																						textOverflow: "ellipsis",
																					}}>
																					(
																					<VideoOfCourseDuration
																						duration={video?.duration || ""}
																					/>
																					)
																				</span>
																			</div>
																			<Delete
																				className='cursor-pointer'
																				onClick={() => {
																					deleteVideo(video?.id);
																				}}
																			/>
																		</div>
																	)
															)}

															{Array.from(item?.documents || [])?.map(
																(file, index) => (
																	<div
																		key={index}
																		className='flex flex-row items-center justify-between overflow-hidden'>
																		<div className='flex flex-row items-center overflow-hidden'>
																			<PDF />
																			<h6
																				style={{
																					fontWeight: "500",
																					color: "#0077FF",
																					overflow: "hidden",
																					textOverflow: "ellipsis",
																					whiteSpace: "nowrap",
																				}}
																				className='w-[70%] md:w-full md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																				{file?.name}
																			</h6>
																		</div>
																		<a
																			href={file}
																			download={file}
																			target='_blank'
																			rel='noreferrer'>
																			<h6
																				className='md:text-[18px] text-[16px]'
																				style={{
																					color: "#0077FF",
																					cursor: "pointer",
																				}}>
																				تحميل
																			</h6>
																		</a>
																	</div>
																)
															)}
														</AccordionDetails>
													</Accordion>
												))}
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.file}
										</span>
										<span className='text-red-500 text-[16px]'>
											{DataError?.video}
										</span>
									</div>
								)}

							<div className='flex flex-col items-start md:flex-row md:items-center md:gap-16 gap-y-4'>
								<label
									className='md:text-[18px] text-[16px] font-medium whitespace-nowrap'
									style={{ color: "#011723" }}>
									الصورة التعريفية<span className='text-red-500'>*</span>
								</label>
								<div className='flex flex-col w-full gap-2'>
									<div className='text-gray-400 font-light text-[16px]'>
										المقاس المقترح : 440 * 440
									</div>
									<ImageUploading
										value={images}
										onChange={onChange}
										maxNumber={2}
										dataURLKey='data_url'
										acceptType={["jpg", "png", "jpeg"]}>
										{({ onImageUpload, dragProps }) => (
											// write your building UI
											<div
												className='relative mx-auto overflow-hidden upload__image-wrapper'
												style={{
													width: "100%",
													padding: 0,
													border: "1px dashed #02466A",
													borderRadius: "10px",
													strokeDasharray: "'6%2c5'",
												}}
												onClick={() => {
													onImageUpload();
												}}
												{...dragProps}>
												<div className='w-full h-full cursor-pointer image-item'>
													<div className='flex flex-col justify-center items-center gap-[5px] h-full w-full'>
														<IoMdCloudUpload size={"2em"} />
														<h2 style={{ color: "#011723", fontSize: "16px" }}>
															اسحب الصورة هنا
														</h2>
														<div className='flex flex-col items-center gap-1'>
															<h2 className='mb-0'>
																(سيتم قبول الصور png & jpg & jpeg)
															</h2>
															<span className='text-gray-400 font-light text-[14px]'>
																(الحد الأقصى للصورة 1MB)
															</span>
														</div>
													</div>
												</div>
											</div>
										)}
									</ImageUploading>
									{DataError?.image && (
										<span className='text-red-500 text-[16px]'>
											{DataError?.image}
										</span>
									)}
								</div>
							</div>
							<div className='flex flex-col items-start justify-center md:flex-row md:gap-16 gap-y-4'>
								{(images[0] || currentCourse) && (
									<div className='md:w-[400px] w-[100%] md:h-[400px] h-[100%] mt-4'>
										<img
											className='mx-auto my-0'
											src={images[0]?.data_url || data?.image || data?.image}
											alt='preview-img'
											loading='lazy'
										/>
									</div>
								)}
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									className='md:text-[20px] text-[18px]'
									style={{ color: "#02466A" }}>
									رابط الدورة
									<span
										className='md:text-[16px] text-[14px]'
										style={{ color: "#02466A" }}>
										{" "}
										(تلقائي){" "}
									</span>
								</label>
								<div
									className='flex flex-row items-center justify-between gap-3 p-4 rounded-md'
									style={{
										backgroundColor: "#F4F5F7",
										border: "1px solid #67747B33",
									}}>
									{copy ? (
										<h6 style={{ color: "#02466A", fontSize: "16px" }}>
											Copied
										</h6>
									) : (
										<Copy
											className='cursor-pointer'
											fill='#02466A'
											onClick={() => handelCopy()}
										/>
									)}
									<input
										className='outline-none text-left md:text-[16px] text-[14px]'
										style={{
											width: "100%",
											backgroundColor: "transparent",
											color: "#02466A",
											whiteSpacepace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
										value={data.link}
										type='text'
										disabled
									/>
								</div>
							</div>
							<div className='flex gap-4 mt-10'>
								<Button
									style={{ backgroundColor: "#02466A" }}
									textStyle={{ color: "#EFF9FF" }}
									className={
										"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
									}
									type={"normal"}
									svg={<IoAddCircleSharp fontSize='1.5rem' color={"#fff"} />}
									onClick={() => {
										setShowAddUnit(true);
									}}
									btnType={"button"}>
									اضافة درس
								</Button>
								<Button
									style={{ borderColor: "#02466A" }}
									textStyle={{ color: "#02466A" }}
									className={
										"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
									}
									type={"outline"}
									onClick={handleClosePage}
									btnType={"button"}>
									إلغاء
								</Button>
							</div>
							<Button
								btnType={"submit"}
								style={{ width: "100%", backgroundColor: "#1DBBBE" }}
								textStyle={{ color: "#EFF9FF" }}
								className={
									"md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
								}
								type={"normal"}>
								حفظ التعديلات
							</Button>
						</div>
					)}
				</div>
			</form>
		</>
	);
};

export default EditCourse;
