import React, { useState, useContext } from "react";
import styles from "./AddQuestion.module.css";
/* Third party */
import { Helmet } from "react-helmet";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { LoadingContext } from "../../../store/LoadingProvider";
import { TextEditorContext } from "../../../store/TextEditorProvider";
/* Custom Button */
import Button from "../../../UI/Button/Button";
import { TextEditor } from "../../TextEditor";
import { useAddNewCommonQuestionsMutation } from "../../../RTK/apiSlices/commonQuestionsApi";

// back_drop
const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

// some styles
const formTitleClasses =
	"md:w-[315px] w-full font-semibold md:text-lg text-[16px] md:mb-0 mb-2";
const formInputClasses =
	"md:w-[555px] w-full md:h-14 h-[45px] p-4 outline-0 rounded-md";
const formInputStyle = {
	border: "1px solid #A7A7A7",
	backgroundColor: "#F6F6F6",
};

const AddQuestion = () => {
	// to get token that user set it from local storage
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
	});
	const [addNewCommonQuestions] = useAddNewCommonQuestionsMutation();
	const addNewCommonQuestionsHandel = async (formData) => {
		try {
			const res = await addNewCommonQuestions({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/faqs");
				setEditorValue("");
			} else {
				setLoadingTitle(null);

				setDataError({
					question: res?.data?.message?.en?.question?.[0],
					answer: res?.data?.message?.en?.answer?.[0],
				});
				toast.error(res?.data?.message?.en?.question?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.answer?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddQuestion = (data) => {
		resetData();
		setLoadingTitle("جاري حفظ السؤال");
		const formData = new FormData();
		formData.append("question", data?.question);
		formData.append("answer", editorValue);
		addNewCommonQuestionsHandel(formData);
	};

	const [DataError, setDataError] = useState({
		question: "",
		answer: "",
	});
	const resetData = () => {
		setDataError({
			question: "",
			answer: "",
		});
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | إضافة سؤال</title>
			</Helmet>
			<BackDrop onClick={() => navigate("/faqs")}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-[#F6F6F6] z-30 ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleAddQuestion)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='md:px-8 px-4 pt-24 md:pt-[40px] pb-[20px]'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2 className='font-semibold md:text-2xl text-[20px]'>
								إضافة سؤال
							</h2>
						</div>
						<div
							className={`overflow-y-scroll md:py-12 md:pr-8 p-4 ${styles.content}`}>
							<div className='flex flex-col mb-8 md:flex-row'>
								<h2 className={formTitleClasses}>
									السؤال<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col'>
									<input
										className={formInputClasses}
										style={formInputStyle}
										placeholder='ادخل السؤال'
										type='text'
										name='question'
										{...register("question", {
											required: "حقل السؤال مطلوب",
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.question}
										{errors?.question && errors.question.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col mb-8 md:flex-row '>
								<h2 className={formTitleClasses}>
									الجواب<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col md:w-[555px] w-full'>
									<TextEditor ToolBar={"product"} placeholder={"الاجابة ..."} />
									<span className='text-red-500 text-[16px]'>
										{DataError?.description}
										{errors?.description && errors.description.message}
									</span>
								</div>
							</div>
						</div>
						<div
							className='flex items-center justify-center gap-4 p-4 md:p-8'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<Button
								btnType={"submit"}
								className={"md:h-14 h-[45px] md:w-1/3 w-full text-xl"}
								style={{ backgroundColor: `rgba(2, 70, 106, 1)` }}
								type={"normal"}>
								حفظ
							</Button>
							<Button
								style={{
									borderColor: `rgba(2, 70, 106, 1)`,
								}}
								textStyle={{ color: "rgba(2, 70, 106, 1)" }}
								className={"md:h-14 h-[45px] md:w-1/3 w-full text-xl"}
								type={"outline"}
								onClick={() => navigate("/faqs")}>
								إلغاء
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddQuestion;
