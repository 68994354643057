import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const marketsApi = createApi({
	reducerPath: "marketsApi",

	baseQuery: axiosBaseQuery({
		baseUrl: "https://backend.atlbha.com/api/",
	}),
	tagTypes: ["Markets"],

	endpoints: (builder) => ({
		// Get Markets Data
		marketsData: builder.query({
			query: (args) => ({
				url: `Admin/store?page=${args.page}&number=${args.number}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["Markets"],
		}),

		// Get Markets Data
		getMarketData: builder.query({
			query: ({ marketId }) => ({
				url: `Admin/store/${marketId}`,
			}),

			transformResponse: (response) => response.data,
			providesTags: (result, error, id) => [{ type: "Markets", id }],
		}),

		// Get unVerification Markets Data
		unVerificationStoreData: builder.query({
			query: () => ({
				url: `Admin/unVerificationStore`,
			}),

			transformResponse: (response) => response.data,
			providesTags: ["unVerificationMarkets"],
		}),

		// Get Markets Report Year Data
		getStoreReportYearData: builder.query({
			query: ({ year }) => ({
				url: `Admin/storeReport?year=${year}`,
			}),
			transformResponse: (response) => response.data,
			providesTags: ["MarketsReport"],
		}),

		// Search In Markets
		searchInMarkets: builder.mutation({
			query: (arg) => ({
				url: `Admin/searchStoreName?query=${arg.query}`,
				method: "GET",
			}),
		}),

		//Add New Market
		addNewMarket: builder.mutation({
			query: ({ body }) => {
				return {
					url: `Admin/store`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Markets"],
		}),

		// Change Special Status
		changeSpecialStatus: builder.mutation({
			query: ({ id }) => ({
				url: `Admin/specialStatus/${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Markets"],
		}),

		//Add To Store Note
		addToStoreNote: builder.mutation({
			query: (data) => {
				return {
					url: `Admin/addToStoreNote`,
					method: "POST",
					data: data,
				};
			},
		}),

		// Login To Store
		loginToStore: builder.mutation({
			query: ({ storeId }) => ({
				url: `store_token/${storeId}`,
			}),
			transformResponse: (response) => response.data,
		}),

		updateMarket: builder.mutation({
			query: ({ marketId, body }) => {
				return {
					url: `Admin/updateProfile/${marketId}`,
					method: "POST",
					data: body,
				};
			},

			invalidatesTags: ["Markets"],
		}),

		changeMarketStatus: builder.mutation({
			query: ({ queryParams }) => ({
				url: `Admin/changeStoreStatus?${queryParams}`,
				method: "GET",
			}),
			invalidatesTags: ["Markets"],
		}),

		changeMarketStatusById: builder.mutation({
			query: ({ id }) => ({
				url: `Admin/changeStoreStatus?id[]=${id}`,
				method: "GET",
			}),
			invalidatesTags: ["Markets"],
		}),

		marketDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `Admin/store/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Markets"],
		}),
	}),
});

// Export endpoints and hooks
export const {
	useMarketsDataQuery,
	useGetMarketDataQuery,
	useUnVerificationStoreDataQuery,
	useGetStoreReportYearDataQuery,
	useSearchInMarketsMutation,
	useAddNewMarketMutation,
	useChangeSpecialStatusMutation,
	useAddToStoreNoteMutation,
	useLoginToStoreMutation,
	useUpdateMarketMutation,
	useChangeMarketStatusMutation,
	useChangeMarketStatusByIdMutation,
	useMarketDeleteItemMutation,
} = marketsApi;
