import React, { useState, useEffect, useContext } from "react";
/* Third party */
/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { IoIosArrowDown } from "react-icons/io";
import { useSectionUpdateMutation } from "../../../../RTK/apiSlices/templateApi";

const activate = [
  { ar: "نشط", en: "active" },
  { ar: "غير نشط", en: "not_active" },
];

const PartitionsSections = ({ fetchedData }) => {
  const LoadingStore = useContext(LoadingContext);
  const { setLoadingTitle } = LoadingStore;
  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;
  const [firstSection, setFirstSection] = useState({
    name: "",
    status: "",
  });
  const [secondSection, setSecondSection] = useState({
    name: "",
    status: "",
  });
  const [thirdSection, setThirdSection] = useState({
    name: "",
    status: "",
  });
  const [value, setValue] = React.useState("1");

  const handleChange = (event, id) => {
    setValue(id);
  };

  useEffect(() => {
    setFirstSection({
      ...firstSection,
      name: fetchedData?.Sections[0]?.name || "",
      status: fetchedData?.Sections[0]?.status || "",
    });
    setSecondSection({
      ...secondSection,
      name: fetchedData?.Sections[1]?.name || "",
      status: fetchedData?.Sections[1]?.status || "",
    });
    setThirdSection({
      ...thirdSection,
      name: fetchedData?.Sections[2]?.name || "",
      status: fetchedData?.Sections[2]?.status || "",
    });
  }, [fetchedData?.Sections]);

  const [sectionUpdate] = useSectionUpdateMutation();
  const sectionUpdateHandel = async (formData) => {
    try {
      const res = await sectionUpdate({
        body: formData,
      });
      if (res?.data?.data?.status === 200) {
        setLoadingTitle(null);
      } else {
        setLoadingTitle(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePartitions = () => {
    setLoadingTitle("جاري تعديل التقسيم");
    const data = {
      "data[0][id]": 1,
      "data[0][name]": firstSection?.name,
      "data[0][status]":
        firstSection?.status === "نشط" ? "active" : "not_active",
      "data[1][id]": 2,
      "data[1][name]": secondSection?.name,
      "data[1][status]":
        secondSection?.status === "نشط" ? "active" : "not_active",
      "data[2][id]": 3,
      "data[2][name]": thirdSection?.name,
      "data[2][status]":
        thirdSection?.status === "نشط" ? "active" : "not_active",
    };
    sectionUpdateHandel(data);
  };
  return (
    <Box
      className="p-4 lg:pr-20 lg:pt-5 lg:pl-24"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <TabContext value={value}>
        <Box>
          <TabList
            sx={{
              "& .MuiButtonBase-root": {
                minWidth: "180px",
                height: "56px",
                backgroundColor: "#ECECEC",
                ml: "1rem",
                borderRadius: "0.25rem",
                color: "#011723",
                transition: "0.1s",
                whiteSpace: "nowrap",
                "@media(max-width:992px) ": {
                  minWidth: "150px",
                },
              },
              "& .MuiButtonBase-root.Mui-selected": {
                backgroundColor: "#5EBFF2",
                color: "#011723",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTabs-flexContainer": {
                overflow: "auto",
              },
            }}
            onChange={handleChange}
          >
            {fetchedData?.Sections?.map((section, index) => (
              <Tab
                key={index}
                disableRipple={true}
                className="text-lg font-medium"
                label={section?.name}
                value={section?.id?.toString()}
              />
            ))}
          </TabList>
        </Box>
        <Box sx={{ height: "27.5rem", mt: "2.5rem" }}>
          <TabPanel value="1" className="p-0 md:pr-0">
            {permissions?.includes("admin.section.sectionupdate") && (
              <div className="w-full mb-5">
                <h2
                  className="mb-2 text-lg font-normal"
                  style={{ color: "#011723" }}
                >
                  اسم القسم
                </h2>
                <label>
                  <input
                    value={firstSection?.name}
                    onChange={(e) =>
                      setFirstSection({ ...firstSection, name: e.target.value })
                    }
                    className="w-full p-4 rounded outline-none"
                    placeholder="المنتجات المميزة"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #ECECEC",
                    }}
                    type="text"
                  />
                </label>
              </div>
            )}
            {permissions?.includes("admin.section.changestatus") && (
              <div className="mb-5">
                <h2
                  className="mb-2 text-lg font-normal"
                  style={{ color: "#011723" }}
                >
                  الحالة
                </h2>
                <Select
                  value={firstSection?.status}
                  IconComponent={() => {
                    return <IoIosArrowDown size={"1rem"} />;
                  }}
                  onChange={(e) =>
                    setFirstSection({ ...firstSection, status: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={(selected) => {
                    if (firstSection?.status === "") {
                      return <h2>الحالة</h2>;
                    }
                    const result = activate?.filter(
                      (item) => item?.ar === selected
                    );
                    return result[0]?.ar;
                  }}
                  className={"rounded"}
                  sx={{
                    height: "3.5rem",
                    backgroundColor: "#fff",
                    width: "100%",
                    pl: "1rem",

                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ECECEC",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #03787A",
                    },
                  }}
                >
                  {activate?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          backgroundColor: "#fff",
                          height: "3rem",

                          "&:hover": {},
                          "ul:has(&)": {
                            padding: "0",
                          },
                        }}
                        value={`${item?.ar}`}
                      >
                        {item?.ar}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
          </TabPanel>
          <TabPanel value="2" className="p-0 md:pr-0">
            {permissions?.includes("admin.section.sectionupdate") && (
              <div className="mb-5">
                <h2
                  className="mb-2 text-lg font-normal"
                  style={{ color: "#011723" }}
                >
                  اسم القسم
                </h2>
                <label>
                  <input
                    value={secondSection?.name}
                    onChange={(e) =>
                      setSecondSection({
                        ...secondSection,
                        name: e.target.value,
                      })
                    }
                    className="w-full p-4 rounded outline-none"
                    placeholder="المتاجر المميزة"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #ECECEC",
                    }}
                    type="text"
                  />
                </label>
              </div>
            )}
            {permissions?.includes("admin.section.changestatus") && (
              <div className="mb-5">
                <h2
                  className="mb-2 text-lg font-normal"
                  style={{ color: "#011723" }}
                >
                  الحالة
                </h2>
                <Select
                  value={secondSection?.status}
                  IconComponent={() => {
                    return <IoIosArrowDown size={"1rem"} />;
                  }}
                  onChange={(e) =>
                    setSecondSection({
                      ...secondSection,
                      status: e.target.value,
                    })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={(selected) => {
                    if (secondSection?.status === "") {
                      return <h2>مفعل</h2>;
                    }
                    const result = activate?.filter(
                      (item) => item?.ar === selected
                    );
                    return result[0]?.ar;
                  }}
                  className={"rounded"}
                  sx={{
                    height: "3.5rem",
                    backgroundColor: "#fff",
                    width: "100%",
                    pl: "1rem",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ECECEC",
                      "&:hover": { border: "none" },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #03787A",
                    },
                  }}
                >
                  {activate?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          backgroundColor: "#fff",
                          height: "3rem",

                          "&:hover": {},
                          "ul:has(&)": {
                            padding: "0",
                          },
                        }}
                        value={`${item?.ar}`}
                      >
                        {item?.ar}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
          </TabPanel>
          <TabPanel value="3" className="p-0 md:pr-0">
            {permissions?.includes("admin.section.sectionupdate") && (
              <div className="mb-5">
                <h2
                  className="mb-2 text-lg font-normal"
                  style={{ color: "#011723" }}
                >
                  اسم القسم
                </h2>
                <label>
                  <input
                    value={thirdSection?.name}
                    onChange={(e) =>
                      setThirdSection({ ...thirdSection, name: e.target.value })
                    }
                    className="w-full p-4 rounded outline-none"
                    placeholder=""
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #ECECEC",
                    }}
                    type="text"
                  />
                </label>
              </div>
            )}
            {permissions?.includes("admin.section.changestatus") && (
              <div className="mb-5">
                <h2
                  className="mb-2 text-lg font-normal"
                  style={{ color: "#011723" }}
                >
                  الحالة{" "}
                </h2>
                <Select
                  value={thirdSection?.status}
                  IconComponent={() => {
                    return <IoIosArrowDown size={"1rem"} />;
                  }}
                  onChange={(e) =>
                    setThirdSection({ ...thirdSection, status: e.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={(selected) => {
                    if (thirdSection?.status === "") {
                      return <h2>___</h2>;
                    }
                    const result = activate?.filter(
                      (item) => item?.ar === selected
                    );
                    return result[0]?.ar;
                  }}
                  className={"rounded"}
                  sx={{
                    height: "3.5rem",
                    backgroundColor: "#fff",
                    width: "100%",
                    pl: "1rem",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ECECEC",
                      "&:hover": { border: "none" },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #03787A",
                    },
                  }}
                >
                  {activate?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          backgroundColor: "#fff",
                          height: "3rem",

                          "&:hover": {},
                          "ul:has(&)": {
                            padding: "0",
                          },
                        }}
                        value={`${item?.ar}`}
                      >
                        {item?.ar}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
          </TabPanel>
        </Box>
      </TabContext>
      {(permissions?.includes("admin.section.sectionupdate") ||
        permissions?.includes("admin.section.changestatus")) && (
        <div className="flex gap-4 mt-8">
          <Button
            onClick={updatePartitions}
            className={"rounded h-14"}
            style={{ backgroundColor: "#3AE374", width: "180px" }}
            fontSize={"text-xl"}
            type={"normal"}
          >
            تعديل
          </Button>
          <Button
            className={"rounded h-14"}
            style={{
              backgroundColor: "#3AE37400",
              border: "1px solid #ADB5B9",
              width: "180px",
            }}
            textStyle={{ color: "#011723" }}
            fontSize={"text-xl"}
            type={"normal"}
          >
            إلغاء
          </Button>
        </div>
      )}
    </Box>
  );
};

export default PartitionsSections;
