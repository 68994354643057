import React, { useState, useContext } from "react";
import styles from "./TraderAlert.module.css";
/* Third party */
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DateRange } from "antd";
/* Context */

import { LoadingContext } from "../../../store/LoadingProvider";
import { TextEditorContext } from "../../../store/TextEditorProvider";
/* Components */
import { TextEditor } from "../../TextEditor";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
/* Icons */
import { DataIcon } from "../../../assets/Icons/index";
import { FiSend } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useAddToAlertMutation } from "../../../RTK/apiSlices/subscriptionsApi";

const packagesOptions = ["تجديد الاشتراك", "الغاء الاشتراك"];

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const TraderAlert = ({ cancel, traderPackageDetails }) => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const [packageOption, setPackageOption] = useState("");
	const [type, setType] = useState("schedule");

	const handleCategory = (event) => {
		setPackageOption(event.target.value);
	};

	const [addToAlert] = useAddToAlertMutation();

	const addToAlertHandel = async (data) => {
		try {
			const res = await addToAlert(data);
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				cancel();
				setEditorValue("");
			} else {
				setLoadingTitle(null);
				cancel();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const addStoreNote = () => {
		setLoadingTitle("جاري ارسال تنبيه للتاجر ");
		const data = {
			store_id: traderPackageDetails?.id,
			type: type,
			subject: "subject",
			message: editorValue,
		};
		addToAlertHandel(data);
	};

	return (
		<>
			<BackDrop onClick={cancel} />
			<div
				className='fixed trader_alert   flex flex-col top-[400px] translate-x-2/4 -translate-y-2/4 right-2/4 z-20 rounded-lg md:overflow-hidden overflow-auto'
				style={{ width: "51.25rem", maxWidth: "90%", maxHeight: "600px" }}>
				<div
					className='flex items-center justify-center w-full h-16 px-4 py-4 trader_alert'
					style={{ backgroundColor: "#1DBBBE" }}>
					<h2 className='flex-1 text-slate-50 text-center md:text-[22px] text-[18px] font-medium'>
						ارسال تنبيه للتاجر{" "}
					</h2>
					<IoMdCloseCircleOutline
						size={"1.25rem"}
						color={"#fff"}
						className={"md:hidden flex cursor-pointer mr-auto"}
						onClick={cancel}></IoMdCloseCircleOutline>
				</div>
				<div
					className='flex-1 p-4 pb-8 md:pt-10'
					style={{
						backgroundColor: "rgb(246,246,246)",
						overflowY: "auto",
						maxHeight: "520px",
					}}>
					<div className='flex md:flex-row flex-col md:gap-24 gap-[18px]'>
						<div>
							<h2 className='font-normal md:text-[18px] text-[16px] h-8'>
								نوع التنبيه
							</h2>
							<Select
								className='text-[#1DBBBE] md:w-[11rem] w-full'
								IconComponent={IoIosArrowDown}
								value={packageOption}
								onChange={handleCategory}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								renderValue={(selected) => {
									if (packageOption === "") {
										return <h2 className='text-[#1DBBBE]'>تجديد اشتراك</h2>;
									}
									return selected;
								}}
								sx={{
									height: "3rem",
									backgroundColor: "#fff",
									border: "1px solid rgba(29, 187, 190, 1)",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"& .MuiSelect-icon": {
										right: "90%",
										color: "#1DBBBE",
									},
								}}>
								{packagesOptions?.map((item) => {
									return (
										<MenuItem
											className='souq_storge_category_filter_items'
											sx={{
												backgroundColor: "rgba(211, 211, 211, 1)",
												height: "3rem",
												"&:hover": {
													backgroundColor: "#1DBBBE",
												},
											}}
											value={`${item}`}>
											{item}
										</MenuItem>
									);
								})}
							</Select>
						</div>
						<div className='flex flex-col'>
							<FormControl>
								<RadioGroup
									value={type}
									onChange={(e) => setType(e.target.value)}
									row
									aria-labelledby='demo-row-radio-buttons-group-label'
									defaultValue='schedule'
									name='row-radio-buttons-group'
									sx={{
										gap: "3rem",
										height: "2rem",
										"&  .MuiTypography-root": {
											color: "#ADB5B9",
											fontWeight: "500",
											transition: "0.2s",
										},
										"& .MuiFormControlLabel-root:has(.Mui-checked) .MuiTypography-root":
											{
												color: "#011723",
											},
										"& .MuiRadio-root ": {
											color: "#ADB5B9",
										},
										"& .Mui-checked ": {
											color: "#011723 !important",
										},
									}}>
									<FormControlLabel
										value='schedule'
										control={<Radio />}
										label='جدولة التنبيه'
										sx={{
											margin: "0",
											gap: "1rem",
											alignItems: "flex-start",
											"& .MuiButtonBase-root": { padding: "0" },
										}}
									/>
									<FormControlLabel
										value='now'
										control={<Radio />}
										label='ارسال فورى'
										sx={{
											margin: "0",
											gap: "1rem",
											alignItems: "flex-start",
											"& .MuiButtonBase-root": { padding: "0" },
										}}
									/>
								</RadioGroup>
								{type === "schedule" && (
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Stack
											spacing={3}
											sx={{ flexDirection: "row", maxWidth: "100%" }}>
											<div
												className='flex items-center justify-center w-20 p-2 rounded-r-md'
												style={{ backgroundColor: "#1DBBBE" }}>
												<DataIcon />
											</div>
											<DateRange.RangePicker
												className='w-full mt-0 rounded-none rounded-l-lg main_page_date_picker md:w-80'
												style={{
													backgroundColor: "#fff",
													border: "1px solid #1DBBBE",
												}}
												placeholder={["اختر الفترة من إلى", ""]}
												allowEmpty={[true, true]}
												onChange={(e) => {
													console.log(e);
													const date = new Date(e[0]);
													const [year, month, day] = [
														date.getFullYear(),
														date.getMonth() + 1,
														date.getDate(),
													];
													console.log(year, month, day);
												}}
												suffixIcon={""}
											/>
										</Stack>
									</LocalizationProvider>
								)}
							</FormControl>
						</div>
					</div>
					<div className={`${styles.editor} md:mt-20 mt-4 rounded-lg`}>
						<TextEditor ToolBar={"TraderAlert"} placeholder={"نص الرسالة"} />
						<h2>
							صديقنا التاجر،
							<span style={{ color: "#FF9F1A" }}>
								باقي {traderPackageDetails.daysLeft} يوم على انتهاء اشتراكك
							</span>
						</h2>
						<h2>تواصل مع الدعم الفني للحصول على كود خصم لتجديد اشتراكك</h2>
					</div>
					<div className='flex justify-center gap-4'>
						<Button
							onClick={addStoreNote}
							type={"normal"}
							className={
								"text-center mt-12 w-[163px] md:h-16 h-[45px] text-xl font-normal"
							}
							style={{ backgroundColor: "#02466A" }}
							svg={<FiSend color={"#fff"} />}>
							ارسال
						</Button>
						<Button
							type={"outline"}
							className={
								"text-center  mt-12 w-[163px] md:h-16 h-[45px] text-xl font-normal"
							}
							style={{ borderColor: "#02466A" }}
							textStyle={{ color: "#02466A" }}
							onClick={cancel}>
							الغاء
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TraderAlert;
