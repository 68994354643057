import React, { useState } from "react";
import styles from "./DynamicMenu.module.css";
/* MUI */
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { ListMoreCategory } from "../../assets/Icons/index";

const DynamicMenu = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListMoreCategory className="cursor-pointer" onClick={handleClick} />
      <Menu
        className={styles.menus}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items?.slice(1)?.map((item, index) => (
          <MenuItem
            className="flex flex-row items-center justify-start gap-2"
            style={{ width: "100%", color: "#4D4F5C" }}
            key={index}
            onClick={handleClose}
          >
            <div className="flex flex-row items-center justify-center md:w-[30px] w-[20px] md:h-[30px] h-[20px] border-[1px] border-[#ddd] p-[5px] rounded-full overflow-hidden">
              <img src={item?.icon} alt={item?.name} loading="lazy" />
            </div>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DynamicMenu;
