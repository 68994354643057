import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const registrationStatusApi = createApi({
  reducerPath: "registrationStatusApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["RegistrationStatus"],

  endpoints: (builder) => ({
    getRegistrationStatus: builder.query({
      query: () => ({url: `registration_status_show`}),

      transformResponse: (response) => response.data,
      providesTags: ["RegistrationStatus"],
    }),
    updateRegistrationStatus: builder.mutation({
      query: ({ body }) => {
        return {
          url: `registration_status_update`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["RegistrationStatus"],
    }),
  }),
});

// Export endpoints and hooks
export const {
  useGetRegistrationStatusQuery,
  useUpdateRegistrationStatusMutation,
} = registrationStatusApi;
