import React, { useContext } from "react";
import styles from "./Navbar.module.css";
/* Components */
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import NotificationsMenu from "../NotificationsMenu/NotificationsMenu";
import EmailMenu from "../EmailMenu/EmailMenu";
import SearchInput from "../SearchInput/SearchInput";
/* Icons */
import MenuIcon from "@mui/icons-material/Menu";
import { HomeIcon } from "../../assets/Icons/index";
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";

const Navbar = ({ openSidebar }) => {
  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;

  return (
    <nav className={styles.navbar}>
      <div className="flex flex-row items-center justify-between w-full md:justify-start">
        <a
          href="https://atlbha.sa"
          target="_blank"
          className={`hidden md:flex items-center gap-2 ${styles.logo}`}
          rel="noreferrer"
        >
          <HomeIcon />
          <h1 className="text-base font-medium text-slate-50">الموقع</h1>
        </a>
        <div
          className="flex flex-col items-center justify-center md:hidden"
          onClick={() => {
            openSidebar();
          }}
        >
          <MenuIcon className={styles.menuIcon} />
        </div>
        <div className={`flex items-center gap-10 md:px-8 px-0`}>
          <ProfileMenu />
          <div className="flex gap-4">
            {(permissions?.includes("admin.notification") ||
              permissions?.includes("admin.notification.index")) && (
              <NotificationsMenu />
            )}
            {(permissions?.includes("admin.email") ||
              permissions?.includes("admin.email.index")) && <EmailMenu />}
          </div>
        </div>
      </div>
      <div className="relative hidden h-12 lg:flex ">
        <SearchInput />
      </div>
    </nav>
  );
};

export default Navbar;
