import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const countriesApi = createApi({
  reducerPath: "countriesApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Countries"],

  endpoints: (builder) => ({
    getCountries: builder.query({
      query: (args) => ({
        url: `country?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["Countries"],
    }),

    getCountryData: builder.query({
      query: ({ showCountryId }) => ({
        url: `country/${showCountryId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Countries", id }],
    }),

    searchInCountries: builder.mutation({
      query: (arg) => ({
        url: `searchCountry?query=${arg.query}`,
        method: "GET",
      }),
    }),

    updateCountry: builder.mutation({
      query: ({ countryId, body }) => {
        return {
          url: `country/${countryId}`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Countries"],
    }),

    addNewCountry: builder.mutation({
      query: ({ body }) => {
        return {
          url: `country`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Countries"],
    }),

    countryDeleteAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `cityedeleteall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Cities"],
    }),

    countriesDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `countryedeleteall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Countries"],
    }),
    

  }),
});

// Export endpoints and hooks
export const {
  useGetCountriesQuery,
  useGetCountryDataQuery,
  useSearchInCountriesMutation,
  useUpdateCountryMutation,
  useAddNewCountryMutation,
  useCountryDeleteAllByParamsMutation,
  useCountriesDeleteItemMutation,
} = countriesApi;
