import React from "react";

// redux and store
import { Provider } from "react-redux";
import { store } from "./RTK/store";

// react Dom
import ReactDOM from "react-dom/client";

// main css styles files
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

/* Components */
import App from "./App";

/* Context */
import ContextProvider from "./store/ContextProvider";
import NotificationProvider from "./store/NotificationProvider";
import LoadingProvider from "./store/LoadingProvider";
import DeleteProvider from "./store/DeleteProvider";
import TextEditorProvider from "./store/TextEditorProvider";
import RolePermissionsProvider from "./store/RolePermissionsProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<ContextProvider>
			<NotificationProvider>
				<LoadingProvider>
					<DeleteProvider>
						<TextEditorProvider>
							<RolePermissionsProvider>
								<App />
							</RolePermissionsProvider>
						</TextEditorProvider>
					</DeleteProvider>
				</LoadingProvider>
			</NotificationProvider>
		</ContextProvider>
	</Provider>
);
