import React, { useContext, useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import UsersTable from "../../components/UsersPageComp/UsersTable";
import PageNavigate from "../../components/PageNavigate/PageNavigate";
/* Custom Button */
import Button from "../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useGetUsersQuery } from "../../RTK/apiSlices/usersApi";

const Users = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: fetchedData, isLoading: loading } = useGetUsersQuery({
    page,
    number: rowsPerPage,
  });

  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;
  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | المستخدمين</title>
      </Helmet>
      <div className="relative p-4 2xl:pl-36">
        <div className="flex flex-col items-start justify-between xl:flex-row xl:items-center gap-y-4">
          <PageNavigate currentPage={"جدول المستخدمين"} />
          <div className="flex flex-row items-center w-full gap-4 md:w-auto">
            {permissions?.includes("admin.user.store") && (
              <Button
                className="md:h-14 h-[45px] md:w-[198px] w-full text-lg whitespace-nowrap"
                type={"outline"}
                svg={<AiOutlinePlus color="#B6BE34" className="w-5 h-5" />}
                style={{ borderColor: "#B6BE34" }}
                textStyle={{ color: "#B6BE34" }}
                onClick={() => {
                  navigate("/users/add-user");
                }}
              >
                اضافة مستخدم
              </Button>
            )}
            {(permissions?.includes("admin.role") ||
              permissions?.includes("admin.role.index")) && (
              <Button
                className="md:h-14 h-[45px] md:w-[180px] w-full text-xl whitespace-nowrap"
                onClick={() => {
                  navigate("/users/roles");
                }}
                type={"normal"}
              >
                الأدوار
              </Button>
            )}
          </div>
        </div>

        <UsersTable
          fetchedData={fetchedData}
          loading={loading}
          pageNumber={page}
          setPageNumber={setPage}
          currentPage={rowsPerPage}
          setCurrentPage={setRowsPerPage}
          page_count={fetchedData?.page_count}
        />
      </div>
    </>
  );
};

export default Users;
