import React from "react";
/* Components */
import MostProducts from "./MostProducts/MostProducts";
import MostMarkets from "./MostMarkets/MostMarkets";

const ProductsInfo = ({ more_product_visit, more_store_visit, loading }) => {
  return (
    <div className="flex flex-wrap gap-4 mt-5 md:mt-8">
      <div className="flex-1 w-full md:w-4/12">
        <MostProducts
          more_product_visit={more_product_visit}
          loading={loading}
        />
      </div>
      <div className="flex-1 w-full md:w-5/12">
        <MostMarkets more_store_visit={more_store_visit} loading={loading} />
      </div>
    </div>
  );
};

export default ProductsInfo;
