import React from "react";

/* Custom Button */
import Button from "../../UI/Button/Button";
/* Icons */
import { ActionAdd } from "../../assets/Icons";
import { useNavigate } from "react-router-dom";

const AcademyButtons = ({ selectedTab, permissions }) => {
	const navigate = useNavigate();

	return (
		<div className='flex w-full gap-2 md:w-auto'>
			{selectedTab === 1
				? permissions?.includes("admin.course.store") && (
						<Button
							className={
								"md:w-[180px] w-full md:h-[56px] h-[45px] md:text-[20px] text-[18px] flex justify-center items-center"
							}
							type={"outline"}
							style={{ borderColor: "#02466A" }}
							textStyle={{ color: "#02466A" }}
							svg={<ActionAdd fill='#02466A' />}
							onClick={() => {
								navigate("/add-new-course");
							}}>
							اضافة دورة
						</Button>
				  )
				: selectedTab === 2
				? permissions?.includes("admin.livecourse.store") && (
						<Button
							className={
								"md:w-[230px] w-full md:h-[56px] h-[45px] md:text-[20px] text-[18px] flex justify-center items-center"
							}
							type={"outline"}
							style={{ borderColor: "#02466A" }}
							textStyle={{ color: "#02466A" }}
							svg={<ActionAdd fill='#02466A' />}
							onClick={() => {
								navigate("/add-new-live-course");
							}}>
							اضافة دورة مباشرة
						</Button>
				  )
				: selectedTab === 3
				? permissions?.includes("admin.explainvideo.store") && (
						<Button
							className={
								"md:w-[180px] w-full md:h-[56px] h-[45px] md:text-[20px] text-[18px] flex justify-center items-center"
							}
							type={"outline"}
							style={{ borderColor: "#02466A" }}
							textStyle={{ color: "#02466A" }}
							svg={<ActionAdd fill='#02466A' />}
							onClick={() => {
								navigate("/add-lesson");
							}}>
							اضافة درس
						</Button>
				  )
				: null}
		</div>
	);
};

export default AcademyButtons;
