import React, { useState, useContext, useEffect } from "react";
/* Third party */
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";

import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
/* Icons */
import { MdFileUpload } from "react-icons/md";
import { useBannerUpdateMutation } from "../../../../RTK/apiSlices/templateApi";

// change sliders and banners Switch styles
const switchStyles = {
  width: "35px",
  padding: 0,
  height: "20px",
  borderRadius: "0.75rem",
  "& .MuiSwitch-thumb": {
    width: "12px",
    height: "12px",
  },
  "& .MuiSwitch-switchBase": {
    padding: "0",
    top: "4px",
    left: "4px",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    left: "-4px",
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "#FFFFFF",
  },
  "& .MuiSwitch-track": {
    height: "100%",
  },
  "&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#3AE374",
    opacity: 1,
  },
};

const ChangeBannerSec = ({ fetchedData, loading }) => {
  const LoadingStore = useContext(LoadingContext);
  const { setLoadingTitle } = LoadingStore;

  // check  switch status
  const [bannerstatus1, setBannerStatus1] = useState(true);
  const [banner1, setBanner1] = useState("");
  const [bannerstatus2, setBannerStatus2] = useState(true);
  const [banner2, setBanner2] = useState("");
  const [bannerstatus3, setBannerStatus3] = useState(true);
  const [banner3, setBanner3] = useState("");

  // change switch status
  useEffect(() => {
    setBannerStatus1(
      fetchedData?.Homepages?.banarstatus1 === "active" ? true : false
    );
    setBanner1(fetchedData?.Homepages?.banar1);
    setBannerStatus2(
      fetchedData?.Homepages?.banarstatus2 === "active" ? true : false
    );
    setBanner2(fetchedData?.Homepages?.banar2);
    setBannerStatus3(
      fetchedData?.Homepages?.banarstatus3 === "active" ? true : false
    );
    setBanner3(fetchedData?.Homepages?.banar3);
  }, [
    fetchedData?.Homepages?.banarstatus1,
    fetchedData?.Homepages?.banar1,
    fetchedData?.Homepages?.banarstatus2,
    fetchedData?.Homepages?.banar2,
    fetchedData?.Homepages?.banarstatus3,
    fetchedData?.Homepages?.banar3,
  ]);

  // TO UPLOAD BANNERS
  const [firstimage, setFirstImage] = useState([]);
  const [secondimage, setSecondImage] = useState([]);
  const [thirdimage, setThirdImage] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const maxFileSize = 1 * 1024 * 1024; // 1 MB;

  // to handle width and height of banners
  const handleImageUpload =
    (
      bannerIndex,
      bannerState,
      setBannerState,
      setPreviewBannerState,
      width,
      height
    ) =>
    async (imageList) => {
      // Check if the image size is valid
      const isSizeValid = imageList?.every(
        (image) => image?.file?.size <= maxFileSize
      );

      // If the image size is not valid
      if (!isSizeValid) {
        // Display a warning message and reset the logo state
        toast.warning("حجم البانر يجب أن لا يزيد عن 1 ميجابايت.", {
          theme: "light",
        });
        return;
      }

      const dimensionsErrorMessage = `مقاس البنر يجب أن يكون ${width} بكسل عرض و ${height} بكسل ارتفاع.`;

      const checkImageDimensions = (image) =>
        new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            if (img?.width !== width && img?.height !== height) {
              toast.warning(dimensionsErrorMessage, {
                theme: "light",
              });
              //  if the image dimensions is not valid
              resolve(false);
            } else {
              resolve(true);
            }
          };
          img.src = image?.data_url;
        });

      const isValidDimensions = await Promise?.all(
        imageList?.map(checkImageDimensions)
      ).then((results) => results?.every((result) => result));

      // if the isValidDimensions and  imageSize >= maxFileSize return
      if (!isValidDimensions) {
        return;
      }

      if (!isValidDimensions) {
        toast.warning(dimensionsErrorMessage, {
          theme: "light",
        });
      } else {
        const updatedSliderState = [...bannerState];
        updatedSliderState[bannerIndex] = imageList;
        setBannerState(updatedSliderState);
        setPreviewBannerState(imageList);

        const updatedNameState = updatedSliderState[bannerIndex];
        const bannerNames = [setFirstImage, setSecondImage, setThirdImage];

        if (bannerNames[bannerIndex]) {
          bannerNames[bannerIndex](updatedNameState);
        }
      }
    };

  const [bannerUpdate] = useBannerUpdateMutation();
  const bannerUpdateHandel = async (formData) => {
    try {
      const res = await bannerUpdate({
        body: formData,
      });
      if (res?.data?.data?.status === 200) {
        setLoadingTitle(null);
      } else {
        setLoadingTitle(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ADD Banners FUNCTION
  const addBanners = () => {
    setLoadingTitle("جاري تعديل البنرات");
    const formData = new FormData();
    formData.append("banar1", firstimage[0]?.file || banner1 || null);
    formData.append("banar2", secondimage[0]?.file || banner2 || null);
    formData.append("banar3", thirdimage[0]?.file || banner3 || null);
    formData.append("banarstatus1", bannerstatus1 ? "active" : "not_active");
    formData.append("banarstatus2", bannerstatus2 ? "active" : "not_active");
    formData.append("banarstatus3", bannerstatus3 ? "active" : "not_active");
    bannerUpdateHandel(formData);
  };
  return (
    <div
      className="mt-8 rounded-lg shadow-md"
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div
        className="px-3 py-2 rounded-t-lg md:p-5"
        style={{ backgroundColor: "#F7FCFF", border: "1px solid #EFEFEF" }}
      >
        <h2 className="md:text-xl text-[16px] font-medium flex items-center whitespace-nowrap flex-wrap gap-3">
          البنرات الإعلانية
          <span className="md:text-base text-[14px] font-normal text-[#67747B] whitespace-pre-line">
            (تستطيع تغيير الصورة التي تظهر كإعلانات في وسط الموقع وبين الأقسام)
          </span>
        </h2>
      </div>
      <div className="flex flex-col max-w-full px-4 lg:py-10 xl:pr-24 py-7">
        <ImageUploading
          value={firstimage}
          onChange={handleImageUpload(
            0,
            firstimage,
            setFirstImage,
            setPreviewImage,
            755,
            340
          )}
          maxNumber={2}
          dataURLKey="data_url"
          acceptType={["jpg", "png", "jpeg"]}
        >
          {({ onImageUpload }) => (
            // write your building UI
            <div className="relative max-w-full upload__image-wrapper ">
              <div className="w-full image-item">
                <div className="flex">
                  <div className="xl:w-[274px] w-0"></div>
                  <div
                    style={{ height: "166px", width: "572px" }}
                    className="flex flex-col items-center justify-center gap-6 "
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {(previewImage[0] || banner1) && (
                          <img
                            src={
                              previewImage[0]?.data_url ||
                              banner1 ||
                              "https://placehold.co/755x340"
                            }
                            alt="preview-img"
                            className="object-contain w-full h-full"
                            loading="lazy"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex flex-col items-start lg:flex-row lg:items-center gap-y-2">
                    <h2 className="lg:text-center font-normal text-lg text-[#67747B] lg:w-[274px] w-full">
                      بانر إعلاني رقم 1 (340 * 755)
                    </h2>
                    <div className="flex flex-col max-w-full gap-2">
                      <span className="text-gray-400 font-light text-[14px]">
                        الحد الأقصى للبانر 1MB
                      </span>
                      <div className="flex flex-row items-center max-w-full">
                        <div
                          onClick={() => {
                            onImageUpload();
                          }}
                          className="flex items-center justify-between p-4 rounded cursor-pointer h-11"
                          style={{
                            backgroundColor: "#FAFAFA",
                            border: "1px dashed #E0E0E0",
                            width: "572px",
                            overflow: "hidden",
                          }}
                        >
                          {firstimage?.[0]?.file ? (
                            <div className="" style={{ width: "55px" }}>
                              <img
                                src={URL.createObjectURL(firstimage?.[0]?.file)}
                                alt=""
                                className="img-fluid"
                                loading="lazy"
                              />
                            </div>
                          ) : banner1 ? (
                            <div className="" style={{ width: "55px" }}>
                              <img
                                src={banner1}
                                alt=""
                                className=" img-fluid"
                                loading="lazy"
                              />
                            </div>
                          ) : (
                            <h2
                              className="text-base font-medium"
                              style={{ color: "#67747B" }}
                            >
                              تحديث البانر
                            </h2>
                          )}
                          <MdFileUpload
                            color="#02466A"
                            size={"1.25rem"}
                          ></MdFileUpload>
                        </div>
                        <div className="mr-4">
                          <Switch
                            sx={switchStyles}
                            checked={bannerstatus1}
                            onChange={() => setBannerStatus1(!bannerstatus1)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ImageUploading>
        <ImageUploading
          value={secondimage}
          onChange={handleImageUpload(
            1,
            secondimage,
            setSecondImage,
            setPreviewImage,
            755,
            340
          )}
          maxNumber={2}
          dataURLKey="data_url"
          acceptType={["jpg", "png", "jpeg"]}
        >
          {({ onImageUpload }) => (
            // write your building UI
            <div className="relative max-w-full upload__image-wrapper ">
              <div className="w-full image-item">
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex flex-col items-start lg:flex-row lg:items-center gap-y-2">
                    <h2 className="lg:text-center font-normal text-lg text-[#67747B] lg:w-[274px] w-full">
                      بانر إعلاني رقم 2 (340 * 755)
                    </h2>
                    <div className="flex flex-row items-center max-w-full">
                      <div
                        onClick={() => {
                          onImageUpload();
                        }}
                        className="flex items-center justify-between p-4 rounded cursor-pointer h-11"
                        style={{
                          backgroundColor: "#FAFAFA",
                          border: "1px dashed #E0E0E0",
                          width: "572px",
                          overflow: "hidden",
                        }}
                      >
                        {secondimage?.[0]?.file ? (
                          <div className="" style={{ width: "55px" }}>
                            <img
                              src={URL.createObjectURL(secondimage?.[0]?.file)}
                              alt=""
                              className="img-fluid"
                              loading="lazy"
                            />
                          </div>
                        ) : banner2 ? (
                          <div className="" style={{ width: "55px" }}>
                            <img
                              src={banner2}
                              alt=""
                              className=" img-fluid"
                              loading="lazy"
                            />
                          </div>
                        ) : (
                          <h2
                            className="text-base font-medium"
                            style={{ color: "#67747B" }}
                          >
                            تحديث البانر
                          </h2>
                        )}
                        <MdFileUpload
                          color="#02466A"
                          size={"1.25rem"}
                        ></MdFileUpload>
                      </div>
                      <div className="mr-4">
                        <Switch
                          sx={switchStyles}
                          checked={bannerstatus2}
                          onChange={() => setBannerStatus2(!bannerstatus2)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ImageUploading>
        <ImageUploading
          value={thirdimage}
          onChange={handleImageUpload(
            2,
            thirdimage,
            setThirdImage,
            setPreviewImage,
            1920,
            530
          )}
          maxNumber={2}
          dataURLKey="data_url"
          acceptType={["jpg", "png", "jpeg"]}
        >
          {({ onImageUpload }) => (
            // write your building UI
            <div className="relative max-w-full upload__image-wrapper ">
              <div className="w-full image-item">
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex flex-col items-start lg:flex-row lg:items-center gap-y-2">
                    <h2 className="lg:text-center font-normal text-lg text-[#67747B] lg:w-[274px] w-full">
                      بانر إعلاني رقم 3 (530 * 1920)
                    </h2>
                    <div className="flex flex-row items-center max-w-full">
                      <div
                        onClick={() => {
                          onImageUpload();
                        }}
                        className="flex items-center justify-between p-4 rounded cursor-pointer h-11"
                        style={{
                          backgroundColor: "#FAFAFA",
                          border: "1px dashed #E0E0E0",
                          width: "572px",
                          overflow: "hidden",
                        }}
                      >
                        {thirdimage?.[0]?.file ? (
                          <div className="" style={{ width: "55px" }}>
                            <img
                              src={URL.createObjectURL(thirdimage?.[0]?.file)}
                              alt=""
                              className="img-fluid"
                              loading="lazy"
                            />
                          </div>
                        ) : banner3 ? (
                          <div className="" style={{ width: "55px" }}>
                            <img
                              src={banner3}
                              alt=""
                              className=" img-fluid"
                              loading="lazy"
                            />
                          </div>
                        ) : (
                          <h2
                            className="text-base font-medium"
                            style={{ color: "#67747B" }}
                          >
                            تحديث البانر ( البنر الذي يظهر أسفل المتاجر )
                          </h2>
                        )}
                        <MdFileUpload
                          color="#02466A"
                          size={"1.25rem"}
                        ></MdFileUpload>
                      </div>
                      <div className="mr-4">
                        <Switch
                          sx={switchStyles}
                          checked={bannerstatus3}
                          onChange={() => setBannerStatus3(!bannerstatus3)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ImageUploading>
        <Button
          className={
            "mx-auto mt-9   md:w-1/4 w-11/12 md:h-14 h-[45px] md:text-2xl text-xl"
          }
          fontSize={"text-2xl font-thin"}
          style={{ backgroundColor: "#02466A" }}
          type={"normal"}
          onClick={addBanners}
        >
          حفظ
        </Button>
      </div>
    </div>
  );
};

export default ChangeBannerSec;
