import React, { useState, useContext } from "react";
/* Third party */
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* MUI */
import CircularProgress from "@mui/material/CircularProgress";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { MdFileUpload } from "react-icons/md";
import { useLogoUpdateMutation } from "../../../../RTK/apiSlices/templateApi";

const ChangeLogoSec = ({ fetchedData, loading }) => {
  const LoadingStore = useContext(LoadingContext);
  const { setLoadingTitle } = LoadingStore;

  // to update logo
  const [images, setImages] = useState([]);
  const maxFileSize = 1 * 1024 * 1024; // 1 MB;

  // Function to handle changes in the logo
  const onChangeStoreLogo = (imageList, addUpdateIndex) => {
    // Check if the image size is valid
    const isSizeValid = imageList?.every(
      (image) => image?.file?.size <= maxFileSize
    );

    // If the image size is not valid
    if (!isSizeValid) {
      // Display a warning message and reset the logo state
      toast.warning("حجم الشعار يجب أن لا يزيد عن 1 ميجابايت.", {
        theme: "light",
      });
      setImages([]);
      return;
    }

    // Check if the image dimensions are valid
    imageList?.every((image) => {
      const img = new Image();

      // Set the event listener to check dimensions once the image is loaded
      img.onload = () => {
        if (img.width !== 160 && img.height !== 114) {
          // If dimensions are not valid, display a warning and reset the logo state
          toast.warning(
            "مقاس الشعار يجب أن يكون  160 بكسل عرض 114 بكسل ارتفاع.",
            { theme: "light" }
          );
          setImages([]);
        }
      };
      img.src = image?.data_url;
      return true; // Returning true because the actual check is done in the onload event
    });

    // If all checks are valid, update the state
    setImages(imageList);
  };

  const [logoUpdate] = useLogoUpdateMutation();
  const logoUpdateHandel = async (formData) => {
    try {
      const res = await logoUpdate({
        body: formData,
      });
      if (res?.data?.data?.status === 200) {
        setLoadingTitle(null);
      } else {
        setLoadingTitle(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ADD LOGO FUNCTION
  const addNewLogo = () => {
    setLoadingTitle("جاري تعديل الشعار");
    const formData = new FormData();
    formData.append("logo", images[0]?.file || "");
    logoUpdateHandel(formData);
  };

  return (
    <div
      className="mt-8 rounded-lg shadow-md "
      style={{ backgroundColor: "#FFFFFF" }}
    >
      <div
        className="px-3 py-2 rounded-t-lg md:p-5"
        style={{ backgroundColor: "#F7FCFF", border: "1px solid #EFEFEF" }}
      >
        <h2 className="md:text-xl text-[16px] font-medium flex items-center flex-wrap gap-3">
          الشعار
          <span className="md:text-base text-[14px] font-normal text-[#67747B]">
            (تستطيع تغيير الشعار الذي يظهر في الهيدر في الصفحة الرئيسية)
          </span>
        </h2>
      </div>
      <div className="flex flex-col items-center max-w-full px-4 py-7 md:px-0">
        <ImageUploading
          value={images}
          onChange={onChangeStoreLogo}
          maxNumber={2}
          dataURLKey="data_url"
          acceptType={["jpg", "png", "jpeg"]}
          disabled={true}
        >
          {({ onImageUpload }) => (
            // write your building UI
            <div
              className="relative max-w-full upload__image-wrapper "
              style={{
                width: "572px",
              }}
            >
              <div className="w-full image-item ">
                <div
                  style={{ border: " 1px dashed #02466A", height: "137px" }}
                  className="flex flex-col items-center justify-center w-full gap-6 p-4 rounded-lg"
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {!images[0] && (
                        <img
                          className="object-contain w-full h-full"
                          src={fetchedData?.Homepages?.logo}
                          alt={fetchedData?.Homepages?.logo}
                          loading="lazy"
                        />
                      )}
                      {images[0] && (
                        <img
                          src={images[0]?.data_url}
                          alt=""
                          className="object-contain w-full h-full"
                          loading="lazy"
                        />
                      )}
                    </>
                  )}
                </div>
                <span className="text-gray-400 text-[14px] my-2">
                  الحد الأقصى للشعار 1MB
                </span>
                <div
                  onClick={() => {
                    onImageUpload();
                  }}
                  className="flex items-center justify-between p-4 mt-2 rounded cursor-pointer h-11"
                  style={{
                    backgroundColor: "#FAFAFA",
                    border: "1px dashed #E0E0E0",
                  }}
                >
                  <h2 className="text-base font-medium">
                    تحديث الشعار{" "}
                    <span className="text-sm text-gray-400">
                      (المقاس الانسب 160 بكسل عرض 114 بكسل الارتفاع)
                    </span>
                  </h2>
                  <MdFileUpload color="#02466A" size={"1.25rem"}></MdFileUpload>
                </div>
              </div>
              <Button
                className={
                  "mx-auto mt-9   md:w-1/2 w-11/12 md:h-14 h-[45px] md:text-2xl text-xl"
                }
                fontSize={"text-2xl font-thin"}
                style={{ backgroundColor: "#02466A" }}
                type={"normal"}
                onClick={addNewLogo}
              >
                حفظ
              </Button>
            </div>
          )}
        </ImageUploading>
      </div>
    </div>
  );
};

export default ChangeLogoSec;
