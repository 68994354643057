import React, { useEffect, useContext, Fragment, useState } from "react";
import styles from "./VerificationTableSec.module.css";
import PropTypes from "prop-types";
/* Third party */
import moment from "moment";
/* Context */
import Context from "../../../../store/context";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import {
	Delete,
	SendNote,
	EditButton,
	InfoIcon,
	CheckedSquare,
	SortIcon,
} from "../../../../assets/Icons/index";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
	useSearchInVerificationMutation,
	useVerificationDeleteAllMutation,
	useVerificationDeleteItemMutation,
} from "../../../../RTK/apiSlices/verificationApi";
import NewPagination from "../../../PaginationComp/NewPagination";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "8rem",
	},
	{
		id: "date",
		numeric: true,
		disablePadding: false,
		label: "التاريخ",
		sort: true,
		sortLabel: "verification_date",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		textAlign: "center",
		sortLabel: "verification_status",
	},
	{
		id: "activity",
		numeric: false,
		disablePadding: false,

		label: "رقم الجوال",
	},
	{
		id: "store",
		numeric: true,
		disablePadding: false,
		paddingRight: "60px",
		label: "اسم المتجر",
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#ebebebd9" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							fontSize: "1rem",
							color: "#02466A",
							whiteSpace: "nowrap",
							paddingRight: headCell.paddingRight,
							textAlign: headCell?.textAlign,
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		rowCount,
		onSelectAllClick,
		search,
		setSearch,

		itemsSelected,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 mb-4 md:mb-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.contrastText,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className={styles.search}>
				<input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='البحث باسم المتجر أو رقم الجوال أو البريد الالكترونى'
				/>
				<AiOutlineSearch color='#898989' size={"18px"}></AiOutlineSearch>
			</div>
			<div className='flex flex-wrap items-end justify-end flex-1 gap-4 p-0 mb-2 sm:flex-row md:flex-nowrap'>
				{permissions?.includes("admin.verification.deleteall") && (
					<div className='flex flex-row items-center justify-center gap-2'>
						{numSelected > 0 && (
							<div
								className='flex flex-row items-center justify-center cursor-pointer'
								style={{
									width: "130px",
									height: "40px",
									backgroundColor: "#FF38381A",
									borderRadius: "20px",
								}}
								onClick={() => {
									setNotificationTitle(
										"سيتم حذف جميع طلبات التوثيق التي قمت بتحديدها"
									);
									setItems(itemsSelected);
									setActionType("deleteAll");
								}}>
								<h6
									style={{ color: "#FF3838" }}
									className='md:text-[18px] text-[16px] font-medium'>
									حذف الكل
								</h6>
								<Delete />
							</div>
						)}
					</div>
				)}
				<div className='flex items-center'>
					<h2 className='md:text-[18px] text-[16px] font-medium'>تحديد الكل</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#011723",
							"& .MuiSvgIcon-root": {
								color: "#011723",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	openTraderAlert,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const navigate = useNavigate();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = stores?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const [verificationDeleteAll] = useVerificationDeleteAllMutation();

	const verificationDeleteAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await verificationDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [verificationDeleteItem] = useVerificationDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await verificationDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - stores?.length) : 0;

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	let stores = fetchedData?.stores || [];
	if (search !== "") {
		stores = searchResults?.stores;
	} else {
		stores = fetchedData?.stores;
	}

	const [searchInVerification, { isLoading: searchIsLoading }] =
		useSearchInVerificationMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInVerification({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(stores);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);
	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					backgroundColor: "transparent",
					width: "100%",
					mb: 2,
					boxShadow: "0 0",
				}}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={stores?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table
						sx={{
							minWidth: 750,
							backgroundColor: "#ffffff",
							marginBottom: "3rem",
						}}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={stores?.length || 0}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{stores?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(stores, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}>
														<TableCell id={labelId} className='min-w-[10rem]'>
															<div className='flex items-center gap-2'>
																{permissions?.includes(
																	"admin.verification.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف التوثيق وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.verification.verification_update"
																) && (
																	<EditButton
																		title='تعديل'
																		className='cursor-pointer'
																		onClick={() => {
																			navigate(
																				`/verification/edit-verification/${row?.id}`
																			);
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.verification.addNote"
																) && (
																	<SendNote
																		title='ارسال ملاحظة'
																		className='cursor-pointer'
																		onClick={() => {
																			openTraderAlert({
																				id: row?.id,
																				name: row?.store_name,
																			});
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.verification.show"
																) && (
																	<InfoIcon
																		title='تفاصيل'
																		className='cursor-pointer'
																		onClick={() => {
																			navigate(
																				`/verification/show-verification/${row?.id}`
																			);
																		}}
																	/>
																)}
															</div>
														</TableCell>
														<TableCell align='right'>
															<h2
																className='md:text-[18px] text-[16px] whitespace-nowrap'
																style={{ color: "#4D4F5C" }}>
																{moment(row?.verification_date).format(
																	"YYYY-MM-DD"
																)}
															</h2>
														</TableCell>
														<TableCell align='center'>
															<div
																className='h-full py-1 w-28 rounded-2xl'
																style={{
																	backgroundColor:
																		row?.verification_status === "تم التوثيق"
																			? "#3AE374"
																			: row?.verification_status ===
																			  "جاري التوثيق"
																			? "#FFDD0066"
																			: row?.verification_status === "طلب جديد"
																			? "#1DBBBE66"
																			: "#ff00008a",
																	margin: "auto",
																}}>
																<h2
																	style={{
																		color: "#4D4F5C",
																		fontSize: "16px",
																		whiteSpace: "nowrap",
																	}}>
																	{row?.verification_status}
																</h2>
															</div>
														</TableCell>
														<TableCell align='center'>
															<h2
																style={{
																	direction: "ltr",
																}}
																className='md:text-[16px] text-[14px] inline text-[#4D4F5C] font-normal'>
																{row?.phonenumber}
															</h2>
														</TableCell>
														<TableCell align='right' style={{ width: "200px" }}>
															<div className='flex flex-row items-center justify-end gap-3'>
																<h2
																	style={{
																		color: "#4D4F5C",
																		fontSize: "18px",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		whiteSpace: "nowrap",
																		direction: "rtl",
																		width: "200px",
																	}}>
																	{row.store_name}
																</h2>
																<div
																	className='img_icons'
																	style={{
																		minWidth: "35px",
																		minHeight: "35px",
																		maxWidth: "35px",
																		maxHeight: "35px",
																	}}>
																	<img
																		src={
																			row?.logo ? row?.logo : PlaceholderImage
																		}
																		alt={row?.store_name}
																		loading='lazy'
																		style={{
																			width: "100%",
																			height: "100%",
																			objectFit: "cover",
																			borderRadius: "50%",
																		}}
																	/>
																</div>
															</div>
														</TableCell>
														<TableCell
															align='right'
															style={{ color: "#4D4F5C", fontSize: "18px" }}>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<Checkbox
																checkedIcon={<CheckedSquare />}
																sx={{
																	color: "#1DBBBE",
																	"& .MuiSvgIcon-root": {
																		color: "#ADB5B9",
																	},
																}}
																checked={isItemSelected}
																onClick={(event) => handleClick(event, row.id)}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{!search && (
				<>
					<NewPagination
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						page_count={page_count}
					/>
				</>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}

			{notificationTitle && (
				<DeleteModalComp handleDeleteAllItems={verificationDeleteAllHandel} />
			)}
		</Box>
	);
}
