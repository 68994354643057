import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const profileApi = createApi({
  reducerPath: "profileApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Profile"],

  endpoints: (builder) => ({
    // Get Profile Data
    profileData: builder.query({
      query: () => ({ url: `profile` }),

      transformResponse: (response) => response.data,
      providesTags: ["Profile"],
    }),

    // Edit Profile Data
    editProfileData: builder.mutation({
      query: ({ body }) => ({
        url: `profile`,
        method: "POST",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

// Export endpoints and hooks
export const { useProfileDataQuery, useEditProfileDataMutation } = profileApi;
