import React from "react";
/* Icons */
import {
	StoreGreen,
	CountDown,
	CountDownGry,
} from "../../../../assets/Icons/index";

const QuickDetails = ({ quickInfo }) => {
	const INITIAL_DETAILS = [
		{
			id: 1,
			icon: <StoreGreen />,
			bgColor: "#d2f7e3",
			title: " متجر جديد ",
			num: quickInfo?.last24HoursOfStores || 0,
			time: "Last 24 Hours",
		},
		{
			id: 2,
			icon: <CountDown />,
			bgColor: "#eaf0d6",
			title: " طلب في الانتظار ",
			num: quickInfo?.last24HoursOfPendingOrders || 0,
			time: "Last 24 Hours",
		},
		{
			id: 3,
			icon: <CountDownGry />,
			bgColor: "#f0f0f7",
			title: " طلب مكتمل ",
			num: quickInfo?.last24HoursOfCompleteOrders || 0,
			time: "Last 24 Hours",
		},
		{
			id: 4,
			icon: <StoreGreen />,
			bgColor: "#d2f7e3",
			title: " تاجر جديد ",
			num: quickInfo?.lastMonthOfStores || 0,
			time: "Last Month",
		},
		{
			id: 5,
			icon: <CountDownGry />,
			bgColor: "#f0f0f7",
			title: " طلب مكتمل ",
			num: quickInfo?.lastMonthOfCompleteOrders || 0,
			time: "Last Month",
		},
	];
	return (
		<div className='flex-1 rounded-lg shadow-lg'>
			<div
				className='flex items-center h-12 pr-4 rounded-tl-lg rounded-tr-lg'
				style={{ backgroundColor: "#02466A33" }}>
				<h2 className='font-medium text-[#02466A] md:text-[18px] text-[16px]'>
					تفاصيل سريعة
				</h2>
			</div>
			<div className='bg-white rounded-bl-lg rounded-br-lg'>
				{INITIAL_DETAILS?.length === 0 ? (
					<div className='flex justify-center w-100 h-100 align-center'>
						<p> لا يوجد تفاصيل حتي الآن</p>
					</div>
				) : (
					INITIAL_DETAILS?.map((item, index) => {
						return (
							<div
								key={item.id}
								className={`${
									index === INITIAL_DETAILS.length - 1
										? "flex justify-between md:h-24 h-16 items-center p-4 rounded-bl-lg rounded-br-lg  bg-[#F7FCFF] "
										: "flex justify-between md:h-24 h-16 items-center p-4 shadow-[0px_3px_6px_#02466A0F] bg-[#F7FCFF] mb-2 "
								}`}>
								<div className='flex'>
									<div
										className='flex items-center justify-center w-10 h-10 p-1 ml-3 rounded-full'
										style={{ backgroundColor: `${item?.bgColor}` }}>
										{item?.icon}
									</div>
									<div className='flex items-center justify-center'>
										<h2 className='font-medium md:text-[18px] text-[16px]'>
											{item?.num} {item?.title}
										</h2>
									</div>
								</div>
								<div className='my-auto'>
									<h2 className='md:text-[16px] text-[14px] text-[#4D4F5C]'>
										{item?.time}
									</h2>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};

export default QuickDetails;
