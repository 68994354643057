import React, { useState, useContext } from "react";
import styles from "./AddApi.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Components */
import { LoadingContext } from "../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoMdCloudUpload } from "react-icons/io";
import { useAddApiPlatformMutation } from "../../../RTK/apiSlices/platformApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const AddApi = () => {
	const navigate = useNavigate();

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			link: "",
		},
	});

	const [addApiPlatform] = useAddApiPlatformMutation();

	const addApiPlatformHandel = async (formData) => {
		try {
			const res = await addApiPlatform({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/general_souq");
			} else {
				setLoadingTitle(null);

				setApiDataError({
					name: res?.data?.message?.en?.name?.[0],
					link: res?.data?.message?.en?.link?.[0],
					image: res?.data?.message?.en?.logo?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.link?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.logo?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleApi = (data) => {
		resetData();
		let formData = new FormData();
		formData.append("name", data?.name);
		formData.append("link", data?.link);
		if (images.length !== 0) {
			formData.append("logo", images[0]?.file || null);
		}
		setLoadingTitle("جاري حفظ ال API");
		addApiPlatformHandel(formData);
	};

	const [apiDataError, setApiDataError] = useState({
		name: "",
		link: "",
		image: "",
	});
	const resetData = () => {
		setApiDataError({
			name: "",
			link: "",
			image: "",
		});
	};
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setApiDataError({
				...apiDataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setApiDataError({ ...apiDataError, image: null });
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | اضافة API</title>
			</Helmet>
			<BackDrop onClick={() => navigate("/general_souq")} />
			<div
				className='absolute z-20 flex flex-col overflow-hidden rounded-lg top-5 translate-x-2/4 right-2/4'
				style={{ width: "60.25rem", maxWidth: "90%" }}>
				<div
					className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'
					style={{ backgroundColor: "#1DBBBE" }}>
					<h2 className='text-slate-50 md:text-xl text-[16px] text-center'>
						اضافة API
					</h2>
					<IoMdCloseCircleOutline
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={() => navigate("/general_souq")}></IoMdCloseCircleOutline>
				</div>
				<form onSubmit={handleSubmit(handleApi)} className='h-full'>
					<div
						className='flex-1 p-4 py-8 md:pt-16 md:pb-12'
						style={{ backgroundColor: "#F6F6F6" }}>
						<div className='flex flex-col mb-5'>
							<ImageUploading
								value={images}
								onChange={onChange}
								maxNumber={2}
								dataURLKey='data_url'
								acceptType={["jpg", "png", "jpeg"]}>
								{({ onImageUpload, dragProps }) => (
									// write your building UI
									<div
										className='max-w-full md:h-[170px] h-[118px] md:w-[376px] w-[300px] upload__image-wrapper mx-auto relative overflow-hidden'
										style={{
											border: images[0] ? "none" : "1px dashed #02466A",
											borderRadius: "10px",
											strokeDasharray: "'6%2c5'",
										}}
										onClick={() => {
											onImageUpload();
										}}
										{...dragProps}>
										<div className='w-full h-full cursor-pointer image-item'>
											{!images[0] && (
												<div className='flex flex-col items-center justify-center w-full h-full gap-2 md:gap-3'>
													<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
													<h2 className='font-semibold'>
														أضف الشعار المطلوب
														<span className='text-red-500'>*</span>
													</h2>
													<div className='flex flex-col items-center gap-1'>
														<h2 className='mb-0'>
															(سيتم قبول الصور png & jpg & jpeg)
														</h2>
														<span className='text-gray-400 font-light text-[14px]'>
															(الحد الأقصى للصورة 1MB)
														</span>
													</div>
												</div>
											)}
											{images[0] && (
												<img
													src={images[0]?.data_url}
													alt=''
													className='object-contain w-full h-full'
													loading='lazy'
												/>
											)}
										</div>
									</div>
								)}
							</ImageUploading>
							<span className='text-red-500 text-[16px] text-center'>
								{apiDataError?.image}
							</span>
						</div>
						<div
							className={` w-full mx-auto gap-4 justify-center ${styles.inputs}`}>
							<div className='mb-5'>
								<input
									className=' w-full md:h-14 h-[45px] p-4 text-center outline-none'
									style={{ backgroundColor: "#EBEBEB" }}
									placeholder={"اسم المنصة*"}
									type='text'
									name='name'
									{...register("name", {
										required: "حقل الاسم مطلوب",
										pattern: {
											value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
											message: "يجب ان يتكون الاسم من أحرف",
										},
									})}
								/>
								<span className='text-red-500 text-[16px]'>
									{apiDataError?.name}
									{errors?.name && errors.name.message}
								</span>
							</div>
							<div className='mb-5'>
								<input
									className=' w-full md:h-14 h-[45px] p-4 text-center outline-none'
									style={{ backgroundColor: "#EBEBEB" }}
									placeholder={"لصق رابط Api*"}
									type='text'
									name='link'
									{...register("link", {
										required: "حقل الرابط مطلوب",
										pattern: {
											value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
											message: "صيغة الرابط يجب ان تكون صحيحة",
										},
									})}
								/>
								<span className='text-red-500 text-[16px]'>
									{apiDataError?.link}
									{errors?.link && errors.link.message}
								</span>
							</div>
							<Button
								btnType={"submit"}
								type={"normal"}
								className={"text-center w-full md:h-14 h-[45px]"}>
								حفظ
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddApi;
