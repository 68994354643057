import React from "react";

// Icons

import { HiOutlineMail } from "react-icons/hi";
import { FaUserAlt, FaAddressBook } from "react-icons/fa";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import {
  FaPhone,
  FaBuildingCircleArrowRight,
  FaTreeCity,
} from "react-icons/fa6";

const ClientInfo = ({ order, translateProvinceName, translateCityName }) => {
  return (
    <div className="p-4 mb-8 bg-white shadow-sm rounded-xl clint-details">
      <h3 className="mb-5 text-2xl font-medium text-gray-900 ">
        بيانات العميل
      </h3>
      <div className="flex flex-col flex-wrap items-center justify-start gap-4 mb-5 md:flex-row boxes">
        {order?.user?.owner_name && (
          <div className="md:w-[49%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <FaUserAlt className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">اسم العميل</span>
            </div>
            <h5 className="md:min-h-[60px] min-h-[48px] shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.user?.owner_name}
            </h5>
          </div>
        )}
        {order?.user?.phonenumber && (
          <div className="md:w-[49%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <FaPhone className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">رقم الهاتف</span>
            </div>
            <h5 className="md:min-h-[60px] min-h-[48px] shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.user?.phonenumber?.startsWith("+966")
                ? order?.user?.phonenumber?.slice(4)
                : order?.user?.phonenumber?.startsWith("00966")
                ? order?.user?.phonenumber.slice(5)
                : order?.user?.phonenumber}
              <span style={{ direction: "ltr", display: "inline-block" }}>
                +966
              </span>
            </h5>
          </div>
        )}
        {order?.user?.email && (
          <div className="md:w-[49%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <HiOutlineMail className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">البريد الالكتروني</span>
            </div>
            <h5 className="md:min-h-[60px] min-h-[48px] shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.user?.email}
            </h5>
          </div>
        )}
        {order?.OrderAddress?.district && (
          <div className="md:w-[49%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <FaBuildingCircleArrowRight className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">المنطقة</span>
            </div>
            <h5 className="md:min-h-[60px] min-h-[48px] shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {translateProvinceName(order?.OrderAddress?.district)}
            </h5>
          </div>
        )}
        {order?.OrderAddress?.city && (
          <div className="md:w-[49%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <FaTreeCity className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">المدينة</span>
            </div>

            <h5 className="md:min-h-[60px] min-h-[48px] shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {translateCityName(order?.OrderAddress?.city)}
            </h5>
          </div>
        )}
        {order?.OrderAddress?.postal_code && (
          <div className="sm:w-[35%] md:w-[50%] w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <HiOutlineClipboardDocumentCheck className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">الرمز البريدي</span>
            </div>

            <h5 className="md:min-h-[60px] min-h-[48px] shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.OrderAddress?.postal_code}
            </h5>
          </div>
        )}
        {order?.OrderAddress?.street_address && (
          <div className="md:w-[49%]  w-full">
            <div className="flex items-center justify-start gap-1 mb-1">
              <span className="flex items-center justify-center w-10 h-10 p-1 rounded-full ">
                <FaAddressBook className=" w-6 h-6 text-[#1DBBBE]" />
              </span>
              <span className="text-lg font-normal">العنوان</span>
            </div>

            <h5 className="md:min-h-[60px] min-h-[48px] flex justify-center items-center gap-1 shadow-sm md:rounded-md rounded-sm text-center text-lg font-normal bg-slate-50 md:py-4 py-2 border-[1px] border-[#f9f8f8]">
              {order?.OrderAddress?.street_address}
            </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientInfo;
