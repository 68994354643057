const CourseDuration = ({ duration }) => {
  // To check the hour, minutes, and seconds
  const [hours, minutes] = duration?.split(":");

  // Convert hours, minutes, and seconds values to integers
  const hoursValue = parseInt(hours, 10);
  const minutesValue = parseInt(minutes, 10);

  // Generate the formatted duration string
  let formattedDuration = "";

  // Handle hours
  if (hoursValue > 0) {
    formattedDuration += `${
      hoursValue === 1
        ? "ساعة"
        : hoursValue === 2
        ? "ساعتين"
        : hoursValue <= 10
        ? `ساعات ${hoursValue}`
        : `ساعة ${hoursValue}`
    } `;
  }

  // Handle minutes
  if (minutesValue > 0) {
    formattedDuration += `${formattedDuration ? "و " : ""} ${
      minutesValue <= 9 ? `${minutesValue} دقائق` : `${minutesValue} دقيقة`
    }  `;
  }

  return <>{formattedDuration}</>;
};

export default CourseDuration;
