import React, { useEffect } from "react";

// MUI
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Icons
import { IoIosArrowDown } from "react-icons/io";

const AddStoreAddress = ({
  error,
  shipping,
  currentOrder,
  setShipping,
  removeDuplicates,
  shippingCitiesData,
  getCityFromProvince,
  translateProvinceName,
}) => {
  // To handle the shipping information
  useEffect(() => {
    if (currentOrder?.orders?.shipping) {
      setShipping({
        ...shipping,
        district: currentOrder?.orders?.shipping?.destination_district,
        city: currentOrder?.orders?.shipping?.destination_city,
        address: currentOrder?.orders?.shipping?.destination_streetaddress,
        weight: currentOrder?.orders?.shipping?.weight,
      });
    }
  }, [currentOrder?.orders?.shipping]);

  return (
    <div class="p-4 mb-8 bg-white shadow-lg rounded-xl order-details">
      <h3 class="mb-5 text-2xl font-medium text-gray-900 ">
        اضافة بيانات الشحنة
      </h3>
      <div class="flex  flex-wrap items-start justify-start gap-4 mb-5 !print:flex-col flex-col  w-full">
        <div className="w-full mb-3 row mb-md-5">
          <div className="col-lg-3 col-md-3 col-12">
            <div class="flex items-center justify-start gap-1 mb-1">
              <span class="text-lg font-normal">
                المنطقة <span style={{ color: "red" }}>*</span>
              </span>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <Select
              name="district"
              value={shipping?.district || ""}
              onChange={(e) => {
                setShipping({
                  ...shipping,
                  district: e.target.value,
                });
              }}
              sx={{
                fontSize: "18px",
                width: "100%",
                backgroundColor: "#cce4ff38",
                boxShadow: "0 0 5px 0px #eded",
                "&.Mui-disabled": {
                  color: "#1f1f1f",
                },

                "& .MuiOutlinedInput-input": {
                  "&.Mui-disabled": {
                    "-webkit-text-fill-color": "#1f1f1f",
                  },
                },
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    paddingRight: "20px",
                  },
                "& .MuiOutlinedInput-root": {
                  "& :hover": {
                    border: "none",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  right: "95%",
                },
              }}
              IconComponent={IoIosArrowDown}
              displayEmpty
              disabled={
                currentOrder?.orders?.status === "ملغي" ||
                currentOrder?.orders?.status === "مكتمل"
                  ? true
                  : false
              }
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                if (!selected || shipping?.district === "") {
                  return <p className="text-[#ADB5B9]">اختر المنطقة</p>;
                }
                return translateProvinceName(selected);
              }}
            >
              {removeDuplicates(shippingCitiesData?.cities)?.map(
                (district, index) => {
                  return (
                    <MenuItem
                      key={index}
                      className="souq_storge_category_filter_items"
                      sx={{
                        backgroundColor: "rgba(211, 211, 211, 1)",
                        height: "3rem",
                        "&:hover": {},
                      }}
                      value={district?.region?.name_en || ""}
                    >
                      {district?.region?.name}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </div>
          <div className="col-lg-3 col-md-3 col-12"></div>
          <div className="col-lg-9 col-md-9 col-12">
            <span className="fs-6 text-danger">{error?.district}</span>
          </div>
        </div>
        <div className="w-full mb-3 row mb-md-5">
          <div className="col-lg-3 col-md-3 col-12">
            <div class="flex items-center justify-start gap-1 mb-1">
              <span class="text-lg font-normal">
                المدينة <span style={{ color: "red" }}>*</span>
              </span>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <Select
              name="category_id"
              value={shipping?.city || ""}
              onChange={(e) => {
                setShipping({
                  ...shipping,
                  city: e.target.value,
                });
              }}
              sx={{
                fontSize: "18px",
                width: "100%",
                backgroundColor: "#cce4ff38",
                boxShadow: "0 0 5px 0px #eded",
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    paddingRight: "20px",
                  },
                "&.Mui-disabled": {
                  color: "#1f1f1f",
                },

                "& .MuiOutlinedInput-input": {
                  "&.Mui-disabled": {
                    "-webkit-text-fill-color": "#1f1f1f",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "& :hover": {
                    border: "none",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  right: "95%",
                },
              }}
              IconComponent={IoIosArrowDown}
              displayEmpty
              disabled={
                currentOrder?.orders?.status === "ملغي" ||
                currentOrder?.orders?.status === "مكتمل"
                  ? true
                  : false
              }
              inputProps={{ "aria-label": "Without label" }}
              renderValue={(selected) => {
                if (!selected || shipping?.city === "") {
                  return <p className="text-[#ADB5B9]">اختر المدينة</p>;
                }
                const result =
                  getCityFromProvince?.filter(
                    (district) => district?.name_en === selected
                  ) || "";
                return result[0]?.name;
              }}
            >
              {getCityFromProvince?.map((city, index) => {
                return (
                  <MenuItem
                    key={index}
                    className="souq_storge_category_filter_items"
                    sx={{
                      backgroundColor: "rgba(211, 211, 211, 1)",
                      height: "3rem",
                      "&:hover": {},
                    }}
                    value={city?.name_en || ""}
                  >
                    {city?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="col-lg-3 col-md-3 col-12"></div>
          <div className="col-lg-9 col-md-9 col-12">
            <span className="fs-6 text-danger">{error?.city}</span>
          </div>
        </div>
        <div className="w-full mb-3 row mb-md-5">
          <div className="col-lg-3 col-md-3 col-12">
            <div class="flex items-center justify-start gap-1 mb-1">
              <span class="text-lg font-normal">
                العنوان <span style={{ color: "red" }}>*</span>
              </span>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <input
              disabled={
                currentOrder?.orders?.status === "ملغي" ||
                currentOrder?.orders?.status === "مكتمل"
                  ? true
                  : false
              }
              type="text"
              placeholder="عنوان الشحنة"
              name="name"
              value={shipping?.address || ""}
              onChange={(e) =>
                setShipping({
                  ...shipping,
                  address: e.target.value,
                })
              }
              style={{
                width: "100%",
                height: "56px",
                padding: "5px 1rem",
                backgroundColor: "#cce4ff38",
                boxShadow: "0 0 5px 0px #eded",
              }}
            />
          </div>
          <div className="col-lg-3 col-md-3 col-12"></div>
          <div className="col-lg-9 col-md-9 col-12">
            <span className="fs-6 text-danger">{error?.address}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStoreAddress;
