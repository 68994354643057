import React, { useContext } from "react";
import PropTypes from "prop-types";
/* Third party */
/* Contexts */
import Context from "../../../../../store/context";
import { DeleteContext } from "../../../../../store/DeleteProvider";
import { NotificationContext } from "../../../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../../UI/CircularLoading/CircularLoading";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import {
	SortIcon,
	Delete,
	EditButton,
	CheckedSquare,
} from "../../../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import NewPagination from "../../../../PaginationComp/NewPagination";
import {
	useCitiesDeleteAllByParamsMutation,
	useCitiesDeleteItemMutation,
} from "../../../../../RTK/apiSlices/citiesApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../../DeleteModal/DeleteModal";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},

	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		label: "اسم المدينة",
		sort: true,
		sortLabel: "name",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "رقم المدينة",
		sort: true,
		sortLabel: "code",
	},
	{
		id: "opened",
		numeric: true,
		disablePadding: false,
		label: "رقم الدولة",
	},

	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "rgba(29, 187, 190, 0.2)" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='font-medium md:text-[18px] text-[16px]'
						key={index}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#02466A",
							whiteSpace: "nowrap",

							"& .MuiButtonBase-root": {
								color: "#02466A",
							},
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				gap: "2rem",
				justifyContent: "flex-end",
			}}>
			<div
				className='gap-2 px-4 rounded-full fcc'
				style={{ backgroundColor: "rgba(255, 159, 26, 0.04)" }}></div>
			<div className='flex items-center gap-2'>
				{permissions?.includes("admin.city.deleteall") && numSelected > 0 && (
					<Tooltip
						onClick={() => {
							setNotificationTitle("سيتم حذف جميع المدن التي قمت بتحديدها");
							setItems(itemsSelected);
							setActionType("deleteAll");
						}}
						title='Delete'>
						<div
							className='rounded-full fcc'
							style={{
								width: "130px",
								backgroundColor: "rgba(255, 56, 56, 0.1)",
								cursor: "pointer",
							}}>
							<h2
								className={"font-medium md:text-[18px] text-[16px]"}
								style={{ color: "#FF3838" }}>
								حذف الكل
							</h2>
							<IconButton>
								<Delete
									style={{
										cursor: "pointer",
										color: "red",
										fontSize: "0.8rem",
									}}
								/>
							</IconButton>
						</div>
					</Tooltip>
				)}
			</div>

			<div className='flex items-center'>
				<h2 className='font-medium md:text-[18px] text-[16px]'>تحديد الكل</h2>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#011723",
						"& .MuiSvgIcon-root": {
							color: "#011723",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": " all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	data,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	search,
}) {
	const navigate = useNavigate();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = data?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

	const [citiesDeleteItem] = useCitiesDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await citiesDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const [citiesDeleteAllByParams] = useCitiesDeleteAllByParamsMutation();
	const citiesDeleteAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await citiesDeleteAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={data?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={data?.length || 0}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{data?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(data, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={index}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-2'>
																{permissions?.includes(
																	"admin.city.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف المدينة وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1.2rem",
																		}}></Delete>
																)}
																{permissions?.includes("admin.city.update") && (
																	<EditButton
																		title='تعديل'
																		onClick={() => {
																			navigate(`/cities/edit-city/${row?.id}`);
																		}}
																		style={{
																			cursor: "pointer",

																			fontSize: "1.2rem",
																		}}
																	/>
																)}
															</div>
														</TableCell>

														<TableCell align='right'>
															<h2
																onClick={() => {
																	if (
																		permissions?.includes("admin.city.show")
																	) {
																		navigate(`/cities/show-city/${row?.id}`);
																	}
																}}
																className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'>
																{row?.name}
															</h2>
														</TableCell>

														<TableCell align='right'>
															<h2 className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'>
																{row?.code}
															</h2>
														</TableCell>
														<TableCell align='right'>
															<h2 className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'>
																{row?.country?.code}
															</h2>
														</TableCell>

														<TableCell
															align='right'
															className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<Checkbox
																sx={{
																	color: "#1DBBBE",
																	"& .MuiSvgIcon-root": {
																		color: "#011723",
																	},
																}}
																checked={isItemSelected}
																onClick={(event) => handleClick(event, row.id)}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{search ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp handleDeleteAllItems={citiesDeleteAllByParamsHandel} />
			)}
		</Box>
	);
}
