import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const templateApi = createApi({
  reducerPath: "templateApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Template"],

  endpoints: (builder) => ({

    // Get Home Page Data
    getHomePageData: builder.query({
      query: () => ({url: `homepage`}),

      transformResponse: (response) => response.data,
      providesTags: ["Template"],
    }),

    logoUpdate: builder.mutation({
      query: ({ body }) => {
        return {
          url: `logoUpdate`,
          method: "POST",
          data: body,
        };
      },

      providesTags: ["Template"],
    }),
    
    bannerUpdate: builder.mutation({
      query: ({ body }) => {
        return {
          url: `banarUpdate`,
          method: "POST",
          data: body,
        };
      },

      providesTags: ["Template"],
    }),

    sliderUpdate: builder.mutation({
      query: ({ body }) => {
        return {
          url: `sliderUpdate`,
          method: "POST",
          data: body,
        };
      },

      providesTags: ["Template"],
    }),

    getSectionsData: builder.query({
      query: () => ({url: `section`}),

      transformResponse: (response) => response.data,
      providesTags: ["Sections"],
    }),

    sectionUpdate: builder.mutation({
      query: ({ body }) => {
        return {
          url: `sectionupdate`,
          method: "POST",
          data: body,
        };
      },

      providesTags: ["Sections"],
    }),

  }),
});

// Export endpoints and hooks
export const {
  useGetHomePageDataQuery,
  useLogoUpdateMutation,
  useBannerUpdateMutation,
  useSliderUpdateMutation,
  useGetSectionsDataQuery,
  useSectionUpdateMutation,
} = templateApi;
