import React, { useState, useContext, useEffect } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";

/* Icons */
import { BsImageAlt } from "react-icons/bs";
import { MdFileUpload } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useAddNewWebsiteSocialMediaMutation } from "../../../../RTK/apiSlices/websiteSocialMediaApi";

const BackDrop = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900"
    ></div>
  );
};

const AddNewLink = () => {
  const navigate = useNavigate();
  const LoadingStore = useContext(LoadingContext);
  const { setLoadingTitle } = LoadingStore;

  /** handle scroll to top when this page is opened */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // to upload new icon
  const [images, setImages] = useState([]);
  // handle images size
  const maxFileSize = 1 * 1024 * 1024; // 1 MB;
  const onChangeLogoImage = (imageList, addUpdateIndex) => {
    // Check image size before updating state
    const isSizeValid = imageList.every(
      (image) => image.file.size <= maxFileSize
    );

    if (!isSizeValid) {
      setDataError({
        ...DataError,
        logo: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
      });
      toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
        theme: "light",
      });
    } else {
      setImages(imageList);
      setDataError({ ...DataError, logo: null });
    }
  };

  // store values
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      link: "",
    },
  });

  const [DataError, setDataError] = useState({
    name: "",
    link: "",
    logo: "",
  });
  const resetData = () => {
    setDataError({
      name: "",
      link: "",
      logo: "",
    });
  };

  const [addNewWebsiteSocialMedia] = useAddNewWebsiteSocialMediaMutation();

  const addNewWebsiteSocialMediaHandel = async (formData) => {
    try {
      const res = await addNewWebsiteSocialMedia({
        body: formData,
      });
      if (res?.data?.success === true && res?.data?.data?.status === 200) {
        setLoadingTitle(null);
        navigate("/social_media");
      } else {
        setLoadingTitle(null);
        setDataError({
          name: res?.data?.message?.en?.name?.[0],
          link: res?.data?.message?.en?.link?.[0],
          logo: res?.data?.message?.en?.logo?.[0],
        });
        toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
        toast.error(res?.data?.message?.en?.link?.[0], { theme: "light" });
        toast.error(res?.data?.message?.en?.logo?.[0], { theme: "light" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddNewSocialLink = (data) => {
    resetData();
    setLoadingTitle("جاري اضافة رابط سوشيال ميديا");
    let formData = new FormData();
    formData.append("name", data?.name);
    formData.append("link", data?.link);
    if (images?.length !== 0) {
      formData.append("logo", images[0]?.file);
    }

    addNewWebsiteSocialMediaHandel(formData);
  };

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | اضافة رابط سوشيال ميديا</title>
      </Helmet>
      <BackDrop onClick={() => navigate("/social_media")} />
      <form onSubmit={handleSubmit(handleAddNewSocialLink)} className="h-full">
        <div
          className="absolute z-20 flex flex-col overflow-hidden rounded-lg top-28 translate-x-2/4 add_new_page_popup right-2/4"
          style={{ width: "1062px", maxWidth: "90%" }}
        >
          <div
            className="flex items-center justify-between w-full h-16 px-4 py-4 trader_alert"
            style={{ backgroundColor: "#F7FCFF" }}
          >
            <h2 className="md:text-[20px] text-[18px] text-center">
              اضافة رابط سوشيال ميديا
            </h2>
            <IoMdCloseCircleOutline
              size={"1.25rem"}
              color={"#02466a"}
              className={"cursor-pointer"}
              onClick={() => navigate("/social_media")}
            />
          </div>

          <div
            className="p-4 rounded-b-lg md:p-6"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <div className="flex flex-col items-center py-7">
              <ImageUploading
                value={images}
                onChange={onChangeLogoImage}
                maxNumber={2}
                dataURLKey="data_url"
                acceptType={["jpg", "png", "jpeg", "svg"]}
                disabled={true}
              >
                {({ onImageUpload }) => (
                  // write your building UI
                  <div className="md:w-[572px] w-full upload__image-wrapper relative ">
                    <div className="w-full image-item ">
                      <div
                        style={{
                          height: "137px",
                          width: "180px",
                          backgroundColor: "#D3D3D3",
                        }}
                        className="flex flex-col items-center justify-center gap-6 p-4 mx-auto rounded-lg"
                      >
                        {!images[0] && <BsImageAlt size={"1.5rem"} />}
                        {images[0] && (
                          <img
                            src={images[0]?.data_url}
                            alt=""
                            className="object-contain w-full h-full"
                            loading="lazy"
                          />
                        )}
                      </div>

                      <div className="flex flex-col gap-2 mt-6">
                        <span className="text-gray-400 font-light text-[14px]">
                          الحد الأقصى للصورة 1MB
                        </span>
                        <div
                          onClick={() => {
                            onImageUpload();
                          }}
                          className="flex items-center justify-between p-4 cursor-pointer"
                          style={{
                            backgroundColor: "#FAFAFA",
                            border: "1px dashed #E0E0E0",
                          }}
                        >
                          <h2 className="text-base font-medium">
                            شعار منصة السوشيال ميديا
                            <span className="text-red-500">*</span>
                          </h2>
                          <MdFileUpload
                            color="#02466A"
                            size={"1.25rem"}
                          ></MdFileUpload>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ImageUploading>
              <span className="text-red-500 text-[16px]">
                {DataError?.logo}
              </span>
            </div>
            <div className="md:w-[572px] w-full mt-10 mx-auto">
              <div className="mt-5">
                <h2 className="md:text-[18px] text-[16px] font-medium">
                  اسم منصة التواصل الاجتماعي
                  <span className="text-red-500">*</span>
                </h2>
                <div
                  className="md:h-14 h-[45px] flex gap-4 mt-3 p-5 items-center"
                  style={{
                    backgroundColor: "#EBEBEB",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  <input
                    style={{ backgroundColor: "transparent" }}
                    className="flex-1 outline-none"
                    placeholder={"ادخل اسم المنصة"}
                    type="text"
                    name="name"
                    {...register("name", {
                      required: "حقل الاسم مطلوب",
                      pattern: {
                        value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
                        message: "يجب ان يتكون الاسم من أحرف",
                      },
                    })}
                  />
                </div>
                <span className="text-red-500 text-[16px]">
                  {DataError?.name}
                  {errors?.name && errors.name.message}
                </span>
              </div>
              <div className="mt-5">
                <h2 className="md:text-[18px] text-[16px] font-medium">
                  ادخل رابط المنصة<span className="text-red-500">*</span>
                </h2>
                <div
                  className="md:h-14 h-[45px] flex gap-4 mt-3 p-5 items-center"
                  style={{
                    backgroundColor: "#EBEBEB",
                    border: "1px solid #D3D3D3",
                  }}
                >
                  <input
                    style={{ backgroundColor: "#EBEBEB" }}
                    className="flex-1 outline-none "
                    placeholder={"https://www.sample.com"}
                    type="text"
                    name="link"
                    {...register("link", {
                      required: "حقل الرابط مطلوب",
                      pattern: {
                        value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
                        message: "صيغة الرابط الرابط  غير صحيحة",
                      },
                    })}
                  />
                </div>
                <span className="text-red-500 text-[16px]">
                  {DataError?.link}
                  {errors?.link && errors.link.message}
                </span>
              </div>
            </div>

            <div className="flex items-center justify-center gap-8 my-10 md:my-20">
              <Button
                btnType={"submit"}
                className="md:h-14 h-[45px] md:w-[286px] w-full md:text-xl md:text-[18px] font-medium"
                style={{ minWidth: "fit-content", backgroundColor: "#02466A" }}
                type={"normal"}
              >
                نشر
              </Button>
            </div>
          </div>
          <div className="my-20"></div>
        </div>
      </form>
    </>
  );
};

export default AddNewLink;
