import React, { useState, useContext, Fragment } from "react";
import { Helmet } from "react-helmet";
import Context from "../../store/context";
import { NotificationContext } from "../../store/NotificationProvider";
import { DeleteContext } from "../../store/DeleteProvider";
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
import CircularLoading from "../../UI/CircularLoading/CircularLoading";
import ReviewDetails from "./ReviewDetails";
import PlaceholderImage from "../../assets/images/placeholderImage.png";
import NewPagination from "../../components/PaginationComp/NewPagination";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../components/DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../components/DeleteModal/DeleteModal";
import {
  useGetReviewsQuery,
  useReviewDeleteItemMutation,
  useReviewsChangeStatusAllByIdMutation,
  useReviewsChangeStatusAllByParamsMutation,
  useReviewsDeleteAllByParamsMutation,
} from "../../RTK/apiSlices/reviewsApi";
import ReviewsItems from "../../components/Reviews/ReviewsItems";
import ReviewOptions from "../../components/Reviews/ReviewOptions";

function ReviewsPgae() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: fetchedData, isLoading: loading } = useGetReviewsQuery({
    page,
    number: rowsPerPage,
  });

  const NotificationStore = useContext(NotificationContext);
  const DeleteStore = useContext(DeleteContext);
  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;
  const contextStore = useContext(Context);
  const { setEndActionTitle } = contextStore;
  const [showDetails, setShowDetails] = useState(false);
  const [idDetails, setIdDetails] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = fetchedData?.comment?.slice(itemOffset, endOffset);
  const { setItemId, setActionDelete, actionDelete } = DeleteStore;
  const { notificationTitle, setNotificationTitle, setActionType, setItems } =
    NotificationStore;
  const [selected, setSelected] = React.useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = currentItems?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const [reviewsChangeStatusAllByParams] =
    useReviewsChangeStatusAllByParamsMutation();
  const reviewsChangeStatusAllByParamsHandel = async (selected) => {
    const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
    try {
      await reviewsChangeStatusAllByParams({ queryParams })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setEndActionTitle(data.message.ar);
          }
        });
    } catch (err) {
      console.error("Failed to delete the category", err);
    }
  };
  const [reviewsDeleteAllByParams] = useReviewsDeleteAllByParamsMutation();
  const reviewsDeleteAllByParamsHandel = async (selected) => {
    const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
    try {
      await reviewsDeleteAllByParams({ queryParams })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setEndActionTitle(data.message.ar);
          }
        });
    } catch (err) {
      console.error("Failed to delete the category", err);
    }
  };
  const [reviewDeleteItem] = useReviewDeleteItemMutation();
  const handleDeleteSingleItem = async (id) => {
    try {
      await reviewDeleteItem({ id })
        .unwrap()
        .then((data) => {
          if (!data?.success) {
            toast.error(data?.message?.ar, {
              theme: "light",
            });
          } else {
            setEndActionTitle(data?.message?.ar);
          }
        });
    } catch (err) {
      console.error("Failed to delete the etlbha Product", err);
    }
  };
  const [reviewsChangeStatusAllById] = useReviewsChangeStatusAllByIdMutation();
  const publish = async (id) => {
    try {
      await reviewsChangeStatusAllById({ id })
        .unwrap()
        .then((data) => {
          if (data.success) {
            setEndActionTitle(data.message.ar);
          }
        });
    } catch (err) {
      console.error("Failed to delete the category", err);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>لوحة تحكم أطلبها | تقييمات المنصة</title>
      </Helmet>
      {showDetails && (
        <ReviewDetails
          id={idDetails}
          cancel={() => {
            setShowDetails(false);
            setIdDetails("");
          }}
        />
      )}
      <div
        className={`relative xl:pb-10 2xl:pl-36 2xl:pr-24 p-4`}
        style={{ backgroundColor: "#F7F7F7" }}
      >
        <h3
          style={{ color: "#011723" }}
          className="md:text-[24px] text-[20px] font-bold"
        >
          تقييمات المنصة
        </h3>
        <div className="mt-8">
          <ReviewOptions
            selected={selected}
            currentItems={currentItems}
            handleSelectAllClick={handleSelectAllClick}
            permissions={permissions}
            setNotificationTitle={setNotificationTitle}
            setItems={setItems}
            setActionType={setActionType}
          />
          <div className="flex flex-col flex-wrap gap-4 mt-4">
            {loading ? (
              <CircularLoading />
            ) : (
              currentItems?.map((box, index) => {
                const isItemSelected = isSelected(box?.id);
                return (
                  <ReviewsItems
                    index={index}
                    isItemSelected={isItemSelected}
                    handleClick={handleClick}
                    box={box}
                    PlaceholderImage={PlaceholderImage}
                    permissions={permissions}
                    publish={publish}
                    setShowDetails={setShowDetails}
                    setIdDetails={setIdDetails}
                    setActionDelete={setActionDelete}
                    setItemId={setItemId}
                  />
                );
              })
            )}
          </div>
          <NewPagination
            pageNumber={page}
            setPageNumber={setPage}
            currentPage={rowsPerPage}
            setCurrentPage={setRowsPerPage}
            page_count={fetchedData?.page_count}
          />
        </div>
      </div>
      {actionDelete && (
        <DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
      )}
      {notificationTitle && (
        <DeleteModalComp
          handleDeleteAllItems={reviewsDeleteAllByParamsHandel}
          handleChangeAllItemsStatus={reviewsChangeStatusAllByParamsHandel}
        />
      )}
    </Fragment>
  );
}

export default ReviewsPgae;
