import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const productsApi = createApi({
  reducerPath: "productsApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Products"],

  endpoints: (builder) => ({

    // Get Products
    getProducts: builder.query({
      query: (args) => ({
        url: `product?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["Products"],
    }),

    getProductData: builder.query({
      query: ({ id }) => ({
        url: `product/${id}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Products", id }],
    }),

    // Search In Products
    searchInProducts: builder.mutation({
      query: (arg) => ({
        url: `searchStoreProductName?query=${arg.query}`,
        method: "GET",
      }),
    }),

    //Add To Product Note
    addProductNote: builder.mutation({
      query: (data) => {
        return {
          url: `addProductNote`,
          method: "POST",
          data: data,
        };
      },
    }),

    productChangeStatusAll: builder.mutation({
      query: ({ id }) => ({
        url: `productchangeSatusall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Products"],
    }),
    
    productChangeSpecial: builder.mutation({
      query: ({ id }) => ({
        url: `productchangeSpecial/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Products"],
    }),

    productChangeStatusAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `productchangeSatusall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Products"],
    }),

    productChangeSpecialByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `productchangeSpecial?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Products"],
    }),

    productDeleteAll: builder.mutation({
      query: ({ queryParams }) => ({
        url: `productdeleteall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Products"],
    }),

    productDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `productdeleteall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Products"],
    }),



  }),
});

// Export endpoints and hooks
export const {
  useGetProductsQuery,
  useGetProductDataQuery,
  useSearchInProductsMutation,
  useAddProductNoteMutation,
  useProductChangeStatusAllMutation,
  useProductChangeSpecialMutation,
  useProductChangeStatusAllByParamsMutation,
  useProductChangeSpecialByParamsMutation,
  useProductDeleteAllMutation,
  useProductDeleteItemMutation,
} = productsApi;
