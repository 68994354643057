import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const categoriesApi = createApi({
  reducerPath: "categoriesApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Categories"],

  endpoints: (builder) => ({

    getCategories: builder.query({
      query: (args) => ({
        url: `category?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["Categories"],
    }),

    getCategoryData: builder.query({
      query: ({ categoryId }) => ({
        url: `category/${categoryId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Categories", id }],
    }),

    categoryChangeStatusAll: builder.mutation({
      query: ({ id }) => ({
        url: `categorychangeSatusall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Categories"],
    }),

    categoryDeleteAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `categorydeleteall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Categories"],
    }),

    categoryChangeStatusAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `categorychangeSatusall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Categories"],
    }),

    updateCategory: builder.mutation({
      query: ({ categoryId, body }) => {
        return {
          url: `category/${categoryId}`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Categories"],
    }),

    addNewCategory: builder.mutation({
      query: ({ body }) => {
        return {
          url: `category`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Categories"],
    }),

    categoryDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `categorydeleteall?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Categories"],
    }),

    filterCategory: builder.mutation({
      query: ({ number, id, page }) => ({
        url: `category?&number=${number}&category_id=${id}&page=${page}`,
				method: "GET",
			}),
		}),

    


  }),
});

// Export endpoints and hooks
export const {
  useGetCategoriesQuery,
  useGetCategoryDataQuery,
  useCategoryChangeStatusAllMutation,
  useCategoryDeleteAllByParamsMutation,
  useCategoryChangeStatusAllByParamsMutation,
  useUpdateCategoryMutation,
  useAddNewCategoryMutation,
  useCategoryDeleteItemMutation,
  useFilterCategoryMutation,
} = categoriesApi;
