import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const technicalSupportApi = createApi({
  reducerPath: "technicalSupportApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["TechnicalSupport"],

  endpoints: (builder) => ({
    getTechnicalSupport: builder.query({
      query: (args) => ({
        url: `technicalSupport?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["TechnicalSupport"],
    }),

    getTechnicalSupportData: builder.query({
      query: ({ technicalSupportId }) => ({
        url: `technicalSupport/${technicalSupportId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "TechnicalSupport", id }],
    }),

    searchInTechnicalSupport: builder.mutation({
      query: (arg) => ({
        url: `searchTechnicalSupport?query=${arg.query}`,
        method: "GET",
      }),
    }),

    technicalSupportDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `technicalSupport/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TechnicalSupport"],
    }),
    

  }),
});

// Export endpoints and hooks
export const {
  useGetTechnicalSupportQuery,
  useGetTechnicalSupportDataQuery,
  useSearchInTechnicalSupportMutation,
  useTechnicalSupportDeleteItemMutation,
} = technicalSupportApi;
