import React from "react";
/* Icons */
import { MdNewReleases } from "react-icons/md";
import { BestSeller, NotActivated, RunOut } from "../../assets/Icons";

const OrdersStats = ({ fetchedData }) => {
	const OrdersStatsData = [
		{
			id: 1,
			title: "الجديدة",
			icon: <MdNewReleases className='w-8 h-8 text-[#ff9f1a]' />,
			num: fetchedData?.newProducts || 0,
		},
		{
			id: 2,
			title: "الأكثر مبيعاً",
			icon: <BestSeller />,
			num: fetchedData?.more_sales || 0,
		},
		{
			id: 3,
			title: "الغير مفعلة",
			icon: <NotActivated />,
			num: fetchedData?.not_active_products || 0,
		},
		{
			id: 4,
			title: "على وشك النفاذ",
			icon: <RunOut />,
			num: fetchedData?.about_to_finish_products || 0,
		},
	];
	return (
		<div className='grid grid-cols-2 gap-4 mb-6 lg:grid-cols-4 '>
			{OrdersStatsData?.map((item) => (
				<div
					key={item.id}
					className='bg-[#F3FCFD] shadow-[0px_3px_6px_#0000000F] rounded-lg 
				text-center md:py-5 md:px-7 p-3'>
					<div className='flex items-center justify-center gap-3'>
						<span>{item.icon}</span>
						<h4 className='text-[#02466A] font-normal md:text-lg text-[16px] whitespace-nowrap'>
							{item.title}
						</h4>
					</div>
					<div className='text-[#02466A] font-medium md:text-[20px] text-[18px] whitespace-nowrap text-ellipsis'>
						{item.num}
					</div>
				</div>
			))}
		</div>
	);
};

export default OrdersStats;
