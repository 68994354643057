import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const reviewsApi = createApi({
  reducerPath: "reviewsApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Reviews"],

  endpoints: (builder) => ({
    getReviews: builder.query({
      query: (args) => ({
        url: `comment?page=${args.page}&number=${args.number}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: ["Reviews"],
    }),

    getReviewData: builder.query({
      query: ({ id }) => ({
        url: `comment/${id}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Reviews", id }],
    }),

    reviewsChangeStatusAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `commentchangeSatusall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Reviews"],
    }),

    reviewsChangeStatusAllById: builder.mutation({
      query: ({ id }) => ({
        url: `commentchangeSatusall?id[0]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Reviews"],
    }),

    reviewsDeleteAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `commentdeleteall?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["Reviews"],
    }),

    reviewDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `commentdeleteall?id[0]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Reviews"],
    }),



  }),
});

// Export endpoints and hooks
export const {
  useGetReviewsQuery,
  useGetReviewDataQuery,
  useReviewsChangeStatusAllByParamsMutation,
  useReviewsChangeStatusAllByIdMutation,
  useReviewsDeleteAllByParamsMutation,
  useReviewDeleteItemMutation,
} = reviewsApi;
