import React, { useContext, useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CountriesInfo from "../../../components/SettingComp/CountriesPageComp/CountriesInfo/CountriesInfo";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Icons */
import { AiOutlinePlus } from "react-icons/ai";
import { useGetCountriesQuery } from "../../../RTK/apiSlices/countriesApi";

const CountriesPages = () => {
  const navigate = useNavigate();
  const RolePermissionsStore = useContext(RolePermissionsContext);
  const { permissions } = RolePermissionsStore;

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: fetchedData, isLoading: loading } = useGetCountriesQuery({
    page,
    number: rowsPerPage,
  });

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | الدول</title>
      </Helmet>
      <div
        className={`xl:px-4 2xl:pl-36 2xl:pr-24 p-4`}
        style={{ backgroundColor: "#fafafa" }}
      >
        <div className="flex flex-col items-start justify-between md:flex-row md:items-center gap-y-4">
          <h2 className="md:text-2xl text-[20px] font-bold">جدول الدول</h2>
          {permissions?.includes("admin.country.store") && (
            <Button
              className="md:h-14 h-[45px] md:w-[213px] w-full md:text-[22px] text-[18px]"
              svg={<AiOutlinePlus color="#fff" className="w-5 h-5" />}
              type={"normal"}
              style={{ backgroundColor: "#B6BE34" }}
              fontSize={"font-medium"}
              onClick={() => {
                navigate("/countries/add-country");
              }}
            >
              اضافة دولة
            </Button>
          )}
        </div>

        <CountriesInfo
          fetchedData={fetchedData?.countries}
          loading={loading}
          pageNumber={page}
          setPageNumber={setPage}
          currentPage={rowsPerPage}
          setCurrentPage={setRowsPerPage}
          page_count={fetchedData?.page_count}
        ></CountriesInfo>
      </div>
    </>
  );
};

export default CountriesPages;
