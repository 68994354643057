import React, { useContext } from "react";
/* Third party */
import { useNavigate } from "react-router-dom";
import PlaceholderImage from "../../assets/images/placeholderImage.png";

/* Contexts */
import Context from "../../store/context";
import { DeleteContext } from "../../store/DeleteProvider";
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../UI/CircularLoading/CircularLoading";
/* MUI */
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
/* Icons */
import { Delete, EditButton } from "../../assets/Icons/index";
import {
	useChangeStatusPlatformMutation,
	usePlatformDeleteItemMutation,
} from "../../RTK/apiSlices/platformApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../DeleteOneModal/DeleteOneModal";

const Item = styled(Paper)(() => ({
	height: "100%",
}));

const MarketsApis = ({ fetchedData, loading }) => {
	const navigate = useNavigate();
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const [changeStatusPlatform] = useChangeStatusPlatformMutation();

	const changePlatformStatus = async (id) => {
		try {
			await changeStatusPlatform({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [platformDeleteItem] = usePlatformDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await platformDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the Platform", err);
		}
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				{loading ? (
					<div className='flex flex-col items-center w-full h-full'>
						<CircularLoading />
					</div>
				) : (
					<Grid container spacing={2}>
						{fetchedData?.map((item, index) => {
							return (
								<Grid
									key={index}
									sx={{
										height: "13.125rem",
										mb: "5.625rem",
										"@media(max-width:767px)": {
											mb: "8px",
										},
									}}
									item
									md={4}
									xs={6}>
									<Item className='flex flex-col overflow-hidden rounded-md'>
										<a
											className='flex-1 h-[140px] p-4 flex items-center justify-center'
											href={item?.link}
											target='_blank'
											rel='noreferrer'>
											<img
												className={`w-9/12 h-full object-contain ${
													item?.status === "نشط" ? "" : "grayscale"
												}`}
												src={item?.logo ? item?.logo : PlaceholderImage}
												alt={item?.name}
												loading='lazy'
											/>
										</a>

										<div
											className='flex items-center justify-center gap-2 h-14'
											style={{ backgroundColor: "rgb(32,125,175)" }}>
											{permissions?.includes(
												"admin.platform.changePlatformStatus"
											) && (
												<Switch
													title='تغيير الحالة'
													onChange={() => changePlatformStatus(item?.id)}
													sx={{
														width: "32px",
														padding: 0,
														height: "20px",
														borderRadius: "0.75rem",
														"& .MuiSwitch-thumb": {
															width: "12px",
															height: "12px",
														},
														"& .MuiSwitch-switchBase": {
															padding: "0",
															top: "4px",
															left: "4px",
														},
														"& .MuiSwitch-switchBase.Mui-checked": {
															left: "-4px",
														},
														"& .Mui-checked .MuiSwitch-thumb": {
															backgroundColor: "#FFFFFF",
														},
														"& .MuiSwitch-track": {
															height: "100%",
														},
														"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
															backgroundColor: "#3AE374",
															opacity: 1,
														},
													}}
													checked={item?.status === "نشط" ? true : false}
												/>
											)}
											{permissions?.includes("admin.platform.destroy") && (
												<button className='flex items-center justify-center w-8 h-8 rounded-full bg-slate-50'>
													<Delete
														title='حذف'
														onClick={() => {
															setActionDelete(
																"سيتم حذف ال API وهذة الخطوة غير قابلة للرجوع"
															);
															setItemId(item?.id);
														}}
														className='w-[1.2rem]'
														color={"red"}
													/>
												</button>
											)}

											{permissions?.includes("admin.platform.update") && (
												<button
													title='تعديل'
													onClick={() => {
														navigate(`/general_souq/edit-souq/${item?.id}`);
													}}
													className='flex items-center justify-center w-8 h-8 rounded-full bg-slate-50'>
													<EditButton className='w-[1.2rem]' />
												</button>
											)}
										</div>
									</Item>
								</Grid>
							);
						})}
					</Grid>
				)}
			</Box>
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</div>
	);
};

export default MarketsApis;
