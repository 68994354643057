import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// RTK Query
import {
	useDeleteLiveCourseMutation,
	useGetLiveCoursesDataQuery,
} from "../../../RTK/apiSlices/coursesApi";

// Permissions
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

// Context
import { DeleteContext } from "../../../store/DeleteProvider";

// Components
import { toast } from "react-toastify";
import NewPagination from "../../PaginationComp/NewPagination";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";

// Icons and images
import PlaceholderImage from "../../../assets/images/placeholderImage.png";
import { Delete, EditButton } from "../../../assets/Icons";

const LiveCourses = () => {
	const navigate = useNavigate();

	// courses data
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const { data: liveCourses, isLoading } = useGetLiveCoursesDataQuery({
		page,
		number: rowsPerPage,
	});

	// permissions
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	// --------------------------------------------------------

	// delete course
	const [deleteLiveCourse] = useDeleteLiveCourseMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await deleteLiveCourse({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<>
			<div className='mt-6 md:mt-12'>
				{isLoading ? (
					<div className='flex flex-col items-center justify-center'>
						<CircularLoading />
					</div>
				) : (
					<>
						{liveCourses?.courses?.length > 0 ? (
							<>
								<div className='grid grid-cols-1 gap-6 mb-12 md:grid-cols-2 lg:grid-cols-4 '>
									{liveCourses?.courses?.map((course, idx) => (
										<div
											key={idx}
											className='shadow-lg bg-white w-[95%] md:w-auto md:m-0  m-auto rounded-ss-lg rounded-t-lg'>
											<div className='flex flex-col justify-between'>
												<div className=' h-64 rounded-t-lg rounded-ss-lg'>
													<img
														className='rounded-t-lg rounded-ss-lg w-full h-full object-cover '
														src={
															course?.image ? course?.image : PlaceholderImage
														}
														alt={course?.name}
														loading='lazy'
													/>
												</div>
												<div className='flex flex-col  '>
													<h2 className='mt-1 border-y-[1px] border-y-slate-100 text-md font-medium text-center min-h-[60px] flex justify-center items-center'>
														{course?.name}
													</h2>

													<div
														className='flex flex-row items-center justify-center gap-5'
														style={{
															backgroundColor: "rgba(180, 237, 238, 0.2)",
															height: "52px",
														}}>
														{permissions?.includes(
															"admin.livecourse.update"
														) && (
															<EditButton
																title='تعديل'
																className='cursor-pointer'
																onClick={() => {
																	navigate(`/edit-live-course/${course?.id}`);
																}}
															/>
														)}
														{permissions?.includes(
															"admin.livecourse.destroy"
														) && (
															<Delete
																title='حذف'
																className='cursor-pointer'
																onClick={() => {
																	setActionDelete(
																		"سيتم حذف الدورة وهذة الخطوة غير قابلة للرجوع"
																	);
																	setItemId(course?.id);
																}}
															/>
														)}
													</div>
												</div>
											</div>
										</div>
									))}
								</div>

								{!isLoading && (
									<div dir='ltr'>
										<NewPagination
											pageNumber={page}
											setPageNumber={setPage}
											currentPage={rowsPerPage}
											setCurrentPage={setRowsPerPage}
											page_count={liveCourses?.page_count}
										/>
									</div>
								)}
							</>
						) : (
							<div className='flex flex-col items-center justify-center mt-20 text-lg'>
								لاتوجد دورات
							</div>
						)}
					</>
				)}
				{actionDelete && (
					<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
				)}
			</div>
		</>
	);
};

export default LiveCourses;
