import React, { useContext, useEffect, useState } from "react";
import styles from "./UserDetails.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Custom Button */
import Button from "../../../UI/Button/Button";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Icons */
import { HiOutlineMail } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import { useGetUserDataQuery } from "../../../RTK/apiSlices/usersApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const UserInfo = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	console.log(id);
	const { data: fetchedData, isLoading: loading } = useGetUserDataQuery({
		id,
	});

	const [userData, setUserData] = useState({
		image: "",
		name: "",
		email: "",
		phonenumber: "",
		role: "",
	});

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setUserData({
				...userData,
				image: fetchedData?.users?.image,
				name: fetchedData?.users?.name,
				email: fetchedData?.users?.email,
				phonenumber: fetchedData?.users?.phonenumber?.startsWith("+966")
					? fetchedData?.users?.phonenumber?.slice(4)
					: fetchedData?.users?.phonenumber?.startsWith("00966")
					? fetchedData?.users?.phonenumber.slice(5)
					: fetchedData?.users?.phonenumber,
				role: fetchedData?.users?.role?.name,
			});
		}
	}, [fetchedData]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | تفاصيل المستخدم</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/users");
				}}
			/>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-20 otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='md:p-8 p-5 md:h-[135px] h-[100px]'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2 className='font-bold md:text-2xl text-[18px] mb-3'>
								تفاصيل المستخدم
							</h2>
							<div className='flex'>
								<h2 className='font-medium md:text-lg text-[14px] md:ml-4 ml-1'>
									جدول المستخدمين
								</h2>

								<h3
									className='font-medium md:text-lg text-[14px]'
									style={{ color: "#7C7C7C" }}>
									/ تفاصيل مستخدم موظف في النظام
								</h3>
							</div>
						</div>
						{loading ? (
							<div className='flex flex-col items-center justify-center w-full h-full'>
								<CircularLoading />
							</div>
						) : (
							<>
								<div
									className={`flex-1 md:px-20 px-5 overflow-y-scroll md:py-12 py-5  ${styles.content}`}>
									<div className='flex flex-col-reverse justify-between md:flex-row'>
										<div className='flex flex-col items-center gap-4 md:flex-row md:items-start'>
											<div className='w-20 h-20 md:h-44 md:w-44'>
												<img
													src={userData?.image}
													alt='profile-img'
													loading='lazy'
												/>
											</div>
											<div>
												<h2 className='md:text-xl text-[18px] font-medium mb-3  text-center'>
													{userData?.name}
												</h2>
												<h2 className='md:text-lg text-[16px] font-normal mb-3 text-[#67747B] flex gap-2 items-center'>
													<HiOutlineMail
														style={{
															cursor: "pointer",
															color: "rgba(29, 187, 190, 1)",
															fontSize: "1.5rem",
														}}></HiOutlineMail>
													{userData?.email}
												</h2>
												<h2 className='md:text-lg text-[16px] font-normal mb-3 flex gap-2 items-center'>
													<IoIosCall
														style={{
															cursor: "pointer",
															color: "rgba(29, 187, 190, 1)",
															fontSize: "1.5rem",
														}}></IoIosCall>
													<span style={{ direction: "ltr" }}>
														{userData?.phonenumber}
													</span>
												</h2>
											</div>
										</div>
										<div>
											<Button
												btnType={"button"}
												className='md:h-14 h-[45px] md:w-[278px] w-full cursor-auto md:text-xl text-[18px] font-normal md:mb-0 mb-4'
												style={{
													backgroundColor: "rgba(2, 70, 106, 1)",
												}}
												type={"normal"}>
												{userData?.role}
											</Button>
										</div>
									</div>
								</div>
								<div
									className='md:h-[135px] h-[100px] p-8 flex justify-center gap-4'
									style={{
										backgroundColor: "rgba(235, 235, 235, 1)",
									}}>
									<Button
										style={{
											borderColor: `#02466A`,
										}}
										textStyle={{ color: "#02466A" }}
										className={"md:h-14 h-[45px] w-44"}
										type={"outline"}
										onClick={() => {
											navigate("/users");
										}}>
										اغلاق
									</Button>
								</div>
							</>
						)}
					</div>
				</form>
			</div>
		</>
	);
};

export default UserInfo;
