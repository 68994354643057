import React from "react";
/* Components */
import PieChartSec from "./PieChartSec/PieChartSec";

const ChartsComp = ({ fetchedData, loading }) => {
  return (
    <div className="xl:h-[190px] h-fit flex xl:flex-row flex-col gap-4">
      <div
        className="flex-1 flex items-center rounded-lg shadow-lg py-10 px-5"
        style={{ backgroundColor: "#DDE199" }}
      >
        <PieChartSec
          count_of_serivces_order={fetchedData?.count_of_serivces_order}
          design={fetchedData?.count_of_Design}
          technicalSupport={fetchedData?.count_of_TechnicalSupport}
          celebrities={fetchedData?.count_of_celebrities}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ChartsComp;
