import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const platformApi = createApi({
  reducerPath: "platformApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["Platform"],

  endpoints: (builder) => ({
    // Get Platform Data
    platformData: builder.query({
      query: () => ({ url: `platform` }),

      transformResponse: (response) => response.data,
      providesTags: ["Platform"],
    }),

    // Get Platform Details
    platformDetailsData: builder.query({
      query: ({ souqId }) => ({
        url: `platform/${souqId}`,
      }),

      transformResponse: (response) => response.data,
      providesTags: (result, error, id) => [{ type: "Platform", id }],
    }),

    // Add Platform
    addApiPlatform: builder.mutation({
      query: ({ body }) => {
        return {
          url: `platform`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["Platform"],
    }),

    // Edit Platform
    editApiPlatform: builder.mutation({
      query: ({ souqId, body }) => {
        return {
          url: `platform/${souqId}`,
          method: "POST",
          data: body,
        };
      },

      providesTags: (result, error, id) => [{ type: "Platform", id }],
    }),

    // Change Status Platform
    changeStatusPlatform: builder.mutation({
      query: ({ id }) => ({
        url: `changePlatformStatus/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Platform"],
    }),

    platformDeleteItem: builder.mutation({
			query: ({ id }) => ({
				url: `platform/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Platform"],
		}),

  }),
});

// Export endpoints and hooks
export const {
  usePlatformDataQuery,
  usePlatformDetailsDataQuery,
  useAddApiPlatformMutation,
  useEditApiPlatformMutation,
  useChangeStatusPlatformMutation,
  usePlatformDeleteItemMutation,
} = platformApi;
