import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../API/axiosBaseQuery";

// Create API slice
export const emailIndexApi = createApi({
  reducerPath: "emailIndexApi",

  baseQuery: axiosBaseQuery({
    baseUrl: "https://backend.atlbha.com/api/Admin/",
  }),
  tagTypes: ["EmailIndex"],

  endpoints: (builder) => ({
    getEmailIndex: builder.query({
      query: () => ({url: `EmailIndex`}),

      transformResponse: (response) => response.data,
      providesTags: ["EmailIndex"],
    }),

    addNewEmailIndex: builder.mutation({
      query: ({ body }) => {
        return {
          url: `addEmail`,
          method: "POST",
          data: body,
        };
      },

      invalidatesTags: ["EmailIndex"],
    }),

    emailIndexDeleteAllByParams: builder.mutation({
      query: ({ queryParams }) => ({
        url: `EmailDeleteAll?${queryParams}`,
        method: "GET",
      }),
      invalidatesTags: ["EmailIndex"],
    }),

    emailIndexDeleteItem: builder.mutation({
      query: ({ id }) => ({
        url: `EmailDeleteAll?id[]=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["EmailIndex"],
    }),
    




  }),
});

// Export endpoints and hooks
export const {
  useGetEmailIndexQuery,
  useAddNewEmailIndexMutation,
  useEmailIndexDeleteAllByParamsMutation,
  useEmailIndexDeleteItemMutation,
} = emailIndexApi;
