import React, { useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import VerificationTableSec from "../../../components/MarketsPagesComp/VerificationTable/VerificationTableSec/VerificationTableSec";
import TraderAlert from "../../../components/MarketsPagesComp/VerificationTable/TraderAlert/TraderAlert";
import { useGetVerificationQuery } from "../../../RTK/apiSlices/verificationApi";

const VerificationTable = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: fetchedData, isLoading: loading } = useGetVerificationQuery({
    page,
    number: rowsPerPage,
  });
  const [traderAlert, setTraderAlert] = useState(false);
  const [traderPackageDetails, setTraderPackageDetails] = useState({});

  return (
    <>
      <Helmet>
        <title>لوحة تحكم أطلبها | التوثيق</title>
      </Helmet>
      <div className="2xl:pl-36 p-4 lg:bg-[#fafafa] bg-[#ffffff]">
        <div className="flex items-center justify-between">
          <PageNavigate currentPage={"التوثيق"} parentPage={"المتاجر"} />
        </div>
        {traderAlert && (
          <TraderAlert
            cancel={() => {
              setTraderAlert(false);
            }}
            traderPackageDetails={traderPackageDetails}
          />
        )}

        <div className="mt-4 md:mt-8" dir="ltr">
          <VerificationTableSec
            fetchedData={fetchedData}
            loading={loading}
            openTraderAlert={({ id, name }) => {
              setTraderAlert(true);
              setTraderPackageDetails({ id, name });
            }}
            pageNumber={page}
            setPageNumber={setPage}
            currentPage={rowsPerPage}
            setCurrentPage={setRowsPerPage}
            page_count={fetchedData?.page_count}
          />
        </div>
      </div>
    </>
  );
};

export default VerificationTable;
